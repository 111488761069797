import React from "react";
import { IconButton, Button, Typography } from "@mui/material";

import LocationOnIcon from "@mui/icons-material/LocationOn";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

export default function MapButton({ showMap, setShowMap }) {
  const { isMobile } = useCustomMediaQuery();

  return (
    <>
      {setShowMap && (
        <>
          {isMobile ? (
            <IconButton
              sx={{
                mt: -1,
                ml: 1,
                border: "solid 1px",
                borderColor: showMap ? "primary.main" : "default",
                width: "25px",
                height: "25px",
              }}
              onClick={() => setShowMap(!showMap)}
            >
              <LocationOnIcon
                fontSize="small"
                color={showMap ? "primary" : "default"}
              />
            </IconButton>
          ) : (
            <>
              <Button
                variant="outlined"
                startIcon={<LocationOnIcon style={{ fontSize: 14 }} />}
                size="small"
                sx={{
                  p: 0,
                  px: 0.4,
                  ml: 1,
                  mt: -0.5,
                  color: showMap ? "primary" : "grey",
                  borderColor: showMap ? "primary" : "grey",
                  border: "solid 1px",
                }}
                onClick={() => setShowMap(!showMap)}
              >
                <Typography variant="caption" sx={{ mt: 0.2, ml: -0.2 }}>
                  Žemėlapyje
                </Typography>
              </Button>
            </>
          )}
        </>
      )}
    </>
  );
}
