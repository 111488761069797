export const emptyFilterParams = {
  municipality: "",
  town: "",
  suburb: "",
  street: "",
  min_price: "",
  max_price: "",
  price_from: "",
  price_to: "",
  min_size: "",
  max_size: "",
  area_from: "",
  area_to: "",
  min_rooms: "",
  max_rooms: "",
  rooms_from: "",
  rooms_to: "",
  min_plot_area: "",
  max_plot_area: "",

  min_floor: "",
  max_floor: "",
  min_floors: "",
  max_floors: "",
  not_highest_floor: "",
  min_year: "",
  max_year: "",
  renovated_only: "",
  include_renovated: "",
  building_type: "",
  heating: "",
  efficient_heating: "",
  heating_all: "",
  max_heating_bill: "",
  installation: "",
  condition: "",
  equipment: "",
  equipment_all: "",
  premises: "",
  premises_all: "",
  other_features: "",
  other_features_all: "",
  partial_amenities: "",
  partial_amenities_all: "",
  sale_type: "",
  sale_type_all: "",
  rent_type: "",
  rent_type_all: "",
  min_rent_period: "",
  long_term_rent_only: "",
  // ad_owner: "",

  house_type: "",
  plot_infrastructure: "",
  plot_infrastructure_all: "",
  water_sewage: "",
  water_sewage_all: "",
  parking: "",
  parking_all: "",
  security: "",
  security_all: "",
  recreation: "",
  recreation_all: "",

  plot_purpose: "",
  min_fertility_score: "",
  max_fertility_score: "",
  water_body: "",
  water_body_all: "",
  forest_type: "",
  forest_mature: "",
  forest_group: "",
  forest_group_all: "",
  is_logging_forest: "",

  premises_purpose: "",
  floor_from: "",
  floor_to: "",

  garage_type: "",
  garage_installation: "",
  garage_installation_all: "",

  floors_from: "",
  floors_to: "",

  owner_type: "",
  list_date: "",
};

export const nonCountableFilters = {
  heating_all: "",
  equipment_all: "",
  premises_all: "",
  other_features_all: "",
  partial_amenities_all: "",
  sale_type_all: "",
  rent_type_all: "",
  long_term_rent_only: "",
  plot_infrastructure_all: "",
  water_sewage_all: "",
  parking_all: "",
  security_all: "",
  recreation_all: "",
  water_body_all: "",
  forest_group_all: "",
  garage_installation_all: "",
};
