import {
  Box,
  Button,
  FormControl,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../../context";
import {
  fetchPaymentMethods,
  fetchPaymentPlans,
  initiatePayment,
} from "../fetchOrSendAdDraftData";
import { blue, grey } from "@mui/material/colors";
import { Form, Formik } from "formik";
import * as Yup from "yup";
// import FormPreview from "../utils/FormPreview";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import ClientInfoForm from "./ClientInfoForm";
import Spinner from "../../../utils/Spinner";
import FormikCheckbox from "../../SharedComponents/FormikCheckBox";
// import { crudClientInfo } from "../../Accounts/fetchOrSendAccountsData";

const validationSchema = Yup.object().shape({
  genericPlanId: Yup.number().required("Payment plan id is required"),
  adId: Yup.number().required("Ad id is required"),
  adDuration: Yup.number().required("Ad duration is required"),
  paymentSize: Yup.number().required("Payment size is required"),
  bankCode: Yup.string().required("Bank code is required"),
  extension: Yup.boolean().required(),
  client_type: Yup.string().required("Privaloma pasirinkti").nullable(),
  client_name: Yup.string()
    .when("client_type", {
      is: "enterprise",
      then: Yup.string().required("Įveskite įmonės pavadinimą"),
    })
    .when("client_type", {
      is: "private",
      then: Yup.string().required("Privalomas laukas"),
    }),
  client_address: Yup.string()
    .when("client_type", {
      is: "enterprise",
      then: Yup.string().required("Įveskite įmonės adresą"),
    })
    .when("client_type", {
      is: "private",
      then: Yup.string().required("Įveskite kliento adresą"),
    }),
  client_phone: Yup.string(),
  client_email: Yup.string().email("Netinkamas el. paštas"),
  company_code: Yup.string().when("client_type", {
    is: "enterprise",
    then: Yup.string().required("Įveskite įmonės kodą"),
  }),
  vat_code: Yup.string(),
  consent: Yup.boolean().oneOf([true], "Privalomas sutikimas"),
});

function FormStep3({ ad, handleBack, setOpen }) {
  const { isMobile } = useCustomMediaQuery();
  const { dispatchAdForm, adFormState, routerState, userState, dispatchUser } =
    useGlobalContext();
  const isAdForm = routerState.from === "createAd";
  const expirationDateString = ad?.properties?.expires_at;
  const expirationDate = !!expirationDateString
    ? new Date(expirationDateString)
    : undefined;
  const [durationOption, setDurationOption] = useState(
    adFormState.durationOption || null
  );
  const paymentPlans =
    adFormState.paymentPlans?.length >= 3 ? adFormState.paymentPlans : [];
  const initialPaymentPlan = paymentPlans.find(
    (plan) => plan.option === durationOption
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    adFormState.selectedPaymentMethod || null
  );
  const paymentMethods = adFormState.paymentMethods;
  let clientInfo = userState.clientInfo || {};

  const initialValues = {
    genericPlanId: initialPaymentPlan?.id || null,
    adId: ad.id,
    adDuration: initialPaymentPlan?.duration || null,
    paymentSize: initialPaymentPlan?.price || null,
    bankCode:
      adFormState.paymentMethods?.find(
        (paymentMethod) => paymentMethod.uiPosition === selectedPaymentMethod
      )?.code || null,
    extension: !isAdForm,
    client_type: clientInfo?.client_type || null,
    client_name: clientInfo?.client_name || "",
    client_address: clientInfo?.client_address || "",
    company_code: clientInfo?.company_code || "",
    vat_code: clientInfo?.vat_code || "",
    client_phone: clientInfo?.client_phone || "",
    client_email: clientInfo?.client_email || "",
    consent: false,
  };

  const [paymentSubmitted, setPaymentSubmitted] = useState(false);

  useEffect(() => {
    // refresh paymentPlans on each rendering
    if (!adFormState.paymenPlansLoading) {
      fetchPaymentPlans(dispatchAdForm);
    }
    if (!adFormState.paymentMethodsLoading && !adFormState.paymentMethodsLoaded)
      fetchPaymentMethods(dispatchAdForm);
  }, [
    adFormState.paymentMethodsLoaded,
    adFormState.paymentMethodsLoading,
    adFormState.paymenPlansLoading,
    // adFormState.paymentPlansLoaded,
    dispatchAdForm,
  ]);

  // useEffect(() => {
  //   if (!userState.clientInfoLoaded && !userState.clientInfoLoading)
  //     crudClientInfo(dispatchUser, userState.userId, "GET");
  // }, [userState.clientInfoLoaded]);

  function handleChangePaymentPlan(option, formik) {
    const paymentPlan = paymentPlans.find((plan) => plan.option === option);
    console.log("paymentPlan", paymentPlan);
    formik.setFieldValue("genericPlanId", paymentPlan.id);
    formik.setFieldValue("paymentSize", paymentPlan.price);
    formik.setFieldValue("adDuration", paymentPlan.duration);
    // formik.validateForm();
    // formik.setFieldError("adDuration", null);
    // formik.setFieldError("paymentSize", null);
    setDurationOption(option);
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { durationOption: option },
    });
  }

  function handleSelectPaymentMethod(uiPosition, formik) {
    const selectedPaymentMethod = adFormState.paymentMethods?.find(
      (paymentMethod) => paymentMethod.uiPosition === uiPosition
    );
    formik.setFieldValue("bankCode", selectedPaymentMethod?.code);
    setSelectedPaymentMethod(uiPosition);
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { selectedPaymentMethod: uiPosition },
    });
  }

  function handleSubmitPayment(values, errors) {
    setPaymentSubmitted(true);
    initiatePayment(values);
  }

  console.log("paymentMethods", paymentMethods);
  console.log("adFormState", adFormState);
  console.log("selectedPaymentMethod", selectedPaymentMethod);
  console.log("routerState from FormStep3", routerState);
  console.log("expirationDate from FormStep3", expirationDate);

  return (
    <>
      {(!adFormState.paymentMethodsLoaded ||
        !adFormState.paymentPlansLoaded) && (
        <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
          <Spinner />
        </Box>
      )}
      {!!adFormState.paymentMethodsLoaded &&
        !!adFormState.paymentPlansLoaded && (
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitPayment}
            validateOnChange={true}
          >
            {(formik) => (
              <Form>
                <Paper sx={{ mt: 2, p: 1 }} elevation={isAdForm ? 1 : 0}>
                  <Grid
                    container
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ my: 2 }}
                      color={
                        !!formik.errors.adDuration && formik.touched.adDuration
                          ? "error"
                          : "primary.text"
                      }
                    >
                      Pasirinkite skelbimo galiojimo trukmę
                    </Typography>
                    <Grid
                      container
                      spacing={isMobile ? 1 : 5}
                      sx={{ border: "none" }}
                    >
                      {paymentPlans.map((plan) => (
                        <Grid item xs={4} key={plan.option}>
                          <Paper
                            elevation={0}
                            sx={{
                              border:
                                durationOption === plan.option
                                  ? "solid lightblue 2px"
                                  : "solid lightgrey 1px",
                              height: "100px",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              "&:hover": {
                                bgcolor:
                                  durationOption !== plan.option && grey[100],
                                cursor: "pointer",
                              },
                              bgcolor:
                                durationOption === plan.option
                                  ? blue[50]
                                  : "background.paper",
                            }}
                            onClick={() =>
                              handleChangePaymentPlan(plan.option, formik)
                            }
                          >
                            <Typography variant="body1" fontWeight="bold">
                              {plan.duration} dienų
                            </Typography>
                            {plan.basic_price !== plan.price && (
                              <Typography
                                variant="body1"
                                color="lightgrey"
                                sx={{ textDecoration: "line-through" }}
                              >
                                {plan.basic_price} €
                              </Typography>
                            )}
                            <Typography variant="h6" color="primary">
                              {plan.price} €
                            </Typography>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>

                    {paymentPlans[0]?.basic_price !==
                      paymentPlans[0]?.price && (
                      <Grid container sx={{ border: "none", mt: 2 }}>
                        <LocalOfferOutlinedIcon
                          fontSize="small"
                          color="success"
                          sx={{ mx: 0.5 }}
                        />
                        <Typography
                          variant="body2"
                          sx={{ color: "success.main" }}
                        >
                          {paymentPlans[0].discount_description}
                        </Typography>
                      </Grid>
                    )}

                    {!!expirationDate && expirationDate > new Date() && (
                      <Grid container sx={{ border: "none", mt: 2 }}>
                        <Typography
                          variant={isMobile ? "caption" : "body2"}
                          sx={{ ml: 0.5 }}
                        >
                          Skelbimo galiojimas bus pratęstas nuo{" "}
                          {expirationDate.toISOString().split("T")[0]}.
                        </Typography>
                      </Grid>
                    )}

                    {!!paymentMethods?.length && (
                      <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid container item justifyContent="center">
                          <Typography
                            variant="subtitle1"
                            sx={{ mt: 1 }}
                            color={
                              !!formik.errors.bankCode &&
                              formik.touched.bankCode
                                ? "error"
                                : "primary.text"
                            }
                          >
                            Pasirinkite banką
                          </Typography>
                        </Grid>
                        {paymentMethods.map((paymentMethod) => (
                          <Grid
                            container
                            item
                            xs={6}
                            sm={4}
                            key={paymentMethod.uiPosition}
                            justifyContent="center"
                          >
                            <Paper
                              elevation={0}
                              sx={{
                                width: "170px",
                                height: "60px",
                                border:
                                  selectedPaymentMethod ===
                                  paymentMethod.uiPosition
                                    ? "solid lightblue 2px"
                                    : "solid lightgrey 1px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                "&:hover": {
                                  bgcolor:
                                    selectedPaymentMethod !==
                                      paymentMethod.uiPosition && grey[100],
                                  cursor: "pointer",
                                },
                                bgcolor:
                                  selectedPaymentMethod ===
                                  paymentMethod.uiPosition
                                    ? blue[50]
                                    : "background.paper",
                              }}
                              onClick={() =>
                                handleSelectPaymentMethod(
                                  paymentMethod.uiPosition,
                                  formik
                                )
                              }
                            >
                              <Box
                                component="img"
                                src={paymentMethod.logoUrl}
                                alt={paymentMethod.name}
                                width="100px"
                                // sx={{ bgcolor: "background.paper" }}
                              />
                            </Paper>
                          </Grid>
                        ))}
                      </Grid>
                    )}

                    <ClientInfoForm formik={formik} />
                  </Grid>
                  <Grid container justifyContent="center">
                    {/* <Grid item xs={12}> */}
                    <FormikCheckbox
                      name="consent"
                      label="Susipažinau ir sutinku su Privatumo politika ir Naudojimosi taisyklėmis"
                      sx={{ mt: 3, ml: 0, border: "none" }}
                    />
                  </Grid>

                  <Grid
                    container
                    sx={{
                      display: "flex",
                      justifyContent: isAdForm ? "space-between" : "right",
                      mt: 4,
                      border: "none",
                    }}
                  >
                    {isAdForm ? (
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Atgal
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="medium"
                        onClick={() => setOpen(false)}
                        sx={{ mr: 1 }}
                      >
                        Atšaukti
                      </Button>
                    )}

                    <Button
                      variant="contained"
                      size="medium"
                      type="submit"
                      disabled={paymentSubmitted}
                    >
                      Apmokėti
                    </Button>
                  </Grid>
                  {/* <FormPreview /> */}
                </Paper>
              </Form>
            )}
          </Formik>
        )}
    </>
  );
}

export default FormStep3;
