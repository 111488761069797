import React, { useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { initializeModels } from "./fetchOrSendManagementData";

export default function ModelInitialization() {
  const [disableButton, setDisableButton] = useState(false);

  async function handleModelInitialization() {
    let taskId = await initializeModels();
    if (!!taskId) {
      setDisableButton(true);
    }
  }

  return (
    <Paper
      elevation={1}
      sx={{ p: 2, my: 2, display: "block", justifyContent: "center" }}
    >
      <Grid container justifyContent="center" sx={{ border: "none" }}>
        <Grid item xs={12}>
          <Typography variant="h6" textAlign="center">
            Duomenų bazės modelių inicializacija
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" sx={{ my: 2 }} textAling="left">
            Užpildyti duomenų bazės modelius pradine informacija (pvz., šildymo
            tipai, įrangos variantai ir pan.)
          </Typography>
        </Grid>
        <Button
          variant="outlined"
          onClick={handleModelInitialization}
          disabled={disableButton}
        >
          Pildyti
        </Button>
      </Grid>
    </Paper>
  );
}
