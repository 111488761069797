import React, { useState } from "react";
import { IconButton, Menu, MenuItem, Typography } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { Box } from "@mui/system";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { changeContactStatus } from "../fetchOrSendDashboardData";
import CheckIcon from "@mui/icons-material/Check";
import { CHAT_STATUS_MENU_OPTIONS } from "../../../utils/defaults";
import ReportEnquirerDialog from "../MyAds/MyAdChat/ReportEnquirerDialog";

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default function ContactStatusSelector({ enquiry, index }) {
  const { dispatchDashboard, userState } = useGlobalContext();
  const isEnquirer = userState.userId === enquiry.enquirer;
  const chatStatusMenuOptions = isEnquirer
    ? CHAT_STATUS_MENU_OPTIONS.filter((option) => option.value !== "report")
    : CHAT_STATUS_MENU_OPTIONS;

  console.log("enquiry from ContactStatusSelector", enquiry);

  const [anchorElChatStatus, setAnchorElChatStatus] = useState(null);
  const [menuIndex, setMenuIndex] = useState(null);
  const [openEnquirerDialog, setOpenEnquirerDialog] = useState(false);

  function reportEnquirer(enquiry, option) {
    console.log("enquiry from reportEnquirer", enquiry);
    setOpenEnquirerDialog(true);
  }

  function handleStatusChange(e, enquiry, option) {
    e.stopPropagation();
    setAnchorElChatStatus(null);
    let newStatus = option.value;

    if (option.value === "report") {
      reportEnquirer(enquiry, option);
      return;
    }

    if (isEnquirer) {
      if (
        (enquiry.owner_status === "blocked" && option.value === "blocked") ||
        (enquiry.owner_status === "irrelevant" && option.value === "irrelevant")
      ) {
        newStatus = "relevant";
      }
    } else {
      if (
        (enquiry.enquirer_status === "blocked" && option.value === "blocked") ||
        (enquiry.enquirer_status === "irrelevant" &&
          option.value === "irrelevant")
      ) {
        newStatus = "relevant";
      }
    }

    console.log("option from handleStatusChange", option);

    let form = {
      id: enquiry.id,
      [isEnquirer ? "owner_status" : "enquirer_status"]: newStatus,
    };
    changeContactStatus(userState.userId, dispatchDashboard, form);
  }

  function openChatStatusMenu(e, index) {
    e.stopPropagation();
    setAnchorElChatStatus(e.currentTarget);
    setMenuIndex(index);
  }

  return (
    <Box sx={{ border: "none" }}>
      <IconButton onClick={(e) => openChatStatusMenu(e, index)}>
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorElChatStatus}
        open={!!anchorElChatStatus && menuIndex === index}
        onClose={(e) => {
          e.stopPropagation();
          setAnchorElChatStatus(null);
        }}
        {...menuProps}
      >
        {chatStatusMenuOptions.map((option, key) => (
          <MenuItem
            key={key}
            dense
            value={option.value}
            onClick={(e) => handleStatusChange(e, enquiry, option)}
          >
            {
              <CheckIcon
                fontSize="16px"
                sx={{
                  mr: 1,
                  visibility:
                    (enquiry[
                      isEnquirer ? "owner_status" : "enquirer_status"
                    ] === "irrelevant" &&
                      option.value === "irrelevant") ||
                    (enquiry[
                      isEnquirer ? "owner_status" : "enquirer_status"
                    ] === "blocked" &&
                      option.value === "blocked") ||
                    (!!userState?.enquirerComplaints?.find(
                      (complaint) =>
                        complaint.ad_id === enquiry.ad &&
                        complaint.enquirer_id === enquiry.enquirer
                    ) &&
                      option.value === "report")
                      ? "visible"
                      : "hidden",
                }}
                color="action"
              />
            }
            <Typography variant="caption">{option.label}</Typography>
          </MenuItem>
        ))}
      </Menu>
      <ReportEnquirerDialog
        open={openEnquirerDialog}
        setOpen={setOpenEnquirerDialog}
        enquiry={enquiry}
      />
    </Box>
  );
}
