import React from "react";
import Typography from "@mui/material/Typography";
import { Grid, Stack } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import {
  fetchSuburbOptions,
  fetchTownOptions,
  fetchStreetOptions,
  fetchGeoLocation,
} from "../../fetchOrSendAdDraftData";
import AdFormMap from "./AdFormMap";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";
// import FormPreview from "../../utils/FormPreview";
// import LocationOptionsProvider from "./LocationOptionsProvider";
import FormikDispatcher from "../../utils/FormikDispatcher";
import FormikRadioGroup from "../../../SharedComponents/FormikRadioGroup";
// import GeoLocation from "./GeoLocation";
import FormikAutocomplete from "../../../SharedComponents/FormikAutocomplete";
import { debounce } from "../../../../utils/debounce";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

function LocationPanel({ ad, handleSubmit, edit, formikRef, initiateSubmit }) {
  const { adFormState, dispatchAdForm } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const municipalityOptions = !!edit && [
    {
      value: ad.properties.municipality.id,
      label: ad.properties.municipality.name,
    },
  ];
  const townOptions = !!edit && [
    {
      value: ad.properties.town.id,
      label: ad.properties.town.name,
    },
  ];
  const suburbOptions = !!edit && [
    {
      value: ad.properties?.suburb?.id,
      label: ad.properties?.suburb?.name,
    },
  ];
  const streetOptions = !!edit && [
    {
      value: ad.properties?.street?.id,
      label: ad.properties?.street?.name,
    },
  ];

  const { isFlat, isHouse, isLand } = useAdType(ad);
  const panelName = "locationPanel";
  const initialValues =
    (!edit && adFormState?.formik && adFormState?.formik[panelName]) ||
    init.getLocationInitialValues(ad, adOptions);
  const validationSchema = schema.getLocationValidationSchema(
    adFormState.municipalityOptions,
    adOptions
  );
  const debouncedFetchGeoLocation = debounce(
    (formik, form) => fetchGeoLocation(formik, form),
    300
  );

  async function handleMunicipalityChange(municipality, formik) {
    let form = {
      ...formik.values,
      municipality,
      town: "",
      suburb: "",
      street: "",
      house: "",
    };
    formik.setFieldValue("town", "");
    formik.setFieldValue("suburb", "");
    formik.setFieldValue("street", "");
    formik.setFieldValue("house", "");
    formik.setFieldValue("flat", "");
    formik.setFieldValue("is_exact", "unknown");

    formik.setFieldTouched("town", false);
    formik.setFieldTouched("suburb", false);
    formik.setFieldTouched("street", false);
    formik.setFieldTouched("house", false);
    formik.setFieldTouched("flat", false);
    formik.setFieldTouched("is_exact", false);

    const townOptions = await fetchTownOptions(dispatchAdForm, form);
    const municipalityMainTown = adFormState.municipalityOptions.find(
      (option) => option.value === municipality
    )?.town;
    if (!!municipalityMainTown && !!townOptions.length) {
      let town = townOptions.find(
        (town) => town.label === municipalityMainTown
      ).value;
      form = { ...form, town };
      formik.setFieldValue("town", town);
      formik.setFieldTouched("town", false);
      fetchSuburbOptions(dispatchAdForm, form);
      fetchStreetOptions(dispatchAdForm, form);
    } else {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { suburbOptions: [], streetOptions: [] },
      });
    }
    // console.log("form from LocationPanel", form);
    fetchGeoLocation(formik, form);
  }

  function handleTownChange(town, formik) {
    let form = { ...formik.values, town, suburb: "", street: "", house: "" };
    formik.setFieldValue("suburb", "");
    formik.setFieldValue("street", "");
    formik.setFieldValue("house", "");
    formik.setFieldValue("flat", "");
    formik.setFieldValue("is_exact", "unknown");

    formik.setFieldTouched("suburb", false);
    formik.setFieldTouched("street", false);
    formik.setFieldTouched("house", false);
    formik.setFieldTouched("flat", false);
    formik.setFieldTouched("is_exact", false);

    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { suburbOptions: [], streetOptions: [] },
    });
    fetchSuburbOptions(dispatchAdForm, form);
    fetchStreetOptions(dispatchAdForm, form);
    fetchGeoLocation(formik, form);
  }

  function handleSuburbChange(suburb, formik) {
    let form = { ...formik.values, suburb, street: "", house: "" };
    formik.setFieldValue("street", "");
    formik.setFieldValue("house", "");
    formik.setFieldValue("flat", "");
    formik.setFieldValue("is_exact", "unknown");

    formik.setFieldTouched("street", false);
    formik.setFieldTouched("house", false);
    formik.setFieldTouched("flat", false);
    formik.setFieldTouched("is_exact", false);

    fetchGeoLocation(formik, form);
  }

  function handleStreetChange(street, formik) {
    let form = { ...formik.values, street, house: "" };
    formik.setFieldValue("house", "");
    formik.setFieldValue("flat", "");
    formik.setFieldValue("is_exact", "unknown");

    formik.setFieldTouched("house", false);
    formik.setFieldTouched("flat", false);
    formik.setFieldTouched("is_exact", false);

    fetchGeoLocation(formik, form);
  }

  function handleHouseChange(house, formik) {
    let form = { ...formik.values, house };
    formik.setFieldValue("flat", "");
    formik.setFieldValue("is_exact", "unknown");

    formik.setFieldTouched("flat", false);
    formik.setFieldTouched("is_exact", false);

    debouncedFetchGeoLocation(formik, form);
  }

  console.log("render LocationPanel");
  console.log("adFormState", adFormState);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1} sx={{ border: "none" }}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikAutocomplete
                name="municipality"
                label="Savivaldybė *"
                options={
                  !!edit ? municipalityOptions : adFormState.municipalityOptions
                }
                onChange={(value) => {
                  handleMunicipalityChange(value, formik);
                }}
                disabled={!!edit}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikAutocomplete
                name="town"
                label="Gyvenvietė *"
                options={!!edit ? townOptions : adFormState.townOptions}
                onChange={(value) => handleTownChange(value, formik)}
                disabled={!!edit || !adFormState.townOptions.length}
              />
            </Grid>
            {(!adFormState.suburbOptionsLoaded ||
              adFormState.suburbOptionsLoading ||
              !formik.values.town ||
              !!adFormState.suburbOptions?.length) && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikAutocomplete
                  name="suburb"
                  label="Mikrorajonas"
                  options={!!edit ? suburbOptions : adFormState.suburbOptions}
                  onChange={(value) => handleSuburbChange(value, formik)}
                  disabled={!!edit || !adFormState.suburbOptions.length}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikAutocomplete
                name="street"
                label="Gatvė"
                options={!!edit ? streetOptions : adFormState.streetOptions}
                onChange={(value) => handleStreetChange(value, formik)}
                disabled={!!edit || !adFormState.streetOptions.length}
              />
            </Grid>

            <Grid item xs={6} sm={3} sx={{ mt: 2, mb: 1 }}>
              <FormikTextField
                name="house"
                label={isLand ? "Sklypo Nr." : "Namo Nr."}
                onChange={(value) => handleHouseChange(value, formik)}
              />
            </Grid>
            {(isFlat || isHouse) && (
              <Grid item xs={6} sm={3} sx={{ mt: 2, mb: 1 }}>
                <FormikTextField name="flat" label="Butas" />
              </Grid>
            )}
            <Grid container item sx={{ my: 1 }}>
              {formik.values.municipality && (
                <>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography variant="body2">
                      Jei reikia patikslinti, spustelkite ant žemėlapio ir
                      vilkite.
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <AdFormMap formik={formik} />
              </Grid>
            </Grid>

            {!!formik.values.municipality && (
              <Grid
                container
                item
                alignItems="top"
                justifyContent="right"
                sx={{ my: 1, border: "none" }}
              >
                <Typography variant="body2" sx={{ mr: 2, mt: 1.3 }}>
                  Objekto vieta žemėlapyje tiksli:
                </Typography>
                <FormikRadioGroup name="is_exact" label1="Ne" label2="Taip" />
                {["false", false].includes(formik.values.is_exact) && (
                  <Stack
                    direction={isMobile ? "column" : "row"}
                    sx={{ alignItems: "center" }}
                    spacing={2}
                  >
                    <ErrorOutlineIcon
                      fontSize="large"
                      sx={{ mr: 1 }}
                      color="warning"
                    />

                    <Typography
                      variant="subtitle2"
                      // color="warning.main"
                      align="justify"
                      sx={{ flexGrow: 1, border: "none" }}
                    >
                      Rekomenduojame nurodyti tikslią objekto vietą žemėlapyje.
                      Automatinis būsto vertės nustatymo įrankis pritaikomas tik
                      tiems objektams, kurių vieta yra tiksli. Be to, atliekant
                      išmanųjį reitingavimą (t.y. rikiavimą pagal kriterijų
                      "Geriausiai atitinka poreikius"), objektui priskiriamas
                      žemiausias reitingo balas, jei jo tiksli vieta nenurodyta.
                    </Typography>
                  </Stack>
                )}
              </Grid>
            )}
          </Grid>

          {/* <LocationOptionsProvider />
          <GeoLocation edit={edit} /> */}
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default LocationPanel;
