import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import { SALE_TYPE_CHOICES } from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
import { useGlobalContext } from "../../../../context";
// import FormPreview from "../../utils/FormPreview";
import FormikDispatcher from "../../utils/FormikDispatcher";

function PricePanel({ ad, handleSubmit, edit, formikRef, initiateSubmit }) {
  const { adFormState } = useGlobalContext();
  const panelName = "pricePanel";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getPriceInitialValues(ad, adOptions);
  const validationSchema = schema.priceValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="price"
                label="Kaina (Eur) *"
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={9} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="sale_type"
                label="Pardavimo sąlygos"
                options={SALE_TYPE_CHOICES}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default PricePanel;
