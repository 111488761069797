import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "react-image-crop/dist/ReactCrop.css";
import { changePhotoOrder } from "../../Dashboard/fetchOrSendDashboardData";
import PhotoCard from "./PhotoCard";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

function DragDropPhotoList({ ad, disableDrag, setDisableDrag, isDraft }) {
  const { dispatch, dispatchAdForm, dispatchDashboard } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();

  const photos = ad.properties.photos.sort((a, b) => a.place - b.place);

  function handleOnDragEnd(result) {
    console.log("handleOnDragEnd result", result);
    if (!result.destination) return;
    const reorderedPhotos = Array.from(photos);
    const [reorderedItem] = reorderedPhotos.splice(result.source.index, 1); // get the dragged item
    reorderedPhotos.splice(result.destination.index, 0, reorderedItem); // insert dragged and droped item in new place
    let photoOrder = reorderedPhotos.map((item, index) => {
      return { id: item.id, place: index + 1 };
    });
    reorderedPhotos.forEach((item, index) => {
      item.place = index + 1;
    });
    console.log("reorderedPhotos", reorderedPhotos);
    changePhotoOrder(
      ad.id,
      reorderedPhotos,
      photoOrder,
      isDraft ? dispatchAdForm : dispatchDashboard,
      dispatch
    );
  }

  return (
    <Grid container sx={{ border: "none", mt: 1 }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable
          droppableId="droppable-list"
          direction={isMobile ? "vertical" : "horizontal"}
        >
          {(providedDroppable) => (
            <Stack
              direction={isMobile ? "column" : "row"}
              className="file-list"
              {...providedDroppable.droppableProps}
              ref={providedDroppable.innerRef}
              spacing={1.5}
              sx={{
                overflowX: isMobile ? "unset" : "auto",
                overflowY: isMobile ? "unset" : "hidden",
                // overflow: "auto",
                border: "none",
                width: "100%",
                py: 1,
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              {photos.map((photo, index) => {
                return (
                  <Draggable
                    key={`img-${index}`}
                    draggableId={`img-${index}`}
                    index={index}
                    isDragDisabled={
                      ad.properties.photos.length <= 1 ? true : disableDrag
                    }
                  >
                    {(providedDraggable) => (
                      <Box
                        ref={providedDraggable.innerRef}
                        {...providedDraggable.draggableProps}
                        {...providedDraggable.dragHandleProps}
                        sx={{
                          border: "none",
                          //   width: "400px",
                          height: "100%",
                          width: isMobile ? "100%" : "auto",
                          my: 1,
                          position: "relative",
                        }}
                      >
                        <PhotoCard
                          photo={photo}
                          index={index}
                          disableDrag={disableDrag}
                          setDisableDrag={setDisableDrag}
                          isDraft={isDraft}
                        />
                      </Box>
                    )}
                  </Draggable>
                );
              })}
              {providedDroppable.placeholder}
            </Stack>
          )}
        </Droppable>
      </DragDropContext>
    </Grid>
  );
}

export default DragDropPhotoList;
