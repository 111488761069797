import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../../context";
import { deleteNotifications } from "../../fetchHeaderData";

export default function useClearAppointmentNotifications() {
  const { adId, enquirerId, extension } = useParams();
  const { notificationState, userState, dispatchNotification } =
    useGlobalContext();
  const userId = userState.userId;

  const appointmentNotificationId = notificationState.notifications.find(
    (notification) =>
      notification.type === "appointment_message" &&
      notification.recipient === userId &&
      notification.enquiry.ad === +adId &&
      notification.enquiry.enquirer === +enquirerId
  )?.id;

  useEffect(() => {
    console.log("appointmentNotificationId", appointmentNotificationId);
    if (
      !extension &&
      !!appointmentNotificationId &&
      !notificationState.notificationsDeleting
    ) {
      deleteNotifications(dispatchNotification, appointmentNotificationId);
    }
  }, [
    dispatchNotification,
    appointmentNotificationId,
    notificationState.notificationsDeleting,
    notificationState.notifications.length,
    extension,
  ]);
}
