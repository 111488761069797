import React, { useEffect, useState } from "react";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { Divider, Grid, IconButton, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useAdType from "../../IndividualAd/BasicAdInfo/useAdType";
import { Link as RouterLink } from "react-router-dom";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";
import { getDefaultPhotoUrl } from "../../../utils/getDefaultPhotoUrl";
import { red } from "@mui/material/colors";
import useHandleLikeClick from "../../../hooks/useHandleLikeClick";
import { useGlobalContext } from "../../../context";

function PopupContent({ adArray }) {
  const { dispatchRouter } = useGlobalContext();
  const [adIdx, setAdIdx] = useState(0);
  const [ad, setAd] = useState(adArray[0]);
  const { isRent, isLand } = useAdType(adArray[0]);
  const { userLikes, handleLikeClick } = useHandleLikeClick(ad.id);

  // useEffect(() => {
  //   dispatchRouter({
  //     type: "CHANGE_ROUTER_STATE",
  //     payload: {
  //       hoverOverAd: ad.id,
  //     },
  //   });
  // }, []);

  function getPrice() {
    let price = ad.properties.price;
    // price =
    //   price >= 10000
    //     ? (price / 1000).toFixed(price % 1000 === 0 ? 0 : 1) + "k"
    //     : price;
    let units = isRent
      ? ad.properties.min_rent_period < 30
        ? "€/para"
        : "€/mėn."
      : "€";
    return (
      <Typography variant="subtitle2" color="CaptionText" sx={{ ml: 1 }}>
        {price} {units}
      </Typography>
    );
  }

  function getPriceSqm() {
    let priceSqm = ad.properties.price_sqm;
    priceSqm = priceSqm > 100 ? Math.round(priceSqm) : priceSqm;
    let units = isLand ? "€/aras" : "€/m²";
    return (
      <Typography
        variant="caption"
        color="CaptionText"
        fontSize={10}
        sx={{ ml: 1 }}
      >
        {priceSqm} {units}
      </Typography>
    );
  }

  function getConstructionYear() {
    let constructionYear;
    constructionYear = !!ad.properties.construction_year
      ? `${ad.properties.construction_year} m.`
      : "";
    return (
      constructionYear && (
        <Typography
          variant="caption"
          color="CaptionText"
          fontSize={10}
          sx={{ ml: 1 }}
        >
          {constructionYear}
        </Typography>
      )
    );
  }

  function getFeatures() {
    let features = [];
    if (!!ad.properties.rooms) features.push(`${ad.properties.rooms} k.`);
    if (!!ad.properties.area) features.push(`${ad.properties.area} m²`);
    if (!!ad.properties.plot_area)
      features.push(`${ad.properties.plot_area} a`);
    if (!!ad.properties.plot_purpose)
      features.push(`${ad.properties.plot_purpose}`);
    return (
      <Typography
        variant="caption"
        color="CaptionText"
        fontSize={10}
        noWrap
        sx={{ overflow: "hidden", maxWidth: 110, ml: 1 }}
      >
        {features.join(", ").toLowerCase()}
      </Typography>
    );
  }

  const price = getPrice();
  const priceSqm = getPriceSqm();
  const constructionYear = getConstructionYear();
  const features = getFeatures();

  function handleChangeAd() {
    let newIdx = adIdx + 1;
    if (newIdx === adArray.length) newIdx = 0;
    if (newIdx < 0) newIdx = adArray.length - 1;
    setAdIdx(newIdx);
    setAd(adArray[newIdx]);
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: {
        hoverOverAd: adArray[newIdx].id,
      },
    });
  }

  return (
    <>
      <Box
        sx={{
          width: "100%",
          borderTopLeftRadius: "3px",
          borderBottomLeftRadius: "3px",
        }}
      >
        <Stack
          direction="row"
          sx={{ borderTopLeftRadius: "3px", borderBottomLeftRadius: "3px" }}
        >
          <RouterLink to={`/ad/${ad.id}`}>
            <Box
              component="img"
              src={
                getFullImgUrl(ad.properties.photos[0]?.photo_thumbnail) ||
                getDefaultPhotoUrl()
              }
              sx={{
                border: "none",
                width: "120px",
                height: "auto",
                maxHeight: "67px",
                borderTopLeftRadius: "3px",
                borderBottomLeftRadius: "3px",
                objectFit: "cover",
              }}
            />
          </RouterLink>

          <Stack
            sx={{
              // ml: 1,
              width: "100%",
              border: "none",
              flexDirection: "column",
            }}
          >
            <Grid container alignItems="center" justifyContent="space-between">
              {price}
              <IconButton
                size="small"
                sx={{ p: "2px" }}
                onClick={() => handleLikeClick(ad.id)}
              >
                <FavoriteIcon
                  fontSize="16px"
                  sx={{
                    color: userLikes ? red[500] : "default",
                    opacity: userLikes ? 1 : 0.5,
                  }}
                />
              </IconButton>
            </Grid>
            <Grid
              container
              alignItems="center"
              sx={{ mt: -0.5, width: "100%", position: "relative" }}
            >
              {priceSqm}
              {constructionYear}
            </Grid>
            <Grid container alignItems="center" sx={{ flexGrow: 1 }}>
              {features}
            </Grid>

            {adArray.length > 1 && (
              <>
                <Divider />
                <Grid
                  container
                  alignItems="center"
                  sx={{ display: "flex", flexDirection: "row" }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      border: "none",
                    }}
                    onClick={() => handleChangeAd(-1)}
                  >
                    <ChevronLeft fontSize="small" />
                  </Box>
                  <Typography
                    variant="caption"
                    color="CaptionText"
                    fontSize={10}
                    sx={{
                      flexGrow: 0,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {adIdx + 1}/{adArray.length} skelb.
                  </Typography>
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                      border: "none",
                    }}
                    onClick={() => handleChangeAd(1)}
                  >
                    <ChevronRight fontSize="small" />
                  </Box>
                </Grid>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
}

export default PopupContent;
