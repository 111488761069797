import React, { useState } from "react";
import {
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import humanizeDate from "../../../utils/humanizeDate";
import { grey, deepPurple } from "@mui/material/colors";
import humanizeStatus from "../../../utils/humanizeStatus";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { deleteEnquiryMessage } from "../fetchOrSendDashboardData";
import { useGlobalContext } from "../../../context";

const MessageContent = ({ message: m }) => {
  return m.deleted ? (
    <Stack direction="row" sx={{ alignItems: "center" }}>
      <DeleteForeverIcon
        sx={{ color: "grey", mr: 0.5, mt: 0.5, fontSize: 18 }}
      />
      <Typography
        variant="body2"
        sx={{ mt: 1, color: "grey", fontStyle: "italic" }}
      >
        Žinutė ištrinta
      </Typography>
    </Stack>
  ) : (
    <Typography variant="body2" sx={{ mt: 1 }}>
      {m.message}
    </Typography>
  );
};

export default function MessageBubble({ m, myMessage, adContact }) {
  const { dispatchDashboard } = useGlobalContext();

  console.log("message from MessageBubble", m);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function deleteMessage() {
    deleteEnquiryMessage(dispatchDashboard, m.id);
    handleClose();
  }

  return (
    <Box
      sx={{
        border: "none",
        display: "flex",
        justifyContent: myMessage ? "right" : "left",
        maxWidth: "100%",
        ml: myMessage ? 7 : 0.5,
        mr: myMessage ? 0.5 : 7,
      }}
    >
      <Paper
        sx={{
          m: "3px",
          p: "3px",
          backgroundColor: !!m.appointment_status
            ? "beige"
            : myMessage
            ? deepPurple[100]
            : grey[50],
          display: "block",
        }}
      >
        {!m.appointment_status && <MessageContent message={m} />}
        {!!m.appointment_status && (
          <>
            <Typography variant="subtitle2">
              {m.reply ? adContact.owner_name : adContact.name}{" "}
              {humanizeStatus(m.appointment_status, "both")}
              {!!m.appointment ? `: ${humanizeDate(m.appointment)}` : ""}
            </Typography>
            <Divider />
            <MessageContent message={m} />
          </>
        )}
        <Box
          sx={{
            ml: 3,
            display: "flex",
            border: "none",
            alignItems: "center",
            justifyContent: "end",
          }}
        >
          <Typography variant="caption" noWrap sx={{ color: grey[500] }}>
            {humanizeDate(m.contact_date)}
          </Typography>
          {myMessage && m.message && !m.deleted && (
            <>
              <IconButton size="small" sx={{ mt: -0.6 }} onClick={handleClick}>
                <MoreVertIcon
                  sx={{ color: grey[500], height: "16px", width: "16px" }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                MenuListProps={{ sx: { py: 0 } }}
              >
                <MenuItem onClick={deleteMessage}>
                  <ListItemIcon>
                    <DeleteForeverIcon fontSize="small" color="error" />
                  </ListItemIcon>
                  <Typography variant="body2">Ištrinti žinutę</Typography>
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
}
