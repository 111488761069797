import { Box, Grid, Typography } from "@mui/material";

export function getPrice(ad, isRent, isParkingOrGarage) {
  const price = `${Number(ad.properties.price)} ${
    isRent
      ? +ad.properties.min_rent_period < 30 && !isParkingOrGarage
        ? "€/para"
        : "€/mėn."
      : "€"
  }`;
  return price;
}

export function getPriceSqm(ad) {
  const priceSqm = Number(ad.properties.price_sqm).toFixed(
    Number(ad.properties.price_sqm) > 100 ? 0 : 1
  );
  return priceSqm;
}

export function getAddress(ad) {
  const streetHouseFlat = ad.properties?.street?.name
    ? `${ad.properties?.street?.name}${
        ad.properties?.street?.name && ad.properties?.house
          ? ` ${ad.properties?.house}`
          : ""
      }${
        ad.properties?.house && ad.properties?.flat
          ? `-${ad.properties.flat}`
          : ""
      }, `
    : "";
  const address = `${streetHouseFlat}${ad.properties.town?.name}, ${
    !!ad.properties.suburb?.name
      ? ad.properties.suburb?.name
      : ad.properties.municipality?.name
  }`;
  return address;
}

export function getRooms(ad, xs, md) {
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Kambariai:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {ad.properties.rooms}
      </Typography>
    </Grid>
  );
}

export function getArea(ad, xs, md) {
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Plotas:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {Number(ad.properties.area) % 1 === 0
          ? Number(ad.properties.area).toFixed(0)
          : Number(ad.properties.area).toFixed(1)}{" "}
        m²
      </Typography>
    </Grid>
  );
}

export function getFloor(ad, xs, md, isFlat, isHouse) {
  console.log("isFlat", isFlat);
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Aukštas:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {isFlat && `${ad.properties.floor} iš ${ad.properties.floors}`}
        {isHouse && ad.properties.floors}
      </Typography>
    </Grid>
  );
}

export function getBuildingType(ad, xs, md) {
  return (
    <Grid container item xs={xs} sm={md}>
      <Typography variant="caption">Konstrukcija:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {ad.properties.building_type}
      </Typography>
    </Grid>
  );
}

export function getPlotArea(ad, xs, md, isLand) {
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Sklypas:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {`${
          Number(ad.properties.plot_area) % 1 === 0
            ? Number(ad.properties.plot_area).toFixed(0)
            : Number(ad.properties.plot_area).toFixed(1)
        } a`}
      </Typography>
    </Grid>
  );
}

export function getConstructionYear(ad, xs, md) {
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Metai:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {ad.properties.construction_year}
      </Typography>
    </Grid>
  );
}

export function getInstallation(ad, xs, md) {
  return (
    <Grid
      container
      item
      // alignItems="center"
      xs={xs}
      md={md}
      sx={{ display: "flex" }}
    >
      <Typography variant="caption">
        {ad.properties.ad_type === "Naujas daugiabučių projektas"
          ? "Apdaila:"
          : "Įrengimas:"}
      </Typography>
      <Typography
        variant="caption"
        fontWeight={600}
        noWrap
        sx={{ pl: 1, maxWidth: "55%", overflow: "hidden" }}
      >
        {ad.properties.installation}
      </Typography>
    </Grid>
  );
}

export function getHeatingBill(ad, xs, md) {
  return (
    <>
      {ad.properties.heating_bill && (
        <Grid container item xs={xs} md={md}>
          <Typography variant="caption">Šildymas žiemą:</Typography>
          <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
            {ad.properties.heating_bill} €/mėn.
          </Typography>
        </Grid>
      )}
    </>
  );
}

export function getEnergyEfficiency(ad, xs, md) {
  return (
    <Grid
      container
      item
      xs={xs}
      md={md}
      alignItems="center"
      sx={{ display: "flex", pt: 0.5 }}
    >
      <Typography variant="caption">Energ. efekt.:</Typography>
      <Box
        sx={{
          display: "flex",
          maxWidth: "60%",
          border: "none",
        }}
      >
        <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }} noWrap>
          {ad.properties.energy_efficiency}
        </Typography>
      </Box>
    </Grid>
  );
}

export function getPlotPurpose(ad, xs, md) {
  return (
    <Grid
      container
      item
      xs={xs}
      sm={md}
      sx={{ display: "flex" }}
      alignItems="center"
    >
      <Typography variant="caption">Paskirtis:</Typography>
      <Box sx={{ display: "flex", maxWidth: "70%" }}>
        <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }} noWrap>
          {ad.properties.plot_purpose}
        </Typography>
      </Box>
    </Grid>
  );
}

export function getInfrastructure(ad, xs, md) {
  let infraArray = ad.properties?.plot_infrastructure
    ?.filter((x) =>
      ["Elektra", "Dujos", "Vanduo"].includes(x.plot_infrastructure)
    )
    ?.map((x) => x.plot_infrastructure);
  let infraStr;
  console.log("infraArray", infraArray);
  if (infraArray?.length === 3) {
    infraStr = "Yra";
  } else if (!!infraArray?.length) {
    infraStr = infraArray.toString().toLowerCase().replaceAll(",", ", ");
    infraStr = infraStr.replace(infraStr[0], infraStr[0].toUpperCase());
  } else {
    infraStr = "Nėra";
  }
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Komunikacijos:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {infraStr}
      </Typography>
    </Grid>
  );
}

export function getWaterBody(ad, xs, md) {
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Prie vandens telkinio:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {!!ad.properties?.water_body?.length ? "Taip" : "Ne"}
      </Typography>
    </Grid>
  );
}

export function getFertility(ad, xs, md) {
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Našumo balas:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {ad.properties.fertility_score}
      </Typography>
    </Grid>
  );
}

export function getForestProp(ad, xs, md, isLoggingForest) {
  const propLabel = ad.properties.forest_type
    ? "Miško tipas"
    : ad.properties.forest_mature
    ? "Miško branda"
    : "Komercinis kirtimas";

  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">{propLabel}:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {ad.properties.forest_type ||
          ad.properties.forest_mature ||
          isLoggingForest}
      </Typography>
    </Grid>
  );
}

export function getPremisesPurpose(ad, xs, md) {
  return (
    <Grid
      container
      item
      xs={xs}
      sm={md}
      sx={{ display: "flex", border: "none" }}
      alignItems="center"
    >
      <Typography variant="caption">Paskirtis:</Typography>
      <Box sx={{ display: "flex", maxWidth: "70%" }}>
        <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }} noWrap>
          {ad.properties.premises_purpose[0].premises_purpose}{" "}
          {ad.properties.premises_purpose.length > 1 && "ir kt."}
        </Typography>
      </Box>
    </Grid>
  );
}

export function getAreaRange(ad, xs, md) {
  const isOneArea = ad.properties.area_from === ad.properties.area_to;
  let area;
  if (!Number(ad.properties.area_from)) {
    area = Number(ad.properties.area).toFixed(0);
  } else {
    area = isOneArea
      ? Number(ad.properties.area_from).toFixed(0)
      : `${Number(ad.properties.area_from).toFixed(0)}-${Number(
          ad.properties.area_to
        ).toFixed(0)}`;
  }
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">
        {isOneArea ? "Plotas:" : "Plotai:"}
      </Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {area} m²
      </Typography>
    </Grid>
  );
}

export function getFloorRange(ad, xs, md) {
  const isOneFloor =
    ad.properties.floor || ad.properties.floor_from === ad.properties.floor_to;
  const floor = isOneFloor
    ? ad.properties.floor_from
    : `${ad.properties.floor_from}-${ad.properties.floor_to}`;
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">
        {isOneFloor ? "Aukštas:" : "Aukštai:"}
      </Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {floor} iš {ad.properties.floors}
      </Typography>
    </Grid>
  );
}

export function getPremisesHeatingCost(ad) {
  return (
    ad.properties.heating_bill && (
      <Grid item xs={6} sm={4}>
        <Typography variant="caption">Šildymas:</Typography>
        <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
          {ad.properties.heating_bill} €/m² per mėn.
        </Typography>
      </Grid>
    )
  );
}

export function getPriceRange(ad, isRent) {
  let price;
  if (!!ad.properties?.price_from) {
    const isOnePrice = ad.properties.price_from === ad.properties.price_to;
    price = isOnePrice
      ? ad.properties.price_from
      : `${ad.properties.price_from}-${ad.properties.price_to}`;
  } else {
    price = ad.properties.price;
  }
  price = `${price} ${isRent ? "€/mėn." : "€"}`;
  return price;
}

export function getPriceSqmRange(ad) {
  let priceSqm;
  const isSmall =
    (!!ad.properties.price_sqm && Number(ad.properties.price_sqm) <= 100) ||
    (!!ad.properties.price_sqm_from &&
      Number(ad.properties.price_sqm_from) <= 100);
  if (!!ad.properties?.price_sqm_from) {
    const isOnePrice =
      ad.properties.price_sqm_from === ad.properties.price_sqm_to;
    priceSqm = isOnePrice
      ? Number(ad.properties.price_sqm_from).toFixed(isSmall ? 1 : 0)
      : `${Number(ad.properties.price_sqm_from).toFixed(
          isSmall ? 1 : 0
        )}-${Number(ad.properties.price_sqm_to).toFixed(isSmall ? 1 : 0)}`;
  } else {
    priceSqm = Number(ad.properties.price_sqm).toFixed(isSmall ? 1 : 0);
  }
  return priceSqm;
}

export function getSize(ad, xs, md) {
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Telpa automobilių:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {ad.properties.garage_size}
      </Typography>
    </Grid>
  );
}

export function getType(ad, xs, md) {
  return (
    <Grid
      container
      item
      xs={xs}
      md={md}
      //   sx={{ position: "relative", width: "100%" }}
      alignItems="center"
    >
      <Typography variant="caption">Tipas:</Typography>
      <Typography
        variant="caption"
        fontWeight={600}
        noWrap
        sx={{
          pl: 1,
          overflow: "hidden",
          maxWidth: "80%",
          //   position: "relative",
        }}
      >
        {ad.properties.garage_type}
      </Typography>
    </Grid>
  );
}

export function getRoomsRange(ad, xs, md) {
  const isOneRoom = ad.properties.rooms_from === ad.properties.rooms_to;
  const rooms = isOneRoom
    ? ad.properties.rooms_from
    : `${ad.properties.rooms_from}-${ad.properties.rooms_to}`;
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Kambariai:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {rooms}
      </Typography>
    </Grid>
  );
}

export function getPriceRangeApartmentComplex(ad, xs, md) {
  const priceRange = getPriceRange(ad, false);
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Kaina:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {priceRange}
      </Typography>
    </Grid>
  );
}

export function getPriceSqmRangeApartmentComplex(ad, xs, md) {
  const priceSqmRange = getPriceSqmRange(ad);
  return (
    <Grid container item xs={xs} md={md}>
      <Typography variant="caption">Kaina už m²:</Typography>
      <Typography variant="caption" fontWeight={600} sx={{ pl: 1 }}>
        {priceSqmRange} €/m²
      </Typography>
    </Grid>
  );
}
