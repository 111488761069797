import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useAdType from "./useAdType";
import {
  getAddress,
  getAreaRange,
  getBuildingType,
  getConstructionYear,
  getEnergyEfficiency,
  getFloorRange,
  getInstallation,
  getPremisesPurpose,
  getPriceRange,
  getPriceSqmRange,
} from "./utils";
import MapButton from "./MapButton";

function BasicAdInfoPremises({ ad, setShowMap, showMap }) {
  const { isSale, isRent } = useAdType(ad);

  const address = getAddress(ad);
  const priceRange = getPriceRange(ad, isRent);
  const priceSqmRange = getPriceSqmRange(ad, isRent);
  const buildingType = getBuildingType(ad, 6, 4);
  const constructionYear = getConstructionYear(ad, 6, 4);
  const installation = getInstallation(ad, 6, 4);
  const energyEfficiency = getEnergyEfficiency(ad, 6, 4);
  const premisesPurpose = getPremisesPurpose(ad, 6, 4);
  const areaRange = getAreaRange(ad, 6, 4);
  const floorRange = getFloorRange(ad, 6, 4);

  return (
    <>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          justifyContent: "space-between",
          // display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ mt: 1, mx: 1 }}
            color="primary"
            variant="body2"
            fontSize={20}
          >
            {priceRange}
          </Typography>
          <Typography sx={{ mt: 1, pt: 0.3 }} variant="body2">
            ({priceSqmRange} €/m²)
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          alignItems: "center",
          px: 1,
          mt: 0.2,
        }}
      >
        <Typography variant="caption">{address} </Typography>
        <MapButton setShowMap={setShowMap} showMap={showMap} />
      </Grid>

      <Grid container sx={{ my: 1, pl: 1, border: "none" }}>
        {areaRange}
        {premisesPurpose}
        {floorRange}
        {buildingType}
        {constructionYear}
        {isSale && installation}
        {isRent && energyEfficiency}
      </Grid>
    </>
  );
}

export default BasicAdInfoPremises;
