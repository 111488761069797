import React from "react";
import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import FormikRadioGroup from "../../../SharedComponents/FormikRadioGroup";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

function PricePanelRent({ ad, handleSubmit, edit, formikRef, initiateSubmit }) {
  const { adFormState } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const panelName = "pricePanelRent";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getPriceRentInitialValues(ad, adOptions);
  const validationSchema = schema.priceRentValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  function handleMinPeriodChange(e, formik) {
    console.log("e, formik from handleMinPeriodChange", e, formik);
    const newMinRentPeriod = e;
    const prevMinRentPeriod = formik.values.min_rent_period;
    const switchToLongTerm = newMinRentPeriod >= 30 && prevMinRentPeriod < 30;
    const switchToShortTerm = newMinRentPeriod < 30 && prevMinRentPeriod >= 30;
    if (switchToShortTerm || switchToLongTerm) {
      formik.setFieldValue("price", "".trim());
      formik.setFieldTouched("price", false);
      formik.setFieldValue("rent_type", []);
      formik.setFieldTouched("rent_type", false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container sx={{ border: "none" }}>
            <Grid
              container
              sx={{ my: 1, display: "flex", alignItems: "start" }}
            >
              <Grid item xs={6} sx={{ pr: 0.5, mt: 2, border: "none" }}>
                <Typography variant={isMobile ? "body2" : "body1"}>
                  Minimalus nuomos terminas:
                </Typography>
              </Grid>
              <Grid container xs={6} sm={3}>
                <FormikSelect
                  name="min_rent_period"
                  label="Min. trukmė *"
                  options={adOptions["MIN_RENT_PERIOD_CHOICES"]}
                  onChange={(e) => handleMinPeriodChange(e, formik)}
                />
              </Grid>
            </Grid>

            <Grid
              container
              sx={{
                my: 2,
                display: "flex",
                alignItems: "start",
                border: "none",
              }}
            >
              <Grid item xs={6} sx={{ mt: 2 }}>
                <Typography variant={isMobile ? "body2" : "body1"}>
                  {!formik.values.min_rent_period ||
                  formik.values.min_rent_period >= 30
                    ? "Mėnesio "
                    : "Paros "}
                  kaina, nuomojantis minimaliam terminui:
                </Typography>
              </Grid>
              <Grid item xs={6} sm={3} sx={{ pr: 0.5 }}>
                <FormikTextField
                  name="price"
                  label={
                    !formik.values.min_rent_period ||
                    formik.values.min_rent_period >= 30
                      ? "Kaina už mėnesį (Eur/mėn.) *"
                      : "Paros kaina (Eur/d.) *"
                  }
                  type="number"
                />
              </Grid>
            </Grid>

            <Grid
              container
              sx={{ display: "flex", alignItems: "start", my: 2 }}
            >
              <Grid item xs={6} sx={{ pr: 0.5, mt: 1 }}>
                <Typography variant={isMobile ? "body2" : "body1"}>
                  Komunaliniai mokesčiai į kainą jau įtraukti:
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                sm={3}
                sx={{ border: "none", alignItems: "center", my: 0, pl: 0.5 }}
              >
                <FormikRadioGroup
                  name="utils_included"
                  label1="Ne"
                  label2="Taip"
                />
              </Grid>
            </Grid>

            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="rent_type"
                label={
                  !formik.values.min_rent_period ||
                  formik.values.min_rent_period >= 30
                    ? "Nuomos sąlygos"
                    : "Trumpalaikės nuomos sąlygos"
                }
                options={
                  !formik.values.min_rent_period ||
                  formik.values.min_rent_period >= 30
                    ? adOptions["RENT_TYPE_CHOICES"]
                    : adOptions["SHORT_TERM_RENT_TYPE_CHOICES"]
                }
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default PricePanelRent;
