import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import {
  PARKING_CHOICES,
  RECREATION_CHOICES,
  SECURITY_CHOICES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
import { useGlobalContext } from "../../../../context";
// import FormPreview from "../../utils/FormPreview";
import FormikDispatcher from "../../utils/FormikDispatcher";

function EnvironmentPanel({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "environmentPanel";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getEnvironmentInitialValues(ad, adOptions);
  const validationSchema = schema.environmentValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="security"
                label="Saugumas"
                options={SECURITY_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="parking"
                label="Automobilio parkavimas"
                options={PARKING_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="recreation"
                label="Poilsio erdvės"
                options={RECREATION_CHOICES}
              />
            </Grid>
          </Grid>

          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default EnvironmentPanel;
