import React from "react";
import { Box, Typography } from "@mui/material";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";

export default function PageNotFound() {
  const { isMobile } = useCustomMediaQuery();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="60vh"
      textAlign="center"
      sx={{ border: "none" }}
    >
      <HomeWorkIcon sx={{ fontSize: 200, color: "lightgrey" }} />
      <Typography variant={isMobile ? "h6" : "h4"} gutterBottom>
        404 Puslapis nerastas
      </Typography>
      <Typography variant={isMobile ? "body2" : "body1"} gutterBottom>
        Skelbimas neegzistuoja arba yra neaktyvus.
      </Typography>
      {/* <Button variant="contained" color="primary" onClick={handleGoBack}>
        Go to Home
      </Button> */}
    </Box>
  );
}
