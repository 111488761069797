import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../../context";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "leaflet-draw/dist/leaflet.draw-src.js";
import { Box, Chip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import reloadAds from "../../../../utils/getUrlFromParams";
import { PREFERENCE_ARRAY } from "../../../../utils/defaults";
import { useMap } from "react-leaflet";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import { PiMapPinLine } from "react-icons/pi";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

function PlaceSelectorChip() {
  const { state, dispatch, userState, dispatchUser } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const map = useMap();
  // const [showSnackBar, setShowSnackBar] = useState(false);
  // const [snackBarMessage, setSnackBarMessage] = useState("");
  // const [transitionInProgress, setTransitionInProgress] = useState(0);

  const params = useParamsFromUrl();
  const history = useHistory();
  const placeChipX = !!params?.place_coords;
  // const placesChipLabel = `${placeChipX ? "Keisti vietas" : "Pažymėti vietas"}`;
  const placeChipLabel = "Vietos";

  function deleteMarker(e, deleteAll = false) {
    if (deleteAll) {
      changeParams("");
    } else {
      state.placeLayerRef.current.removeLayer(e.target);
    }
  }

  function changeParams(place_coords) {
    let newParams = { ...params, place_coords };

    // when place coords are removed, remove preference.
    // additionally reset ordering, if it was the only preference
    if (!place_coords) {
      newParams = { ...newParams, distance: 0 };
      let selectedPreferencesCount = PREFERENCE_ARRAY.filter(
        (preference) => !!newParams[preference.param]
      ).length;
      if (params?.ordering === "-preferences" && !selectedPreferencesCount) {
        const snackbar = userState.snackbar;
        snackbar.open = true;
        snackbar.message = 'Pakeistas rikiavimas į "Naujausi viršuje"';
        snackbar.severity = "warning";
        dispatchUser({
          type: "CHANGE_USER_STATE",
          payload: { snackbar },
        });
        newParams = { ...newParams, ordering: "" };
        delete newParams.page;
      }
    }
    // add new preference when place coordinates change
    if (place_coords && !newParams?.distance) {
      newParams = { ...newParams, distance: 1 };
    }
    reloadAds(newParams, dispatch, history);
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { drawPlacesMode: false },
    });
  }

  function handlePlacesSelect() {
    let mapBounds = map.getBounds();
    let mapZoom = map.getZoom();
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { mapBounds, mapZoom, drawPlacesMode: true },
    });
  }

  return (
    <Chip
      variant="filled"
      clickable
      sx={{
        display: "flex",
        position: "absolute",
        zIndex: 1000,
        borderRadius: "3px",
        border: "solid lightgrey 1px",
        bgcolor: grey[50],
        color: placeChipX ? "primary.light" : "default",
        borderColor: placeChipX ? "primary.light" : "default",
        top: 45,
        right: 10,
        // width: 130,
        "& .MuiChip-label": { px: "8px" },
        "& .MuiChip-deleteIcon": {
          color: "primary.light",
          "&:hover": {
            color: "primary.dark",
          },
        },
        "&:hover": {
          backgroundColor: grey[200],
        },
      }}
      label={
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box sx={{ mt: 0.5, mr: isMobile && !placeChipX ? 0 : 0.5 }}>
            <PiMapPinLine fontSize={18} />
          </Box>
          {!isMobile && (
            <Typography variant="caption">{placeChipLabel}</Typography>
          )}
        </Box>
      }
      disabled={state?.drawPlacesMode || state?.drawPolygonMode}
      onClick={handlePlacesSelect}
      onDelete={placeChipX ? (e) => deleteMarker(e, true) : null}
    />
  );
}

export default PlaceSelectorChip;
