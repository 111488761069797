import { Grid } from "@mui/material";
import { useGlobalContext } from "../../../context";
import FormikSelect from "../../SharedComponents/FormikSelect";
import { INVOICE_CHOICES, CLIENT_TYPE_CHOICES } from "../../../utils/adOptions";
import FormikTextField from "../../SharedComponents/FormikTextField";

export default function ClientInfoForm({ formik }) {
  const { userState, dispatchUser, routerState } = useGlobalContext();
  let clientInfo = userState.clientInfo || {};

  function handleClientDataChange(value, name) {
    clientInfo = { ...clientInfo, [name]: value };
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { clientInfo },
    });
  }

  return (
    <>
      <Grid
        container
        item
        alignItems="top"
        justifyContent="center"
        sx={{ border: "none", mt: 7 }}
      >
        <Grid item xs={12} sm={6}>
          {routerState.from === "userProfile" ? (
            <FormikSelect
              name="client_type"
              label="Kliento tipas *"
              options={CLIENT_TYPE_CHOICES}
              onChange={(value) => handleClientDataChange(value, "client_type")}
            />
          ) : (
            <FormikSelect
              name="client_type"
              label="Sąskaita-faktūra *"
              options={INVOICE_CHOICES}
              onChange={(value) => handleClientDataChange(value, "client_type")}
            />
          )}
        </Grid>
      </Grid>

      {formik.values.client_type === "enterprise" && (
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_name"
              label="Įmonės pavadinimas *"
              onBlur={(value) => handleClientDataChange(value, "client_name")}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_address"
              label="Įmonės adresas *"
              onBlur={(value) =>
                handleClientDataChange(value, "client_address")
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="company_code"
              label="Įmonės kodas *"
              onBlur={(value) => handleClientDataChange(value, "company_code")}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="vat_code"
              label="Įmonės PVM kodas"
              onBlur={(value) => handleClientDataChange(value, "vat_code")}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_phone"
              label="Įmonės kontaktinis telefonas"
              onBlur={(value) => handleClientDataChange(value, "client_phone")}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_email"
              label="Įmonės el. paštas"
              onBlur={(value) => handleClientDataChange(value, "client_email")}
            />
          </Grid>
        </Grid>
      )}

      {formik.values.client_type === "private" && (
        <Grid container spacing={1} sx={{ mt: 1 }}>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_name"
              label="Vardas, pavardė *"
              onBlur={(value) => handleClientDataChange(value, "client_name")}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_address"
              label="Pirkėjo adresas *"
              onBlur={(value) =>
                handleClientDataChange(value, "client_address")
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_phone"
              label="Pirkėjo kontaktinis telefonas"
              onBlur={(value) => handleClientDataChange(value, "client_phone")}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ my: 1 }}>
            <FormikTextField
              name="client_email"
              label="Pirkėjo kontaktinis el. paštas"
              onBlur={(value) => handleClientDataChange(value, "client_email")}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
