import { useCallback, useEffect, useState } from "react";
import { useGlobalContext } from "../../../../context";
import { w3cwebsocket as W3CWebSocket } from "websocket";

export default function useLocationSearchSocket(socketRef) {
  const { userState } = useGlobalContext();
  // const wsUrl = "ws://localhost:8000/ws/search/";
  let wsUrl;
  if (process.env.NODE_ENV === "production") {
    wsUrl = "wss://frontend-test-pp8b8.ondigitalocean.app/ws/search/";
  } else {
    wsUrl = "ws://localhost:8000/ws/search/";
  }
  const [locationSearchOptions, setLocationSearchOptions] = useState([]);
  const [timer, setTimer] = useState(1000);

  const connectSearchSocket = useCallback(() => {
    // alert("connectSearchSocket");
    socketRef.current = new W3CWebSocket(wsUrl);
    socketRef.current.onopen = (e) => {
      if (socketRef.current.readyState === 1) {
        console.log("searchSocket ready");
        socketRef.current.send("ping");
      }
    };
    socketRef.current.onmessage = (e) => {
      console.log("received locationSearchOptions through ws", e.data);
      if (e.data !== "pong") {
        let locationSearchOptions = JSON.parse(e.data);
        setLocationSearchOptions(locationSearchOptions);
      }
    };
    socketRef.current.onclose = (e) => {
      console.log("Closing search socket...", e);
      // alert("reconnecting socket");
      setTimeout(connectSearchSocket, timer);
      setTimer(timer > 3600000 ? 1000 : timer * 2);
    };
    socketRef.current.onerror = (e) => {
      console.log("Search socket client Error", e);
      console.log(
        `WebSocket closed with code ${e.code} and reason "${e.reason}"`
      );
      // alert("error" + e);
    };
  }, [socketRef, timer, wsUrl]);

  useEffect(() => {
    if (!socketRef?.current) {
      connectSearchSocket();
    }
    return () => {
      socketRef?.current?.close();
    };
  }, [connectSearchSocket, socketRef]);

  console.log("locationSearchOptions", locationSearchOptions);
  console.log(
    "search socket readyState from hook",
    socketRef?.current?.readyState
  );
  console.log("userState from useLocationSearchSocket", userState);

  return { locationSearchOptions };
}
