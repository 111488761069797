import React from "react";
import { Grid, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import { grey } from "@mui/material/colors";

import MyAds from "../MyAds/MyAds";
import MyFavorites from "../MyFavorites/MyFavorites";
import MyEnquiries from "../MyEnquiries/MyEnquiries";
import MySearches from "../MySearches/MySearches";
import MyCalendar from "../Calendar/MyCalendar";
import MyOrders from "../MyOrders/MyOrders";

export const tabArray = [
  {
    index: 0,
    value: "my-favorites",
    label: "Įsiminti skelbimai",
    component: <MyFavorites />,
  },
  {
    index: 1,
    value: "my-searches",
    label: "Įsimintos paieškos",
    component: <MySearches />,
  },
  {
    index: 2,
    value: "my-ads",
    label: "Mano skelbimai",
    component: <MyAds />,
  },
  {
    index: 3,
    value: "my-enquiries",
    label: "Mano užklausos",
    component: <MyEnquiries />,
  },
  {
    index: 4,
    value: "my-calendar",
    label: "Apžiūrų kalendorius",
    component: <MyCalendar />,
  },
  {
    index: 5,
    value: "my-orders",
    label: "Mano užsakymai",
    component: <MyOrders />,
  },
];

export default function DashboardToolBar() {
  let history = useHistory();
  const { routerState, dispatchRouter } = useGlobalContext();

  // tab change action (click)
  function handleTabChange(event, newValue) {
    const tabName = tabArray.find((tab) => tab.index === newValue).value;
    console.log("tabName from handleTabChange", tabName);
    let destinationUrl = `/dashboard/${tabName}`;
    if (tabName === "my-ads") {
      let myAdsAd = routerState.myAdsAd && `/${routerState.myAdsAd}`;
      let myAdsEnquirer =
        routerState.myAdsEnquirer && `/${routerState.myAdsEnquirer}`;
      let myAdsExtension =
        routerState.myAdsExtension && `/${routerState.myAdsExtension}`;
      destinationUrl += `${myAdsAd}${myAdsEnquirer}${myAdsExtension}`;
    }
    if (tabName === "my-enquiries") {
      let myEnquiriesAd =
        routerState.myEnquiriesAd && `/${routerState.myEnquiriesAd}`;
      let myEnquiriesEnquirer =
        routerState.myEnquiriesEnquirer &&
        `/${routerState.myEnquiriesEnquirer}`;
      let myEnquiriesExtension =
        routerState.myEnquiriesExtension &&
        `/${routerState.myEnquiriesExtension}`;
      destinationUrl += `${myEnquiriesAd}${myEnquiriesEnquirer}${myEnquiriesExtension}`;
    }
    console.log("destinationUrl from handleTabChange", destinationUrl);
    dispatchRouter({ type: "CHANGE_ROUTER_STATE", payload: { tab: newValue } });
    history.push(destinationUrl);
  }

  return (
    <>
      <Toolbar variant="dense" disableGutters sx={{ mt: -1, pb: 0.5 }}>
        <Grid
          container
          item
          xs={12}
          alignItems="center"
          sx={{ border: "none" }}
        >
          <Tabs
            value={routerState.tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            sx={{
              "& .MuiTabs-scrollButtons": {
                color: "text.primary",
                opacity: 1,
              },
              "& .MuiTabs-scrollButtons.Mui-disabled": {
                color: grey[400],
              },
            }}
          >
            <Tab
              disableRipple
              label={
                <Typography variant="body2">Įsiminti skelbimai</Typography>
              }
            />
            <Tab
              disableRipple
              label={
                <Typography variant="body2">Įsimintos paieškos</Typography>
              }
            />
            <Tab
              disableRipple
              label={
                // <Badge
                //   badgeContent={newEnquiriesCount}
                //   variant="dot"
                //   color="secondary"
                // >
                <Typography variant="body2" sx={{ pr: 0.5 }}>
                  Mano skelbimai
                </Typography>
                // </Badge>
              }
            />
            <Tab
              disableRipple
              label={
                // <Badge
                //   badgeContent={newRepliesCount}
                //   variant="dot"
                //   color="secondary"
                // >
                <Typography variant="body2" sx={{ border: "none", pr: 0.5 }}>
                  Mano užklausos{"  "}
                </Typography>
                // </Badge>
              }
            />
            <Tab
              disableRipple
              label={
                <Typography variant="body2">Apžiūrų kalendorius</Typography>
              }
            />
            <Tab
              disableRipple
              label={<Typography variant="body2">Mano užsakymai</Typography>}
            />
          </Tabs>
        </Grid>
      </Toolbar>
    </>
  );
}
