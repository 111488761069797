import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../../context";
import ProfilePhoto from "./ProfilePhoto";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateUserData } from "../fetchOrSendAccountsData";
import CustomAvatar from "../../Layout/CustomAvatar";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { useEffect } from "react";
import { blue, grey } from "@mui/material/colors";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input";
import CustomPhoneTextField from "../../AdForm/Step1AdInfo/OwnerDetailsPanels/CustomPhoneTextField";
import Footer from "../../Layout/Footer/Footer";
import ClientCard from "./ClientCard";
import DeleteUserDialog from "./DeleteUserDialog";
import { getLocalDate } from "../../../utils/humanizeDate";

const validationSchema = Yup.object({
  name: Yup.string(),
  phone: Yup.string()
    .max(20, "Maks. 20 simbolių")
    .test("is-valid-phone", "Netinkamas telefono numeris", (value) => {
      if (value && value.startsWith("+370")) {
        return value.length === 12;
      }
      return true;
    })
    .nullable(),
  // contact_email: Yup.string().email("Turi būti tinkamas el. pašto adresas"),
});

function UserProfile() {
  const { userState, dispatchUser, routerState, dispatchRouter } =
    useGlobalContext();
  const [enableEdit, setEnableEdit] = useState(false);
  const { isSmallOrMobile } = useCustomMediaQuery();
  const [openDeleteUserDialog, setOpenDeleteUserDialog] = useState(false);
  const { isMobile } = useCustomMediaQuery();

  const formik = useFormik({
    initialValues: {
      name: userState.currentUser?.name,
      phone: userState.currentUser?.phone,
      // contact_email: userState.currentUser?.contact_email,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log("formik values", values);
      let formData = new FormData();
      for (let key in values) {
        formData.append(key, values[key]);
      }
      updateUserData(userState.userId, dispatchUser, formData, () => {});
      setEnableEdit(false);
    },
  });

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "userProfile" },
    });
  }, []);

  useEffect(() => {
    if (formik.dirty) {
      setEnableEdit(true);
    }
  }, [formik.values]);

  function handlePhoneChange(value, formik) {
    const newPhoneValue = !!value ? value : "";
    formik.setFieldValue("phone", newPhoneValue);
  }
  return (
    <Box
      sx={{
        border: "none",
        width: "100%",
        minHeight: "100vh",
        backgroundColor: grey[300],
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {userState?.currentUser && (
        <Container
          maxWidth={isSmallOrMobile ? "xs" : "md"}
          sx={{
            display: "flex",
            justifyContent: "center",
            pt: 9,
          }}
          disableGutters
        >
          <Grid container justifyContent="center" sx={{ border: "none" }}>
            {!isSmallOrMobile && (
              <Grid sm={4} container sx={{ border: "none", px: 0.5 }}>
                <ProfilePhoto />
              </Grid>
            )}
            <Grid
              container
              sm={12}
              md={8}
              sx={{
                border: "none",
                display: "flex",
                justifyContent: "center",
                px: 0.5,
              }}
            >
              <Paper
                sx={{
                  width: "100%",
                  mb: 0.5,
                  p: 1,
                  // display: "block",
                  // justifyContent: "center",
                  backgroundColor: blue[100],
                }}
              >
                <Grid
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Stack sx={{ justifyContent: "center" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        border: "none",
                      }}
                    >
                      <CustomAvatar />
                      <Box sx={{ display: "block", ml: 1 }}>
                        <Typography
                          variant="h6"
                          color="primary.dark"
                          textAlign="left"
                        >
                          {userState.currentUser.username}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="primary.dark"
                          sx={{ mt: -0.5 }}
                        >
                          Vartotojo ID: {userState.userId}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography
                      variant="body1"
                      sx={{
                        border: "none",
                        display: "flex",
                        justifyContent: "center",
                        mx: 1,
                        mt: 2,
                      }}
                    >
                      {userState.currentUser.email}
                    </Typography>
                    <Typography
                      variant="body2"
                      textAlign="center"
                      // sx={{ mb: 2 }}
                    >
                      Narys nuo{" "}
                      {getLocalDate(userState.currentUser.date_joined)}
                    </Typography>
                  </Stack>
                </Grid>
                <Grid
                  container
                  xs={12}
                  sx={{ display: "flex", justifyContent: "left", pt: 1 }}
                >
                  <Button
                    size="small"
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                      setOpenDeleteUserDialog(true);
                    }}
                    sx={{
                      borderColor: grey[500],
                      color: grey[700],
                    }}
                  >
                    Ištrinti paskyrą
                  </Button>
                </Grid>
              </Paper>

              {isSmallOrMobile && (
                <Grid xs={12} container sx={{ border: "none", py: 1 }}>
                  <ProfilePhoto />
                </Grid>
              )}

              <Paper
                sx={{
                  width: "100%",
                  mt: 0.5,
                  px: 1,
                  // display: "block",
                  // justifyContent: "center",
                }}
              >
                <Grid
                  container
                  xs={12}
                  sx={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                    position: "relative",
                    // pt: 1,
                    // pr: 1,
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize={18}
                    sx={{
                      m: 1,
                      border: "none",
                      // display: "flex",
                      // justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    Kontaktiniai duomenys
                  </Typography>
                </Grid>
                <form
                  onSubmit={formik.handleSubmit}
                  autoComplete="off"
                  noValidate
                >
                  <Grid
                    container
                    xs={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                      mt: 2,
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={9}
                      md={6}
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Stack
                        spacing={3}
                        sx={{
                          border: "none",
                          width: "100%",
                          mb: 1,
                        }}
                      >
                        <TextField
                          id="name"
                          name="name"
                          label="Vardas, pavardė"
                          value={formik.values.name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                          fullWidth
                          variant="outlined"
                        />
                        <Input
                          international
                          value={formik.values.phone || ""}
                          onChange={(value) => handlePhoneChange(value, formik)}
                          onBlur={() => formik.setFieldTouched("phone", true)}
                          // placeholder="Telefonas"
                          defaultCountry="LT"
                          countryCallingCodeEditable={false}
                          error={formik.touched.phone && formik.errors.phone}
                          inputComponent={CustomPhoneTextField}
                          variant="outlined"
                          label="Telefonas"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    xs={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "right",
                      py: 0.5,
                      mb: 1,
                      visibility: enableEdit ? "visible" : "hidden",
                    }}
                  >
                    <Button
                      variant="outlined"
                      size="small"
                      sx={{ mr: 1 }}
                      onClick={(e) => {
                        setEnableEdit(false);
                        formik.setFieldValue(
                          "name",
                          userState.currentUser.name
                        );
                        formik.setFieldValue(
                          "phone",
                          userState.currentUser.phone
                        );
                        // formik.setFieldValue(
                        //   "contact_email",
                        //   userState.currentUser.contact_email
                        // );
                      }}
                    >
                      Atšaukti
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      disabled={!!Object.keys(formik.errors).length}
                    >
                      Išsaugoti
                    </Button>
                  </Grid>
                </form>
              </Paper>
              {/* </Stack> */}
            </Grid>
            <ClientCard />
          </Grid>
          <DeleteUserDialog
            open={openDeleteUserDialog}
            setOpen={setOpenDeleteUserDialog}
          />
        </Container>
      )}
      {userState.currentUser && <Footer />}
    </Box>
  );
}

export default UserProfile;
