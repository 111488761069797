import React, { useState, useMemo, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Chip } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import reloadAds from "../../../../utils/getUrlFromParams";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import StreetFilter from "./StreetFilter";
import StreetFilterDataProvider from "./StreetFilterDataProvider";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import CustomMenu from "../SharedComponents/CustomMenu";

export default function StreetFilterChip() {
  const { dispatch, state } = useGlobalContext();
  const { isMediumOrSmaller } = useCustomMediaQuery();

  const formikRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const params = useParamsFromUrl();

  const chipLabel = useMemo(() => {
    let chipLabel = "Gatvė";
    if (!!params?.street && !!state.streetOptionsLoaded) {
      let selectedStreetIds = params?.street.split(",");
      let selectedCount = selectedStreetIds.length;
      chipLabel = state.streetOptions.find(
        (street) => Number(street.id) === Number(selectedStreetIds[0])
      ).name;
      if (selectedCount > 1) {
        chipLabel += ` + ${selectedCount - 1}`;
      }
    }
    return chipLabel;
  }, [params?.street, state.streetOptions]);

  const handleXClick = () => {
    handleSubmit({ street: "" });
  };

  function handleSubmit(formikValues) {
    if (formikValues.street !== (params?.street || "")) {
      let newParams = { ...params, ...formikValues };
      delete newParams.page;
      reloadAds(newParams, dispatch, history);
    }
    setAnchorEl(null);
  }

  return (
    <StreetFilterDataProvider>
      {!isMediumOrSmaller && (
        <Chip
          label={chipLabel}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          onDelete={chipLabel === "Gatvė" ? null : handleXClick} // !
          variant="outlined"
          disabled={!state.streetOptions?.length}
          color={chipLabel === "Gatvė" ? "default" : "primary"}
          sx={{ borderRadius: "3px", mr: 1 }}
        />
      )}
      <CustomMenu
        formikRef={formikRef}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <StreetFilter formikRef={formikRef} handleSubmit={handleSubmit} />
      </CustomMenu>
    </StreetFilterDataProvider>
  );
}
