import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../context";
import { getAppointmentStatus } from "./utils";

export default function useAppointment(newAppointmentDate) {
  const { userState, dashboardState } = useGlobalContext();
  const { adId, enquirerId } = useParams();
  let isEnquirer = userState.userId === Number(enquirerId);

  const existingAppointment =
    dashboardState.myAppointments.find(
      (appt) => appt.ad === +adId && appt.enquirer === +enquirerId
    ) || {};
  const appointment = {
    ...existingAppointment,
    ad: +adId,
    enquirer: +enquirerId,
  };

  // const existingAppointmentDate = !!appointment?.appointment
  //   ? new Date(appointment.appointment)
  //   : null;
  const existingAppointmentDate = !!existingAppointment?.appointment
    ? new Date(existingAppointment.appointment)
    : null;
  const newAppointmentDateString = !!newAppointmentDate
    ? newAppointmentDate?.toISOString()?.split(".")[0] + "Z"
    : null;

  appointment.appointment = newAppointmentDateString;
  appointment[isEnquirer ? "appointment_enquirer" : "appointment_owner"] =
    newAppointmentDateString;
  appointment.appointment_status = getAppointmentStatus(
    isEnquirer,
    existingAppointmentDate,
    newAppointmentDate
  );

  console.log(
    "appointment from useAppointment",
    appointment,
    "existingAppointment\n",
    existingAppointment,
    "newAppointmentDate \n",
    newAppointmentDate
  );

  return { appointment };
}
