export const initialRouterState = {
  mainAppUrlString: "/",
  tab: 0,
  myAdsAd: "",
  myAdsEnquirer: "",
  myAdsExtension: "",
  myEnquiriesAd: "",
  myEnquiriesEnquirer: "",
  myEnquiriesExtension: "",
  appParams: {},
  from: "mainApp",
};

export function routerReducer(routerState, action) {
  switch (action.type) {
    case "CHANGE_ROUTER_STATE":
      const payload = action.payload;
      routerState = { ...routerState, ...payload };
      return routerState;
    case "UPDATE_DASHBOARD_ROUTES":
      let updateRouts = action.payload;
      routerState = {
        ...routerState,
        ...updateRouts,
      };
      return routerState;
    case "LOGOUT_USER":
      return { ...initialRouterState };

    default:
      throw new Error(`Unknown router action type: ${action.type}`);
  }
}
