import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useField } from "formik";

function FormikSelect({
  name,
  label,
  options,
  onChange,
  enableClear,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  const errorText = meta.touched && meta.error;

  function handleChange(e) {
    helpers.setValue(e.target.value);
    if (!!onChange) {
      onChange(e.target.value);
    }
  }

  return (
    <FormControl fullWidth error={!!errorText}>
      <InputLabel>{label}</InputLabel>
      <Select
        name={name}
        value={field.value}
        onChange={handleChange}
        onBlur={field.onBlur}
        label={label}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 200 } },
        }}
        {...props}
      >
        {enableClear && (
          <MenuItem value="" disabled={!field.value}>
            Pašalinti pasirinkimą
          </MenuItem>
        )}
        {options.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
    </FormControl>
  );
}

export default FormikSelect;
