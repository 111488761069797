import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { blue, indigo } from "@mui/material/colors";
import { useGlobalContext } from "../../../context";
import AppointmentDialog from "../MyAds/Appointment/AppointmentDialog";
import { useParams } from "react-router-dom";
import EventIcon from "@mui/icons-material/Event";
import NewCalendarEntryDialog from "./NewCalendarEntry/NewCalendarEntryDialog";
import CalendarEntry from "./CalendarEntry/CalendarEntry";
import CalendarDatePicker from "./CalendarDatePicker";
import { humanizeCalendarDate } from "../../../utils/humanizeDate";
// import CalendarDataProvider from "./CalendarDataProvider";
import CalendarFilter from "./CalendarFilter";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import Spinner from "../../../utils/Spinner";

function MyCalendar() {
  const { dashboardState } = useGlobalContext();
  let { adId, enquirerId } = useParams();
  const { isMobile } = useCustomMediaQuery();

  const [filteredEntriesOnGivenDate, setFilteredEntriesOnGivenDate] = useState(
    []
  );
  const [appointmentObject, setAppointmentObject] = useState(null);
  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const [openNewEntryDialog, setOpenNewEntryDialog] = useState(false);
  const [confirmTimeChange, setConfirmTimeChange] = useState(false);

  const [showFilter, setShowFilter] = useState(false);
  const [selectedFilterValue, setSelectedFilterValue] = useState(null);

  // close chat websocket when unmounting component
  useEffect(() => {
    return () => {
      if (dashboardState.chatSocket?.url) {
        dashboardState.chatSocket.close();
      }
    };
  }, []);

  // set appointment object from dashboardState
  useEffect(() => {
    if (!!adId && !!enquirerId && !!dashboardState?.myAppointments?.length) {
      let appointment = dashboardState.myAppointments.filter(
        (apt) =>
          Number(apt.ad) === Number(adId) &&
          Number(apt.enquirer) === Number(enquirerId)
      )[0];
      setAppointmentObject(appointment || null);
    } else {
      setAppointmentObject(null);
    }
  }, [adId, enquirerId]);

  function handleNewEntry() {
    setOpenNewEntryDialog(true);
  }

  console.log("selectedFilterValue", selectedFilterValue);
  console.log("appointmentObject from MyCalendar", appointmentObject);
  console.log("openNewEntryDialog", openNewEntryDialog);
  console.log(
    "filteredEntriesOnGivenDate",
    !!filteredEntriesOnGivenDate?.length
  );

  return (
    <>
      {/* <CalendarDataProvider> */}

      {!dashboardState.myCalendarEntriesLoaded && (
        <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
          <Spinner />
        </Box>
      )}

      {dashboardState.myCalendarEntriesLoaded && (
        <Container maxWidth="lg" disableGutters sx={{ border: "none" }}>
          <Grid xs={12} container sx={{ border: "none", mt: 1 }}>
            <Grid
              container
              // xs={12}
              // sm={7}
              sm={12}
              md={6}
              justifyContent="center"
              sx={{ border: "none", my: 1, px: 0.5 }}
            >
              <Paper
                sx={{
                  width: isMobile ? "100%" : "80%",
                  display: "flex",
                  justifyContent: "center",
                  border: "none",
                }}
              >
                <Stack>
                  <CalendarDatePicker
                    setFilteredEntriesOnGivenDate={
                      setFilteredEntriesOnGivenDate
                    }
                    selectedFilterValue={selectedFilterValue}
                  />
                  <Box
                    sx={{
                      mb: 2,
                      border: "none",
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    {!showFilter && (
                      <>
                        <Button
                          variant="contained"
                          color="info"
                          onClick={handleNewEntry}
                        >
                          <EventIcon fontSize="small" sx={{ mr: 1 }} />
                          <Typography variant="caption">
                            Naujas įrašas
                          </Typography>
                        </Button>
                        {!!openNewEntryDialog && (
                          <NewCalendarEntryDialog
                            openNewEntryDialog={openNewEntryDialog}
                            setOpenNewEntryDialog={setOpenNewEntryDialog}
                          />
                        )}
                      </>
                    )}
                    <CalendarFilter
                      showFilter={showFilter}
                      setShowFilter={setShowFilter}
                      selectedFilterValue={selectedFilterValue}
                      setSelectedFilterValue={setSelectedFilterValue}
                    />
                  </Box>
                </Stack>
              </Paper>
            </Grid>
            <Grid
              container
              // xs={12}
              sm={12}
              md={6}
              sx={{
                border: "none",
                display: "block",
                justifyContent: "center",
                my: 1,
                px: 0.5,
              }}
            >
              <Stack
                sx={{
                  border: "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Paper
                  elevation={1}
                  sx={{
                    width: isMobile ? "100%" : "80%",
                    // height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Grid
                    container
                    justifyContent="center"
                    sx={{
                      border: "none",
                    }}
                  >
                    <Grid
                      container
                      xs={12}
                      alignItems="center"
                      // justify="center"
                      sx={{
                        border: "none",
                        backgroundColor: blue[100],
                        px: 1,
                        py: 0.5,
                        justifyContent: "center",
                        borderTopLeftRadius: "3px",
                        borderTopRightRadius: "3px",
                      }}
                    >
                      <Box
                        // container
                        // xs={12}
                        sx={{
                          border: "none",
                          display: "flex",
                          alignItems: "center",
                          p: 0.5,
                        }}
                      >
                        <Typography
                          // variant={isMobile ? "caption" : "body2"}
                          variant="subtitle1"
                          sx={{ mr: 1 }}
                        >
                          {humanizeCalendarDate(dashboardState.calendarDate)}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
                <Box
                  sx={{
                    py: 1,
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    width: isMobile ? "100%" : "80%",
                  }}
                >
                  <Box sx={{ border: "none", flexGrow: 1 }}>
                    {filteredEntriesOnGivenDate.map((entry) => (
                      <Box key={entry.id}>
                        <CalendarEntry entry={entry} />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Stack>
            </Grid>
          </Grid>
        </Container>
      )}
      {/* </CalendarDataProvider> */}

      {!!enquirerId && (
        <AppointmentDialog
          openAppointmentDialog={openAppointmentDialog}
          setOpenAppointmentDialog={setOpenAppointmentDialog}
          appointmentObject={appointmentObject}
        />
      )}
    </>
  );
}

export default MyCalendar;
