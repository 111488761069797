import React, { useState } from "react";
import {
  Typography,
  Step,
  StepLabel,
  Stepper,
  Container,
  Grid,
  Paper,
  Box,
  // Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import StepContentComponent from "./StepContentComponent";
import { crudAdDraft } from "./fetchOrSendAdDraftData";
import { useGlobalContext } from "../../context";
import { useEffect } from "react";
import AdType from "./AdType";
// import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";
import { blue, grey } from "@mui/material/colors";
import Spinner from "../../utils/Spinner";
import CancelIcon from "@mui/icons-material/Cancel";
import ConfirmationDialog from "../../utils/ConfirmationDialog";
import Footer from "../Layout/Footer/Footer";

const steps = ["Informacija apie objektą", "Nuotraukų įkėlimas", "Apmokėjimas"];

export default function StepperComponent() {
  const {
    userState,
    dispatchDashboard,
    adFormState,
    dispatchAdForm,
    dispatchRouter,
  } = useGlobalContext();
  const [activeStep, setActiveStep] = useState(adFormState?.activeStep || 0);
  // const { isMobile } = useCustomMediaQuery();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);

  const ad = adFormState.adDraft;

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "createAd" },
    });
  }, []);

  useEffect(() => {
    if (userState.isAuthenticated && !adFormState.adDraftLoaded) {
      crudAdDraft(dispatchAdForm, "GET");
    }
  }, [adFormState.adDraftLoaded, dispatchDashboard, dispatchAdForm]);

  useEffect(() => {
    if (!!confirmDelete) {
      crudAdDraft(dispatchAdForm, "DELETE", null, ad.id);
      setConfirmDelete(false);
    }
  }, [confirmDelete]);

  function handleNext() {
    let newActiveStep = activeStep + 1;
    setActiveStep(newActiveStep);
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { activeStep: newActiveStep },
    });
  }
  function handleBack() {
    let newActiveStep = activeStep - 1;
    setActiveStep(newActiveStep);
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { activeStep: newActiveStep },
    });
  }

  // function handleDelete() {
  //   crudAdDraft(dispatchAdForm, "DELETE", null, ad.id);
  // }

  console.log(
    "adFormState.adDraftLoaded from Stepper",
    adFormState.adDraftLoaded
  );

  return (
    <Box
      sx={{
        border: "none",
        backgroundColor: grey[300],
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      {!adFormState.adDraftLoaded && !!adFormState.adDraftLoading && (
        <Box
          sx={{
            width: "100%",
            height: "100vh",
            display: "flex",
            border: "none",
          }}
        >
          <Spinner />
        </Box>
      )}
      {adFormState.adDraftLoaded && (
        <Box sx={{ width: "100%", border: "none" }}>
          <Container disableGutters maxWidth="md" sx={{ px: 0.5, pt: 7 }}>
            {!adFormState.adDraft ? (
              <AdType />
            ) : (
              <Box sx={{ border: "none" }}>
                <Paper sx={{ p: 0.5, mt: 2, backgroundColor: blue[100] }}>
                  <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    sx={{ position: "relative" }}
                  >
                    <Typography variant="h6">Naujas skelbimas </Typography>
                    <Tooltip title="Ištrinti skelbimą" placement="bottom">
                      <IconButton
                        variant="outlined"
                        sx={{ position: "absolute", right: -5, top: -5 }}
                        onClick={() => setOpenDeleteDialog(true)}
                      >
                        <CancelIcon color="error" />
                      </IconButton>
                    </Tooltip>
                    <ConfirmationDialog
                      open={openDeleteDialog}
                      setAgree={setConfirmDelete}
                      setOpenDialog={setOpenDeleteDialog}
                      title="Ištrinti kuriamą skelbimą?"
                      // contentText={null}
                      agreeButtonText="Taip"
                      disagreeButtonText="Ne"
                      showDisagreeButton={true}
                      alert={true}
                    />
                  </Grid>
                  <Grid container justifyContent="center">
                    <Typography variant="subtitle1">
                      {ad.properties.ad_type}
                    </Typography>
                  </Grid>
                  <Stepper
                    activeStep={activeStep}
                    // orientation="horizontal"
                    alternativeLabel
                    sx={{ mt: 2 }}
                  >
                    {steps.map((step) => (
                      <Step key={step} sx={{ border: "none" }}>
                        <StepLabel>{step}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Paper>

                <StepContentComponent
                  activeStep={activeStep}
                  handleNext={handleNext}
                  handleBack={handleBack}
                />
              </Box>
            )}
          </Container>
        </Box>
      )}
      {adFormState.adDraftLoaded && <Footer />}
    </Box>
  );
}
