import React from "react";
import { Typography, List } from "@mui/material";
import useDescription from "./useDescription";

const AccordionDetailFlat = ({ ad }) => {
  const { flat } = useDescription(ad);

  return (
    flat && (
      <>
        <Typography variant="subtitle2">Pastatas </Typography>
        <List dense>
          {flat.buildingDescription}
          {flat.renovation}
          {flat.heating}
          {flat.energyEfficiency}
          {flat.heatingBill}
        </List>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Būstas
        </Typography>
        <List dense>
          {flat.flatDescription}
          {ad.properties.ad_type === "Parduodamas butas" && (
            <>
              {flat.installation}
              {ad.properties.installation === "Pilna apdaila" &&
                flat.flatRenovation}
            </>
          )}
          {flat.equipment}
          {flat.premises}
          {flat.otherFeatures}
          {flat.partialAmenities}
        </List>

        {(flat.parking || flat.security || flat.recreation) && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Aplinka
            </Typography>
            <List dense>
              {flat.parking}
              {flat.security}
              {flat.recreation}
            </List>
          </>
        )}

        {ad.properties.sale_type.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Pardavimo sąlygos
            </Typography>
            <List dense>
              {flat.auction}
              {flat.exchange}
              {flat.preferences}
              {flat.nonnegotiable}
              {flat.noIntermediaries}
            </List>
          </>
        )}

        {ad.properties.rent_type.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Nuomos sąlygos
            </Typography>
            <List dense>
              {flat.minRentPeriod}
              {flat.preferLongerContracts}
              {flat.allowedTenants}
              {flat.depositRequired}
              {flat.formalContract}
              {flat.noRentAgency}
            </List>
          </>
        )}
      </>
    )
  );
};

export default AccordionDetailFlat;
