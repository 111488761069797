import { useCallback, useEffect, useState } from "react";

function useScrollListener(boxRef, scale, minScale) {
  const [wheelEvent, setWheelEvent] = useState(0);
  const [deltaY, setDeltaY] = useState(0);
  const [wheelScale, setWheelScale] = useState(scale);

  const handleScroll = useCallback(
    (e) => {
      e.preventDefault();
      setWheelEvent(wheelEvent + 1);
      setDeltaY(e.deltaY);
      console.log("e.deltaY", e.deltaY, "wheelEvent", wheelEvent);
    },
    [wheelEvent]
  );

  // add/remove mouse scroll listener
  useEffect(() => {
    if (!!boxRef.current) {
      boxRef.current.addEventListener("wheel", handleScroll, {
        passive: false,
      });
    }
    // return () => {
    //   alert("remove scroll listener");
    //   boxRef.current.removeEventListener("wheel", handleScroll, {
    //     passive: false,
    //   });
    // };
  }, [boxRef, handleScroll]);

  // zoom in and out with mouse wheel
  useEffect(() => {
    console.log("deltaY", deltaY);
    if (!!wheelEvent && deltaY < 0) {
      setWheelScale(Math.min(scale - (deltaY / 125) * 0.1, 3));
      setWheelEvent(0);
      setDeltaY(0);
    }
    if (!!wheelEvent && deltaY > 0) {
      setWheelScale(Math.max(scale - (deltaY / 125) * 0.1, minScale));
      setWheelEvent(0);
      setDeltaY(0);
    }
  }, [wheelEvent, deltaY, scale, minScale]);

  return { wheelScale };
}

export default useScrollListener;
