import React from "react";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import MessageController from "./MessageController";
import ViewsLikesHistory from "./ViewsLikesHistory";
import AdExpirationController from "./AdExpirationController";
import BasicAdInfo from "./BasicAdInfo";
import AdTitleAndDelete from "./AdTitleAndDelete";

export default function MyAdInfo({ ad }) {
  return (
    !!ad?.id && (
      <Box sx={{ width: "100%", pl: 1 }}>
        <AdTitleAndDelete ad={ad} />
        <BasicAdInfo ad={ad} />
        <Stack spacing={0.5} sx={{ my: 1 }}>
          <ViewsLikesHistory ad={ad} />
          <AdExpirationController ad={ad} />
        </Stack>
        <MessageController ad={ad} />
      </Box>
    )
  );
}
