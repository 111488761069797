function useFilterType(params) {
  let isFlat = false;
  let isHouse = false;
  let isLand = false;
  let isPremises = false;
  let isParking = false;
  let isApartmentComplex = false;
  let isSale = false;
  let isRent = false;

  switch (params.ad_type) {
    case "flat_sale":
      isFlat = true;
      isSale = true;
      break;
    case "flat_rent":
      isFlat = true;
      isRent = true;
      break;
    case "house_sale":
      isHouse = true;
      isSale = true;
      break;
    case "house_rent":
      isHouse = true;
      isRent = true;
      break;
    case "land_sale":
      isLand = true;
      isSale = true;
      break;
    case "land_rent":
      isLand = true;
      isRent = true;
      break;
    case "premises_sale":
      isPremises = true;
      isSale = true;
      break;
    case "premises_rent":
      isPremises = true;
      isRent = true;
      break;
    case "garage_sale":
      isParking = true;
      isSale = true;
      break;
    case "garage_rent":
      isParking = true;
      isRent = true;
      break;
    case "parking_sale":
      isParking = true;
      isSale = true;
      break;
    case "parking_rent":
      isParking = true;
      isRent = true;
      break;
    case "apartment_complex":
      isApartmentComplex = true;
      isSale = true;
      break;
    default: {
      isFlat = true;
      isSale = true;
    }
  }

  // cannot use this in detailed filter (because the params may not reflet the actual plot purpose because of formik)
  const isForest = params.plot_purpose === "forest";

  return {
    isFlat,
    isHouse,
    isLand,
    isPremises,
    isParking,
    isApartmentComplex,
    isSale,
    isRent,
    isForest,
  };
}

export default useFilterType;
