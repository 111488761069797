import * as Yup from "yup";

export const validationSchemaFlatHouse = Yup.object().shape({
  min_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  max_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .when("min_price", {
      is: (min_price) => min_price > 0,
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_price"), "Turi būti didesnė už min. reikšmę"),
    }),
  min_size: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_size: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_size", {
      is: (min_size) => min_size > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_size"), "Negali būti mažesnis už min. reikšmę"),
    }),
  area_from: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .typeError("Turi būti skaičius")
    .nullable(),
  area_to: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("area_from", {
      is: (area_from) => !!area_from,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("area_from"), "Turi būti didesnė už min. reikšmę"),
    }),
  min_rooms: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_rooms: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_rooms", {
      is: (min_rooms) => min_rooms > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_rooms"), "Turi būti didesnis už min. reikšmę"),
    }),
  min_floor: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable()
    .when("max_floors", {
      is: (max_floors) => max_floors > 0,
      then: Yup.number()
        .typeError("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .max(Yup.ref("max_floors"), "Negali viršyti pastato aukštų sk.")
        .nullable(),
    }),
  max_floor: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti teigiamas skaičius")
    .when("min_floor", {
      is: (min_floor) => min_floor > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti teigiamas skaičius")
        .min(Yup.ref("min_floor"), "Turi būti didesnė už min. reikšmę"),
    })
    .when("max_floors", {
      is: (max_floors) => max_floors > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .max(Yup.ref("max_floors"), "Negali viršyti pastato aukštų sk.")
        .nullable()
        .typeError("Turi būti teigiamas skaičius"),
    }),
  not_highest_floor: Yup.bool(),
  floor_from: Yup.number()
    .min(-5, "Minimali leidžiama reikšmė -5")
    .typeError("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  floor_to: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .min(-5, "Minimali leidžiama reikšmė -5")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("floor_from", {
      is: (floor_from) => !!floor_from,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("floor_from"), "Turi būti didesnė už min. reikšmę"),
    }),
  min_floors: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_floors: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti teigiamas skaičius")
    .when("min_floors", {
      is: (min_floors) => min_floors > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti teigiamas skaičius")
        .min(Yup.ref("min_floors"), "Turi būti didesnė už min. reikšmę"),
    }),
  min_year: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_year: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_year", {
      is: (min_year) => min_year > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_year"), "Turi būti didesnė už min. reikšmę"),
    }),
  max_heating_bill: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    // .integer("Turi būti sveikas skaičius")
    .nullable(),
  min_plot_area: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable(),
  max_plot_area: Yup.number()
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_plot_area", {
      is: (min_plot_area) => min_plot_area > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_plot_area"), "Turi būti didesnė už min. reikšmę"),
    }),
  include_renovated: Yup.bool(),
  renovated_only: Yup.bool(),
  efficient_heating: Yup.bool(),
  long_term_rent_only: Yup.bool(),
  sale_type: Yup.string(),
  building_type: Yup.string(),
  heating: Yup.string(),
  installation: Yup.string(),
  condition: Yup.string(),
  equipment: Yup.string(),
  premises: Yup.string(),
  other_features: Yup.string(),
  partial_amenities: Yup.string(),
  parking: Yup.string(),
  security: Yup.string(),
  recreation: Yup.string(),
  min_rent_period: Yup.number().positive().integer(),
  rent_type: Yup.string(),

  rent_type_all: Yup.bool(),
  sale_type_all: Yup.bool(),
  heating_all: Yup.bool(),
  equipment_all: Yup.bool(),
  premises_all: Yup.bool(),
  other_features_all: Yup.bool(),
  partial_amenities_all: Yup.bool(),
  parking_all: Yup.bool(),
  security_all: Yup.bool(),
  recreation_all: Yup.bool(),
});

export const validationSchemaLand = Yup.object().shape({
  min_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  max_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .when("min_price", {
      is: (min_price) => min_price > 0,
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_price"), "Mažesnė už min"),
    }),
  min_plot_area: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable(),
  max_plot_area: Yup.number()
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_plot_area", {
      is: (min_plot_area) => min_plot_area > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_plot_area"), "Turi būti didesnis už min. reikšmę"),
    }),
  min_fertility_score: Yup.number()
    .typeError("Turi būti skaičius tarp 0 ir 100")
    .positive("Turi būti skaičius tarp 0 ir 100")
    .max(100, "Turi būti skaičius tarp 0 ir 100")
    .nullable(),
  max_fertility_score: Yup.number()
    .positive("Turi būti skaičius tarp 0 ir 100")
    .nullable()
    .max(100, "Turi būti skaičius tarp 0 ir 100")
    .typeError("Turi būti skaičius tarp 0 ir 100")
    .when("min_fertility_score", {
      is: (min_fertility_score) => min_fertility_score > 0,
      then: Yup.number()
        .integer("Turi būti skaičius tarp 0 ir 100")
        .positive("Turi būti skaičius tarp 0 ir 100")
        .max(100, "Turi būti skaičius tarp 0 ir 100")
        .nullable()
        .typeError("Turi būti skaičius tarp 0 ir 100")
        .min(
          Yup.ref("min_fertility_score"),
          "Turi būti didesnis už min. reikšmę"
        ),
    }),
});

export const validationSchemaParking = Yup.object().shape({
  min_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  max_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .when("min_price", {
      is: (min_price) => min_price > 0,
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_price"), "Mažesnė už min"),
    }),
  min_size: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_size: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_size", {
      is: (min_size) => min_size > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_size"), "Negali būti mažesnis už min. reikšmę"),
    }),
});

export const validationSchemaApartmentComplex = Yup.object().shape({
  price_from: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  price_to: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .when("price_from", {
      is: (price_from) => price_from > 0,
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("price_from"), "Turi būti didesnė už min. reikšmę"),
    }),
  rooms_from: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  rooms_to: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .when("price_from", {
      is: (rooms_from) => rooms_from > 0,
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("rooms_from"), "Turi būti didesnė už min. reikšmę"),
    }),
  area_from: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .typeError("Turi būti skaičius")
    .nullable(),
  area_to: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("area_from", {
      is: (area_from) => !!area_from,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("area_from"), "Turi būti didesnė už min. reikšmę"),
    }),
  floors_from: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .typeError("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  floors_to: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("floors_from", {
      is: (floors_from) => !!floors_from,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("floors_from"), "Turi būti didesnė už min. reikšmę"),
    }),
  min_year: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_year: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_year", {
      is: (min_year) => min_year > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_year"), "Turi būti didesnė už min. reikšmę"),
    }),
});

export const validationSchemaPriceFilter = Yup.object().shape({
  min_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  max_price: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .when("min_price", {
      is: (min_price) => min_price > 0,
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_price"), "Turi būti didesnė už min. reikšmę"),
    }),
  price_from: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius"),
  price_to: Yup.number("Turi būti skaičius")
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .when("price_from", {
      is: (price_from) => price_from > 0,
      then: Yup.number("Turi būti skaičius")
        .positive("Turi būti teigiamas skaičius")
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("price_from"), "Turi būti didesnė už min. reikšmę"),
    }),
});

export const validationSchemaRoomFilter = Yup.object().shape({
  min_size: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_size: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_size", {
      is: (min_size) => min_size > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_size"), "Turi būti didesnė už min. reikšmę"),
    }),
  // for land plot size
  min_plot_area: Yup.number()
    .typeError("Turi būti skaičius")
    .positive("Turi būti teigiamas skaičius")
    .integer("Turi būti sveikas skaičius")
    .nullable(),
  max_plot_area: Yup.number()
    .integer("Turi būti sveikas skaičius")
    .positive("Turi būti teigiams skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("min_plot_area", {
      is: (min_plot_area) => min_plot_area > 0,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .positive("Turi būti teigiamas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("min_plot_area"), "Turi būti didesnė už min. reikšmę"),
    }),
  // for area ranges (premises, apartment complexes)
  area_from: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .typeError("Turi būti skaičius")
    .nullable(),
  area_to: Yup.number()
    .positive("Turi būti teigiamas skaičius")
    .nullable()
    .typeError("Turi būti skaičius")
    .when("area_from", {
      is: (area_from) => !!area_from,
      then: Yup.number()
        .integer("Turi būti sveikas skaičius")
        .nullable()
        .typeError("Turi būti skaičius")
        .min(Yup.ref("area_from"), "Turi būti didesnė už min. reikšmę"),
    }),
});
