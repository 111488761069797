import React from "react";
import { TextField } from "@mui/material";

const CustomPhoneTextField = React.forwardRef((props, ref) => {
  return (
    <TextField
      inputRef={ref}
      label="Telefonas *"
      variant="outlined"
      error={Boolean(props.error)}
      helperText={props.error}
      {...props}
    />
  );
});

export default CustomPhoneTextField;
