import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

export default function useCustomMediaQuery() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isMedium = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const isLarge = useMediaQuery(theme.breakpoints.between("lg", "xl"));
  const isExtraLarge = useMediaQuery(theme.breakpoints.up("xl"));
  const isSmallOrMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumOrSmaller = useMediaQuery(theme.breakpoints.down("lg"));
  const isLargeOrSmaller = useMediaQuery(theme.breakpoints.down("xl"));

  return {
    isMobile,
    isSmall,
    isMedium,
    isLarge,
    isExtraLarge,
    isSmallOrMobile,
    isMediumOrSmaller,
    isLargeOrSmaller,
  };
}
