export default function humanizeStatus(status, agent) {
  let result;
  if (agent === "owner") {
    switch (status) {
      case "owner proposed appointment":
        result = "Pasiūliau apžiūros laiką";
        break;
      case "owner changed appointment":
        result = "Pakeičiau apžiūros laiką";
        break;
      case "owner canceled appointment":
        result = "Atšaukiau apžiūros laiką";
        break;
      case "enquirer proposed appointment":
        result = "Pasiūlė apžiūros laiką";
        break;
      case "enquirer changed appointment":
        result = "Pakeitė apžiūros laiką";
        break;
      case "enquirer canceled appointment":
        result = "Atšaukė apžiūros laiką";
        break;
      case "enquirer confirmed appointment":
        result = "Apžiūros laikas (suderintas)";
        break;
      case "owner confirmed appointment":
        result = "Apžiūros laikas (suderintas)";
        break;
      case "enquirer confirmed appointment cancelation":
        result = "Apžiūros laikas";
        break;
      case "owner confirmed appointment cancelation":
        result = "Apžiūros laikas";
        break;

      case "appointment":
        result = "Apžiūros laikas";
        break;
      case "appointment confirmed":
        result = "Apžiūros laikas (suderintas)";
        break;
      default:
        console.log("no such status description");
    }
  } else if (agent === "enquirer") {
    switch (status) {
      case "owner proposed appointment":
        result = "Pasiūlė apžiūros laiką";
        break;
      case "owner changed appointment":
        result = "Pakeitė apžiūros laiką";
        break;
      case "owner canceled appointment":
        result = "Atšaukė apžiūros laiką";
        break;
      case "enquirer proposed appointment":
        result = "Pasiūliau apžiūros laiką";
        break;
      case "enquirer changed appointment":
        result = "Pakeičiau apžiūros laiką";
        break;
      case "enquirer canceled appointment":
        result = "Atšaukiau apžiūros laiką";
        break;
      case "owner confirmed appointment":
        result = "Apžiūros laikas (suderintas)";
        break;
      case "enquirer confirmed appointment":
        result = "Apžiūros laikas (suderintas)";
        break;
      case "owner confirmed appointment cancelation":
        result = "Apžiūros laikas";
        break;
      case "enquirer confirmed appointment cancelation":
        result = "Apžiūros laikas";
        break;

      case "appointment":
        result = "Apžiūros laikas";
        break;
      case "appointment confirmed":
        result = "Apžiūros laikas (suderintas)";
        break;
      default:
        console.log("no such status description");
    }
  } else if (agent === "both") {
    switch (status) {
      case "owner proposed appointment":
        result = "pasiūlė apžiūros laiką";
        break;
      case "owner changed appointment":
        result = "pakeitė apžiūros laiką";
        break;
      case "owner canceled appointment":
        result = "atšaukė apžiūros laiką";
        break;
      case "owner confirmed appointment":
        result = "patvirtino apžiūros laiką";
        break;
      case "owner confirmed appointment cancelation":
        result = "patvirtino apžiūros atšaukimą";
        break;
      case "enquirer proposed appointment":
        result = "pasiūlė apžiūros laiką";
        break;
      case "enquirer changed appointment":
        result = "pakeitė apžiūros laiką";
        break;
      case "enquirer canceled appointment":
        result = "atšaukė apžiūros laiką";
        break;
      case "enquirer confirmed appointment":
        result = "patvirtino apžiūros laiką";
        break;
      case "enquirer confirmed appointment cancelation":
        result = "patvirtino apžiūros atšaukimą";
        break;
      case "appointment confirmed":
        result = "patvirtino apžiūros laiką";
        break;
      case "appointment":
        result = "patvirtino apžiūros atšaukimą";
        break;
      default:
        console.log("no such status description");
    }
  }
  return result;
}
