import React, { useEffect } from "react";
import { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Stack,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useGlobalContext } from "../../../context";
import Downpayment from "./Downpayment";
import DownpaymentSlider from "./DownpaymentSlider";
import MonthlyPayment from "./MonthlyPayment";
import DurationSlider from "./DurationSlider";
import InterestRate from "./InterestRate";
import LoanSize from "./LoanSize";
import NetIncome from "./NetIncome";
import Price from "./Price";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

const AccordionLoanCalculator = ({ ad }) => {
  const { isMobile } = useCustomMediaQuery();
  const { loanState, dispatchLoan } = useGlobalContext();

  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);

  function handleSecondLoan(value) {
    let checked = value.target.checked;
    setChecked(checked);
    console.log("value from handleSecondLoan", value.target.checked);
    if (checked) {
      dispatchLoan({
        type: "CHANGE_LOAN_STATE",
        payload: { minDownPercent: 30 },
      });
    } else {
      dispatchLoan({
        type: "CHANGE_LOAN_STATE",
        payload: { minDownPercent: 15 },
      });
    }
  }

  useEffect(() => {
    return () => {
      dispatchLoan({ type: "CHANGE_LOAN_STATE_TO_INITIAL" });
    };
  }, [dispatchLoan]);

  return (
    <>
      <Accordion
        expanded={expanded}
        onChange={(event, expanded) => {
          setExpanded(expanded);
        }}
        sx={{ width: "100%" }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography variant="button" color={expanded ? "primary" : "default"}>
            Paskolos skaičiuoklė
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ display: "block" }}>
          {isMobile && (
            <>
              {/* <Grid container sx={{ border: "none", display: "block" }}> */}
              <Grid container justifyContent="center" sx={{ border: "none" }}>
                <Grid item xs={6} sx={{ pr: 0.5 }}>
                  <Price ad={ad} />
                </Grid>
                <Grid container item xs={6} sx={{ pl: 0.5 }}>
                  <InterestRate />
                </Grid>
                <Grid item xs={9} sx={{ mt: 4 }}>
                  <DownpaymentSlider />
                </Grid>
                <Grid item xs={6} sx={{ mt: 1, pr: 0.5 }}>
                  <Downpayment />
                </Grid>
                <Grid item xs={6} sx={{ mt: 1, pl: 0.5 }}>
                  <LoanSize />
                </Grid>
                <Grid item xs={9} sx={{ mt: 4 }}>
                  <DurationSlider />
                </Grid>
                <Grid item xs={12} sx={{ mt: 1, pr: 0.5 }}>
                  <MonthlyPayment />
                </Grid>
              </Grid>

              <Grid container spacing={1} alignItems="center" sx={{ mt: 2 }}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
                    Atsakingo skolinimo reikalavimai
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <Typography variant="body2">
                    Šeimos grynosios pajamos
                  </Typography>
                </Grid>
                <Grid item xs={6} sx={{ mt: 2 }}>
                  <NetIncome />
                </Grid>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  <Box sx={{ border: "none" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={handleSecondLoan}
                          color="primary"
                        />
                      }
                      label={
                        <Typography
                          variant="body2"
                          sx={{ border: "none", ml: -2 }}
                        >
                          Ne pirmas būstas
                        </Typography>
                      }
                      labelPlacement="start"
                    />
                    {checked &&
                      loanState.downPercent < loanState.minDownPercent && (
                        <FormHelperText error>
                          Nepakankamas pradinis įnašas (min 30%)
                        </FormHelperText>
                      )}
                  </Box>
                </Grid>
              </Grid>
            </>
          )}

          {!isMobile && (
            <>
              <Grid container sx={{ border: "none", display: "block" }}>
                <Grid container justifyContent="center">
                  <Price ad={ad} />
                </Grid>
              </Grid>

              <Grid
                container
                sx={{
                  mt: 6,
                  border: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "start",
                }}
              >
                <Box>
                  <Downpayment />
                </Box>
                <Box
                  sx={{
                    border: "none",
                    flexGrow: 1,
                    px: { xs: 3, sm: 6 },
                    display: "flex",
                    alignItems: "top",
                  }}
                >
                  <DownpaymentSlider />
                </Box>
                <Box sx={{ border: "none" }}>
                  <LoanSize />
                </Box>
              </Grid>

              <Grid
                container
                sx={{
                  marginTop: 4,
                  border: "none",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <MonthlyPayment />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1,
                    px: { xs: 3, sm: 6 },
                    display: "flex",
                    alignItems: "top",
                    justifyContent: "space-between",
                    border: "none",
                  }}
                >
                  <DurationSlider />
                </Box>
                <Box>
                  <InterestRate />
                </Box>
              </Grid>

              <Grid
                container
                sx={{
                  mt: 1,
                  justifyContent: "center",
                }}
              >
                <Typography variant="subtitle2">
                  Atsakingo skolinimo reikalavimai
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ArrowRightIcon />
                <Box sx={{ border: "none", flexGrow: 1, mr: 2, wrap: true }}>
                  <Typography variant="caption" sx={{ whiteSpace: "normal" }}>
                    Įmoka bankui negali viršyti 40% šeimos grynųjų pajamų
                  </Typography>
                </Box>
                <Box sx={{ border: "none" }}>
                  <NetIncome />
                </Box>
              </Grid>
              <Grid
                item
                sx={{
                  mt: 1,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <ArrowRightIcon />
                <Box sx={{ border: "none", flexGrow: 1, mr: 2, wrap: true }}>
                  <Typography variant="caption" sx={{ whiteSpace: "normal" }}>
                    Ne pirmo būsto paskoloms minimalus pradinis inašas turi
                    siekti 30% būsto vertės
                  </Typography>
                </Box>
                <Box sx={{ border: "none" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={handleSecondLoan}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="caption">
                        Ne pirmas būstas
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                  {loanState.downPercent < loanState.minDownPercent && (
                    <FormHelperText error>
                      Nepakankamas pradinis įnašas
                    </FormHelperText>
                  )}
                </Box>
              </Grid>
            </>
          )}

          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            sx={{ width: "100%", alignItems: "center", my: 2 }}
          >
            <WarningAmberIcon color="warning" fontSize="small" />
            <Typography variant="caption" sx={{ mb: 2 }}>
              Ši skaičiuoklė yra orientacinio pobūdžio. Norėdami gauti tikslią
              informaciją apie savo paskolos sąlygas, kreipkitės į bankus arba
              paskolų specialistus.
            </Typography>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default AccordionLoanCalculator;
