import { Alert, IconButton, Slide, Snackbar } from "@mui/material";
import React from "react";
import { useGlobalContext } from "../../context";
import CloseIcon from "@mui/icons-material/Close";

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

export default function SnackbarComponent() {
  const { userState, dispatchUser } = useGlobalContext();
  const snackbar = userState.snackbar;

  function handleClose() {
    snackbar.open = false;
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { snackbar },
    });
  }

  return (
    <>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={5000}
        onClose={handleClose}
        // message="Rūšiavimas pakeistas į numatytąjį"
        anchorOrigin={{
          vertical: snackbar.vertical,
          horizontal: snackbar.horizontal,
        }}
        // action={
        //   <IconButton
        //     aria-label="close"
        //     color="inherit"
        //     sx={{ p: 0.5 }}
        //     onClick={handleClose}
        //   >
        //     <CloseIcon />
        //   </IconButton>
        // }
        TransitionComponent={TransitionDown}
      >
        <Alert
          variant="standard"
          severity={snackbar.severity}
          //   sx={{ display: "flex", justifyContent: "center" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  );
}
