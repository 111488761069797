import React from "react";
import { TextField } from "@mui/material";
import { useField } from "formik";

export default function FormikTextField({
  name,
  label,
  onChange,
  onBlur,
  ...props
}) {
  const [field, meta] = useField(name);
  const errorText = meta.touched && meta.error;

  function handleChange(e) {
    field.onChange(e);
    if (!!onChange) {
      onChange(e.target.value);
    }
  }

  function handleBlur(e) {
    field.onBlur(e);
    if (!!onBlur) {
      onBlur(e.target.value);
    }
  }

  return (
    <TextField
      {...field}
      onChange={handleChange}
      onBlur={handleBlur}
      label={label}
      error={!!errorText}
      helperText={errorText}
      fullWidth
      variant="outlined"
      type="text"
      autoComplete="off"
      autoCorrect="off"
      spellCheck="false"
      {...props}
    />
  );
}
