import { useEffect, useState } from "react";
import { useGlobalContext } from "../../../../context";
import { fetchNotifications } from "../fetchHeaderData";
import {
  fetchSelectedAds,
  crudAppointment,
  listAdContacts,
  listCreateEnquiryMessage,
  crudCalendarEntry,
} from "../../../Dashboard/fetchOrSendDashboardData";
import useNotificationSocket from "./useNotificationSocket";
import { crudClientInfo } from "../../../Accounts/fetchOrSendAccountsData";

export default function NotificationDataProvider({ children }) {
  const {
    notificationState,
    dispatchNotification,
    dashboardState,
    dispatchDashboard,
    userState,
    dispatchUser,
  } = useGlobalContext();

  useNotificationSocket();

  // update notifications list every time user logs in or new notification is received through websocket
  useEffect(() => {
    if (!!userState.isAuthenticated) {
      if (
        !notificationState.notificationsLoaded &&
        !notificationState.notificationsLoading
      )
        fetchNotifications(dispatchNotification);
      if (!dashboardState.myAdsLoaded && !dashboardState.selectedAdsLoading) {
        fetchSelectedAds(dispatchDashboard, "my_ads");
      }
      if (!userState.clientInfoLoaded && !userState.clientInfoLoading)
        crudClientInfo(dispatchUser, userState.userId, "GET");

      if (
        !dashboardState.myEnquiryAdsLoaded &&
        !dashboardState.selectedAdsLoading
      )
        fetchSelectedAds(dispatchDashboard, "my_enquiry_ads");
      if (
        !dashboardState.myAppointmentsLoaded &&
        !dashboardState.myAppointmentsLoading
      ) {
        crudAppointment(dispatchDashboard, null, "get");
      }
      if (
        !dashboardState.myCalendarEntriesLoaded &&
        !dashboardState.myCalendarEntriesLoading
      ) {
        crudCalendarEntry(dispatchDashboard, null, "get");
      }
      if (!dashboardState.adContactsLoaded && !dashboardState.adContactsLoading)
        listAdContacts(dispatchDashboard);
      if (!dashboardState.enquiriesLoaded && !dashboardState.enquiriesLoading) {
        listCreateEnquiryMessage(dispatchDashboard, "get");
      }
    }
  }, [
    notificationState.notificationsLoaded,
    // notificationState.notificationsLoading,
    userState.isAuthenticated,
    dashboardState.myAdsLoaded,
    dashboardState.myEnquiryAdsLoaded,
    // dashboardState.selectedAdsLoading,
    dispatchDashboard,
    dispatchNotification,
    dashboardState.myAppointmentsLoaded,
    // dashboardState.myAppointmentsLoading,
    dashboardState.myCalendarEntriesLoaded,
    // dashboardState.myCalendarEntriesLoading,
    dashboardState.adContactsLoaded,
    // dashboardState.adContactsLoading,
    dashboardState.enquiriesLoaded,
    // dashboardState.enquiriesLoading,
    userState.userId,
    userState.clientInfoLoaded,
    // userState.clientInfoLoading,
    dispatchUser,
  ]);

  console.log("notificationState", notificationState);

  return !!notificationState?.notifications?.length && children;
}
