import React, { useEffect, useMemo } from "react";
import { useGlobalContext } from "../../../context";
import Grid from "@mui/material/Grid";
import AdCard from "./AdCard";
import { useLocation } from "react-router-dom";

const AdList = React.memo(() => {
  const { state, dispatchRouter } = useGlobalContext();
  const location = useLocation();

  useEffect(() => {
    const mainAppUrlString = location.pathname + location.search;
    console.log("mainAppUrlString", mainAppUrlString);
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { mainAppUrlString },
    });
  }, [dispatchRouter, location.pathname, location.search]);

  console.log("mainAppState", state);
  console.log("render AdList component");

  // const memoizedAdCards = useMemo(() => state.adCards, [state.adCards]);

  return (
    // !!state?.adCards?.length &&
    <Grid container sx={{ border: "none", mt: 0 }} spacing={2}>
      {state.adCards.map((ad) => {
        return (
          <React.Fragment key={ad.id}>
            {!!state.adCardsLoaded && !state?.adCardsLoading && (
              <Grid
                item
                // key={ad.id}
                xs={12}
                md={!state.showMap ? 6 : 12}
                lg={!state.showMap ? 4 : 12}
                sx={{
                  // mt: 2,
                  border: "none",
                  display: "flex",
                  justifyContent: "center",
                  // px: !state.showMap ? 0.5 : 0,
                }}
                // spacing={2}
              >
                <AdCard ad={ad} />
              </Grid>
            )}
          </React.Fragment>
        );
      })}
    </Grid>
  );
});

export default AdList;
