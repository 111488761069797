import React from "react";
import { Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useGlobalContext } from "../../../../context";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import CustomAvatar from "../../../Layout/CustomAvatar";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { grey } from "@mui/material/colors";

export default function ChatHeader() {
  const { dashboardState } = useGlobalContext();
  let { adId } = useParams();
  const { isMobile, isSmallOrMobile } = useCustomMediaQuery();
  const enquiry = dashboardState.adContacts.find(
    (enquiry) => Number(enquiry.ad) === Number(adId)
  );

  return (
    !!enquiry && (
      <Box
        sx={{
          border: "none",
          display: "flex",
          width: "100%",
          // ml: 2,
        }}
      >
        <Box sx={{ flexShrink: 1, mx: 0.5, my: 0.15 }}>
          <CustomAvatar
            userId={enquiry.owner}
            name={enquiry.owner_name}
            styleProps={{
              width: "40px",
              height: "40px",
              border: "solid 1px grey",
              ml: 1,
              my: 1,
            }}
          />
        </Box>
        <Box
          sx={{
            display:
              !!enquiry.owner_phone ||
              ["expired", "deleted", "blocked"].includes(enquiry.ad_status)
                ? "block"
                : "flex",
            flexGrow: 1,
            justifyContent: "left",
            alignItems: "center",
            mt: isMobile ? 1.2 : 0.7,
            pl: 1,
          }}
        >
          <Box sx={{ alignItems: "center", display: "flex", border: "none" }}>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "200px" : "400px",
              }}
              color="initial"
            >
              {enquiry.owner_name}
            </Typography>
          </Box>

          {!!enquiry.owner_phone && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneIphoneIcon
                color="action"
                sx={{
                  width: isSmallOrMobile ? "12px" : "15px",
                  height: isSmallOrMobile ? "12px" : "15px",
                  mr: 0.5,
                  mb: 0.2,
                }}
              />
              <Typography
                variant={isSmallOrMobile ? "caption" : "body2"}
                color={grey[700]}
              >
                {formatPhoneNumberIntl(enquiry.owner_phone)}
              </Typography>
            </Box>
          )}

          {enquiry.ad_status === "expired" && (
            <Typography variant="caption" color="error">
              Skelbimas nebegalioja
            </Typography>
          )}
          {enquiry.ad_status === "deleted" && (
            <Typography variant="caption" color="error">
              Skelbimas ištrintas
            </Typography>
          )}
          {enquiry.ad_status === "blocked" && (
            <Typography variant="caption" color="error">
              Skelbimas užblokuotas
            </Typography>
          )}

          {/* {!!enquiry.owner_email &&
            (!isSmallOrMobile || !enquiry.owner_phone) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "none",
                  ml: 3,
                }}
              >
                <MailIcon
                  // fontSize="small"
                  color="action"
                  sx={{
                    width: "14px",
                    height: "14px",
                    mr: 0.5,
                  }}
                />
                <Typography
                  variant={isSmallOrMobile ? "caption" : "body1"}
                  color="initial"
                >
                  {enquiry.owner_email}
                </Typography>
              </Box>
            )} */}
        </Box>

        <Box sx={{ border: "none", flexShrink: 1, mr: 0, my: "auto" }}>
          <CustomAvatar
            styleProps={{
              width: "40px",
              height: "40px",
              border: "solid 1px grey",
              mr: 1,
            }}
          />
        </Box>
      </Box>
    )
  );
}
