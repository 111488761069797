import React from "react";
import LocationFilter from "./LocationFilter";
import FiltersFlat from "./FiltersFlat";
import FiltersAd from "./FiltersAd";
import useFilterType from "../hooks/useFilterType";
import FiltersHouse from "./FiltersHouse";
import FiltersLand from "./FiltersLand";
import FiltersPremises from "./FiltersPremises";
import FiltersParking from "./FiltersParking";
import FiltersApartmentComplex from "./FiltersApartmentComplex";
import { Form, Formik } from "formik";
import getFormikProps from "../hooks/getFormikProps";
// import FormPreview from "../../../AdForm/utils/FormPreview";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import MunicipalityFilterDataProvider from "../MunicipalityFilter/MunicipalityFilterDataProvider";
import TownFilterDataProvider from "../TownFilter/TownFilterDataProvider";
import SuburbFilterDataProvider from "../SuburbFilter/SuburbFilterDataProvider";
import StreetFilterDataProvider from "../StreetFilter/StreetFilterDataProvider";
import { Stack } from "@mui/material";

function DetailedFilter({ formikRef, handleSubmit }) {
  const params = useParamsFromUrl();
  const formikProps = getFormikProps(params, handleSubmit);
  const {
    isFlat,
    isHouse,
    isLand,
    isPremises,
    isGarage,
    isParking,
    isApartmentComplex,
  } = useFilterType(params);

  return (
    <Stack sx={{ mb: 4 }}>
      <Formik innerRef={formikRef} {...formikProps}>
        <Form autoComplete="off" noValidate>
          <MunicipalityFilterDataProvider>
            <TownFilterDataProvider>
              <SuburbFilterDataProvider>
                <StreetFilterDataProvider>
                  <LocationFilter />
                </StreetFilterDataProvider>
              </SuburbFilterDataProvider>
            </TownFilterDataProvider>
          </MunicipalityFilterDataProvider>
          {isFlat && <FiltersFlat />}
          {isHouse && <FiltersHouse />}
          {isLand && <FiltersLand />}
          {isPremises && <FiltersPremises />}
          {(isGarage || isParking) && <FiltersParking />}
          {isApartmentComplex && <FiltersApartmentComplex />}
          <FiltersAd />
          {/* <FormPreview /> */}
        </Form>
      </Formik>
    </Stack>
  );
}

export default DetailedFilter;
