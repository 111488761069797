import axiosInstance from "../../utils/axios";

export function fetchAdCards(params, dispatch) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { adCardsLoading: true },
  });

  return axiosInstance
    .get(`api/ads/ad-cards/`, { params })
    .then((res) => {
      console.log("server response: adCards loaded", res);
      let adCards = res.data.results.features;
      let resultCount = res.data.count;
      let resultsPerPage = +params?.ads_per_page || 20;
      let totalPages = Math.floor(resultCount / resultsPerPage);
      totalPages !== resultCount / resultsPerPage && ++totalPages;
      let page = params?.page || 1;
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          adCards,
          adCardsLoaded: true,
          resultCount,
          totalPages,
          resultsPerPage,
          page,
          previousScrollPosition: 0,
        },
      });
      return adCards;
    })
    .catch((error) => {
      console.log("server response: Failed to fetch adCards", error);
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adCardsLoading: false, adCardsLoaded: true },
      });
    });
}

export function fetchAds(params, dispatch) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { adsLoading: true },
  });

  return axiosInstance
    .get(`api/ads/`, { params })
    .then((res) => {
      console.log("server response: ads loaded", res.data.results.features);
      const ads = res.data.results.features;
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { ads, adsLoaded: true },
      });
      return ads;
    })
    .catch((error) => {
      console.log("server response: Failed to fetch ads", error);
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adsLoading: false, adsLoaded: true },
      });
    });
}

export function fetchMunicipalityOptions(dispatch) {
  const url = "api/addresses/municipality-options/";
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: {
      municipalityOptionsLoading: true,
    },
  });
  axiosInstance
    .get(url)
    .then((res) => {
      console.log("server response: municipalityOptions fetched", res);
      let municipalityOptions = res.data;
      // let selectedMunicipalities = changeCityNames(
      //   res.data.selected_value.municipality
      // );
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          municipalityOptions,
          municipalityOptionsLoaded: true,
        },
      });
    })
    .catch(function (error) {
      console.log(
        "Server response: failed to fetch municipalityOptions",
        error
      );
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          municipalityOptionsLoading: false,
          municipalityOptionsLoaded: true,
        },
      });
    });
}

export function fetchTownOptions(params, dispatch, setTownOptions) {
  const url = "api/addresses/town-options/";
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: {
      townOptionsLoading: true,
    },
  });

  axiosInstance
    .get(url, { params })
    .then((res) => {
      console.log(
        "server response: town options successfully fetched",
        res,
        !!setTownOptions
      );
      let townOptions = res.data;
      if (!!setTownOptions) {
        setTownOptions(townOptions);
      } else {
        dispatch({
          type: "CHANGE_APP_STATE",
          payload: {
            townOptions,
            townOptionsLoaded: true,
          },
        });
      }
    })
    .catch((error) =>
      console.log("server response: Failed to fetch town options", error)
    )
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          townOptionsLoading: false,
        },
      });
    });
}

export function fetchSuburbOptions(params, dispatch, setSuburbOptions) {
  const url = "api/addresses/suburb-options/";
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: {
      suburbOptionsLoading: true,
    },
  });

  axiosInstance
    .get(url, { params })
    .then((res) => {
      console.log("server response: suburb options successfully fetched", res);
      let suburbOptions = res.data;
      if (!!setSuburbOptions) {
        setSuburbOptions(res.data);
      } else {
        dispatch({
          type: "CHANGE_APP_STATE",
          payload: {
            suburbOptions,
            suburbOptionsLoaded: true,
          },
        });
      }
    })
    .catch((error) =>
      console.log("server response: Failed to fetch suburb options", error)
    )
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          suburbOptionsLoading: false,
        },
      });
    });
}

// // Delete when ready
// export function fetchLocationOptions(params, dispatch) {
//   const endpoints = [
//     "api/addresses/town-options/",
//     "api/addresses/suburb-options/",
//   ];

//   Promise.all(
//     endpoints.map((endpoint) => axiosInstance.get(endpoint, { params }))
//   )
//     .then((res) => {
//       console.log(
//         "server response: result of concurrent fetch of location options",
//         res
//       );
//       let townOptions = res[0].data.results;
//       let selectedTowns = res[0].data.selected_value.town;
//       let suburbOptions = res[1].data.results.map((s) => {
//         return { id: -s.id, name: s.name };
//       });
//       let selectedSuburbs =
//         res[1].data.selected_value.suburb?.map((s) => {
//           return { id: -s.id, name: s.name };
//         }) || [];
//       let locationOptions = suburbOptions.concat(townOptions);
//       locationOptions.sort((a, b) =>
//         a.name.toLowerCase().localeCompare(b.name.toLowerCase())
//       );

//       dispatch({
//         type: "CHANGE_APP_STATE",
//         payload: {
//           townOptions,
//           selectedTowns,
//           townOptionsLoaded: true,
//           suburbOptions,
//           selectedSuburbs,
//           suburbOptionsLoaded: true,
//           locationOptions,
//           locationOptionsLoaded: true,
//         },
//       });
//       //   changeChipLabel(params, townOptions, suburbOptions, locationOptions);
//     })
//     .catch((error) =>
//       console.log(
//         "server response: Failed to fetch location options data",
//         error
//       )
//     );
// }

export function fetchStreetOptions(params, dispatch, setStreetOptions) {
  const url = "api/addresses/street-options/";
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: {
      streetOptionsLoading: true,
    },
  });
  axiosInstance
    .get(url, { params })
    .then((res) => {
      console.log("server response: street options fetched", res.data);
      let streetOptions = res.data;
      if (!!setStreetOptions) {
        setStreetOptions(streetOptions);
      } else {
        dispatch({
          type: "CHANGE_APP_STATE",
          payload: {
            streetOptions,
            streetOptionsLoaded: true,
          },
        });
      }
    })
    .catch(function (error) {
      console.log(
        "server response: Failed to fetch street options data",
        error
      );
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          streetOptionsLoading: false,
        },
      });
    });
}

export function fetchLocationGeom(params, dispatch) {
  const apiUrl = "/api/addresses/geom/";
  axiosInstance
    .get(apiUrl, { params })
    .then((res) => {
      console.log("server response: fetched geom data", res.data);
      let geom = res.data.map((x) => x.geom);

      // to deal with a bug
      // if (geom[0][0] < 50) {
      //   geom = geom.map((x) => x.reverse());
      // }
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { geom },
      });
    })
    .catch(function (error) {
      console.log("server response: Failed to fetch geom data", error);
    });
}

export function fetchLikedAdIds(params, dispatch) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { likedAdIdsLoading: true },
  });
  axiosInstance
    .post("api/ads/like/", params)
    .then((res) => {
      console.log("server response: fetched liked ad ids", res.data);
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { likedAds: res.data, likedAdIdsLoaded: true },
      });
      // setLikedAds(res.data);
      // handleLike(res.data);
    })
    .catch(function (error) {
      console.log("server response: Failed to load liked ad ids", error);
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { likedAdIdsLoading: false },
      });
    });
}

export function fetchOwnerAds(dispatch, params) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { ownerAdsLoading: true },
  });

  axiosInstance
    .get("api/ads/owner-ads/", { params })
    .then((res) => {
      console.log(
        `server response: owner ads successfully loaded`,
        res.data.features
      );
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { ownerAds: res.data.features, ownerAdsLoaded: true },
      });
    })
    .catch((error) => {
      console.log(`Failed to load ownerAds`, error);
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { ownerAdsLoading: false },
      });
    });
}

export function fetchPrivacyPolicy(dispatch) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { privacyPolicyLoading: true },
  });
  return axiosInstance
    .get("api/policies/privacy-policy/")
    .then((res) => {
      console.log("server response: fetched privacy policy", res.data);
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { privacyPolicy: res.data, privacyPolicyLoaded: true },
      });
      return res.data;
    })
    .catch((error) => {
      console.log("server response: failed to fetch privacy policy", error);
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { privacyPolicyLoading: false },
      });
    });
}

export function fetchTermsOfUse(dispatch) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { termsOfUseLoading: true },
  });
  return axiosInstance
    .get("api/policies/terms-of-use/")
    .then((res) => {
      console.log("server response: fetched terms of use", res.data);
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { termsOfUse: res.data, termsOfUseLoaded: true },
      });
      return res.data;
    })
    .catch((error) => {
      console.log("server response: failed to fetch terms of use", error);
      return Promise.reject(error);
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { termsOfUseLoading: false },
      });
    });
}
