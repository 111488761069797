import React, { useRef, useEffect, useState } from "react";
import L from "leaflet";
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  ZoomControl,
  useMap,
  useMapEvents,
} from "react-leaflet";
import { Box } from "@mui/material";
import DisableMapDrag from "./DisableMapDrag";
import { MapLibreTileLayer } from "../../../MainPage/MainMap/MapLibreTileLayer.ts";
import { BiMove } from "react-icons/bi";
import { GiClick } from "react-icons/gi";
import { PiCursorClickLight } from "react-icons/pi";
import { LiaHandPointer } from "react-icons/lia";

import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery.js";

// determine zoom level
function determineZoom(formik) {
  if (!!formik.values.house && formik.values.street) return 18;
  if (formik.values.street && (formik.values.town || formik.values.suburb))
    return 16;
  if (!formik.values.street && formik.values.suburb) return 14;
  if (!formik.values.street && formik.values.town) return 13;
  return 13;
}

const placeMarkerIcon = L.icon({
  shadowUrl: null,
  iconSize: [20, 30],
  iconAnchor: [10, 30],
  iconUrl: "https://unpkg.com/leaflet@1.0.3/dist/images/marker-icon.png",
});

const AdFormMap = ({ formik }) => {
  const mapRef = useRef(null);
  const [zoom, setZoom] = useState(determineZoom(formik));
  const initialMapCenter = !!formik.values.loc_x
    ? [+formik.values.loc_x, +formik.values.loc_y]
    : [54.686741, 25.290675]; // Gedimino pilis
  const [mapCenter, setMapCenter] = useState(initialMapCenter);
  const [isMapActive, setIsMapActive] = useState(false);
  const [newLocationSelected, setNewLocationSelected] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const { isMobile } = useCustomMediaQuery();

  useEffect(() => {
    if (!!mapRef?.current) {
      mapRef.current.invalidateSize();
    }
  }, [mapRef.current]);

  // determine if new location is selected from selectboxes
  useEffect(() => {
    if (!initialLoad && !newLocationSelected) {
      // alert("new location selected");
      setNewLocationSelected(true);
    }
    setInitialLoad(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    formik.values.municipality,
    formik.values.town,
    formik.values.suburb,
    formik.values.street,
    formik.values.house,
  ]);

  // change zoom level as new location is selected from selectboxes
  useEffect(() => {
    if (newLocationSelected) {
      // console.log(
      //   "new location selected",
      //   // formik.values.municipality,
      //   // formik.values.town,
      //   // formik.values.suburb,
      //   // formik.values.street,
      //   // formik.values.house,
      //   [formik.values.loc_x, formik.values.loc_y]
      // );
      const zoom = determineZoom(formik);
      const mapCenter = !!formik.values.loc_x
        ? [+formik.values.loc_x, +formik.values.loc_y]
        : initialMapCenter;

      console.log(
        "mapCenter and zoom from new location selected",
        mapCenter,
        zoom
      );
      // mapRef.current?.setView(mapCenter, zoom);
      setZoom(zoom);
      setMapCenter(mapCenter);
      setNewLocationSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.loc_x, formik.values.loc_y]);

  useEffect(() => {
    mapRef.current?.setView(mapCenter, zoom);
  }, [mapCenter, zoom]);

  // This component will update the map's view whenever mapCenter or zoom changes
  function UpdateView() {
    const map = useMap();
    mapRef.current = map;
    useMapEvents({
      dragend: () => {
        const mapCenter = map.getCenter();
        console.log("mapCenter from moveend", mapCenter);
        let loc_x = Number(mapCenter.lat.toFixed(7));
        let loc_y = Number(mapCenter.lng.toFixed(7));
        setMapCenter([loc_x, loc_y]);
        map.setView(mapCenter, zoom);
        formik.setFieldValue("loc_x", loc_x);
        formik.setFieldValue("loc_y", loc_y);
      },
      zoomend: () => {
        setZoom(map.getZoom());
        map.setView(mapCenter, map.getZoom());
      },
    });
    // map.setView(mapCenter, zoom);

    // useEffect(() => {
    //   mapRef.current = map;
    // }, [map]);
    return null; // Component must return something
  }

  // useEffect(() => {
  //   formik.setFieldValue("loc_x", mapCenter[0]);
  //   formik.setFieldValue("loc_y", mapCenter[1]);
  //   mapRef.current?.setView(mapCenter, zoom);
  // }, [mapCenter, zoom]);

  // function handleDragEnd(e) {
  //   let changedPosition = e.target.getLatLng();
  //   let loc_x = Number(changedPosition.lat.toFixed(7));
  //   let loc_y = Number(changedPosition.lng.toFixed(7));
  //   formik.setFieldValue("loc_x", loc_x);
  //   formik.setFieldValue("loc_y", loc_y);
  // }

  // console.log("mapCenter from AdFormMap", mapCenter);
  // console.log("formik values from AdFormMap", formik.values);
  // console.log("mapRef.current from AdFormMap", mapRef.current);
  // console.log("zoom from AdFormMap", zoom);
  console.log("isMapActive from AdFormMap", isMapActive);

  return (
    <Box
      sx={{
        width: "100%",
        height: "450px",
        position: "relative",
        border: "grey solid 1px",
        opacity: isMapActive ? 1 : 0.6,
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 5,
          left: 5,
          borderRadius: 5,
          backgroundColor: "Background",
          display: "flex",
          opacity: 0.6,
          zIndex: 1000,
        }}
      >
        {isMapActive ? (
          <BiMove fontSize="40px" color="grey" />
        ) : (
          <LiaHandPointer
            fontSize="40px"
            color="grey"
            onClick={() => setIsMapActive(true)}
          />
        )}
      </Box>
      <Box
        sx={{
          pointerEvents: "none",
          backgroundImage:
            "url('https://unpkg.com/leaflet@1.0.3/dist/images/marker-icon.png')",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          width: "20px", // adjust as needed
          height: "30px", // adjust as needed
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -100%)",
          zIndex: 1000,
        }}
      />
      <MapContainer
        center={mapCenter}
        zoom={zoom}
        zoomControl={false}
        maxZoom={18}
        minZoom={7}
        inertia={false}
        dragging={false}
        style={{ height: "100%", width: "100%" }}
      >
        <UpdateView />
        <DisableMapDrag setIsMapActive={setIsMapActive} />
        <ZoomControl position="bottomleft" />

        <MapLibreTileLayer
          attribution={
            '&copy; <a href="https://stadiamaps.com/" target="_blank">Stadia Maps</a>, <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
          }
          url="https://tiles.stadiamaps.com/styles/osm_bright.json"
        />
      </MapContainer>
    </Box>
  );
};

export default AdFormMap;
