import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import { useGlobalContext } from "../../../context";
import AccordionDetailFlat from "./AccordionDetailFlat";
import AccordionDetailHouse from "./AccordionDetailHouse";
import AccordionDetailLand from "./AccordionDetailLand";
import AccordionDetailPremises from "./AccordionDetailPremises";
import AccordionDetailParking from "./AccordionDetailParking";
import AccordionDetailApartmentComplex from "./AccordionDetailApartmentComplex";
import useAdType from "../BasicAdInfo/useAdType";

const AccordionDetail = ({ ad }) => {
  const [expanded, setExpanded] = useState(true);
  const {
    isFlat,
    isHouse,
    isLand,
    isPremises,
    isParking,
    isGarage,
    isApartmentComplex,
  } = useAdType(ad);

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      sx={{ mt: 0, width: "100%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="button" color={expanded ? "primary" : "default"}>
          Standartinė informacija
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "block" }}>
        {isFlat && <AccordionDetailFlat ad={ad} />}
        {isHouse && <AccordionDetailHouse ad={ad} />}
        {isLand && <AccordionDetailLand ad={ad} />}
        {isPremises && <AccordionDetailPremises ad={ad} />}
        {(isParking || isGarage) && <AccordionDetailParking ad={ad} />}
        {isApartmentComplex && <AccordionDetailApartmentComplex ad={ad} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionDetail;
