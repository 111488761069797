import React from "react";
import Grid from "@mui/material/Grid";
import {
  getAreaRange,
  getPriceRangeApartmentComplex,
  getPriceSqmRangeApartmentComplex,
  getRoomsRange,
} from "../../../IndividualAd/BasicAdInfo/utils";

const AdCardInfoApartmentComplex = ({ ad }) => {
  const roomsRange = getRoomsRange(ad, 6, 6);
  const areaRange = getAreaRange(ad, 6, 6);
  const priceRangeApartmentComplex = getPriceRangeApartmentComplex(ad, 12, 12);
  const priceSqmRangeApartmentComplex = getPriceSqmRangeApartmentComplex(
    ad,
    12,
    12
  );

  return (
    <Grid
      container
      sx={{ flexGrow: 1, display: "flex", alignContent: "space-around" }}
    >
      {roomsRange}
      {areaRange}
      {priceRangeApartmentComplex}
      {priceSqmRangeApartmentComplex}
    </Grid>
  );
};

export default AdCardInfoApartmentComplex;
