import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import {
  INFRASTRUCTURE_CHOICES_PREMISES,
  PARKING_CHOICES_PREMISES,
  SECURITY_CHOICES_PREMISES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function EnvironmentPanelPremises({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "environmentPanelPremises";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getEnvironmentPremisesInitialValues(ad, adOptions);
  const validationSchema = schema.environmentPremisesValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField
                name="plot_area"
                label="Priklausančios teritorijos plotas (arai)"
                type="number"
                inputProps={{ min: 0, step: 1 }}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="security"
                label="Saugumas"
                options={SECURITY_CHOICES_PREMISES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="plot_infrastructure"
                label="Komunikacijos ir infrastruktūra"
                options={INFRASTRUCTURE_CHOICES_PREMISES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="parking"
                label="Automobilio parkavimas"
                options={PARKING_CHOICES_PREMISES}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
