import React, { useEffect, useRef } from "react";
import { FeatureGroup, Marker } from "react-leaflet";
import { placeMarkerIcon } from "../BasicMapComponent";
import { useGlobalContext } from "../../../../context";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

function SelectedPlaces() {
  const { dispatch } = useGlobalContext();
  const params = useParamsFromUrl();
  const selectedPlacesRef = useRef(null);

  const placePositions = !!params?.place_coords
    ? JSON.parse(params.place_coords).map((pos) => pos.coordinates.reverse())
    : null;

  useEffect(() => {
    let placesBounds = "";
    if (!!selectedPlacesRef?.current && !!params.place_coords) {
      placesBounds = selectedPlacesRef.current.getBounds();
    }
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { placesBounds },
    });
  }, [selectedPlacesRef.current, dispatch]);

  return (
    <>
      {!!params.place_coords && (
        <FeatureGroup ref={selectedPlacesRef}>
          {placePositions.map((position, index) => {
            return (
              <Marker
                key={index}
                position={position}
                icon={new placeMarkerIcon()}
              />
            );
          })}
        </FeatureGroup>
      )}
    </>
  );
}

export default SelectedPlaces;
