import React, { useState } from "react";
import axiosInstance from "../../utils/axios";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const initialCheckBoxState = {
  scrapeFlatSaleAds: false,
  scrapeFlatRentAds: false,
  scrapeHouseSaleAds: false,
  estimateFlatSaleRegressionModel: false,
  estimateFlatRentRegressionModel: false,
  estimateHouseSaleRegressionModel: false,
  makeBulkPredictions: false,
  estimateAmenitiesAndRanks: false,
  uploadScrapedAdsToSpaces: false,
  deleteScrapedInstances: false,
  downloadScrapedAdsFromSpacesToProductionDB: false,
};

const nameLabelArray = [
  { name: "scrapeFlatSaleAds", label: "Parsisiųsti butų pardavimo skelbimus" },
  {
    name: "scrapeFlatRentAds",
    label: "Parsisiųsti butų nuomos skelbimus",
  },
  {
    name: "scrapeHouseSaleAds",
    label: "Parsisiųsti namų pardavimo skelbimus",
  },
  {
    name: "estimateFlatSaleRegressionModel",
    label: "Įvertinti butų kainų modelį",
  },
  {
    name: "estimateFlatRentRegressionModel",
    label: "Įvertinti butų nuomos kainų modelį",
  },
  {
    name: "estimateHouseSaleRegressionModel",
    label: "Įvertinti namų kainų modelį",
  },
  {
    name: "makeBulkPredictions",
    label: "Atlikti parsisiųstų skelbimų kainų vertinimą",
  },
  {
    name: "estimateAmenitiesAndRanks",
    label:
      "Įvertinti parsisiųstų skelbimų geografinį patrauklumą ir priskirti reitingus",
  },
  {
    name: "uploadScrapedAdsToSpaces",
    label: "Įkelti parsisiųstus skelbimus į DigitalOcean Spaces",
  },
  {
    name: "deleteScrapedInstances",
    label: "Pašalinti prieš seniau nei savaitę parsisiųstus skelbimus",
  },
  {
    name: "downloadScrapedAdsFromSpacesToProductionDB",
    label:
      "Perkelti parsisiųstus skelbimus iš DigitalOcean Spaces į produkcijos duomenų bazę",
  },
];

export function ValuationManagement() {
  const [valuationCheckboxState, setValuationCheckboxState] =
    useState(initialCheckBoxState);

  function updateAddresses() {
    axiosInstance.defaults.timeout = 0; // turn of timeout because it will take a lot of time to wait for response
    axiosInstance
      .post("api/management/update-valuation/", valuationCheckboxState)
      .then((res) => {
        console.log("server response: successfully updated valuation", res);
      })
      .catch((err) => {
        console.error("Error occured while trying to update valuation", err);
      });
  }

  const handleChange = (event) => {
    setValuationCheckboxState({
      ...valuationCheckboxState,
      [event.target.name]: event.target.checked,
    });
  };

  console.log("valuationCheckboxState", valuationCheckboxState);

  return (
    <Paper elevation={1} sx={{ p: 2, my: 2 }}>
      <Grid container justifyContent="center" sx={{ border: "none" }}>
        <Typography variant="h6">
          Skelbimų statistikos ir vertinimo modelių atnaujinimas
        </Typography>
      </Grid>
      <FormGroup column>
        {nameLabelArray.map((checkBox) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={valuationCheckboxState[checkBox.name]}
                  onChange={handleChange}
                  name={checkBox.name}
                  color="primary"
                />
              }
              label={checkBox.label}
            />
          );
        })}
      </FormGroup>

      <Button
        variant="outlined"
        style={{ marginLeft: "50px" }}
        onClick={updateAddresses}
      >
        Atnaujinti vertės nustatymą
      </Button>
    </Paper>
  );
}
