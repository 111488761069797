import { FormControl, Select, MenuItem, Typography } from "@mui/material";
import useParamsFromUrl from "../hooks/useParamsFromUrl";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import reloadAds from "../../../utils/getUrlFromParams";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

function PageSelector() {
  const history = useHistory();
  const { state, dispatch } = useGlobalContext();
  const params = useParamsFromUrl();
  const { isMobile } = useCustomMediaQuery();

  function handleChange(e) {
    let newPage = e.target.value;
    let newParams = { ...params, page: newPage };
    reloadAds(newParams, dispatch, history);
  }

  let menuItems = [];
  for (let i = 1; i <= state.totalPages; i++) {
    menuItems.push(i);
  }

  return (
    <>
      {/* <Typography variant="body2" sx={{ mr: 1 }}>
        Rodomas
      </Typography> */}
      <FormControl size="small">
        <Select
          value={params.page || 1}
          onChange={handleChange}
          inputProps={{
            sx: { fontSize: { xs: 13, sm: 13.5 }, p: 0.5, pl: 1 },
          }}
          renderValue={() => (
            <Typography variant="body2">
              {isMobile ? "" : "Rodomas "}
              {params.page || 1} iš {state.totalPages} psl.
            </Typography>
          )}
          MenuProps={menuProps}
        >
          {menuItems.map((item) => (
            <MenuItem key={item} value={item} dense>
              Rodyti {item} iš {state.totalPages} psl.
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}

export default PageSelector;
