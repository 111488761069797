import React, { useRef, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Chip } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import reloadAds from "../../../../utils/getUrlFromParams";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import MunicipalityFilter from "./MunicipalityFilter";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import MunicipalityFilterDataProvider from "./MunicipalityFilterDataProvider";
import CustomMenu from "../SharedComponents/CustomMenu";

const emptyLocationParams = {
  municipality: "",
  town: "",
  suburb: "",
  street: "",
};

export default function MunicipalityFilterChip() {
  const { dispatch, state } = useGlobalContext();
  const { isMediumOrSmaller } = useCustomMediaQuery();

  const formikRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const params = useParamsFromUrl();

  const chipLabel = useMemo(() => {
    let chipLabel = "Savivaldybė";
    if (!!params?.municipality && state?.municipalityOptionsLoaded) {
      let selectedMunicipalities = params.municipality.split(",");
      let selectedCount = selectedMunicipalities.length;
      chipLabel = state.municipalityOptions?.find(
        (muni) => Number(muni.id) === Number(selectedMunicipalities[0])
      )?.name;
      if (selectedCount > 1) {
        chipLabel += ` + ${selectedCount - 1}`;
      }
    }
    return chipLabel;
  }, [
    params?.municipality,
    state.municipalityOptions,
    state.municipalityOptionsLoaded,
  ]);

  function handleXClick() {
    handleSubmit({ municipality: "" });
  }

  function handleSubmit(formikValues) {
    if (formikValues.municipality !== (params.municipality || "")) {
      const muniCenter = state.municipalityOptions.find(
        (option) => option.id.toString() === formikValues.municipality
      )?.town;
      let newParams = { ...params, ...emptyLocationParams, ...formikValues };
      delete newParams.page;
      reloadAds(newParams, !!muniCenter ? false : dispatch, history);
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          townOptions: [],
          suburbOptions: [],
          streetOptions: [],
          townOptionsLoaded: false,
          suburbOptionsLoaded: false,
          streetOptionsLoaded: false,
        },
      });
    }
    setAnchorEl(null);
  }

  return (
    <MunicipalityFilterDataProvider>
      {!isMediumOrSmaller && (
        <Chip
          label={chipLabel}
          onClick={(e) => setAnchorEl(e.currentTarget)}
          onDelete={chipLabel === "Savivaldybė" ? null : handleXClick}
          variant="outlined"
          color={
            chipLabel !== "Savivaldybė" || anchorEl ? "primary" : "default"
          }
          sx={{ borderRadius: "3px", mr: 1 }}
        />
      )}
      <CustomMenu
        formikRef={formikRef}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <MunicipalityFilter formikRef={formikRef} handleSubmit={handleSubmit} />
      </CustomMenu>
    </MunicipalityFilterDataProvider>
  );
}
