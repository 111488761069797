import React from "react";
import { Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import { useGlobalContext } from "../../../../context";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import CustomAvatar from "../../../Layout/CustomAvatar";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { grey } from "@mui/material/colors";

export default function ChatHeader() {
  const { dashboardState } = useGlobalContext();
  let { adId, enquirerId } = useParams();

  const { isMobile, isSmallOrMobile } = useCustomMediaQuery();

  const enquiry = dashboardState.adContacts.find(
    (x) =>
      Number(x.ad) === Number(adId) && Number(x.enquirer) === Number(enquirerId)
  );

  console.log("enquiry from ChatHeader:", enquiry);

  return (
    !!enquiry && (
      <Box
        sx={{
          border: "none",
          display: "flex",
          width: "100%",
          ml: 2,
        }}
      >
        <Box sx={{ flexShrink: 1, mx: 0.5, my: 0.15 }}>
          <CustomAvatar
            userId={enquiry.enquirer}
            name={enquiry.name}
            styleProps={{
              width: "40px",
              height: "40px",
              border: "solid 1px grey",
              ml: 1,
              my: 1,
            }}
          />
        </Box>
        <Box
          sx={{
            display:
              !!enquiry.phone || !enquiry.enquirer_active ? "block" : "flex",
            flexGrow: 1,
            justifyContent: "left",
            alignItems: "center",
            mt: isMobile ? 1.2 : 0.7,
            pl: 1,
          }}
        >
          <Box sx={{ alignItems: "center", display: "flex", border: "none" }}>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: isMobile ? "200px" : "400px",
              }}
              color="initial"
            >
              {enquiry.name}
            </Typography>
          </Box>

          {!!enquiry.phone && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneIphoneIcon
                color="action"
                sx={{
                  width: isSmallOrMobile ? "12px" : "15px",
                  height: isSmallOrMobile ? "12px" : "15px",
                  mr: 0.5,
                  mb: 0.2,
                }}
              />
              <Typography
                variant={isSmallOrMobile ? "caption" : "body2"}
                color={grey[700]}
              >
                {formatPhoneNumberIntl(enquiry.phone)}
              </Typography>
            </Box>
          )}

          {enquiry.enquirer_active === false && (
            <Typography
              variant={isSmallOrMobile ? "caption" : "body1"}
              color="error"
            >
              Nebeaktyvi paskyra
            </Typography>
          )}

          {/* {(!isSmallOrMobile || !enquiry.phone) && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                border: "none",
                ml: 3,
              }}
            >
              <MailIcon
                // fontSize="small"
                color="action"
                sx={{
                  width: "14px",
                  height: "14px",
                  mr: 0.5,
                }}
              />
              <Typography
                variant={isSmallOrMobile ? "caption" : "body1"}
                color="initial"
              >
                {enquiry.email}
              </Typography>
            </Box>
          )} */}
        </Box>

        {/* <Box
          sx={{
            ml: 2,
            display: "flex",
            justifyContent: "left",
            border: "groove",
            flexWrap: "wrap",
          }}
        ></Box> */}
        <Box sx={{ border: "none", flexShrink: 1, mr: 0, my: "auto" }}>
          <CustomAvatar
            styleProps={{
              width: "40px",
              height: "40px",
              border: "solid 1px grey",
              mr: 1,
            }}
          />
        </Box>
      </Box>
    )
  );
}
