import { InputAdornment, TextField } from "@mui/material";
import React, { useState } from "react";
import { useGlobalContext } from "../../../context";
import { Finance } from "financejs";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

function NetIncome() {
  const { loanState } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const finance = new Finance();

  const minRequiredIncome = finance.PMT(
    Math.max(5, loanState.interestRate) / 100 / 12,
    loanState.duration * 12,
    -loanState.loanSize / 0.4
  );
  const [netIncome, setNetIncome] = useState(loanState.netIncome || null);
  const error = !!netIncome && netIncome < minRequiredIncome;

  function handleChange(e) {
    const netIncome = !!e.target.value ? +e.target.value : "";
    setNetIncome(netIncome);
  }

  console.log("minRequiredIncome", minRequiredIncome, loanState);

  return (
    <TextField
      sx={{
        width: isMobile ? "100%" : "13ch",
      }}
      error={error}
      label="Pajamos"
      size="small"
      type="number"
      name="netIncome"
      value={netIncome}
      onChange={handleChange}
      InputProps={{
        sx: { fontSize: "14px" },
        endAdornment: <InputAdornment position="end">€</InputAdornment>,
        inputProps: {
          min: 0,
          step: 10,
        },
      }}
      InputLabelProps={{ sx: { fontSize: "14px" } }}
      helperText={
        error
          ? `Pajamos turi viršyti ${Math.ceil(minRequiredIncome)}€ ${
              isMobile ? "(įmoka neturi viršyti 40% pajamų)" : ""
            }`
          : null
      }
    />
  );
}

export default NetIncome;
