import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";
import { useState } from "react";

export default function FormikBasicAutocomplete(props) {
  const {
    name,
    label,
    options,
    onChange,
    removeSpinners = false,
    ...otherProps
  } = props;
  const formik = useFormikContext();
  const [field, meta, helpers] = useField(name);
  const errorText = meta.touched && meta.error;

  const [openOptions, setOpenOptions] = useState(false);
  const [value, setValue] = useState(field.value);
  const [firstRender, setFirstRender] = useState(true);

  function handleChange(e, value) {
    helpers.setValue(+value || null);
    setValue(value);
    setOpenOptions(false);
    if (onChange) {
      onChange(+value || null);
    }
  }

  function handleInputChange(e, value) {
    if (!firstRender) {
      const optionsIncludeInput = options.some((option) =>
        option.toString().includes(value?.toString())
      );
      setOpenOptions(optionsIncludeInput);
    }
    helpers.setValue(+value || null);
    setFirstRender(false);
  }

  function handleInputClick(e) {
    !!e?.target?.select && e?.target?.select();
    setOpenOptions(!openOptions);
  }

  function handleKeyDown(e) {
    if (e.key === "Enter") {
      openOptions ? setOpenOptions(false) : formik.submitForm();
    }
  }

  function handleBlur(e) {
    field.onBlur(e);
    setOpenOptions(false);
  }

  const filterOptions = createFilterOptions({
    matchFrom: "start",
  });

  return (
    <Autocomplete
      open={openOptions}
      fullWidth
      freeSolo
      openOnFocus
      // autoHighlight
      disableListWrap
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      // blurOnSelect
      onInputChange={handleInputChange}
      options={options}
      PopperComponent={"bottom-center"}
      ListboxProps={{ sx: { maxHeight: "10rem" } }}
      filterOptions={filterOptions}
      getOptionLabel={(option) => option.toString()}
      isOptionEqualToValue={(option, value) => option === value}
      renderInput={(params) => {
        return (
          <TextField
            // autoFocus={otherProps.autoFocus}
            error={!!errorText}
            helperText={errorText}
            name={name}
            {...params}
            label={label}
            margin="dense"
            onClick={handleInputClick}
            onKeyDown={handleKeyDown}
            {...otherProps}
            sx={
              removeSpinners
                ? {
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }
                : {}
            }
          />
        );
      }}
      {...otherProps}
    />
  );
}
