import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../context";

export default function useAppointmentMessage(message, appointment) {
  const { userState, dashboardState } = useGlobalContext();
  const { adId, enquirerId } = useParams();
  const isEnquirer = userState.userId === +enquirerId;
  const reply = !isEnquirer;

  if (!adId || !enquirerId) return { appointmentMessage: null };

  // const existingAppointment =
  //   dashboardState.myAppointments.find(
  //     (appt) => appt.ad === +adId && appt.enquirer === +enquirerId
  //   ) || {};

  let appointmentMessage = {
    ad: +adId,
    enquirer: +enquirerId,
    message,
    reply,
  };

  appointmentMessage = { ...appointmentMessage, ...appointment };
  delete appointmentMessage.id;

  return { appointmentMessage };
}
