import React from "react";
import { Grid, Typography, Paper, Stack } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box } from "@mui/system";

function AdStats({ ad }) {
  const validFrom = ad.properties.list_date.slice(
    0,
    ad.properties.list_date.indexOf("T")
  );
  const validTo = ad.properties.expires_at.slice(
    0,
    ad.properties.expires_at.indexOf("T")
  );

  return (
    <Paper
      //   elevation={2}
      sx={{
        border: "none",
        width: "100%",
        // height: "100%",
        px: 1,
        py: 0.6,
        // my: 0.5,
        // mx: 0.5,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container justifyContent="center" sx={{ display: "flex", my: 1 }}>
        <Typography variant="body2" sx={{ border: "none" }}>
          Skelbimo ID: {ad.id}
        </Typography>
      </Grid>
      <Stack sx={{ border: "none", flexGrow: 1, flexDirection: "column" }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ border: "none", flexGrow: 1 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <AccessTimeIcon fontSize="small" color="action" sx={{ mr: 1 }} />
            <Typography variant="caption">Galioja (nuo/iki):</Typography>
          </Box>
          <Typography variant="caption">
            {validFrom} / {validTo}
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ border: "none", mt: 0.5, flexGrow: 1 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <VisibilityIcon fontSize="small" color="action" sx={{ mr: 1 }} />
            <Typography variant="caption">
              Peržiūros (šiandien/iš viso):
            </Typography>
          </Box>
          <Typography variant="caption">
            {Number(ad.properties.views_today)} /{" "}
            {Number(ad.properties.views_total)}
          </Typography>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{ border: "none", flexGrow: 1, mt: 0.5 }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <FavoriteBorder fontSize="small" color="action" sx={{ mr: 1 }} />
            <Typography variant="caption">
              Įsiminė (šiandien/iš viso):
            </Typography>
          </Box>
          <Typography variant="caption">
            {Number(ad.properties.likes_today)} /{" "}
            {Number(ad.properties.likes_total)}
          </Typography>
        </Grid>
      </Stack>
    </Paper>
  );
}

export default AdStats;
