import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGlobalContext } from "../../../context";
import { tabArray } from "./DashboardToolBar";

function useTab() {
  const { tabName = "my-favorites", adId, enquirerId, extension } = useParams();
  const { dispatchRouter } = useGlobalContext();
  const [showComponent, setShowComponent] = useState(
    tabArray.find((tab) => tab.value === tabName)?.component
  );
  const location = useLocation();

  useEffect(() => {
    const payload = {};
    payload["tab"] = tabArray.find((tab) => tab.value === tabName)?.index || 0;
    switch (tabName) {
      case "my-ads":
        payload["myAdsAd"] = adId || "";
        payload["myAdsEnquirer"] = enquirerId || "";
        payload["myAdsExtension"] = extension || "";
        break;
      case "my-enquiries":
        payload["myEnquiriesAd"] = adId || "";
        payload["myEnquiriesEnquirer"] = enquirerId || "";
        payload["myEnquiriesExtension"] = extension || "";
        break;
      default:
        console.log("switch was not triggered");
    }
    dispatchRouter({ type: "UPDATE_DASHBOARD_ROUTES", payload });
    setShowComponent(tabArray.find((tab) => tab.value === tabName)?.component);
  }, [location.pathname]);

  console.log("tabName", tabName, location);

  return { tabArray, showComponent };
}

export default useTab;
