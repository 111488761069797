export const PRICE_OPTIONS = [
  10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000, 100000, 110000,
  120000, 130000, 140000, 150000, 160000, 170000, 180000, 190000, 200000,
  250000, 300000, 350000, 400000,
];

export const PRICE_OPTIONS_PARKING = [
  1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000,
  13000, 14000, 15000, 16000, 17000, 18000, 19000, 20000, 25000, 30000, 35000,
  40000,
];

export const PRICE_OPTIONS_RENT = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 150, 200, 250, 300, 350, 400, 450,
  500, 550, 600, 650, 700, 750, 800, 850, 900, 950, 1000, 1100, 1200, 1300,
  1400, 1500, 1600, 1700, 1800, 1900, 2000, 2500, 3000,
];

export const RENT_PRICE_OPTIONS_PARKING = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170,
  180, 190, 200, 250, 300,
];

export const SIZE_OPTIONS = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 250, 300, 400,
];

export const SIZE_OPTIONS_PARKING = [
  5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
  30, 35, 40, 45, 50, 55, 60,
];

export const FLOOR_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

export const GARAGE_FLOOR_OPTIONS = [
  -5, -4, -3, -2, -1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
];

export const FLOOR_OPTIONS_PREMISES = [
  -5, -4, -3, -2, -1, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
  18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
];

export const FLOORS_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
  23, 24, 25, 26, 27, 28, 29, 30,
];

export const FLOORS_OPTIONS_HOUSE = [1, 2, 3, 4, 5];

export const ROOM_OPTIONS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const YEAR_OPTIONS = [
  2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010,
  2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000, 1990, 1980, 1970,
  1960, 1950, 1940, 1930, 1920, 1910, 1900,
];

export const HEATING_BILL_OPTIONS_FLAT = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 250, 300, 400,
];

export const HEATING_BILL_OPTIONS_PREMISES = [
  0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5,
  5, 6, 7, 8, 9, 10,
];

export const PLOT_AREA_OPTIONS = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90,
  100, 200, 300, 400, 500, 600, 700, 800, 900, 1000,
];

export const FERTILITY_SCORE_OPTIONS = [
  10, 20, 30, 40, 50, 60, 70, 80, 90, 100,
];

export const AD_TYPE_CHOICES = [
  { value: "flat_sale", label: "Parduodamas butas" },
  { value: "flat_rent", label: "Nuomojamas butas" },
  { value: "house_sale", label: "Parduodamas namas" },
  { value: "house_rent", label: "Nuomojamas namas" },
  { value: "land_sale", label: "Parduodamas sklypas" },
  { value: "land_rent", label: "Nuomojamas sklypas" },
  { value: "premises_sale", label: "Parduodamos patalpos" },
  { value: "premises_rent", label: "Nuomojamos patalpos" },
  { value: "garage_sale", label: "Parduodamas garažas" },
  { value: "garage_rent", label: "Nuomojamas garažas" },
  { value: "parking_sale", label: "Parduodama parkavimo vieta" },
  { value: "parking_rent", label: "Nuomojama parkavimo vieta" },
  { value: "apartment_complex", label: "Naujas daugiabučių projektas" },
];

export const AD_TYPE_OPTIONS = [
  { value: "flat_sale", label: "Butai" },
  { value: "house_sale", label: "Namai" },
  { value: "land_sale", label: "Sklypai" },
  { value: "premises_sale", label: "Patalpos" },
  { value: "garage_sale", label: "Garažai" },
  { value: "parking_sale", label: "Parkavimo vietos" },
  { value: "flat_rent", label: "Butų nuoma" },
  { value: "house_rent", label: "Namų nuoma" },
  { value: "land_rent", label: "Žemės nuoma" },
  { value: "premises_rent", label: "Patalpų nuoma" },
  { value: "garage_rent", label: "Garažų nuoma" },
  { value: "parking_rent", label: "Parkingo nuoma" },
  { value: "apartment_complex", label: "Nauji projektai" },
];

export const STATUS_CHOICES = [
  { value: "draft", label: "Rengiamas" },
  { value: "inactive", label: "Neaktyvus" },
  { value: "active", label: "Aktyvus" },
  { value: "reserved", label: "Rezervuotas" },
];

export const OWNER_TYPE_CHOICES = [
  { value: "owner", label: "Savininkas" },
  { value: "intermediary", label: "Tarpininkas" },
  { value: "developer", label: "Vystytojo atstovas" },
];

export const PLOT_INFRASTRUCTURE_CHOICES = [
  { value: "asphalt", label: "Asfaltuotas privažiavimas" },
  { value: "electricity", label: "Elektra" },
  { value: "gas", label: "Dujos" },
  { value: "solar_power", label: "Saulės jėgainė" },
  { value: "wind_power", label: "Vėjo jėgainė" },
  { value: "tv_internet_cables", label: "Televizijos ir interneto kabeliai" },
  { value: "lawn", label: " Veja / gėlynai" },
  { value: "garden", label: "Sodas / daržas" },
  { value: "greenhouse", label: "Šiltnamis" },
  { value: "watering_system", label: "Automatinė laistymo sistema" },
  { value: "outbuildings", label: "Ūkiniai pastatai" },
  { value: "playground", label: "Vaikų žaidimų įrenginiai" },
  { value: "sport_facilities", label: "Sporto erdvės ir įrenginiai" },
  { value: "sauna", label: "Pirtis" },
  { value: "outside_pool", label: "Lauko baseinas" },
  { value: "inside_pool", label: "Vidaus baseinas" },
];

export const INFRASTRUCTURE_CHOICES_HOUSE_PLOT = [
  { value: "good_access", label: "Geras privažiavimas" },
  { value: "asphalt", label: "Asfaltuotas privažiavimas" },
  { value: "electricity", label: "Elektra" },
  { value: "gas", label: "Dujos" },
  { value: "water", label: "Vanduo" },
  { value: "fenced", label: "Aptverta teritorija" },
];

export const INFRASTRUCTURE_CHOICES_GARDEN_PLOT = [
  { value: "garden_house", label: "Sodo namelis" },
  { value: "good_access", label: "Geras privažiavimas" },
  { value: "asphalt", label: "Asfaltuotas privažiavimas" },
  { value: "electricity", label: "Elektra" },
  { value: "gas", label: "Dujos" },
  { value: "water", label: "Vanduo" },
  { value: "solar_power", label: "Saulės jėgainė" },
  { value: "lawn", label: " Veja / gėlynai" },
  { value: "garden", label: "Sodas / daržas" },
  { value: "greenhouse", label: "Šiltnamis" },
  { value: "watering_system", label: "Automatinė laistymo sistema" },
  { value: "outbuildings", label: "Ūkiniai pastatai" },
  { value: "playground", label: "Vaikų žaidimų įrenginiai" },
  { value: "sport_facilities", label: "Sporto erdvės ir įrenginiai" },
  { value: "sauna", label: "Pirtis" },
  { value: "outside_pool", label: "Lauko baseinas" },
  { value: "fenced", label: "Aptverta teritorija" },
];

export const INFRASTRUCTURE_CHOICES_PREMISES = [
  { value: "electricity", label: "Elektra" },
  { value: "water", label: "Vanduo" },
  { value: "gas", label: "Dujos" },
  { value: "solar_power", label: "Saulės jėgainė" },
  { value: "tv_internet_cables", label: "Televizijos ir interneto kabeliai" },
  { value: "asphalt", label: "Asfaltuotas privažiavimas" },
  { value: "higher_voltage", label: "Didesnės galios elektros įvadas" },
];

export const WATER_SEWAGE_CHOICES = [
  { value: "water_drill", label: "Vandens gręžinys" },
  { value: "water_well", label: "Šulinys" },
  { value: "city_water", label: "Miesto vandentiekis" },
  { value: "sewage_treatment", label: "Nuotekų valymo įrenginiai" },
  { value: "sewage_tanks", label: "Nuotekų surinkimo talpos" },
  { value: "city_sewage", label: "Miesto nuotekų sistema" },
  { value: "rain_water", label: "Lietaus vandens surinkimo sistema" },
];

export const HOUSE_TYPE_CHOICES = [
  { value: "detached", label: "Individualus namas" },
  { value: "semi_detached", label: "Sublokuotas namas" },
  { value: "summer_house", label: "Vasarnamis, sodo namas" },
  { value: "homestead", label: "Sodyba" },
  { value: "other", label: "Kitas" },
];

export const BUILDING_TYPE_CHOICES = [
  { value: "brick", label: "Mūrinis" },
  { value: "block", label: "Blokinis" },
  { value: "monolithic", label: "Monolitinis" },
  { value: "wooden", label: "Medinis" },
  { value: "frame", label: "Karkasinis" },
  { value: "modular", label: "Modulinis" },
  { value: "log", label: "Rąstinis" },
  { value: "other", label: "Kita" },
];

export const FLATS_IN_FLOOR_OPTIONS = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11+", label: "Daugiau nei 10" },
];

export const HEATING_CHOICES = [
  { value: "central", label: "Centrinis" },
  { value: "collector", label: "Centrinis kolektorinis" },
  { value: "gas", label: "Dujinis" },
  { value: "sun", label: "Saulės energija" },
  { value: "solid_fuel", label: "Kietu kuru" },
  { value: "liquid_fuel", label: "Skystu kuru" },
  { value: "geothermal", label: "Geoterminis" },
  { value: "aerothermal", label: "Aeroterminis" },
  { value: "electricity", label: "Elektra" },
  { value: "other", label: "Kita" },
  { value: "no_heating", label: "Nėra šildymo" },
];

export const ENERGY_EFFICIENCY_CHOICES = [
  { value: "very_good", label: "Labai geras" },
  { value: "good", label: "Geras" },
  { value: "average", label: "Vidutinis" },
  { value: "inferior", label: "Prastesnis už vidutinį" },
  { value: "a++", label: "A++" },
  { value: "a+", label: "A+" },
  { value: "a", label: "A" },
  { value: "b", label: "B" },
  { value: "c", label: "C" },
  { value: "d", label: "D" },
  { value: "e", label: "E" },
  { value: "f", label: "F" },
  { value: "g", label: "G" },
];

export const BUILDING_RENOVATED_CHOICES = [
  { value: "full", label: "Pilna renovacija" },
  { value: "facade", label: "Fasadas" },
  { value: "roof", label: "Stogas" },
  { value: "windows", label: "Langai" },
  { value: "balcony", label: "Balkonai" },
  { value: "heating", label: "Šildymo sistema" },
  { value: "water", label: "Vandentiekio sistema" },
  { value: "sewage", label: "Kanalizacijos sistema" },
  { value: "electrical", label: "Elektros instaliacija" },
];

export const HOUSE_RENOVATED_CHOICES = [
  { value: "full", label: "Pilna renovacija" },
  { value: "facade", label: "Fasadas" },
  { value: "roof", label: "Stogas" },
  { value: "windows", label: "Langai" },
  { value: "balcony", label: "Balkonai" },
  { value: "heating", label: "Šildymo sistema" },
  { value: "water", label: "Vandentiekio sistema" },
  { value: "sewage", label: "Kanalizacijos sistema" },
  { value: "electrical", label: "Elektros instaliacija" },
  { value: "bathroom_equipment", label: "Santechnika" },
  { value: "doors", label: "Durys" },
  { value: "floor", label: "Grindys" },
  { value: "walls", label: "Sienos" },
  { value: "ceiling", label: "Lubos" },
];

export const SECURITY_CHOICES = [
  { value: "locked", label: "Rakinama laiptinė" },
  { value: "fenced", label: "Aptverta teritorija" },
  { value: "cctv", label: "Vaizdo kameros" },
  { value: "security_service", label: "Saugos tarnyba" },
  { value: "alarms", label: "Apsaugos signalizacija" },
];

export const SECURITY_CHOICES_PREMISES = [
  { value: "fenced", label: "Aptverta teritorija" },
  { value: "cctv", label: "Vaizdo kameros" },
  { value: "security_service", label: "Saugos tarnyba" },
  { value: "alarms", label: "Apsaugos signalizacija" },
  { value: "electronic_locks", label: "Elektroniniai užraktai" },
];

export const SECURITY_CHOICES_HOUSE = [
  { value: "fenced", label: "Aptverta teritorija" },
  { value: "cctv", label: "Vaizdo kameros" },
  { value: "security_service", label: "Saugos tarnyba" },
  { value: "alarms", label: "Apsaugos signalizacija" },
];

export const SECURITY_CHOICES_GARAGE = [
  { value: "fenced", label: "Aptverta teritorija" },
  { value: "gated", label: "Užtvaras/vartai į teritoriją" },
  { value: "alarms", label: "Apsaugos signalizacija" },
  { value: "security_service", label: "Saugos tarnyba" },
  { value: "cctv", label: "Vaizdo kameros" },
];

export const RECREATION_CHOICES = [
  { value: "playground", label: "Vaikų žaidimo aikštelės" },
  { value: "sports", label: "Sporto erdvės" },
  { value: "green_zone", label: "Žaliosios erdvės" },
  { value: "parks", label: "Parkai" },
  { value: "forest", label: "Miškas" },
  { value: "hiking_trails", label: "Pasivaikščiojimo takai" },
  { value: "biking_trails", label: "Dviračių takai" },
  { value: "beach", label: "Paplūdimys" },
];

export const WATER_BODY_CHOICES = [
  { value: "string", label: "Upelis" },
  { value: "river", label: "Upė" },
  { value: "lake", label: "Ežeras" },
  { value: "pond", label: "Tvenkinys" },
  { value: "lagoon", label: "Marios" },
  { value: "sea", label: "Jūra" },
];

export const FOREST_GROUP_CHOICES = [
  { value: 2, label: "II grupė: Specialios paskirties miškai" },
  { value: 3, label: "III grupė: Apsauginiai miškai" },
  { value: 4, label: "IV grupė: Ūkiniai miškai" },
];

export const FOREST_TYPE_CHOICES = [
  { value: "deciduous", label: "Lapuočių" },
  { value: "coniferous", label: "Spygliuočių" },
  { value: "mixed", label: "Mišrus" },
];

export const FOREST_MATURE_CHOICES = [
  { value: "young", label: "Jaunuolynas" },
  { value: "maturing", label: "Bręstantis" },
  { value: "mature", label: "Brandus" },
  { value: "mixed", label: "Įvairus" },
];

export const PARKING_CHOICES = [
  { value: "ample", label: "Gausu laisvų parkavimo vietų" },
  { value: "yard", label: "Parkavimo aikštelė kieme" },
  { value: "street", label: "Automobilio parkavimas gatvėje" },
  { value: "paid", label: "Mokamas parkavimas" },
  { value: "private_parking", label: "Nuosava parkavimo vieta" },
  { value: "private_carport", label: "Nuosava parkavimo vieta po stogu" },
  { value: "garage", label: "Garažas" },
  { value: "double_garage", label: "Garažas dviems automobiliams" },
  {
    value: "electric_charging",
    label: "Nuosava elektromobilio įkrovimo stotelė",
  },
  {
    value: "electric_charging_possibility",
    label: "Galimybė krauti elektromobilį",
  },
];

export const PARKING_CHOICES_HOUSE = [
  { value: "yard", label: "Parkavimo aikštelė kieme" },
  { value: "carport", label: "Pastogė automobiliams" },
  { value: "garage", label: "Garažas" },
  { value: "double_garage", label: "Garažas dviems automobiliams" },
  {
    value: "electric_charging",
    label: "Nuosava elektromobilio įkrovimo stotelė",
  },
  {
    value: "electric_charging_possibility",
    label: "Galimybė krauti elektromobilį",
  },
  { value: "street", label: "Automobilio parkavimas gatvėje" },
  { value: "paid", label: "Mokamas parkavimas" },
];

export const PARKING_CHOICES_PREMISES = [
  { value: "ample", label: "Gausu laisvų parkavimo vietų" },
  { value: "territory", label: "Parkavimo aikštelė teritorijoje" },
  { value: "underground", label: "Požeminė parkavimo aikštelė" },
  { value: "street", label: "Automobilio parkavimas gatvėje" },
  {
    value: "electric_charging_possibility",
    label: "Galimybė krauti elektromobilį",
  },
  { value: "paid", label: "Mokamas parkavimas" },
];

export const INSTALLATION_CHOICES = [
  { value: "full", label: "Pilna apdaila" },
  { value: "partial", label: "Dalinė apdaila" },
  { value: "no", label: "Neįrengtas" },
  { value: "under_construction", label: "Nebaigtas statyti" },
];

export const INSTALLATION_CHOICES_APARTMENT_COMPLEX = [
  { value: "partial", label: "Dalinė apdaila" },
  { value: "full", label: "Pilna apdaila" },
  { value: "full_or_partial", label: "Dalinė arba pilna apdaila" },
];

export const CONDITION_CHOICES = [
  { value: "newly_installed", label: "Naujai įrengta" },
  { value: "repair_unnecessary", label: "Nereikalauja investicijų" },
  { value: "minor_repair_needed", label: "Reikalingas kosmetinis remontas" },
  { value: "major_repair_needed", label: "Reikalingas remontas" },
];

export const FLAT_RENOVATED_CHOICES = [
  { value: "electrical", label: "Elektros instaliacija" },
  { value: "heating", label: "Šildymo sistema" },
  { value: "water", label: "Vandentiekio sistema" },
  { value: "sewage", label: "Kanalizacijos sistema" },
  { value: "bathroom_equipment", label: "Santechnika" },
  { value: "windows", label: "Langai" },
  { value: "doors", label: "Durys" },
  { value: "floor", label: "Grindys" },
  { value: "walls", label: "Sienos" },
  { value: "ceiling", label: "Lubos" },
];

export const EQUIPMENT_CHOICES = [
  { value: "furniture", label: "Su baldais" },
  { value: "kitchen", label: "Virtuvės įranga" },
  { value: "appliances", label: "Kita buitinė technika" },
  { value: "cable_tv", label: "Kabelinė televizija" },
  { value: "internet", label: "Kabelinis internetas" },
  { value: "recuperator", label: "Rekuperacinė sistema" },
  { value: "ac", label: "Kondicionavimo sistema" },
  { value: "heated_floor", label: "Šildomos grindys" },
  { value: "fireplace", label: "Židinys" },
  { value: "smart_house", label: "Išmaniųjų namų sistemos" },
];

export const EQUIPMENT_CHOICES_PREMISES = [
  { value: "internet", label: "Kabelinis internetas" },
  { value: "recuperator", label: "Rekuperacinė sistema" },
  { value: "ac", label: "Kondicionavimo sistema" },
  { value: "office_furniture", label: "Su biuro baldais" },
  { value: "trade_equipment", label: "Prekybinė įranga" },
  { value: "storage_equipment", label: "Sandėliavimo įranga" },
  { value: "catering_equipment", label: "Maitinimo įstaigų įranga" },
  { value: "industrial_equipment", label: "Gamybinė įranga" },
];

export const PREMISES_CHOICES = [
  { value: "balcony", label: "Balkonas" },
  { value: "storage", label: "Sandėliukas" },
  { value: "cellar", label: "Rūsys" },
  { value: "terace", label: "Terasa" },
  { value: "wardrobe_room", label: "Drabužinės patalpa" },
  { value: "second_bathroom", label: "Papildomas vonios kambarys" },
  { value: "attic", label: "Palėpė" },
  { value: "sauna", label: "Pirtis" },
  { value: "sport_facilities", label: "Sporto patalpos" },
];

export const PREMISES_CHOICES_HOUSE = [
  { value: "balcony", label: "Balkonas" },
  { value: "storage", label: "Sandėliukas" },
  { value: "cellar", label: "Rūsys" },
  { value: "terace", label: "Terasa" },
  { value: "wardrobe_room", label: "Drabužinės patalpa" },
  { value: "second_bathroom", label: "Papildomas vonios kambarys" },
];

export const PREMISES_CHOICES_PREMISES = [
  { value: "wc", label: "Įrengtas nuosavas san. mazgas" },
  { value: "access_to_wc", label: "Prieiga prie bendrų san. mazgų pastate" },
  { value: "kitchenette", label: "Įrengta nuosava virtuvėlė" },
  {
    value: "access_to_kitchenette",
    label: "Prieiga prie bendros virtuvėlės pastate",
  },
  { value: "relaxation_zones", label: "Įrengtos nuosavos poilsio erdvės" },
  {
    value: "access_to_relaxation_zones",
    label: "Prieiga prie bendrų poilsio erdvių pastate",
  },
];

export const OTHER_FEATURES_CHOICES = [
  { value: "connected_kitchen", label: "Virtuvė sujungta su kambariu" },
  { value: "connected_bathroom", label: "Vonia sujungta su tualetu" },
  { value: "several_storeys", label: "Butas per kelis aukštus" },
  { value: "high_ceiling", label: "Aukštos lubos" },
  { value: "attic_flat", label: "Butas palėpėje" },
  { value: "separate_entrance", label: "Atskiras įėjimas" },
  { value: "studio", label: "Studijos tipo butas" },
  { value: "loft", label: "Lofto tipo butas" },
  { value: "dormitory", label: "Butas bendrabučio tipo name" },
];

export const OTHER_FEATURES_CHOICES_PREMISES = [
  { value: "separate_entrance", label: "Atskiras įėjimas" },
  { value: "street_entrance", label: "Įėjimas iš gatvės" },
  { value: "high_ceiling", label: "Aukštos lubos" },
  { value: "showcase_windows", label: "Vitrininiai langai" },
];

export const PARTIAL_AMENITIES_CHOICES = [
  { value: "flat_share", label: "Buto dalis" },
  { value: "shared_kitchen", label: "Dalinamasi bendra virtuve" },
  { value: "shared_bathroom", label: "Dalinamasi vonia ir tualetu" },
  { value: "toilet_outside", label: "Tualetas lauke" },
  { value: "other", label: "Kita" },
];

export const PARTIAL_AMENITIES_CHOICES_HOUSE = [
  { value: "house_share", label: "Namo dalis" },
  { value: "no_water", label: "Neįvestas vanduo" },
  { value: "no_electricity", label: "Neįvesta elektra" },
  { value: "toilet_outside", label: "Tualetas lauke" },
  { value: "other", label: "Kita" },
];

export const SALE_TYPE_CHOICES = [
  { value: "urgent", label: "Parduodama skubiai" },
  { value: "no_loans", label: "Pirmenybė pirkėjui be paskolos" },
  // { value: "cash_preferred", label: "Pirmenybė gryniesiems" },
  { value: "no_agents", label: "Tarpininkų paslaugos nedomina" },
  { value: "change_possible", label: "Domina keitimas" },
  { value: "no_bargaining", label: "Kaina galutinė" },
  { value: "foreclosure", label: "Turto varžytynės" },
  { value: "auction", label: "Parduodama aukciono būdu" },
];

export const RENT_TYPE_CHOICES = [
  { value: "family_friendly", label: "Draugiška šeimoms su vaikais" },
  { value: "one_person", label: "Nuomojama tik vienam asmeniui" },
  {
    value: "one_two_persons",
    label: "Nuomojama tik vienam arba dviems asmenims",
  },
  { value: "pet_friendly", label: "Draugiška augintiniams" },
  {
    value: "long_term_preferred",
    label: "Pirmenybė ilgesnio termino sutartims",
  },
  {
    value: "discounts_possible",
    label: "Galima nuolaida nuomojantis ilgesniam terminui",
  },
  { value: "deposit_required", label: "Imamas užstatas" },
  { value: "no_agency_fee", label: "Nėra tarpininkavimo mokesčio" },
  { value: "no_agents", label: "Tarpininkų paslaugos nedomina" },
  { value: "bank_transfers_allowed", label: "Galimas atsiskaitymas per banką" },
  {
    value: "registration_allowed",
    label: 'Galimas sutarties registravimas "Registrų centre"',
  },
  {
    value: "declaration_allowed",
    label: "Galimas gyvenamosios vietos deklaravimas",
  },
];

export const SHORT_TERM_RENT_TYPE_CHOICES = [
  { value: "family_friendly", label: "Draugiška šeimoms su vaikais" },
  { value: "one_person", label: "Nuomojama tik vienam asmeniui" },
  {
    value: "one_two_persons",
    label: "Nuomojama tik vienam arba dviems asmenims",
  },
  { value: "pet_friendly", label: "Draugiška augintiniams" },
  {
    value: "discounts_possible",
    label: "Galima nuolaida nuomojantis ilgesniam terminui",
  },
  { value: "deposit_required", label: "Imamas užstatas" },
  { value: "no_agency_fee", label: "Nėra tarpininkavimo mokesčio" },
  { value: "no_agents", label: "Tarpininkų paslaugos nedomina" },
  { value: "bank_transfers_allowed", label: "Galimas atsiskaitymas per banką" },
];

export const RENT_TYPE_CHOICES_LAND = [
  {
    value: "long_term_preferred",
    label: "Pirmenybė ilgesnio termino sutartims",
  },
  {
    value: "discounts_possible",
    label: "Galima nuolaida nuomojantis ilgesniam terminui",
  },
  { value: "deposit_required", label: "Imamas užstatas" },
  { value: "no_agency_fee", label: "Nėra tarpininkavimo mokesčio" },
  { value: "no_agents", label: "Tarpininkų paslaugos nedomina" },
  { value: "bank_transfers_allowed", label: "Galimas atsiskaitymas per banką" },
];

export const LIST_DATE_CHOICES = [
  { value: 1, label: "1 dieną" },
  { value: 3, label: "3 dienas" },
  { value: 7, label: "1 savaitę" },
  { value: 14, label: "2 savaites" },
  { value: 30, label: "1 mėnesį" },
  { value: 60, label: "2 mėnesius" },
];

export const MIN_RENT_PERIOD_CHOICES = [
  { value: 1, label: "1 diena" },
  { value: 2, label: "2 dienos" },
  { value: 3, label: "3 dienos" },
  { value: 4, label: "4 dienos" },
  { value: 5, label: "5 dienos" },
  { value: 6, label: "6 dienos" },
  { value: 7, label: "1 savaitė" },
  { value: 14, label: "2 savaitės" },
  { value: 21, label: "3 savaitės" },
  { value: 30, label: "1 mėnuo" },
  { value: 60, label: "2 mėnesiai" },
  { value: 90, label: "3 mėnesiai" },
  { value: 120, label: "4 mėnesiai" },
  { value: 150, label: "5 mėnesiai" },
  { value: 180, label: "6 mėnesiai" },
  { value: 210, label: "7 mėnesiai" },
  { value: 240, label: "8 mėnesiai" },
  { value: 270, label: "9 mėnesiai" },
  { value: 300, label: "10 mėnesių" },
  { value: 330, label: "11 mėnesių" },
  { value: 360, label: "12 mėnesių" },
  { value: 720, label: "2 metai" },
  { value: 1080, label: "3 metai" },
];

export const MIN_RENT_PERIOD_CHOICES_LAND = [
  { value: 90, label: "3 mėnesiai" },
  { value: 180, label: "6 mėnesiai" },
  { value: 270, label: "9 mėnesiai" },
  { value: 360, label: "12 mėnesių" },
  { value: 720, label: "2 metai" },
  { value: 1080, label: "3 metai" },
  { value: 1440, label: "4 metai" },
  { value: 1800, label: "5 metai" },
];

export const PLOT_PURPOSE_CHOICES = [
  { value: "house", label: "Namų valda" },
  { value: "apartment", label: "Daugiabučių teritorija" },
  { value: "garden", label: "Sodo sklypas" },
  { value: "farm", label: "Žemės ūkio" },
  { value: "forest", label: "Miškų ūkio" },
  { value: "commercial", label: "Komercinė" },
  { value: "industrial_storage", label: "Pramonės ir sandėliavimo" },
  { value: "recreational", label: "Rekreacinė" },
  { value: "other", label: "Kita" },
];

export const NUMBER_OF_PREMISES_CHOICES = [
  { value: "one", label: "Vienas" },
  { value: "several", label: "Daugiau nei vienas" },
];

export const PREMISES_PURPOSE_CHOICES = [
  { value: "office", label: "Biurų" },
  { value: "trade", label: "Prekybos" },
  { value: "services", label: "Paslaugų" },
  { value: "accommodation", label: "Apgyvendinimo" },
  { value: "storage", label: "Sandėliavimo" },
  { value: "industrial", label: "Gamybinė" },
  { value: "catering", label: "Maitinimo" },
  { value: "other", label: "Kita" },
];

export const GARAGE_INSTALLATION_CHOICES = [
  { value: "electricity", label: "Elektra" },
  { value: "higher_voltage", label: "Didesnės galios elektros įvadas" },
  {
    value: "electric_charging",
    label: "Įrengta elektromobilio įkrovimo stotelė",
  },
  {
    value: "electric_charging_possibility",
    label: "Galimybė krauti elektromobilį",
  },
  { value: "heated", label: "Šildomas" },
  { value: "water", label: "Vanduo" },
  { value: "pit", label: "Su duobe" },
  { value: "automatic_gate", label: "Automatiniai vartai" },
  {
    value: "for_large_vehicles",
    label: "Pritaikytas didelių gabaritų automobiliams",
  },
];

export const PARKING_INSTALLATION_CHOICES = [
  { value: "electricity", label: "Elektra" },
  { value: "higher_voltage", label: "Didesnės galios elektros įvadas" },
  {
    value: "electric_charging",
    label: "Įrengta elektromobilio įkrovimo stotelė",
  },
  {
    value: "electric_charging_possibility",
    label: "Galimybė krauti elektromobilį",
  },
  {
    value: "for_large_vehicles",
    label: "Pritaikytas didelių gabaritų automobiliams",
  },
  { value: "parking_under_roof", label: "Parkavimo vieta po stogu" },
  { value: "parking_with_no_roof", label: "Parkavimo vieta be stogo" },
];

export const GARAGE_TYPE_CHOICES = [
  { value: "separate_garage", label: "Atskiras garažas" },
  { value: "underground_garages", label: "Požeminių garažų komplekse" },
  { value: "ground_garages", label: "Antžeminių garažų komplekse" },
  { value: "multi_storey_garages", label: "Kelių aukštų garažų komplekse" },
];

export const PARKING_TYPE_CHOICES = [
  { value: "separate_parking_lot", label: "Atskira parkavimo vieta" },
  { value: "underground_parking", label: "Požeminėje parkavimo aikštelėje" },
  { value: "ground_parking", label: "Antžeminėje parkavimo aikštelėje" },
  { value: "multi_storey_parking", label: "Kelių aukštų parkavimo aikštelėje" },
];

export const QUARTER_CHOICES = [
  { value: 1, label: "I ketvirtis" },
  { value: 2, label: "II ketvirtis" },
  { value: 3, label: "III ketvirtis" },
  { value: 4, label: "IV ketvirtis" },
];

export const CLIENT_TYPE_CHOICES = [
  { value: "private", label: "Privatus asmuo" },
  { value: "enterprise", label: "Įmonė" },
];

export const INVOICE_CHOICES = [
  { value: "not_known", label: "Nereikalinga" },
  { value: "private", label: "Išrašoma privačiam asmeniui" },
  { value: "enterprise", label: "Išrašoma įmonei" },
];
