import React from "react";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import {
  PLOT_AREA_OPTIONS,
  SIZE_OPTIONS,
  SIZE_OPTIONS_PARKING,
} from "../../../../utils/adOptions";
import { validationSchemaRoomFilter } from "../hooks/schema";
import useFilterType from "../hooks/useFilterType";
// import FormPreview from "../../../AdForm/utils/FormPreview";
import FormikBasicAutocomplete from "../../../SharedComponents/FormikBasicAutocomplete";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

export default function AreaFilter(props) {
  const { formikRef, handleSubmit } = props;
  const params = useParamsFromUrl();
  const { isLand, isParking, isGarage, isPremises, isApartmentComplex } =
    useFilterType(params);
  const units = isLand ? "arai" : "m²";

  const initialValues = {
    min_size: +params?.min_size || null,
    max_size: +params?.max_size || null,
    min_plot_area: +params?.min_plot_area || null,
    max_plot_area: +params?.max_plot_area || null,
    area_from: +params?.area_from || null,
    area_to: +params?.area_to || null,
  };

  let sizeOptions = SIZE_OPTIONS;
  if (isParking || isGarage) {
    sizeOptions = SIZE_OPTIONS_PARKING;
  }

  let minSize = "min_size";
  let maxSize = "max_size";
  if (isLand) {
    sizeOptions = PLOT_AREA_OPTIONS;
    minSize = "min_plot_area";
    maxSize = "max_plot_area";
  }
  if (isPremises || isApartmentComplex) {
    minSize = "area_from";
    maxSize = "area_to";
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchemaRoomFilter}
      enableReinitialize={true}
      validateOnChange={true}
      validateOnBlur={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate style={{ width: "100%" }}>
          <Grid container spacing={1}>
            {/* <Grid item xs={12}>
              <Divider textAlign="center" sx={{ mt: 1 }}>
                <Typography variant="subtitle1" color="primary">
                  Plotas
                </Typography>
              </Divider>
            </Grid> */}

            <Grid item xs={6}>
              <FormikBasicAutocomplete
                name={minSize}
                label={`Plotas nuo (${units})`}
                options={
                  !!+formik.values[maxSize]
                    ? sizeOptions.filter(
                        (option) => option <= +formik.values[maxSize]
                      )
                    : sizeOptions
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormikBasicAutocomplete
                name={maxSize}
                label={`Plotas iki (${units})`}
                options={sizeOptions.filter(
                  (option) => option >= +formik.values[minSize]
                )}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
        </Form>
      )}
    </Formik>
  );
}
