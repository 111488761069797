import React, { useState } from "react";
import { Typography, Accordion, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useGlobalContext } from "../../../context";
import EnquiryForm from "./EnquiryForm";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const AccordionEnquiry = ({ ad, writeMessage, setWriteMessage }) => {
  const { userState, dispatchUser } = useGlobalContext();
  const [expanded, setExpanded] = useState(false);
  const { enquirerId } = useParams();
  // const existingEnquiry = !!dashboardState.enquiries.filter(
  //   (enquiry) => enquiry.ad === +adId && enquiry.enquirer === +enquirerId
  // ).length;
  const isOwnAd = ad.properties.owner === +enquirerId;

  // close enquiry accordion when user logs out
  useEffect(() => {
    if (!userState.isAuthenticated) {
      setExpanded(false);
    }
  }, [userState.isAuthenticated]);

  useEffect(() => {
    if (writeMessage) {
      setExpanded(true);
      setWriteMessage(false);
    }
  }, [setWriteMessage, writeMessage]);

  function handleCancelClick(resetForm, initialValues) {
    resetForm(initialValues);
    setExpanded(false);
  }

  function handleAccordionClick(event, newValue) {
    if (!userState.isAuthenticated) {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { openLoginDialog: true },
      });
      return;
    }
    if (userState.isAuthenticated && !isOwnAd) {
      setExpanded(newValue);
    }
  }

  return (
    <>
      {!isOwnAd && !userState?.currentUser?.suspended && (
        <Accordion
          expanded={expanded}
          onChange={handleAccordionClick}
          sx={{
            width: "100%",
            // mt: 1,
            // height: expanded ? "100%" : "auto"
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography
              variant="button"
              color={expanded ? "primary" : "default"}
            >
              Žinutės
            </Typography>
          </AccordionSummary>
          <EnquiryForm handleCancelClick={handleCancelClick} />
        </Accordion>
      )}
    </>
  );
};

export default AccordionEnquiry;
