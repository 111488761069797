import React from "react";
import { Grid } from "@mui/material";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
// import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

function DescriptionPanel({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "descriptionPanel";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getDescriptionInitialValues(ad);
  const validationSchema = schema.descriptionValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate spellCheck="false">
          <Grid container spacing={1}>
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikTextField
                name="description"
                label="Aprašymas"
                multiline={true}
                rows={8}
              />
            </Grid>
          </Grid>

          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default DescriptionPanel;
