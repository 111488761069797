import { useEffect, useState } from "react";
import axiosInstance from "../../utils/axios";
import { useHistory } from "react-router-dom";

export default function useAuthenticate() {
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    axiosInstance
      .get("api/management/auth/")
      .then((isAdmin) => {
        setIsAdmin(isAdmin);
        if (!isAdmin) {
          console.log("Unauthorized access");
          history.push("/");
        }
      })
      .catch(function (error) {
        console.log("Error ocurred while trying to authenticate", error);
        history.push("/");
      });
  }, [history]);

  return { isAdmin };
}
