import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../../context";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "leaflet-draw/dist/leaflet.draw-src.js";
import { Alert, Snackbar } from "@mui/material";
import reloadAds from "../../../../utils/getUrlFromParams";
import { FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { placeMarkerIcon } from "../BasicMapComponent";
import { PREFERENCE_ARRAY } from "../../../../utils/defaults";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

function PlaceSelector() {
  const map = useMap();
  const { state, dispatch } = useGlobalContext();

  const params = useParamsFromUrl();
  const history = useHistory();
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const initialPlaceCoords = params?.place_coords;
  const [placeCoords, setPlaceCoords] = useState(initialPlaceCoords);
  const coordinates = !!params?.place_coords
    ? JSON.parse(params.place_coords).map((x) => x.coordinates.reverse())
    : "";

  const editControlRef = useRef(null);
  const featureGroupRef = useRef(null);

  // add preexisting markers to featureGroupRef from params
  useEffect(() => {
    if (!!featureGroupRef.current && !!coordinates) {
      featureGroupRef.current.clearLayers();
      coordinates.forEach((position) =>
        L.marker(position, { icon: new placeMarkerIcon() }).addTo(
          featureGroupRef.current
        )
      );
    }
  }, [featureGroupRef.current]);

  // activate drawing/editing mode
  useEffect(() => {
    if (!!editControlRef.current?._toolbars) {
      !!initialPlaceCoords
        ? editControlRef.current._toolbars.edit._modes.edit.handler.enable()
        : editControlRef.current._toolbars.draw._modes.marker.handler.enable();
    }
  }, [editControlRef.current?._toolbars, initialPlaceCoords]);

  // set map center and zoom after drawing/editing toolbar is loaded
  useEffect(() => {
    if (
      !!editControlRef.current?._toolbars?.draw?._actionButtons?.length ||
      !!editControlRef.current?._toolbars?.edit?._actionButtons?.length
    ) {
      const storedMapCenter = JSON.parse(localStorage.getItem("mapCenter"));
      const storedMapZoom = JSON.parse(localStorage.getItem("mapZoom"));
      if (storedMapCenter && storedMapZoom) {
        map.setView(storedMapCenter, storedMapZoom);
      }
    }
  }, [
    editControlRef.current?._toolbars?.draw?._actionButtons,
    editControlRef.current?._toolbars?.edit?._actionButtons,
    map,
  ]);

  // get editControlRef variable
  function enableDraw(e) {
    editControlRef.current = e;
  }

  function handleCreatedMarker(e) {
    let layers = featureGroupRef.current.getLayers();
    console.log("layers from handleCreatedMarker", layers);
    let placeCoords = JSON.stringify(
      layers.map((layer) => layer.toGeoJSON().geometry)
    );
    setPlaceCoords(placeCoords);
  }

  function handleDeletedMarker() {
    let layers = featureGroupRef.current.getLayers();
    if (!!layers.length) {
      let placeCoords = JSON.stringify(
        layers.map((layer) => layer.toGeoJSON().geometry)
      );
      setPlaceCoords(placeCoords);
    } else {
      setPlaceCoords("");
    }
  }

  // pass down 'accept' action from MapDialog component
  useEffect(() => {
    if (!!state?.mapDialogOkClick && !!state.drawPlacesMode) {
      let placeCoords = "";
      let layers = featureGroupRef.current.getLayers();
      if (!!layers.length) {
        editControlRef.current._toolbars.edit._modes.edit.handler.save();
        placeCoords = JSON.stringify(
          layers.map((layer) => layer.toGeoJSON().geometry)
        );
      }
      changeParams(placeCoords);
    }
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { mapDialogOkClick: false },
    });
  }, [state.mapDialogOkClick]);

  function changeParams(place_coords) {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { drawPlacesMode: false },
    });
    let newParams = { ...params, place_coords };
    // when place coords are removed, remove preference
    // additionally reset ordering, if it was the only preference
    if (!place_coords) {
      newParams = { ...newParams, distance: 0 };
      let selectedPreferencesCount = PREFERENCE_ARRAY.filter(
        (preference) => !!newParams[preference.param]
      ).length;
      if (params?.ordering === "-preferences" && !selectedPreferencesCount) {
        setShowSnackBar(true);
        setSnackBarMessage("Pakeistas rūšiavimas: rodomi naujausi skelbimai");
        newParams = { ...newParams, ordering: "" };
        delete newParams.page;
      }
    }
    // add new preference when place coordinates change
    if (!!place_coords && !newParams?.distance) {
      newParams = { ...newParams, distance: 1 };
    }
    reloadAds(newParams, dispatch, history);
  }

  return (
    <>
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onMounted={enableDraw}
          onCreated={handleCreatedMarker}
          onDeleted={handleDeletedMarker}
          draw={{
            marker: {
              icon: new placeMarkerIcon(),
            },
            polygon: false,
            rectangle: false,
            circle: false,
            polyline: false,
            circlemarker: false,
            workAround: Math.random(),
          }}
          edit={{
            edit: !!placeCoords,
            remove: !!placeCoords,
          }}
        />
      </FeatureGroup>
      {showSnackBar && (
        <Snackbar
          open={showSnackBar}
          autoHideDuration={5000}
          onClose={() => {
            setShowSnackBar(false);
            // setTransitionInProgress(0);
          }}
          // message="Rūšiavimas pakeistas į numatytąjį"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          // action={action}
          // TransitionComponent={TransitionLeft}
        >
          <Alert variant="filled" severity="warning">
            {snackBarMessage}
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default PlaceSelector;
