import retrieveFirebaseToken from "../../firebase";
import axiosInstance from "../../utils/axios";
// import firebase from "../../firebase";
// import { getToken } from "firebase/messaging";

// export function fetchFavorites(dispatchDashboard) {
//   axiosInstance
//     .get("api/ads/selected-ads/", { params: { select_ads: "my_favorites" } })
//     .then((res) => {
//       console.log("favorite ad results", res);
//       dispatchDashboard({
//         type: "CHANGE_DASHBOARD_STATE",
//         payload: { myFavorites: res.data.features, myFavoritesLoaded: true },
//       });
//     })
//     .catch(function (error) {
//       console.log("Failed to load favorites ads", error);
//     });
// }

export function fetchSelectedAds(dispatchDashboard, select_ads) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { selectedAdsLoading: true },
  });

  let adsToSelect = "myAds";
  let adsLoaded = "myAdsLoaded";
  if (select_ads === "my_favorites") {
    adsToSelect = "myFavorites";
    adsLoaded = "myFavoritesLoaded";
  }
  if (select_ads === "my_enquiry_ads") {
    adsToSelect = "myEnquiryAds";
    adsLoaded = "myEnquiryAdsLoaded";
  }
  axiosInstance
    .get("api/ads/selected-ads/", { params: { select_ads } })
    .then((res) => {
      console.log(`server response: ${adsToSelect} loaded`, res.data.features);
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { [adsToSelect]: res.data.features, [adsLoaded]: true },
      });
    })
    .catch((error) => {
      console.log(`Failed to load ${adsToSelect}`, error);
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { selectedAdsLoading: false },
      });
    });
}

export function crudMyAd(dispatch, dispatchDashboard, adId, data, method) {
  const url = `api/ads/${adId}/change/`;
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { adLoading: true },
  });
  axiosInstance({ method, url, data })
    .then((res) => {
      console.log(
        "server response: successful CRUD operation on my ad",
        res.data
      );
      let updatedAd = res?.data || null;
      dispatchDashboard({
        type: "CRUD_MY_AD",
        payload: { adId, updatedAd, method },
      });
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          adsLoaded: false,
          adCardsLoaded: false,
          ad: updatedAd,
          ads: [],
        },
      });
    })
    .catch((error) => {
      console.log("server response: Failed CRUD operation on my ad", error);
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { adLoading: false },
      });
    });
}

// // try to integrate into fetchSelectedAds
// export function fetchEnquiryAds(dispatchDashboard) {
//   axiosInstance
//     .get("api/ads/selected-ads/", { params: { my_enquiries: true } })
//     .then((res) => {
//       console.log("server response: fetch enquiry ads", res.data.features);
//       dispatchDashboard({
//         type: "FETCH_MY_ENQUIRY_ADS",
//         payload: { myEnquiryAds: res.data.features },
//       });
//     })
//     .catch(function (error) {
//       console.log("Server response: Failed to fetch enquiry ads", error);
//     });
// }

export function fetchSelectedAdData(
  adIdValue,
  selectedAd,
  setSelectedAd,
  setShowAdChip,
  getAddress
) {
  if (!!adIdValue) {
    if (Number(adIdValue) === Number(selectedAd?.ad)) {
      setShowAdChip(true);
    } else {
      axiosInstance
        .get(`api/ads/selected-ads/${adIdValue}`)
        .then((res) => {
          console.log("server response: fetched ad data by id", res);
          let ad = res.data;
          let selectedAd = {
            ad: ad.id,
            title: `${ad.properties.ad_type} (skelbimo ID: ${ad.id})`,
            address: getAddress(ad),
            label:
              `${ad.properties.ad_type} (skelbimo ID ${ad.id})` +
              " " +
              getAddress(ad),
            photo: ad.properties.photos[0].photo,
          };
          setSelectedAd(selectedAd);
          setShowAdChip(true);
        })
        .catch((error) => {
          setSelectedAd(null);
          setShowAdChip(true);
          console.log("Failed to fetch selected ad data", error);
        });
    }
  }
}

// // delete this when ready
// export function fetchAdContacts(currentUserId, dispatchDashboard, form) {
//   axiosInstance
//     .get(`api/chat/contacts/`, { params: form })
//     .then((res) => {
//       console.log("server response: adContacts loaded", res.data);
//       const adMessages = res.data;
//       const adContacts = adMessages.filter((enquiry) => !!enquiry.name);
//       // alert("adContacts downloaded");
//       dispatchDashboard({
//         type: "CHANGE_DASHBOARD_STATE",
//         payload: { adContacts, adMessages },
//       });
//     })
//     .catch((error) => {
//       console.log("server response: Failed to load ad contacts", error);
//     });
// }

export function changeContactStatus(currentUserId, dispatchDashboard, form) {
  console.log("form from changeContactStatus", form);

  axiosInstance
    .patch(`api/chat/contacts/${form.id}/`, form)
    .then((res) => {
      console.log("server response: contact status changed", res);
      if (res.status === 200) {
        // fetchAdContacts(currentUserId, dispatchDashboard, form);
        // fetchEnquiries(currentUserId, form, dispatchDashboard);
        dispatchDashboard({
          type: "CHANGE_AD_CONTACTS",
          payload: { contact: res.data },
        });
      }
    })
    .catch((error) => {
      console.log("Server response: Failed to change contact status", error);
      // setLoadingAdContacts(false);
    });
}

// export function fetchEnquiries(currentUserId, form, dispatchDashboard) {
//   axiosInstance
//     .get(`chat/api/enquiries/${currentUserId}/`, { params: form })
//     .then((res) => {
//       console.log("server response: fetched my enquiries", res.data);
//       // alert("loaded enquiries");
//       dispatchDashboard({
//         type: "SET_MY_ENQUIRIES",
//         payload: { myEnquiries: res.data },
//       });
//     })
//     .catch(function (error) {
//       console.log("server response: Failed to fetch enquiries of user", error);
//     });
// }

// export function updateAppointment(dispatchDashboard, appointment) {
//   axiosInstance
//     .patch(`chat/api/appointments/${appointment.id}/`, appointment)
//     .then((response) => {
//       console.log("server response: update appointment", response);
//       let appointmentObject = response.data;
//       dispatchDashboard({
//         type: "UPDATE_DELETE_APPOINTMENT",
//         payload: { appointmentObject },
//       });
//     })
//     .catch((error) => {
//       console.log("server response: Failed to update appointment", error);
//     });
// }

export function crudAppointment(
  dispatchDashboard,
  appointment,
  method,
  appointmentMessage,
  isInitialEnquiry
) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { myAppointmentsLoading: true, myAppointmentsLoaded: false },
  });
  const appointmentId = appointment?.id;
  let url =
    method === "post" || method === "get"
      ? "api/chat/appointments/"
      : `api/chat/appointments/${appointmentId}/`;
  axiosInstance({ method, url, data: appointment })
    .then((res) => {
      console.log(
        "server response: successful CRUD operation on appointment",
        res.data
      );
      if (method !== "get" && !!appointmentMessage && !isInitialEnquiry) {
        listCreateEnquiryMessage(
          dispatchDashboard,
          "post",
          null,
          appointmentMessage
        );
      }
      let appointmentObject = res?.data || null;
      dispatchDashboard({
        type: "CRUD_APPOINTMENT",
        payload: { appointmentId, appointmentObject, method },
      });
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { myAppointmentsLoaded: true },
      });
    })
    .catch((error) => {
      console.log(
        "server response: Failed CRUD operation on appointment",
        error
      );
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { myAppointmentsLoading: false },
      });
    });
}

export function crudCalendarEntry(dispatchDashboard, calendarEntry, method) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { myCalendarEntriesLoading: true },
  });
  const calendarEntryId = calendarEntry?.id;
  let url =
    method === "post" || method === "get"
      ? "api/chat/calendar-entries/"
      : `api/chat/calendar-entries/${calendarEntryId}/`;
  axiosInstance({ method, url, data: calendarEntry })
    .then((res) => {
      console.log(
        "server response: successful CRUD operation on calendar entry",
        res.data
      );
      let calendarEntryObject = res?.data || null;
      dispatchDashboard({
        type: "CRUD_CALENDAR_ENTRY",
        payload: {
          calendarEntryId: calendarEntryId,
          calendarEntryObject,
          method,
        },
      });
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { myCalendarEntriesLoaded: true },
      });
    })
    .catch((error) => {
      console.log(
        "server response: Failed CRUD operation on calendar entry",
        error,
        error.code
      );
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { myCalendarEntriesLoading: false },
      });
    });
}

export function listAdContacts(dispatchDashboard) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { adContactsLoading: true },
  });
  let url = "api/chat/ad-contacts/";
  axiosInstance({ url })
    .then((res) => {
      console.log("server response: list ad contacts", res.data);
      let adContacts = res?.data || null;
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { adContacts, adContactsLoaded: true },
      });
    })
    .catch((error) => {
      console.log("server response: Failed to list ad contacts", error);
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { adContactsLoading: false },
      });
    });
}

export function listCreateEnquiryMessage(
  dispatchDashboard,
  method,
  params,
  data
) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { enquiriesLoading: true },
  });
  let url = "api/chat/enquiries/";
  axiosInstance({ method, url, params, data })
    .then((res) => {
      console.log(
        "server response: successful create or list operation on chat message",
        res.data
      );
      let enquiryObject = res?.data || null;
      dispatchDashboard({
        type: "CRUD_ENQUIRY_MESSAGE",
        payload: { enquiryObject, method, params },
      });
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { enquiriesLoaded: true },
      });
    })
    .catch((error) => {
      console.log(
        "server response: Failed create or list operation on chat message",
        error
      );
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { enquiriesLoading: false },
      });
    });
}

export function deleteEnquiryMessage(dispatchDashboard, enquiryId) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { enquiriesLoading: true },
  });
  let url = `api/chat/enquiries/${enquiryId}/`;
  axiosInstance({ url, method: "patch" })
    .then((res) => {
      console.log(
        "server response: successfully deleted chat message",
        res.data
      );
      let enquiryObject = res?.data || null;
      dispatchDashboard({
        type: "CRUD_ENQUIRY_MESSAGE",
        payload: { enquiryObject, method: "patch" },
      });
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { enquiriesLoaded: true },
      });
    })
    .catch((error) => {
      console.log("server response: failed to delete chat message", error);
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { enquiriesLoading: false },
      });
    });
}

export function uploadAdPhotos(formData, adId, dispatchX, dispatch) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { photosLoading: true },
  });
  axiosInstance
    .post(`api/ads/${adId}/upload-ad-photos/`, formData, {
      headers: {
        // Authorization: localStorage.getItem("access_token")
        //   ? "JWT " + localStorage.getItem("access_token")
        //   : null,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("uploading photos - server response", res);
      // dispatchX is either dispatchDashboard or dispatchAdForm (if we are dealing with ad draft)
      dispatchX({
        type: "ADD_AD_PHOTOS",
        payload: { photos: res.data },
      });
      // update adCards
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adCardsLoaded: false },
      });
    })
    .catch((err) => {
      console.log(
        "error while uploading photos",
        err,
        err?.response?.data?.detail
      );
    })
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { photosLoading: false },
      });
    });
}

export function deletePhoto(photoId, dispatchX, dispatch) {
  dispatchX({
    type: "DELETE_AD_PHOTO",
    payload: { deletedPhoto: photoId },
  });
  axiosInstance
    .delete(`api/ads/photo/${photoId}/`)
    .then((res) => {
      console.log("deleting photo - server response", res);
      // update adCards
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adCardsLoaded: false },
      });
      // dispatchDashboard({
      //   type: "DELETE_AD_PHOTO",
      //   payload: { deletedPhoto: photoId },
      // });
    })
    .catch((err) => {
      console.log(
        "error while deleting photo",
        err,
        err?.response?.data?.detail
      );
    });
}

export function rotatePhoto(photoId, dispatchX) {
  axiosInstance
    .patch(`api/ads/rotate-photo/${photoId}/`)
    .then((res) => {
      console.log("server response: rotate photo", res);
      dispatchX({
        type: "UPDATE_AD_PHOTO",
        payload: { updatedPhoto: res.data },
      });
    })
    .catch((err) =>
      console.log("server response: error while rotating photo", err)
    );
  // .finally(() => setIsRotatingPhoto(false));
}

export function updatePhoto(
  photoId,
  formData,
  dispatchX,
  setIsUpdatingPhoto,
  dispatch
) {
  setIsUpdatingPhoto(true);
  axiosInstance
    .patch(`api/ads/photo/${photoId}/`, formData, {
      headers: {
        // Authorization: localStorage.getItem("access_token")
        //   ? "JWT " + localStorage.getItem("access_token")
        //   : null,
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("server response: successfully updated photo", res);
      dispatchX({
        type: "UPDATE_AD_PHOTO",
        payload: { updatedPhoto: res.data },
      });
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          adCardsLoaded: false,
          adsLoaded: false,
          ads: [],
          adCards: [],
          ad: null,
        },
      });
    })
    .catch((err) => {
      console.log(
        "server response: error while updating photo",
        err,
        err?.response?.data?.detail
      );
    })
    .finally(() => setIsUpdatingPhoto(false));
}

export function changePhotoOrder(
  adId,
  reorderedPhotos,
  photoOrder,
  dispatchX,
  dispatch
) {
  // want immediate response (don't wait for server response but change state immediately)
  dispatchX({
    type: "CHANGE_AD_PHOTOS_ORDER",
    payload: { reorderedPhotos, adToReorder: adId },
  });
  axiosInstance
    .patch(`api/ads/${adId}/change-photo-order/`, photoOrder)
    .then((res) => {
      console.log("updating photo order - server response", res);
      // update adCards
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: {
          adCardsLoaded: false,
          adsLoaded: false,
          ads: [],
          adCards: [],
          ad: null,
        },
      });
    })
    .catch((err) => {
      console.log(
        "error while updating photo order",
        err,
        err?.response?.data?.detail
      );
    });
}

// export function updateAd(
//   adId,
//   adForm,
//   dispatch,
//   dispatchDashboard,
//   setIsUpdated
// ) {
//   axiosInstance
//     .patch(`api/ads/selected-ads/${adId}/`, adForm)
//     .then((res) => {
//       console.log("server response: updating ad", res);
//       dispatchDashboard({
//         type: "UPDATE_MY_AD",
//         payload: { adToUpdate: res.data },
//       });
//       dispatch({
//         type: "UPDATE_AD",
//         payload: { adToUpdate: res.data },
//       });
//       setIsUpdated(true);
//     })
//     .catch((err) => {
//       console.log(
//         "server response: failed to update ad",
//         err,
//         err?.response?.data?.detail
//       );
//     });
// }

// export async function retrieveFirebaseToken() {
//   // let messaging = firebase.messaging();
//   let currentToken;
//   try {
//     currentToken = await getToken(messaging, {
//       vapidKey:
//         "BDksdXvjFr4isYsC114_Nqel26aX3VISlXjBhGePa2ECvmdZg-X0vTf7LvYrccUVmtFQDDNQhmiLbVbck4-8aac",
//     });
//   } catch (err) {
//     console.log("An error occurred while retrieving token. ", err);
//     currentToken = null;
//   }
//   console.log("current token for client: ", currentToken);
//   return currentToken;
// }

// export async function retrieveFirebaseToken(form) {
//   let messaging = firebase.messaging();
//   let token;
//   await messaging
//     .getToken({
//       vapidKey:
//         "BBCE41T6cPIE-G8DXMoJJkGt2nqzRqSl-VUn2jJZ6yVTq-eHliHt6AK-Yitg15vuaQ2v0gYCn0JHtzOrG9gUPas",
//     })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("current token for client: ", currentToken);
//         token = currentToken;
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//     });
//   return token;
// }

// export async function retrieveFirebaseToken(form) {
//   let messaging = firebase.messaging();
//   messaging
//     .getToken({
//       vapidKey:
//         "BBCE41T6cPIE-G8DXMoJJkGt2nqzRqSl-VUn2jJZ6yVTq-eHliHt6AK-Yitg15vuaQ2v0gYCn0JHtzOrG9gUPas",
//     })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("current token for client: ", currentToken);
//         form.firebase_token = currentToken;
//         return form;
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//     });
// }

export async function crudSearchNotificationSettings(
  dispatchNotification,
  method,
  form
) {
  const url = "api/notifications/search-notification/";
  dispatchNotification({
    type: "CHANGE_NOTIFICATION_STATE",
    payload: {
      searchNotificationSettingsLoading: true,
    },
  });

  console.log("notification form", form);
  if (!!form) {
    form.notification_modes = form.notification_modes.split(",").map((m) => {
      return {
        notification_mode: m,
      };
    });
    if (!!form?.send_notifications && form.notification_modes !== "mail") {
      let firebase_token = await retrieveFirebaseToken();
      form = { ...form, firebase_token };
    }
  }

  axiosInstance({ method, url, data: form })
    .then((res) => {
      console.log(
        "server response: successful CRUD operation on search notification settings",
        res.data
      );
      dispatchNotification({
        type: "CHANGE_NOTIFICATION_STATE",
        payload: {
          searchNotificationSettings: method === "get" ? res.data[0] : res.data,
          searchNotificationSettingsLoaded: true,
        },
      });
    })
    .catch((error) => {
      console.log(
        "server response: failed CRUD operation on search notification settings",
        error,
        error.detail
      );
    })
    .finally(() => {
      dispatchNotification({
        type: "CHANGE_NOTIFICATION_STATE",
        payload: {
          searchNotificationSettingsLoading: false,
        },
      });
    });
}

// export async function changeNotificationSettings(dispatchNotification, form) {
//   console.log("notification form", form);
//   if (!!form.send_notifications && form.notification_modes !== "mail") {
//     let messaging = firebase.messaging();
//     let firebase_token;
//     try {
//       firebase_token = await messaging.getToken({
//         vapidKey:
//           "BBCE41T6cPIE-G8DXMoJJkGt2nqzRqSl-VUn2jJZ6yVTq-eHliHt6AK-Yitg15vuaQ2v0gYCn0JHtzOrG9gUPas",
//       });
//       console.log("firebase token acquired", firebase_token);
//     } catch (error) {
//       console.log("An error occurred while retrieving firebase token. ", error);
//       return;
//     }
//     form = { ...form, firebase_token };
//   }

//   form.notification_modes = form.notification_modes.split(",").map((m) => {
//     return {
//       notification_mode: m,
//     };
//   });

//   axiosInstance
//     .post("api/notifications/search-notification/", form)
//     .then((res) => {
//       console.log(
//         "server response: changed search notification settings",
//         res.data
//       );
//       dispatchNotification({
//         type: "CHANGE_NOTIFICATION_STATE",
//         payload: {
//           searchNotificationSettings: res.data[0],
//           searchNotificationSettingsLoaded: true,
//         },
//       });
//     })
//     .catch((error) => {
//       console.log(
//         "Failed to chainge search notification settings",
//         error,
//         error.detail
//       );
//     });
// }

// export function fetchSearchNotificationSettings(dispatchNotification) {
//   axiosInstance
//     .get("api/notifications/search-notification/")
//     .then((res) => {
//       console.log(
//         "server response: fetch interval of automatic checking of search results",
//         res
//       );
//       dispatchNotification({
//         type: "CHANGE_NOTIFICATION_STATE",
//         payload: {
//           searchNotificationSettings: res.data[0],
//           searchNotificationSettingsLoaded: true,
//         },
//       });
//     })
//     .catch((error) => {
//       console.log("Failed to fetch check interval in db", error, error.detail);
//     });
// }

export function crudSavedSearch(
  dispatchDashboard,
  method,
  savedSearch,
  params
) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { mySearchesLoading: true },
  });

  console.log("savedSearch", savedSearch);
  const savedSearchId = savedSearch?.id;
  let url = ["post", "get"].includes(method)
    ? "api/ads/saved-search/"
    : `api/ads/saved-search/${savedSearchId}/`;
  axiosInstance({ method, url, data: savedSearch, params })
    .then((res) => {
      console.log(
        "server response: successful CRUD operation on saved search",
        res.data
      );
      let savedSearchObject = res?.data || null;
      if (!savedSearch?.reordered_ids) {
        dispatchDashboard({
          type: "CRUD_SAVED_SEARCH",
          payload: {
            savedSearchId,
            savedSearchObject,
            idToOverwrite: savedSearch?.id_to_overwrite || "",
            method,
          },
        });
      }
    })
    .catch((error) => {
      console.log(
        "server response: Failed CRUD operation on saved search",
        error,
        error.code
      );
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { mySearchesLoading: false },
      });
    });
}

// export function fetchSavedSearches(dispatchDashboard) {
//   axiosInstance
//     .get("api/ads/saved-search/")
//     .then((res) => {
//       console.log("response from server: fetch my searches", res);
//       dispatchDashboard({
//         type: "FETCH_MY_SEARCHES",
//         payload: { mySearches: res.data },
//       });
//     })
//     .catch(function (error) {
//       console.log("Failed to fetch my searches", error, error.detail);
//     });
// }

// export function saveNewSearch(dispatchDashboard, form) {
//   axiosInstance
//     .post("api/ads/saved-search/", form)
//     .then((res) => {
//       console.log("server response: save new search", res);
//       dispatchDashboard({
//         type: "SAVE_NEW_SEARCH",
//         payload: { savedSearch: res.data },
//       });
//     })
//     .catch(function (error) {
//       console.log(
//         "server response: failed to save new searh",
//         error,
//         error.detail
//       );
//     });
// }

// export function updateSavedSearch(dispatchDashboard, searchId, form) {
//   axiosInstance
//     .patch(`api/ads/saved-search/${searchId}/`, form)
//     .then((res) => {
//       console.log("server response: update saved search", res);
//       if (res.request.status === 200) {
//         dispatchDashboard({
//           type: "UPDATE_SAVED_SEARCH",
//           payload: { updatedSearch: res.data },
//         });
//       }
//     })
//     .catch((error) => {
//       console.log(
//         "server response: failed to update saved search",
//         error,
//         error.detail
//       );
//     });
// }

// export function deleteSavedSearch(dispatchDashboard, searchId) {
//   dispatchDashboard({
//     type: "DELETE_SAVED_SEARCH",
//     payload: { deletedSearchId: searchId },
//   });
//   axiosInstance
//     .delete(`api/ads/saved-search/${searchId}/`)
//     .then((res) => {
//       if (res.status === 204) {
//         console.log("server response: delete search", res);
//       }
//     })
//     .catch((error) =>
//       console.log("server response: failed to delete search", error)
//     );
// }

// export function reorderSavedSearches(dispatchDashboard, searchId, form) {
//   axiosInstance
//     .patch(`api/ads/saved-search/${searchId}/`, form)
//     .then((res) => {
//       console.log("server response: reorder searches", res);
//       // if (res.status === 204) {
//       //   dispatchDashboard({
//       //     type: "DELETE_SAVED_SEARCH",
//       //     payload: { deletedSearchId: searchId },
//       //   });
//       // }
//     })
//     .catch((error) =>
//       console.log("server response: failed to reorder searches", error)
//     );
// }

export function userLikesAd(dispatchDashboard, userId, adId, form) {
  if (form.likes === false) {
    dispatchDashboard({
      type: "REMOVE_FROM_FAVORITES",
      payload: { adToRemoveFromFavorites: adId },
    });
  }
  axiosInstance
    .post(`api/user/${userId}/likes-ad/${adId}/`, form)
    .then((res) => {
      console.log("server response: user likes/dislikes ad", res);
      if (res.status === 200 && form.likes) {
        dispatchDashboard({
          type: "ADD_TO_FAVORITES",
          payload: { adToAddToFavorites: res.data },
        });
      }
    })
    .catch((error) =>
      console.log("server response: failed to register like/dislike", error)
    );
}

export function refreshSearchResults() {
  axiosInstance
    .get("api/ads/refresh-search-results")
    .then((res) =>
      console.log("server response: refreshed search results", res.data)
    )
    .catch((error) =>
      console.log("server response: failed to refresh search results", error)
    );
}

export function fetchAdViewHistory(dispatchDashboard, adId) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { adViewHistoryLoading: true },
  });
  let url = `api/ads/${adId}/ad-view-history/`;
  axiosInstance
    .get(url)
    .then((res) => {
      console.log(
        "server response: ad views history successfully loaded",
        res.data
      );
      const adViewsData = res.data.ad_views_data;
      const adLikesData = res.data.ad_likes_data;
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { adViewsData, adLikesData, adViewHistoryLoaded: adId },
      });
    })
    .catch((error) => {
      console.log("server response: Failed to load ad views history", error);
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { adViewHistoryLoading: false },
      });
    });
}

export function fetchMyOrders(dispatchDashboard) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: { myOrdersLoading: true },
  });
  let url = "api/orders/";
  axiosInstance
    .get(url)
    .then((res) => {
      console.log("server response: successfully fetched orders", res.data);
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { myOrders: res.data, myOrdersLoaded: true },
      });
    })
    .catch((error) =>
      console.log("server response: failed to fetch orders", error)
    )
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { myOrdersLoading: false },
      });
    });
}

export function downloadInvoicePDF(invoiceId, setInvoiceData) {
  const url = `api/orders/invoices/${invoiceId}/pdf/`;
  axiosInstance
    .get(url)
    .then((res) => {
      console.log(
        "server response: successfully downloaded invoice pdf data",
        res
      );
      setInvoiceData(res.data);
    })
    .catch((error) =>
      console.log("server response: failed to download invoice pdf data", error)
    );
}
