import React, { useEffect, useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Box, Link, Paper, Stack } from "@mui/material";
import { activateAccount } from "./fetchOrSendAccountsData";
import { useGlobalContext } from "../../context";
import { grey } from "@mui/material/colors";
import Footer from "../Layout/Footer/Footer";
// import { useHistory } from "react-router-dom";

export default function Activate() {
  const { dispatchRouter } = useGlobalContext();
  const params = useParams();
  // const history = useHistory();
  const [activationFailed, setActivationFailed] = useState(false);
  const [activationSuccess, setActivationSuccess] = useState(false);

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "activate" },
    });
  }, []);

  async function handleSubmit() {
    try {
      await activateAccount(params);
      // history.push("/user-profile");
      setActivationSuccess(true);
    } catch (err) {
      setActivationFailed(true);
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: grey[300],
        border: "none",
        pt: 13,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          border: "none",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
        }}
        disableGutters
      >
        <Paper sx={{ p: 1, mb: 7 }}>
          <Stack justifyContent="center" alignItems="center" sx={{ my: 2 }}>
            <Avatar sx={{ width: 70, height: 70 }} />

            {!activationFailed && !activationSuccess && (
              <>
                <Typography variant="h6">
                  Aktyvuokite sukurtą paskyrą
                </Typography>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  sx={{ mt: 2 }}
                >
                  Aktyvuoti paskyrą
                </Button>
              </>
            )}

            {activationSuccess && (
              <>
                <Typography variant="h6" color="success.main">
                  Paskyra sėkmingai aktyvuota
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Dabar galite prisijungti
                </Typography>
                <Link
                  component={RouterLink}
                  to="/user-profile"
                  variant="body2"
                  sx={{ mt: 2 }}
                >
                  Prisijungti
                </Link>
              </>
            )}

            {activationFailed && (
              <>
                <Typography variant="h6" color="error.main">
                  Aktyvavimo nuoroda negalioja
                </Typography>
                <Typography variant="body2" sx={{ mt: 2 }}>
                  Aktyvavimo nuoroda negalioja arba paskyra jau aktyvuota
                </Typography>
                <Link
                  component={RouterLink}
                  to="/user-profile"
                  variant="body2"
                  sx={{ mt: 2 }}
                >
                  Prisijungti
                </Link>
              </>
            )}
          </Stack>
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}

// {
//   /* <Typography variant="body2" sx={{ mt: 2 }}>
//   Aktyvavimo nuoroda negalioja arba paskyra jau aktyvuota
// </Typography> */
// }
