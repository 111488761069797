import React from "react";
import Grid from "@mui/material/Grid";
import {
  getArea,
  getBuildingType,
  getConstructionYear,
  getEnergyEfficiency,
  getFloor,
  getHeatingBill,
  getInstallation,
  getPlotArea,
  getRooms,
} from "../../../IndividualAd/BasicAdInfo/utils";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";

const AdCardInfoFlatHouse = ({ ad }) => {
  const { isFlat, isHouse, isLand, isRent, isSale } = useAdType(ad);

  const rooms = getRooms(ad, 6, 6);
  const area = getArea(ad, 6, 6);
  const floor = getFloor(ad, 6, 6, isFlat, isHouse);
  const buildingType = getBuildingType(ad, 6, 6);
  const plotArea = getPlotArea(ad, 6, 6, isLand);
  const constructionYear = getConstructionYear(ad, 6, 6);
  const installation = isSale && getInstallation(ad, 6, 6);
  const heatingBill = isRent && getHeatingBill(ad, 6, 6);
  const energyEfficiency = isRent && getEnergyEfficiency(ad, 6, 6);

  return (
    <Grid
      container
      sx={{ flexGrow: 1, display: "flex", alignContent: "space-around" }}
    >
      {rooms}
      {area}
      {isFlat && floor}
      {isHouse && plotArea}
      {buildingType}
      {constructionYear}
      {isSale && installation}
      {isRent && (heatingBill || energyEfficiency)}
    </Grid>
  );
};

export default AdCardInfoFlatHouse;
