import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { AD_FILTER_OPTIONS } from "../../../utils/defaults";

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default function MyFavoritesFilterSelectBox() {
  const { dashboardState, dispatchDashboard } = useGlobalContext();
  const adFilterOptions = AD_FILTER_OPTIONS.filter(
    (option) =>
      dashboardState.myFavorites
        .map((ad) => ad.properties.ad_type)
        .includes(option.value) || option.value === "all"
  );
  const filterOptionsString = adFilterOptions
    .map((option) => option.value)
    .toString();
  const [filterValue, setFilterValue] = useState(
    dashboardState?.myFavoritesFilterValue || "all"
  );

  //  set filterValue to "all" if all favorite ads in a given category are deleted
  useEffect(() => {
    if (
      !!filterValue &&
      !filterOptionsString.split(",").includes(filterValue)
    ) {
      handleChatFilterChange("all");
    }
  }, [filterValue, filterOptionsString]);

  function handleChatFilterChange(newFilterValue) {
    setFilterValue(newFilterValue);
    dispatchDashboard({
      type: "CHANGE_DASHBOARD_STATE",
      payload: { myFavoritesFilterValue: newFilterValue },
    });
  }

  return (
    <FormControl size="small" sx={{ m: 0.5 }}>
      <Select
        value={filterValue}
        variant="outlined"
        onChange={(e) => handleChatFilterChange(e.target.value)}
        MenuProps={menuProps}
        inputProps={{
          sx: { alignItems: "center", display: "flex", borderRadius: "3px" },
        }}
        sx={{ height: 30 }}
      >
        {adFilterOptions.map((option, key) => (
          <MenuItem key={key} dense value={option.value}>
            <Typography variant="caption">{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
