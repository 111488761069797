import React from "react";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { Slider } from "@mui/material";
import { NPER, PV } from "../../../utils/financialFunctions";
import { useGlobalContext } from "../../../context";
import { Finance } from "financejs";
const durationMarks = [{ value: 30, label: "Max 30 m." }];

function DurationSlider() {
  const { loanState, dispatchLoan } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const price = loanState.price;
  const duration = loanState.duration;
  const interestRate = loanState.interestRate;

  function handleChange(e, newValue) {
    let duration = newValue;
    const fixMonthlyPayment = loanState.fixMonthlyPayment;
    let monthlyPayment = loanState.monthlyPayment;
    let loanSize = loanState.loanSize;
    let downpayment = loanState.downpayment;
    let downPercent = loanState.downPercent;
    let minDownPercent = loanState.minDownPercent;
    if (fixMonthlyPayment) {
      loanSize = Math.min(
        price * (1 - minDownPercent / 100),
        PV(interestRate / 12 / 100, duration * 12, -monthlyPayment, 0, 0)
      );
      downPercent = (1 - loanSize / price) * 100;
      downpayment = (price * downPercent) / 100;
      const maxDuration =
        NPER(interestRate / 12 / 100, -monthlyPayment, loanSize, 0, 0) / 12;
      console.log("maxDuration", maxDuration);
      duration = Math.min(duration, maxDuration);
    } else {
      const finance = new Finance();
      monthlyPayment = Math.round(
        finance.PMT(interestRate / 100 / 12, duration * 12, -loanSize)
      );
    }
    dispatchLoan({
      type: "CHANGE_LOAN_STATE",
      payload: { duration, loanSize, downpayment, downPercent, monthlyPayment },
    });
  }

  return (
    <Slider
      sx={{ mt: 0.5 }}
      size={isMobile ? "small" : "medium"}
      value={Number(duration.toFixed(1))}
      onChange={handleChange}
      marks={durationMarks}
      min={0}
      max={30}
      step={1}
      valueLabelDisplay="on"
      valueLabelFormat={(value) => `Trukmė ${value} m.`}
    />
  );
}

export default DurationSlider;
