import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import SaveIcon from "@mui/icons-material/Save";
import usePanels from "../../../AdForm/Step1AdInfo/usePanels";
import { crudMyAd } from "../../fetchOrSendDashboardData";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

function EditDialog({ open, setOpen, ad }) {
  const { state, dashboardState, dispatch, dispatchDashboard } =
    useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const [initiateSubmit, setInitiateSubmit] = useState(false);

  // update ad when user presses ok and formik objects are updated
  useEffect(() => {
    if (initiateSubmit && !!dashboardState.formikLoaded) {
      const formikKeys = Object.keys(dashboardState.formik);
      const formikErrors = formikKeys.map((key) =>
        Object.keys(dashboardState.formik[key].errors)
      );
      const formikHasErrors = formikErrors.some((err) => !!err.length);
      const formikDirty = formikKeys
        .map((key) => dashboardState.formik[key].dirty)
        .some((dirty) => !!dirty);
      if (!formikDirty) {
        handleClose();
        return;
      }
      if (formikHasErrors) {
        let errorKeys = formikErrors
          .map((err, index) => (!!err.length ? formikKeys[index] : null))
          .filter((key) => !!key);
        errorKeys.forEach((errorKey) => {
          dashboardState.formik[errorKey].handleSubmit();
        });
      }
      if (!formikHasErrors) {
        const formikValues = formikKeys
          .map(
            (key) =>
              !!dashboardState.formik[key].dirty &&
              dashboardState.formik[key].values
          )
          .filter((values) => !!values);
        const data = Object.assign({}, ...formikValues);
        if (ad?.properties?.status === "blocked") {
          data.ready_for_review = true;
        }
        crudMyAd(dispatch, dispatchDashboard, ad.id, data, "PATCH");
        handleClose();
      }
      console.log("formikHasErrors", formikHasErrors, formikDirty);
      setInitiateSubmit(false);
    }
  }, [initiateSubmit, dashboardState.formikLoaded]);

  function handleClose() {
    dispatchDashboard({
      type: "CHANGE_DASHBOARD_STATE",
      payload: { formik: {} },
    });
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { ad: null },
    });
    setOpen(false);
  }

  function handleInitiateSubmit() {
    dispatchDashboard({
      type: "CHANGE_DASHBOARD_STATE",
      payload: { formikLoaded: false },
    });
    setInitiateSubmit(true);
  }

  const edit = true;
  const panelProps = { ad, edit, initiateSubmit };
  const { panels } = usePanels(panelProps);

  console.log("render EditDialog");
  console.log("mainAppState", state);

  return (
    <>
      {
        <Dialog
          open={open}
          onClose={handleClose}
          maxWidth="sm"
          fullScreen={isMobile}
          //   PaperProps={{ sx: { minWidth: 200 } }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            Redaguokite skelbimo informaciją
          </DialogTitle>
          <DialogContent>
            {panels.map((panel, index) => (
              <Box key={index}>
                <Grid item justifyContent="center" sx={{ mt: 3, mb: 2 }}>
                  <Divider textAlign="center" sx={{ my: 2 }}>
                    <Typography variant="button">{panel.label}</Typography>
                  </Divider>
                </Grid>
                {panel.component}
              </Box>
            ))}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="outlined">
              Atšaukti
            </Button>
            <Button
              onClick={handleInitiateSubmit}
              autoFocus
              variant="contained"
              startIcon={<SaveIcon fontSize="small" />}
            >
              {ad.properties.status === "blocked"
                ? "Išsaugoti ir pateikti peržiūrai"
                : "Išsaugoti"}
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );
}

export default EditDialog;
