import React from "react";
import { Container, Grid, Paper, Stack, Typography } from "@mui/material";
import { useGlobalContext } from "../../../context";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import MySearch from "./MySearch";
import { Box } from "@mui/system";
import SearchNotification from "./SearchNotification";
import MySearchesDataProvider from "./MySearchesDataProvider";
import { crudSavedSearch } from "../fetchOrSendDashboardData";
import { blue } from "@mui/material/colors";
import Spinner from "../../../utils/Spinner";

function MySearches() {
  const { dashboardState, dispatchDashboard } = useGlobalContext();

  const mySearches = Array.from(dashboardState.mySearches);
  mySearches.sort((a, b) => a.rank - b.rank);

  console.log(
    "mySearches ids",
    mySearches.map((s) => s.id)
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const items = Array.from(mySearches);
    const [reorderedItem] = items.splice(result.source.index, 1); // get the dragged item
    items.splice(result.destination.index, 0, reorderedItem); // insert dragged and droped item in new place
    items.forEach((item, index) => {
      item.rank = index + 1;
    });
    items.sort((a, b) => b.rank - a.rank);
    // console.log(
    //   "mySearches from handleOnDragEnd",
    //   mySearches,
    //   "\n items",
    //   items
    // );

    // setTimeout(() => {
    //   console.log("items test", items.map((item) => item.rank).toString());
    // }, 0);
    dispatchDashboard({
      type: "CHANGE_DASHBOARD_STATE",
      payload: { mySearches: items, mySearchesLoaded: true },
    });
    console.log("mySearches", dashboardState.mySearches[0]);
    let form = { reordered_ids: items.map((item) => item.id) };
    form.id = reorderedItem.id;
    crudSavedSearch(dispatchDashboard, "patch", form);
  }

  function handleMouseDown(e) {
    e.stopPropagation(); // to prevent sliding in the background
  }

  console.log("mySearchesLoaded", dashboardState.mySearchesLoaded);

  return (
    <>
      {!dashboardState.mySearchesLoaded && (
        <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
          <Spinner />
        </Box>
      )}
      <MySearchesDataProvider>
        <Container
          maxWidth="md"
          disableGutters
          sx={{ border: "none", px: 0, position: "relative" }}
        >
          <Paper
            elevation={1}
            sx={{
              my: 2,
              // mb: 5,
              mx: 0.5,
              p: 1,
              position: "relative",
              backgroundColor: blue[100],
              border: "solid 1px lightgrey",
            }}
          >
            <Grid container xs={12}>
              <Typography variant="body1" sx={{ m: 1 }}>
                {!!mySearches.length
                  ? `Įsimintos paieškos: ${mySearches.length}`
                  : `Nėra įsimintų paieškų`}
              </Typography>
            </Grid>
            {!!mySearches.length && <SearchNotification />}
          </Paper>

          <Box sx={{ border: "none", width: "100%", mt: -2 }}>
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="droppable-list" direction="vertical">
                {(providedDroppable) => (
                  <Stack
                    direction="column"
                    className="file-list"
                    {...providedDroppable.droppableProps}
                    ref={providedDroppable.innerRef}
                    sx={{
                      overflowX: "hidden",
                      overflowY: "auto",
                      width: "100%",
                    }}
                  >
                    {mySearches.map((search, index) => {
                      return (
                        <Draggable
                          key={`img-${index}`}
                          draggableId={`img-${index}`}
                          index={index}
                        >
                          {(providedDraggable) => (
                            <Box
                              onMouseDown={handleMouseDown}
                              ref={providedDraggable.innerRef}
                              {...providedDraggable.draggableProps}
                              {...providedDraggable.dragHandleProps}
                              sx={{ mt: 1 }}
                            >
                              <MySearch search={search} />
                            </Box>
                          )}
                        </Draggable>
                      );
                    })}
                    {providedDroppable.placeholder}
                  </Stack>
                )}
              </Droppable>
            </DragDropContext>
            {mySearches?.length > 1 && (
              <Typography variant="body2" sx={{ ml: 1 }}>
                Galite keisti paieškų eiliškumą vilkdami jas
              </Typography>
            )}
          </Box>
        </Container>
      </MySearchesDataProvider>
    </>
  );
}

export default MySearches;
