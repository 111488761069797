import React from "react";
import Typography from "@mui/material/Typography";

const Error = () => {
  return (
    <>
      <Typography variant="h2">Page does not exist</Typography>;
    </>
  );
};

export default Error;
