import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Collapse } from "@mui/material";
import ContactListHeader from "./ContactListHeader";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useHistory, useParams } from "react-router-dom";
import { blue } from "@mui/material/colors";
import ContactList from "./ContactList";
import ChatHeader from "./ChatHeader";
import MessageBar from "../../SharedComponents/MessageBar";
import { AppBar, Drawer, DrawerHeader } from "./utils";
import { useEffect } from "react";
// import connectChatSocket from "../../../../utils/connectChatSocket";
import { useGlobalContext } from "../../../../context";
import MessageBoard from "../../SharedComponents/MessageBoard";

export default function MyAdsChatDrawer() {
  let { adId, enquirerId, extension } = useParams();
  const [open, setOpen] = React.useState(!extension);
  const history = useHistory();
  const { dashboardState, dispatchDashboard } = useGlobalContext();

  // // connect and disconnect websocket when enquirerId changes
  // useEffect(() => {
  //   if (!!enquirerId && extension) {
  //     // alert("open chat socket");
  //     let isEnquirer = false;
  //     connectChatSocket(
  //       adId,
  //       enquirerId,
  //       dashboardState.chatSocket,
  //       isEnquirer,
  //       dispatchDashboard
  //     );
  //   }
  //   return () => {
  //     if (dashboardState.chatSocket?.url) {
  //       // alert("close chat socket");
  //       dashboardState.chatSocket.close();
  //     }
  //   };
  // }, [adId, enquirerId, extension]);

  return (
    <>
      <Box sx={{ display: "flex", border: "none", maxHeight: 400 }}>
        {/* <CssBaseline /> */}
        <AppBar
          position="absolute"
          open={open}
          sx={{ bgcolor: blue[100], zIndex: 2 }}
        >
          <Toolbar disableGutters sx={{ border: "none", pl: 3, pr: 1 }}>
            <IconButton
              size="medium"
              onClick={() => {
                history.replace(`/dashboard/my-ads`);
              }}
              edge="start"
            >
              <ExpandLessIcon fontSize="medium" />
            </IconButton>
            {!!extension ? <ChatHeader /> : <ContactListHeader />}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ border: "none", zIndex: 1 }}
        >
          <DrawerHeader />
          <Divider />

          <ContactList openDrawer={open} setOpenDrawer={setOpen} />
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 0, maxHeight: 400, overflow: "auto" }}
        >
          <DrawerHeader />
          <Collapse in={!open}>
            <MessageBoard />
          </Collapse>
        </Box>
      </Box>
      {!!extension && <MessageBar />}
    </>
  );
}
