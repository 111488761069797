import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCUeOaEwmlSX2h5ZZIQsWoxSbkt0DFrCyg",
  authDomain: "housemarket-57caa.firebaseapp.com",
  projectId: "housemarket-57caa",
  storageBucket: "housemarket-57caa.appspot.com",
  messagingSenderId: "445090765738",
  appId: "1:445090765738:web:7c3da949f9ff36c7a829d3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const messaging = getMessaging(app);

export default async function retrieveFirebaseToken() {
  // let messaging = firebase.messaging();
  let currentToken;
  try {
    currentToken = await getToken(messaging, {
      vapidKey:
        "BDksdXvjFr4isYsC114_Nqel26aX3VISlXjBhGePa2ECvmdZg-X0vTf7LvYrccUVmtFQDDNQhmiLbVbck4-8aac",
    });
    console.log("firebase token retrieved");
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
    currentToken = null;
  }
  console.log("current token for client: ", currentToken);
  return currentToken;
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("firebase payload", payload);
      resolve(payload);
    });
  });

// import firebase from "firebase";
// // import { useGlobalContext } from "./context";
// // import { initializeApp } from "firebase/app";
// // import { getMessaging, getToken } from "firebase/messaging";
// // import "firebase/messaging";

// const firebaseConfig = {
//   apiKey: "AIzaSyCUeOaEwmlSX2h5ZZIQsWoxSbkt0DFrCyg",
//   authDomain: "housemarket-57caa.firebaseapp.com",
//   projectId: "housemarket-57caa",
//   storageBucket: "housemarket-57caa.appspot.com",
//   messagingSenderId: "445090765738",
//   appId: "1:445090765738:web:7c3da949f9ff36c7a829d3",
// };

// firebase.initializeApp(firebaseConfig);
// export default firebase;
// export const messaging = firebase.messaging();

// // let messaging = null;
// // try {
// //   if (firebase.messaging.isSupported()) {
// //     alert("messaging is supported");
// //     messaging = firebase.messaging();
// //     // messaging.usePublicVapidKey("Your Sender ID");
// //   }
// // } catch (e) {
// //   console.log("error trying to set up firebase messaging", e);
// // }
// const messaging = getMessaging();

// getToken(messaging, {
//   vapidKey:
//     "BBCE41T6cPIE-G8DXMoJJkGt2nqzRqSl-VUn2jJZ6yVTq-eHliHt6AK-Yitg15vuaQ2v0gYCn0JHtzOrG9gUPas",
// })
//   .then((currentToken) => {
//     if (currentToken) {
//       console.log("currentToken", currentToken);
//       // Send the token to your server and update the UI if necessary
//       // ...
//     } else {
//       // Show permission request UI
//       console.log(
//         "No registration token available. Request permission to generate one."
//       );
//       // ...
//     }
//   })
//   .catch((err) => {
//     console.log("An error occurred while retrieving token. ", err);
//     // ...
//   });

// // const { REACT_APP_VAPID_KEY } = process.env;
// // const publicKey = REACT_APP_VAPID_KEY;

// // export const getToken = async (setTokenFound) => {
// //   let currentToken = "";

// //   try {
// //     console.log("messaging", messaging);
// //     console.log("publicKey", publicKey);
// //     alert("publicKey");
// //     currentToken = await messaging.getToken({
// //       vapidKey:
// //         "BJWP-VOLf6S0_tadbjVXs51pD10_N1xk5nBa6Ym_WFj5LrCPVFDbKzypbd8VRqW9rdmy1M8hHr8YK8IcA1-HH0U",
// //     });
// //     if (currentToken) {
// //       console.log("current token for client: ", currentToken);
// //       setTokenFound(true);
// //     } else {
// //       console.log(
// //         "No registration token available. Request permission to generate one."
// //       );
// //       setTokenFound(false);
// //     }
// //   } catch (error) {
// //     console.log("An error occurred while retrieving token. ", error);
// //   }

// //   return currentToken;
// // };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       console.log("message payload", payload);
//       resolve(payload);
//     });
//   });
