import { Divider, Grid, Typography } from "@mui/material";
import * as adOptions from "../../../../utils/adOptions";
import { useFormikContext } from "formik";
import FormikBasicAutocomplete from "../../../SharedComponents/FormikBasicAutocomplete";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikBasicMultipleSelect from "../../../SharedComponents/FormikBasicMultipleSelect";

function FiltersApartmentComplex() {
  const formik = useFormikContext();

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pagrindiniai filtrai
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        {/* minimum price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="price_from"
            label="Kaina (Eur, nuo)"
            options={
              !!+formik.values["price_to"]
                ? adOptions.PRICE_OPTIONS.filter(
                    (option) => option <= +formik.values["price_to"]
                  )
                : adOptions.PRICE_OPTIONS
            }
            type="number"
            removeSpinners={true}
          />
        </Grid>
        {/* max price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="price_to"
            label="Kaina (Eur, iki)"
            options={adOptions.PRICE_OPTIONS.filter(
              (option) => option >= +formik.values["price_from"]
            )}
            type="number"
            removeSpinners={true}
          />
        </Grid>

        {/* minimum room setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="rooms_from"
            label="Kambarių sk. (nuo)"
            options={
              !!+formik.values["rooms_to"]
                ? adOptions.ROOM_OPTIONS.filter(
                    (option) => option <= +formik.values["rooms_to"]
                  )
                : adOptions.ROOM_OPTIONS
            }
            type="number"
          />
        </Grid>
        {/* max room setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="rooms_to"
            label="Kambarių sk. (iki)"
            options={adOptions.ROOM_OPTIONS.filter(
              (option) => option >= +formik.values["rooms_from"]
            )}
            type="number"
          />
        </Grid>
        {/* minimum size setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="area_from"
            label="Plotas (m², nuo)"
            options={
              !!+formik.values["area_to"]
                ? adOptions.SIZE_OPTIONS.filter(
                    (option) => option <= +formik.values["area_to"]
                  )
                : adOptions.SIZE_OPTIONS
            }
            type="number"
          />
        </Grid>
        {/* max size setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="area_to"
            label="Plotas (m², iki)"
            options={adOptions.SIZE_OPTIONS.filter(
              (option) => option >= +formik.values["area_from"]
            )}
            type="number"
          />
        </Grid>

        <Grid item xs={12}>
          <FormikSelect
            name="installation"
            label="Baigtumas"
            options={adOptions.INSTALLATION_CHOICES_APARTMENT_COMPLEX}
            enableClear={true}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pastatas
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        {/* minimum construction year setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_year"
            label={`Statybos  metai (nuo)`}
            options={
              !!+formik.values["max_year"]
                ? adOptions.YEAR_OPTIONS.filter(
                    (option) => option <= +formik.values["max_year"]
                  )
                : adOptions.YEAR_OPTIONS
            }
            type="number"
          />
        </Grid>
        {/* max construction year setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_year"
            label={`Statybos  metai (iki)`}
            options={adOptions.YEAR_OPTIONS.filter(
              (option) => option >= +formik.values["min_year"]
            )}
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="floors_from"
            label="Aukštų sk. (nuo)"
            options={
              !!+formik.values["floors_to"]
                ? adOptions.FLOORS_OPTIONS.filter(
                    (option) => option <= +formik.values["floors_to"]
                  )
                : adOptions.FLOORS_OPTIONS
            }
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="floors_to"
            label="Aukštų sk. (iki)"
            options={adOptions.FLOORS_OPTIONS.filter(
              (option) => option >= +formik.values["floors_from"]
            )}
            type="number"
          />
        </Grid>

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="building_type"
            label="Pastato konstrukcija"
            options={adOptions.BUILDING_TYPE_CHOICES}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="heating"
            label="Šildymas"
            options={adOptions.HEATING_CHOICES}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default FiltersApartmentComplex;
