import { Box, Dialog, DialogContent } from "@mui/material";
import React, { useEffect, useCallback } from "react";
import Login from "./Login";
import { useGlobalContext } from "../../context";
import { useLocation } from "react-router-dom";
import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";
import { Cancel } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

export default function LoginDialog() {
  const { userState, dispatchUser } = useGlobalContext();
  const { isMobile, isSmallOrMobile } = useCustomMediaQuery();
  const openLoginDialog = userState.openLoginDialog;
  const location = useLocation();
  const pushTo = location.pathname + location.search;

  console.log("location from LoginDialog", location);

  //   useEffect(() => {
  //     if (!!userState.isAuthenticated) {
  //       !!pushTo
  //         ? history.push(pushTo)
  //         : history.replace(location.state?.from || "/");
  //     }
  //   }, [history, location.state?.from, pushTo, userState.isAuthenticated]);

  const handleCloseDialog = useCallback(() => {
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { openLoginDialog: false },
    });
  }, [dispatchUser]);

  useEffect(() => {
    if (!!userState.isAuthenticated) handleCloseDialog();
  }, [userState.isAuthenticated, handleCloseDialog]);

  console.log("pushTo from LoginDialog", pushTo);

  return (
    <Dialog
      open={openLoginDialog}
      onClose={handleCloseDialog}
      maxWidth="xs"
      fullWidth
      fullScreen={isMobile}
    >
      <Box
        sx={{
          position: "relative",
          overflowY: isSmallOrMobile ? "auto" : "hidden",
          border: "none",
          p: 1,
        }}
      >
        {isMobile && (
          <CloseIcon
            onClick={handleCloseDialog}
            color="action"
            sx={{ position: "absolute", right: 7, top: 7 }}
          />
        )}
        {/* <DialogContent> */}
        <Login pushTo={pushTo} />
        {/* </DialogContent> */}
      </Box>
    </Dialog>
  );
}
