import React, { useEffect, useRef } from "react";
import "./App.css";
import { useGlobalContext } from "../../../context";
import AdList from "../MainListing/AdList";
import PageNavigation from "../ResultControlPanel/PageNavigation";
import { Container, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

import ResultControlPanel from "../ResultControlPanel/ResultControlPanel";
import { debounce } from "../../../utils/debounce";
import useCurrentWidth from "../../../hooks/useCurrentWidth";
import { grey } from "@mui/material/colors";
import BasicMapComponent from "../MainMap/BasicMapComponent";
import Footer from "../../Layout/Footer/Footer";

export default function AppLayoutLargeScreen() {
  const { dispatch, state } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const initialScrollPosition = state?.previousScrollPosition || 0;
  const { width } = useCurrentWidth();
  const { isMediumOrSmaller } = useCustomMediaQuery();

  const adContainerRef = useRef(null);
  const boxRef = useRef(null);
  const mapContainerRef = useRef(null);
  const leftMenuRef = useRef(null);

  useEffect(() => {
    const menuHeight = leftMenuRef.current.getBoundingClientRect().top;
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { leftMenuRef, mapContainerRef, boxRef, menuHeight },
    });
  }, [dispatch, width, state.menuHeight]);

  // set ad container scroll position upon loading
  useEffect(() => {
    if (!!adContainerRef?.current) {
      adContainerRef.current.scrollTop = initialScrollPosition;
    }
  }, [adContainerRef?.current, isMobile]);

  // save debounced scroll position in state
  const getScrollPosition = debounce((scrollPosition) => {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { previousScrollPosition: scrollPosition },
    });
  });

  return (
    <>
      <Box
        ref={leftMenuRef}
        sx={{ width: "40%", minWidth: isMediumOrSmaller ? "480px" : "600px" }}
      />
      <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <Box
          ref={adContainerRef}
          sx={{
            backgroundColor: grey[300],
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: state.showMap ? "40%" : "100%",
            minWidth: isMediumOrSmaller ? "480px" : "600px",
            overflowY: "scroll",
            position: "relative",
          }}
          onScroll={() => getScrollPosition(adContainerRef.current.scrollTop)}
        >
          <Container
            maxWidth={isMediumOrSmaller ? "md" : "lg"}
            disableGutters
            sx={{ px: 0.5 }}
          >
            <ResultControlPanel />
            <Stack>
              {!!state?.adCardsLoading && (
                <CircularProgress
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              )}
              {!!state.adCardsLoaded && !state?.adCardsLoading && (
                <>
                  <AdList />
                  <PageNavigation />
                </>
              )}
            </Stack>
          </Container>
          {!!state.adCardsLoaded && !state.adCardsLoading && <Footer />}
        </Box>

        {(state.showMap || state.drawPlacesMode) && (
          <Box sx={{ position: "relative", flexGrow: 1 }}>
            <Box ref={boxRef} sx={{ border: "none" }} />
            <Box
              ref={mapContainerRef}
              id="map-box"
              sx={{ position: "relative", height: "100%", width: "100%" }}
            >
              <BasicMapComponent />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
