import { useGlobalContext } from "../../../context";
import { crudSavedSearch } from "../fetchOrSendDashboardData";
import { Form, Formik } from "formik";
import FormikTextField from "../../SharedComponents/FormikTextField";
import * as Yup from "yup";
// import FormPreview from "../../AdForm/utils/FormPreview";

function SearchNameEditor({ search, setEditMode, setSearchName }) {
  const { dashboardState, dispatchDashboard } = useGlobalContext();

  const existingNames = dashboardState.mySearches
    .filter((s) => s !== search)
    .map((s) => s.name.toLowerCase().trim());

  const initialValues = { name: search.name };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Įveskite paieškos pavadinimą")
      .max(100, "Maks. 100 simbolių")
      .transform((value) => value.toLowerCase().trim())
      .notOneOf(existingNames, "Paieška tokiu pavadinimu jau yra"),
  });

  function handleSubmit(formikValues) {
    const savedSearch = { ...formikValues, id: search.id };
    setSearchName(formikValues.name);
    crudSavedSearch(dispatchDashboard, "patch", savedSearch);
    setEditMode(false);
  }

  function handleKeyUp(e, formik) {
    if (e.key === "Enter") {
      formik.submitForm();
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnChnage={true}
      onSubmit={handleSubmit}
      initialTouched={{ searchName: true }}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate style={{ width: "70%" }}>
          <FormikTextField
            name="name"
            // label="Keiskite pavadinimą"
            onBlur={(e) => formik.submitForm()}
            inputProps={{
              maxLength: 100,
              sx: { fontSize: "14px" },
              spellCheck: false,
            }}
            variant="standard"
            autoFocus={true}
            onKeyUp={(e) => handleKeyUp(e, formik)}
          />
          {/* <FormPreview /> */}
        </Form>
      )}
    </Formik>
  );
}

export default SearchNameEditor;
