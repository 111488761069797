import React from "react";
import LocationPanel from "./LocationPanels/LocationPanel";
import BuildingPanel from "./BuildingPanels/BuildingPanel";
import BuildingPanelHouse from "./BuildingPanels/BuildingPanelHouse";
import FlatPanel from "./FlatPanels/FlatPanel";
import EquipmentPanelHouse from "./EquipmentPanels/EquipmentPanelHouse";
import EnvironmentPanel from "./EnvironmentPanels/EnvironmentPanel";
import EnvironmentPanelHouse from "./EnvironmentPanels/EnvironmentPanelHouse";
import DescriptionPanel from "./DescriptionPanels/DescriptionPanel";
import PricePanel from "./PricePanels/PricePanel";
import OwnerDetailsPanel from "./OwnerDetailsPanels/OwnerDetailsPanel";
import PricePanelRent from "./PricePanels/PricePanelRent";
import EnvironmentPanelLand from "./EnvironmentPanels/EnvironmentPanelLand";
import PricePanelRentLand from "./PricePanels/PricePanelRentLand";
import PremisesPanel from "./PremisesPanels/PremisesPanel";
import EnvironmentPanelPremises from "./EnvironmentPanels/EnvironmentPanelPremises";
import GaragePanel from "./GaragePanels/GaragePanel";
import ParkingPanel from "./GaragePanels/ParkingPanel";
import BuildingPanelApartmentComplex from "./BuildingPanels/BuildingPanelApartmentComplex";
import FlatPanelApartmentComplex from "./FlatPanels/FlatPanelApartmentComplex";
import useAdType from "../../IndividualAd/BasicAdInfo/useAdType";

export default function usePanels(props) {
  const { ad } = props;

  console.log(
    "formikRef from usePanels",
    props?.formikRef?.current?.values,
    ad
  );

  const {
    isSale,
    isFlat,
    isHouse,
    isLand,
    isPremises,
    isGarage,
    isParking,
    isApartmentComplex,
  } = useAdType(ad);

  const flatPanels = [
    {
      value: "locationPanel",
      label: "Objekto vieta",
      component: <LocationPanel {...props} />,
    },
    {
      value: "buildingPanel",
      label: "Pastatas",
      component: <BuildingPanel {...props} />,
    },
    {
      value: "flatPanel",
      label: "Butas",
      component: <FlatPanel {...props} />,
    },
    {
      value: "environmentPanel",
      label: "Aplinka",
      component: <EnvironmentPanel {...props} />,
    },
    {
      value: "descriptionPanel",
      label: "Papildomas aprašymas",
      component: <DescriptionPanel {...props} />,
    },
    isSale
      ? {
          value: "pricePanel",
          label: "Kaina ir sąlygos",
          component: <PricePanel {...props} />,
        }
      : {
          value: "pricePanelRent",
          label: "Kaina ir sąlygos",
          component: <PricePanelRent {...props} />,
        },
    {
      value: "ownerDetailsPanel",
      label: "Kontaktiniai duomenys",
      component: <OwnerDetailsPanel {...props} />,
    },
  ];

  const housePanels = [
    {
      value: "locationPanel",
      label: "Objekto vieta",
      component: <LocationPanel {...props} />,
    },
    {
      value: "buildingPanelHouse",
      label: "Pastatas",
      component: <BuildingPanelHouse {...props} />,
    },
    {
      value: "equipmentPanelHouse",
      label: "Vidaus įranga",
      component: <EquipmentPanelHouse {...props} />,
    },
    {
      value: "environmentPanelHouse",
      label: "Sklypas ir aplinka",
      component: <EnvironmentPanelHouse {...props} />,
    },
    {
      value: "descriptionPanel",
      label: "Papildomas aprašymas",
      component: <DescriptionPanel {...props} />,
    },
    isSale
      ? {
          value: "pricePanel",
          label: "Kaina ir sąlygos",
          component: <PricePanel {...props} />,
        }
      : {
          value: "pricePanelRent",
          label: "Kaina ir sąlygos",
          component: <PricePanelRent {...props} />,
        },
    {
      value: "ownerDetailsPanel",
      label: "Kontaktiniai duomenys",
      component: <OwnerDetailsPanel {...props} />,
    },
  ];

  const landPanels = [
    {
      value: "locationPanel",
      label: "Objekto vieta",
      component: <LocationPanel {...props} />,
    },
    {
      value: "environmentPanelLand",
      label: "Sklypas",
      component: <EnvironmentPanelLand {...props} />,
    },
    {
      value: "descriptionPanel",
      label: "Papildomas aprašymas",
      component: <DescriptionPanel {...props} />,
    },
    isSale
      ? {
          value: "pricePanel",
          label: "Kaina ir sąlygos",
          component: <PricePanel {...props} />,
        }
      : {
          value: "pricePanelRentLand",
          label: "Kaina ir sąlygos",
          component: <PricePanelRentLand {...props} />,
        },
    {
      value: "ownerDetailsPanel",
      label: "Kontaktiniai duomenys",
      component: <OwnerDetailsPanel {...props} />,
    },
  ];

  const premisesPanels = [
    {
      value: "locationPanel",
      label: "Objekto vieta",
      component: <LocationPanel {...props} />,
    },
    {
      value: "buildingPanel",
      label: "Pastatas",
      component: <BuildingPanel {...props} />,
    },
    {
      value: "premisesPanel",
      label: "Patalpos",
      component: <PremisesPanel {...props} />,
    },
    {
      value: "environmentPanelPremises",
      label: "Aplinka",
      component: <EnvironmentPanelPremises {...props} />,
    },
    {
      value: "descriptionPanel",
      label: "Papildomas aprašymas",
      component: <DescriptionPanel {...props} />,
    },
    {
      value: "ownerDetailsPanel",
      label: "Kontaktiniai duomenys",
      component: <OwnerDetailsPanel {...props} />,
    },
  ];

  const garagePanels = [
    {
      value: "locationPanel",
      label: "Objekto vieta",
      component: <LocationPanel {...props} />,
    },
    {
      value: "garagePanel",
      label: "Garažas",
      component: <GaragePanel {...props} />,
    },
    {
      value: "descriptionPanel",
      label: "Papildomas aprašymas",
      component: <DescriptionPanel {...props} />,
    },
    {
      value: "ownerDetailsPanel",
      label: "Kontaktiniai duomenys",
      component: <OwnerDetailsPanel {...props} />,
    },
  ];

  const parkingPanels = [
    {
      value: "locationPanel",
      label: "Objekto vieta",
      component: <LocationPanel {...props} />,
    },
    {
      value: "parkingPanel",
      label: "Parkavimo vieta",
      component: <ParkingPanel {...props} />,
    },
    {
      value: "descriptionPanel",
      label: "Papildomas aprašymas",
      component: <DescriptionPanel {...props} />,
    },
    {
      value: "ownerDetailsPanel",
      label: "kontaktiniai duomenys",
      component: <OwnerDetailsPanel {...props} />,
    },
  ];

  const apartmentComplexPanels = [
    {
      value: "locationPanel",
      label: "Objekto vieta",
      component: <LocationPanel {...props} />,
    },
    {
      value: "buildingPanelApartmentComplex",
      label: "Pastatas",
      component: <BuildingPanelApartmentComplex {...props} />,
    },
    {
      value: "flatPanelApartmentComplex",
      label: "Butai ir kitos patalpos",
      component: <FlatPanelApartmentComplex {...props} />,
    },
    {
      value: "descriptionPanel",
      label: "Papildomas aprašymas",
      component: <DescriptionPanel {...props} />,
    },
    {
      value: "ownerDetailsPanel",
      label: "Kontaktiniai duomenys",
      component: <OwnerDetailsPanel {...props} />,
    },
  ];

  let panels;
  if (isFlat) panels = flatPanels;
  if (isHouse) panels = housePanels;
  if (isLand) panels = landPanels;
  if (isPremises) panels = premisesPanels;
  if (isGarage) panels = garagePanels;
  if (isParking) panels = parkingPanels;
  if (isApartmentComplex) panels = apartmentComplexPanels;

  console.log("render usePanels");

  return { panels };
}
