import React, { useEffect, useState } from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import { crudClientInfo } from "../fetchOrSendAccountsData";
import { useGlobalContext } from "../../../context";
import ClientInfoForm from "../../AdForm/Step3AdSettings/ClientInfoForm";
import { Form, Formik } from "formik";
import * as Yup from "yup";
// import FormPreview from "../../AdForm/utils/FormPreview";

const validationSchema = Yup.object().shape({
  client_type: Yup.string().required("Privaloma pasirinkti").nullable(),
  client_name: Yup.string()
    .when("client_type", {
      is: "enterprise",
      then: Yup.string().required("Įveskite įmonės pavadinimą"),
    })
    .when("client_type", {
      is: "private",
      then: Yup.string().required("Privalomas laukas"),
    }),
  client_address: Yup.string()
    .when("client_type", {
      is: "enterprise",
      then: Yup.string().required("Įveskite įmonės adresą"),
    })
    .when("client_type", {
      is: "private",
      then: Yup.string().required("Įveskite kliento adresą"),
    }),
  client_phone: Yup.string(),
  client_email: Yup.string().email("Netinkamas el. paštas"),
  company_code: Yup.string().when("client_type", {
    is: "enterprise",
    then: Yup.string().required("Įveskite įmonės kodą"),
  }),
  vat_code: Yup.string(),
});

function ClientCard() {
  const { userState, dispatchUser } = useGlobalContext();
  const userId = userState.userId;
  const [clientInfo, setClientInfo] = useState(userState.clientInfo);

  const initialValues = {
    client_type: clientInfo?.client_type || null,
    client_name: clientInfo?.client_name || "",
    client_address: clientInfo?.client_address || "",
    company_code: clientInfo?.company_code || "",
    vat_code: clientInfo?.vat_code || "",
    client_phone: clientInfo?.client_phone || "",
    client_email: clientInfo?.client_email || "",
  };

  useEffect(() => {
    if (!userState.clientInfoLoaded && !userState.clientInfoLoading)
      crudClientInfo(dispatchUser, userId, "GET");
  }, [userState.clientInfoLoaded]);

  async function handleSubmit(values, { resetForm }) {
    await crudClientInfo(dispatchUser, userId, "PATCH", values);
    setClientInfo(values);
    resetForm({ values });
  }

  return (
    <>
      {!!userState.clientInfoLoaded &&
        !!userState.clientInfo &&
        userState?.clientInfo?.client_type !== "not_known" && (
          <Grid item xs={12} sx={{ px: 0.5, mt: 1.5 }}>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={true}
            >
              {(formik) => (
                <Form>
                  <Paper
                    sx={{
                      width: "100%",
                      p: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize={18}
                      sx={{ textAlign: "center", mb: -4 }}
                    >
                      Kliento duomenų ruošinys (sąskaitoms-faktūroms)
                    </Typography>
                    <ClientInfoForm formik={formik} />
                    <Grid
                      container
                      xs={12}
                      sx={{
                        border: "none",
                        display: "flex",
                        justifyContent: "right",
                        py: 0.5,
                        // mb: 1,
                        visibility: formik.dirty ? "visible" : "hidden",
                      }}
                    >
                      <Button
                        variant="outlined"
                        size="small"
                        sx={{ mr: 1 }}
                        onClick={(e) => {
                          formik.resetForm();
                        }}
                      >
                        Atšaukti
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        disabled={!!Object.keys(formik.errors).length}
                      >
                        Išsaugoti
                      </Button>
                    </Grid>
                  </Paper>
                  {/* <FormPreview /> */}
                </Form>
              )}
            </Formik>
          </Grid>
        )}
    </>
  );
}

export default ClientCard;
