import React from "react";
import Container from "@mui/material/Container";
import { Box, Paper } from "@mui/material";
import { grey } from "@mui/material/colors";
import Login from "./Login";
import Footer from "../Layout/Footer/Footer";

export default function LoginPage() {
  return (
    <Box
      sx={{
        backgroundColor: grey[300],
        border: "none",
        // pt: !!userState.openLoginDialog ? 2 : 13,
        pt: 13,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          border: "none",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
        }}
        disableGutters
      >
        <Paper
          sx={{ width: "100%", height: "100%", p: 1, mb: 7 }}
          // elevation={userState.openLoginDialog ? 0 : 1}
          elevation={1}
        >
          <Login />
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}
