import React from "react";
import Grid from "@mui/material/Grid";
import {
  getPlotArea,
  getPlotPurpose,
  getInfrastructure,
  getWaterBody,
  getForestProp,
} from "../../../IndividualAd/BasicAdInfo/utils";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";

const AdCardInfoLand = ({ ad }) => {
  const { isLand, isForest, isLoggingForest } = useAdType(ad);

  const plotArea = getPlotArea(ad, 6, 6, isLand);
  const plotPurpose = getPlotPurpose(ad, 6, 6);
  const infrastructure = getInfrastructure(ad, 6, 6);
  const waterBody = getWaterBody(ad, 6, 6);
  const forestProp = getForestProp(ad, 6, 6, isLoggingForest);

  const forestHasProps = !!(
    ad.properties.forest_type ||
    ad.properties.forest_mature ||
    !!isLoggingForest
  );

  console.log("infrastructure from AdCardInfoLand", infrastructure);

  return (
    <Grid
      container
      sx={{ flexGrow: 1, display: "flex", alignContent: "space-around" }}
    >
      {plotArea}
      {plotPurpose}
      {!isForest && infrastructure}
      {isForest && forestHasProps && forestProp}
      {waterBody}
    </Grid>
  );
};

export default AdCardInfoLand;
