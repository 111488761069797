import { useEffect } from "react";
import { useMap } from "react-leaflet";

export default function DisableMapDrag({ setIsMapActive }) {
  const map = useMap();

  useEffect(() => {
    map.dragging.disable();
    map.scrollWheelZoom.disable();
    map.doubleClickZoom.disable();
    map.touchZoom.disable();
  }, []);

  useEffect(() => {
    // Add mouse down event listener to the map container when the component mounts
    const handleClick = () => {
      // Enable dragging when the mouse button is pressed down
      map.dragging.enable();
      map.scrollWheelZoom.enable();
      map.doubleClickZoom.enable();
      map.touchZoom.enable();
      setIsMapActive(true);
    };

    // Add mouse out event listener to the map container when the component mounts
    const handleMouseOut = (e) => {
      // Check if the new target is a child of the map container
      if (map.getContainer().contains(e.relatedTarget)) {
        return;
      }

      // Disable dragging when the mouse moves off the map
      map.dragging.disable();
      map.scrollWheelZoom.disable();
      map.doubleClickZoom.disable();
      map.touchZoom.disable();
      setIsMapActive(false);
    };

    const mapContainer = map.getContainer();
    mapContainer.addEventListener("click", handleClick);
    mapContainer.addEventListener("mouseout", handleMouseOut);

    // Remove the event listeners from the map container when the component unmounts
    return () => {
      mapContainer.removeEventListener("click", handleClick);
      mapContainer.removeEventListener("mouseout", handleMouseOut);
    };
  }, [map]);

  return null; // Component must return something
}
