import React, { useState } from "react";
import { Card, CardMedia, Collapse, Grid } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { Box } from "@mui/system";
import MyAdInfo from "./MyAdInfo";
import { defaultPhotoUrl } from "../../../../utils/defaults";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import AdStatusSelector from "./AdStatusSelector";
import MyAdsChatDrawer from "../MyAdChat/MyAdsChatDrawer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getFullImgUrl } from "../../../../utils/getFullImgUrl";
import EditPhotosButton from "../PhotoEditor/EditPhotosButton";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../../../utils/defaults";

const MyAd = ({ ad }) => {
  const { routerState } = useGlobalContext();
  const [infoCollapseComplete, setInfoCollapseComplete] = useState(true);
  const [chatCollapseComplete, setChatCollapseComplete] = useState(true);

  const { isMobile } = useCustomMediaQuery();
  const expandedAd = Number(routerState?.myAdsAd) === Number(ad?.id);

  console.log("render MyAd component");

  return (
    <>
      <Grid item xs={12} justifyContent="center" sx={{ border: "none" }}>
        <Card sx={{ mt: 2 }}>
          <Grid container sx={{ border: "none" }}>
            <Box
              display="block"
              sx={{
                width: isMobile ? "100%" : "40%",
                position: "relative",
              }}
            >
              <Link to={`/ad/${ad.id}`} sx={{ textDecoration: "none" }}>
                <CardMedia
                  sx={{
                    width: "100%",
                    height: "100%",
                    aspectRatio: String(ASPECT),
                    objectFit: "cover",
                  }}
                  image={
                    getFullImgUrl(ad.properties.photos[0]?.photo_thumbnail) ||
                    defaultPhotoUrl
                  }
                />
              </Link>
              <AdStatusSelector ad={ad} expandedAd={expandedAd} />
              <EditPhotosButton ad={ad} expandedAd={expandedAd} />
            </Box>

            <Box sx={{ width: isMobile ? "100%" : "60%", border: "none" }}>
              {!isMobile && <MyAdInfo ad={ad} />}
              {isMobile && (
                <Collapse
                  in={!expandedAd && chatCollapseComplete}
                  onExited={() => {
                    setInfoCollapseComplete(true);
                    setChatCollapseComplete(false);
                  }}
                >
                  <MyAdInfo ad={ad} />
                </Collapse>
              )}
            </Box>

            <Grid item xs={12} style={{ border: "none" }}>
              <Collapse
                in={expandedAd && (infoCollapseComplete || !isMobile)}
                onExited={() => {
                  setChatCollapseComplete(true);
                  setInfoCollapseComplete(false);
                }}
              >
                <Box sx={{ position: "relative" }}>
                  {expandedAd && <MyAdsChatDrawer />}
                </Box>
              </Collapse>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </>
  );
};

export default MyAd;
