import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useAdType from "./useAdType";
import {
  getAddress,
  getArea,
  getPrice,
  getPriceSqm,
  getSize,
  getType,
} from "./utils";
import MapButton from "./MapButton";

function BasicAdInfoParking({ ad, setShowMap, showMap }) {
  const { isRent, isGarage, isParking } = useAdType(ad);

  const price = getPrice(ad, isRent, isParking || isGarage);
  const priceSqm = getPriceSqm(ad);
  const address = getAddress(ad);
  const area = getArea(ad, 6, 4);
  const size = getSize(ad, 6, 4);
  const type = getType(ad, 12, 4);

  return (
    <>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          justifyContent: "space-between",
          // display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ mt: 1, mx: 1 }}
            color="primary"
            variant="body2"
            fontSize={20}
          >
            {price}
          </Typography>
          <Typography sx={{ mt: 1, pt: 0.3 }} variant="body2">
            ({priceSqm} €/m²)
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          alignItems: "center",
          px: 1,
          mt: 0.2,
        }}
      >
        <Typography variant="caption">{address} </Typography>
        <MapButton setShowMap={setShowMap} showMap={showMap} />
      </Grid>

      <Grid container sx={{ my: 1, pl: 1, border: "none" }}>
        {area}
        {size}
        {type}
      </Grid>
    </>
  );
}

export default BasicAdInfoParking;
