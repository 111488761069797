import React, { useState, useEffect } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../context";
import { crudAdDraft } from "./fetchOrSendAdDraftData";
import { AD_TYPE_CHOICES } from "../../utils/adOptions";
import { blue } from "@mui/material/colors";
// import AdFormAppBar from "./AdFormAppBar";

function AdType() {
  const { adFormState, dispatchAdForm, userState } = useGlobalContext();
  // const [isLoading, setIsLoading] = useState(false);
  const [radioValue, setRadioValue] = useState("sale");
  const [adTypeValue, setAdTypeValue] = useState(null);

  // need to make sure that adFormState is empty when ad draft is deleted
  // (as FormikDispatcher component is trying to preserve formik of the current panel to state)
  useEffect(() => {
    if (!!Object.keys(adFormState.formik)?.length) {
      dispatchAdForm({
        type: "DELETE_AD_DRAFT",
      });
    }
  }, [dispatchAdForm, adFormState.formik]);

  function handleClick(adTypeValue) {
    setAdTypeValue(adTypeValue);
    let form = {
      owner: userState.userId,
      ad_type: adTypeValue,
      status: "draft",
    };
    // setIsLoading(true);
    // createAdDraft(dispatchAdForm, form, setIsLoading);
    crudAdDraft(dispatchAdForm, "POST", form);
  }

  console.log("adFormState", adFormState);
  console.log("radioValue", radioValue);

  return (
    <>
      <Backdrop
        open={adFormState.adDraftLoading}
        // invisible
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.07)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      {/* <AdFormAppBar /> */}
      {/* <AppBar
        position="fixed"
        // color="default"
        elevation={0}
        sx={{
          backgroundColor: "rgba(245, 245, 245, 0.7)",
          backdropFilter: "blur(10px)",
          mt: 7,
          height: "40px",
        }}
      /> */}

      <Container
        disableGutters
        maxWidth="sm"
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: "none",
        }}
      >
        <Stack sx={{ border: "none", width: "100%" }}>
          <Paper sx={{ p: 0.5, mt: 2, backgroundColor: blue[100] }}>
            <Grid
              container
              sx={{
                // my: 3,
                display: "flex",
                justifyContent: "center",
                border: "none",
              }}
            >
              <Typography variant="h6">Pasirinkite skelbimo tipą</Typography>
            </Grid>
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={radioValue}
                onChange={(e) => setRadioValue(e.target.value)}
                name="radio-buttons-group"
                row
              >
                <FormControlLabel
                  value="sale"
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Pardavimas</Typography>}
                />
                <FormControlLabel
                  value="rent"
                  control={<Radio size="small" />}
                  label={<Typography variant="body2">Nuoma</Typography>}
                />
              </RadioGroup>
            </Grid>
          </Paper>
          <Paper sx={{ p: 0.5, mt: 2 }}>
            {/* <Grid
            container
            // spacing={2}
            sx={{ display: "flex", justifyContent: "center", border: "groove" }}
          > */}
            {AD_TYPE_CHOICES.map(
              (adType, index) =>
                (adType.value.includes(radioValue) ||
                  (adType.value === "apartment_complex" &&
                    radioValue === "sale")) && (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sx={{
                      border: "none",
                      display: "flex",
                      justifyContent: "center",
                      my: 2,
                      px: 0.5,
                    }}
                  >
                    <Button
                      onClick={() => handleClick(adType.value)}
                      variant="text"
                      // fullWidth
                      sx={{
                        // width: "100%",
                        color:
                          adType.value === adTypeValue
                            ? "primary"
                            : "text.secondary",
                        // borderColor:
                        //   adType.value === adTypeValue
                        //     ? "primary"
                        //     : "text.secondary",
                        // width: isMobile ? "150px" : "250px",
                      }}
                    >
                      {adType.label}
                    </Button>
                  </Grid>
                )
            )}
            {/* </Grid> */}
          </Paper>
        </Stack>
      </Container>
    </>
  );
}

export default AdType;
