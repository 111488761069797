import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import {
  MIN_RENT_PERIOD_CHOICES,
  PARKING_INSTALLATION_CHOICES,
  PARKING_TYPE_CHOICES,
  SECURITY_CHOICES_GARAGE,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function ParkingPanel({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "parkingPanel";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getParkingInitialValues(ad, adOptions);
  const validationSchema = schema.garageValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  function handleGarageTypeChange(value, formik) {
    if (value !== "multi_storey_parking") {
      formik.setFieldValue("garage_floor", null);
      formik.setFieldTouched("garage_floor", false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="area"
                label="Plotas (kv. m) *"
                type="number"
              />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="garage_size"
                label="Kiek telpa automobilių *"
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="garage_installation"
                label="Parkavimo vietos įrengimas"
                options={PARKING_INSTALLATION_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="security"
                label="Saugumas"
                options={SECURITY_CHOICES_GARAGE}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikSelect
                name="garage_type"
                label="Parkavimo vietos tipas *"
                options={PARKING_TYPE_CHOICES}
                onChange={(value) => handleGarageTypeChange(value, formik)}
              />
            </Grid>
            {formik.values.garage_type === "multi_storey_parking" && (
              <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                <FormikTextField
                  name="garage_floor"
                  label="Aukštas *"
                  type="number"
                />
              </Grid>
            )}
            {formik.values.ad_type === "parking_rent" && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikSelect
                  name="min_rent_period"
                  label="Min. nuomos terminas *"
                  options={MIN_RENT_PERIOD_CHOICES}
                />
              </Grid>
            )}
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="price"
                label={`Kaina (Eur${
                  ["garage_rent", "parking_rent"].includes(
                    formik.values.ad_type
                  )
                    ? "/mėn."
                    : ""
                })*`}
                type="number"
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
