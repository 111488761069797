import axiosInstance from "../../utils/axios";
import { crudSavedSearch } from "../Dashboard/fetchOrSendDashboardData";
import axios from "axios";

const baseURL =
  process.env.REACT_APP_API_BASE_URL?.replace("http://", "https://") ||
  "http://localhost:8000/";

export function registerUser(form) {
  return axiosInstance
    .post("api/auth/users/", form)
    .then((res) => {
      console.log("server resonse: user registration", res.data);
      console.log(res.data);
    })
    .catch((err) => {
      console.log("server response: user registration failed", err.response);
      return Promise.reject(err);
    });
}

export function loginUser(form, dispatch, dispatchUser, dispatchDashboard) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { userLoggingIn: true },
  });
  return (
    axiosInstance
      .post(`api/auth/jwt/create/`, form)
      .then((res) => {
        if (res.status === 200) {
          // alert("access token successfully refreshed");
          console.log("server resonse: user authentication", res.data);
          const rememberMe = form.remember;
          if (rememberMe) {
            localStorage.setItem("access_token", res.data.access);
            localStorage.setItem("refresh_token", res.data.refresh);
          } else {
            sessionStorage.setItem("access_token", res.data.access);
            sessionStorage.setItem("refresh_token", res.data.refresh);
          }
          axiosInstance.defaults.headers["Authorization"] =
            "JWT " +
            (rememberMe
              ? localStorage.getItem("access_token")
              : sessionStorage.getItem("access_token"));
          dispatchUser({
            type: "CHANGE_USER_STATE",
            payload: { tokenRefreshed: true },
          });
          dispatch({
            type: "CHANGE_APP_STATE",
            payload: { adsLoaded: false, adCardsLoaded: false, ad: null },
          });
        }
      })
      .then(() => {
        return fetchUserId(dispatchUser);
      })
      // .then((userId) => {
      //   fetchUserData(userId, dispatchUser);
      //   getContactAvatarData(dispatchUser);
      //   crudSavedSearch(dispatchDashboard, "get");
      // })
      .catch((err) => {
        console.log(
          "server response: user authentication failed on login",
          err.response
        );
        dispatchUser({
          type: "CHANGE_USER_STATE",
          payload: { userLoggingIn: false },
        });
        return Promise.reject(err);
      })
  );
}

export function activateAccount(form) {
  return axiosInstance
    .post("api/auth/users/activation/", form)
    .then((res) => {
      console.log(
        "Server response: user account successfully activated",
        res.data
      );
      return res.data;
    })
    .catch((err) => {
      console.log(
        "Server response: user account activation failed",
        err.response
      );
      return Promise.reject(err);
    });
}

export function resetPassword(form) {
  return axiosInstance
    .post("api/auth/users/reset_password/", form)
    .then((res) => {
      console.log(
        "Server response: password reset link successfully sent",
        res.data
      );
      return res.data;
    })
    .catch((err) => {
      console.log(
        "Server response: failed to send password reset link",
        err.response
      );
      return Promise.reject(err);
    });
}

export function submitNewPassword(form) {
  return axiosInstance
    .post("api/auth/users/reset_password_confirm/", form)
    .then((res) => {
      console.log("Server response: password successufly reset", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("Server response: failed to reset password", err.response);
      return Promise.reject(err);
    });
}

export function refreshAccessToken() {
  const refreshTokenfromLocalStorage = localStorage.getItem("refresh_token");
  const refreshTokenfromSessionStorage =
    sessionStorage.getItem("refresh_token");
  const refreshToken =
    refreshTokenfromLocalStorage || refreshTokenfromSessionStorage;

  if (!refreshToken) {
    console.log("No refresh token found");
    return Promise.reject("No refresh token found");
  }

  // Create a new Axios instance without the Authorization header
  const axiosForRefresh = axios.create({
    baseURL,
    timeout: 20000,
    headers: {
      "Content-Type": "application/json",
      accept: "application/json",
    },
  });

  return axiosForRefresh
    .post(`api/auth/jwt/refresh/`, { refresh: refreshToken })
    .then((res) => {
      console.log("server response: refresh access token", res.data);
      if (res.status === 200) {
        let newAccessToken = res.data.access;
        let newRefreshToken = res.data.refresh;
        if (refreshTokenfromLocalStorage) {
          localStorage.setItem("access_token", newAccessToken);
          localStorage.setItem("refresh_token", newRefreshToken);
        } else {
          sessionStorage.setItem("access_token", newAccessToken);
          sessionStorage.setItem("refresh_token", newRefreshToken);
        }
        return newAccessToken;
      }
    })
    .catch((err) => {
      console.log("Server response: failed to refresh access token", err);
      // Remove the access token from storage
      if (refreshTokenfromLocalStorage) {
        localStorage.removeItem("access_token");
      } else {
        sessionStorage.removeItem("access_token");
      }
      return Promise.reject(err);
    });
}

export function fetchUserId(dispatchUser) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { userLoggingIn: true },
  });
  return axiosInstance
    .get(`api/auth/users/me/`)
    .then((res) => {
      if (res.status === 200) {
        // alert("user id successfully fetched");
        console.log("server resonse: get user id", res.data);
        dispatchUser({
          type: "CHANGE_USER_STATE",
          payload: { isAuthenticated: true, userId: res.data.id },
        });

        return res.data.id;
      }
    })
    .catch((err) => {
      console.log(
        "server response: failed to fetch user id",
        err,
        err.response
      );
      // return Promise.reject(err);
    })
    .finally(() => {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { userLoggingIn: false },
      });
    });
}

export function fetchUserData(userId, dispatchUser) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { userDataLoading: true },
  });
  return axiosInstance
    .get(`api/user/${userId}/`)
    .then((res) => {
      // alert("user data successfully fetched");
      console.log("server response: user data successfully fetched", res);
      localStorage.setItem("login_at", new Date().getTime());
      sessionStorage.setItem("authenticated", "true");
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { currentUser: res.data, userDataLoaded: true },
      });
    })
    .catch((err) => {
      console.log(
        "server response: failed to fetch user data",
        err,
        err?.response?.data?.detail
      );
      return Promise.reject(err);
    })
    .finally(() => {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { userDataLoading: false },
      });
    });
}

export function updateUserData(userId, dispatchUser, formData, setIsUpdated) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { photoLoading: true },
  });
  console.log(
    "formData from fetchOrSendAccountsData",
    formData.get("photo_crop")
  );
  const accessToken =
    localStorage.getItem("access_token") ||
    sessionStorage.getItem("access_token");
  axiosInstance
    .patch(`api/user/${userId}/`, formData, {
      headers: {
        Authorization: accessToken ? "JWT " + accessToken : null,
        "Content-Type":
          !!formData.get("photo") ||
          !!formData.get("photo_crop") ||
          !!formData.get("delete_photo") ||
          !!formData.get("logo_thumbnail")
            ? "multipart/form-data"
            : "application/json",
      },
    })
    .then((res) => {
      console.log("server response: updating user data", res);
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { currentUser: res.data, photoLoaded: true },
      });
    })
    .catch((err) => {
      console.log("server response: error while updating user data", err);
    })
    .finally(() => {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { photoLoading: false },
      });
    });
}

export function checkVisitor(dispatchUser) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { vistorLoading: true },
  });
  axiosInstance
    .get("api/user/check-visitor/")
    .then((res) => {
      console.log("server response: visitor id", res);
      let visitor_id = res.data.visitor_id;
      let cookieConsentGiven = res.data.cookie_consent_given;
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { visitor_id, cookieConsentGiven, visitorLoaded: true },
      });
    })
    .catch(function (error) {
      console.log("server response: failed identify visitor", error);
    })
    .finally(() => {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { vistorLoading: false },
      });
    });
}

export function getContactAvatarData(dispatchUser) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { contactAvatarsLoading: true },
  });
  return axiosInstance
    .get(`api/user/contact-avatars/`)
    .then((res) => {
      // alert("avatar array successfully fetched");
      console.log("server response: avatar array", res.data);

      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { contactAvatars: res.data, contactAvatarsLoaded: true },
      });
    })
    .catch((err) => {
      console.log("Error getting avatar array from server", err);
      return Promise.reject(err);
    })
    .finally(() => {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { contactAvatarsLoading: false },
      });
    });
}

export function crudClientInfo(dispatchUser, userId, method, values) {
  const url = `api/orders/client-info/${userId}/`;
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { clientInfoLoading: true },
  });
  return axiosInstance({ method, url, data: values })
    .then((res) => {
      console.log(
        "Server response: client info successfully fetched",
        res.data
      );
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { clientInfo: res.data, clientInfoLoaded: true },
      });
    })
    .catch((err) => {
      console.log("Server response: failed to load client data", err);
      // return Promise.reject(err);
    })
    .finally(() => {
      // need to clientInfoLoaded to true to avoid infinite loop in HeaderDataProvider (as no client info gives 404 error and gets caught in the error boundary)
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { clientInfoLoading: false, clientInfoLoaded: true },
      });
    });
}

export function listAdComplaints(dispatchUser, params) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { adComplaintsLoading: true },
  });
  const url = "api/complaints/ad/list/";
  axiosInstance
    .get(url, { params })
    .then((res) => {
      console.log(
        "server response: successfully fetched ad complaints",
        res.data
      );

      let adComplaints = res.data.map((complaint) => complaint.ad_id);
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { adComplaints, adComplaintsLoaded: true },
      });
    })
    .catch(function (error) {
      console.log("server response: failed to fetch ad complaints", error);
    })
    .finally(() => {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { adComplaintsLoading: false },
      });
    });
}

export function createAdComplaint(dispatchUser, data) {
  const url = "api/complaints/ad/create/";
  axiosInstance
    .post(url, data)
    .then((res) => {
      console.log(
        "server response: successfully created ad complaint",
        res.data
      );
      // this will trigger reload of ad complaints
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { adComplaintsLoaded: false },
      });
    })
    .catch(function (error) {
      console.log("server response: failed to create ad complaint", error);
    });
}

export function listEnquirerComplaints(dispatchUser) {
  dispatchUser({
    type: "CHANGE_USER_STATE",
    payload: { enquirerComplaintsLoading: true },
  });
  const url = "api/complaints/enquirer/list/";
  axiosInstance
    .get(url)
    .then((res) => {
      console.log(
        "server response: successfully fetched enquirer complaints",
        res.data
      );
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: {
          enquirerComplaints: res.data,
          enquirerComplaintsLoaded: true,
        },
      });
    })
    .catch(function (error) {
      console.log(
        "server response: failed to fetch enquirer complaints",
        error
      );
    })
    .finally(() => {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { enquirerComplaintsLoading: false },
      });
    });
}

export function createEnquirerComplaint(dispatchUser, data) {
  const url = "api/complaints/enquirer/create/";
  axiosInstance
    .post(url, data)
    .then((res) => {
      console.log(
        "server response: successfully created enquirer complaint",
        res.data
      );
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { enquirerComplaintsLoaded: false },
      });
    })
    .catch(function (error) {
      console.log(
        "server response: failed to create enquirer complaint",
        error
      );
    });
}

export async function deleteUserAccount() {
  const url = `api/user/delete/`;
  try {
    const res = await axiosInstance.delete(url);
    console.log("server response: user account successfully deleted", res);
    return res.data;
  } catch (err) {
    console.log("server response: failed to delete user account", err);
    throw err; // Rethrow the error to be handled by the calling function
  }
}

export function loginWithGoogle(googleToken, dispatchUser) {
  const url = "api/user/login-with-google/";
  axios
    .post(baseURL + url, { googleToken })
    .then((res) => {
      console.log("server response: google login", res.data);
      const userId = res.data.user_id;
      const userCreated = res.data.user_created;
      localStorage.setItem("access_token", res.data.access);
      localStorage.setItem("refresh_token", res.data.refresh);

      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: {
          isAuthenticated: true,
          userId,
          tokenRefreshed: true,
          userCreated,
        },
      });
      return res.data;
    })
    .catch((err) => {
      console.log("server response: google login failed", err.response);
    });
}

export function loginWithFacebook(facebookToken, dispatchUser) {
  const url = "api/user/login-with-facebook/";
  console.log("baseUrl", baseURL);
  axios
    .post(baseURL + url, { facebookToken })
    .then((res) => {
      console.log("server response: facebook login", res.data);
      const userId = res.data.user_id;
      localStorage.setItem("access_token", res.data.access);
      localStorage.setItem("refresh_token", res.data.refresh);

      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { isAuthenticated: true, userId, tokenRefreshed: true },
      });
      return res.data;
    })
    .catch((err) => {
      console.log("server response: facebook login failed", err.response);
    });
}
