import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import React, { useEffect } from "react";

import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import { useGlobalContext } from "../../../../context";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import {
  createEnquirerComplaint,
  listEnquirerComplaints,
} from "../../../Accounts/fetchOrSendAccountsData";
// import FormPreview from "../../../AdForm/utils/FormPreview";

export default function ReportEnquirerDialog({ open, setOpen, enquiry }) {
  const { userState, dispatchUser } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();

  const initialValues = {
    spam: false,
    bot: false,
    impolite: false,
    credibility: false,
    fraud: false,
    other: false,
    additional_info: "",
  };

  useEffect(() => {
    if (
      !userState.enquirerComplaintsLoaded &&
      !userState.enquirerComplaintsLoading
    ) {
      listEnquirerComplaints(dispatchUser);
    }
  }, [userState.enquirerComplaintsLoaded]);

  function handleSubmit(values) {
    values = { ...values, ad: enquiry.ad, enquirer: enquiry.enquirer };
    createEnquirerComplaint(dispatchUser, values);
    setOpen(false);
  }

  return (
    // <Form>
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullScreen={isMobile}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        Konfidencialus pranešimas apie netinkamą vartotojo elgesį
      </DialogTitle>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <>
            <DialogContent>
              <Typography variant="body1">
                Vartotojo elgesys galimai netinkamas dėl šių priežasčių:
              </Typography>
              <Grid container sx={{ mt: 1 }}>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="spam"
                    label="Nepageidaujama reklama"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="bot"
                    label="Botų veikla"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="impolite"
                    label="Nepagarbus elgesys"
                    labelPlacement="end"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="credibility"
                    label="Susitarimų nevykdymas"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="fraud"
                    label="Galimas sukčiavimas"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormikCheckbox
                    name="other"
                    label="Kita"
                    labelPlacement="end"
                  />
                </Grid>
                <FormikTextField
                  name="additional_info"
                  label="Papildomas komentaras"
                  multiline
                  rows={4}
                  sx={{ mt: 2 }}
                />
                <Typography variant="caption" sx={{ mt: 1 }}>
                  Peržiūrėjus Jūsų pranešimą ir nustačius, kad vartotojo veikla
                  pažeidžia portalo naudojimosi taisykles, vartotojo galimybės
                  rašyti žinutes gali būti apribotos. Dėkojame už pagalbą.
                </Typography>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Atšaukti
              </Button>
              <Button
                // type="submit"
                variant="contained"
                onClick={() => handleSubmit(formik.values)}
                disabled={Object.values(formik.values).every((value) => !value)}
              >
                Pranešti
              </Button>
            </DialogActions>
            {/* <FormPreview /> */}
          </>
        )}
      </Formik>
    </Dialog>
    // </Form>
  );
}
