import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import PhotoIcon from "@mui/icons-material/Photo";
import EditPhotosDialog from "./EditPhotosDialog";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import { alpha } from "@mui/system";

const EditPhotosButton = ({ ad, expandedAd }) => {
  const [openEditPhotosDialog, setOpenEditPhotosDialog] = useState(false);
  const { isMobile } = useCustomMediaQuery();

  return (
    <>
      <Button
        size="small"
        variant="text"
        startIcon={<PhotoIcon color="action" />}
        sx={{
          m: 0.5,
          position: "absolute",
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: alpha("#fff", 0.8),
          },
          right: 0,
          top: 0,
          visibility: expandedAd && isMobile ? "hidden" : "visible",
        }}
        onClick={() => {
          setOpenEditPhotosDialog(true);
        }}
      >
        <Typography variant="caption" color="InfoText">
          Tvarkyti
        </Typography>
      </Button>

      {openEditPhotosDialog && (
        <EditPhotosDialog
          open={openEditPhotosDialog}
          setOpen={setOpenEditPhotosDialog}
          ad={ad}
        />
      )}
    </>
  );
};

export default EditPhotosButton;
