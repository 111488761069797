const storedAdFormState = sessionStorage.getItem("adFormState");

export const initialAdFormState = storedAdFormState
  ? JSON.parse(storedAdFormState)
  : {
      adDraft: null,
      adDraftLoaded: false,
      municipalityOptions: [],
      townOptions: [],
      suburbOptions: [],
      streetOptions: [],
      formik: {},
      locationLoaded: false,
    };

export function adFormReducer(adFormState, action) {
  let payload, filteredPhotos, adDraft;
  switch (action.type) {
    case "CHANGE_AD_FORM_STATE":
      payload = action.payload;
      adFormState = { ...adFormState, ...payload };
      // alert("activeStep: " + adFormState.activeStep);
      // console.log("AdFormState from CHANGE_AD_FORM_STATE", adFormState);
      return adFormState;

    case "CHANGE_FORMIK":
      payload = action.payload;
      let formik = adFormState.formik;
      formik = { ...formik, ...payload };
      adFormState = { ...adFormState, formik };
      return adFormState;

    case "CRUD_AD_DRAFT":
      let adDraftData = action.payload.adDraftData;
      let method = action.payload.method;
      if (method === "GET") {
        adDraftData = adDraftData?.features[0] || null;
      }
      adFormState = {
        ...adFormState,
        adDraft: adDraftData,
        adDraftLoaded: true,
      };
      return adFormState;

    case "DELETE_AD_DRAFT":
      adFormState = { ...initialAdFormState, adDraftLoaded: true };
      return adFormState;

    case "CHANGE_AD_PROPERTIES":
      let propertiesToUpdate = action.payload;
      adDraft = adFormState.adDraft;
      adDraft.properties = { ...adDraft.properties, ...propertiesToUpdate };
      adFormState = { ...adFormState, adDraft };
      return adFormState;

    case "ADD_AD_PHOTOS":
      let newPhotos = action.payload.photos;
      let existingPhotos = adFormState.adDraft.properties.photos;
      existingPhotos.push(...newPhotos);
      adFormState = { ...adFormState };
      return adFormState;

    case "UPDATE_AD_PHOTO":
      let updatedPhoto = action.payload.updatedPhoto;
      adDraft = adFormState.adDraft;
      filteredPhotos = adDraft.properties.photos.filter(
        (photo) => photo.id !== updatedPhoto.id
      );
      filteredPhotos.push(updatedPhoto);
      adDraft.properties.photos = filteredPhotos;
      adFormState = { ...adFormState, adDraft };
      console.log("adFormState from UPDATE_AD_PHOTOS", adFormState);
      return adFormState;

    case "CHANGE_AD_PHOTOS_ORDER":
      // let adToReorder = action.payload.adToReorder;
      let reorderedPhotos = action.payload.reorderedPhotos;
      adDraft = adFormState.adDraft;
      adDraft.properties.photos = reorderedPhotos;
      adFormState = { ...adFormState, adDraft };
      console.log("adFormState from CHANGE_AD_PHOTOS_ORDER", adFormState);
      return adFormState;

    case "DELETE_AD_PHOTO":
      let deletedPhoto = action.payload.deletedPhoto;
      adDraft = adFormState.adDraft;
      filteredPhotos = adDraft.properties.photos.filter(
        (photo) => photo.id !== deletedPhoto
      );
      adDraft.properties.photos = filteredPhotos;
      adFormState = { ...adFormState, adDraft };
      console.log("adFormState from DELETE_AD_PHOTO", adFormState);
      return adFormState;

    case "LOGOUT_USER":
      return { ...initialAdFormState };

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
