import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { crudSearchNotificationSettings } from "../fetchOrSendDashboardData";
import { useGlobalContext } from "../../../context";

export default function AllowNotificationsDialog({
  openAllowNotificationsDialog: open,
  setOpenAllowNotificationsDialog: setOpen,
  setSelectedNotificationMode,
  form,
}) {
  const { dispatchNotification } = useGlobalContext();
  const [hideDialog, setHideDialog] = useState(false);
  const permission = Notification.permission;
  const dialogContentText =
    permission === "default"
      ? 'Bus siunčiami tik pasirinkti pranešimai. Sutikimą galėsite bet kada atšaukti. Spauskite "Tęsti", o tada - "Allow" (leisti pranešimus).'
      : 'Norėdami gauti pranešimus ekrane, turite rankiniu būdu pakeisti naršyklės nustatymus ir leisti pranešimus ("Allow Notifications")';

  useEffect(() => {
    if (open) setHideDialog(false);
  }, [open]);

  async function handleClose(agree, reason) {
    if (reason && reason === "backdropClick") return;
    if (!agree) {
      switchToMailNotification();
    }
    if (agree) {
      setHideDialog(true);
      const permission = await Notification.requestPermission();
      permission === "granted"
        ? crudSearchNotificationSettings(dispatchNotification, "post", form)
        : switchToMailNotification();
    }
    setOpen(false);
  }

  function switchToMailNotification() {
    form.notification_modes = "mail";
    crudSearchNotificationSettings(dispatchNotification, "post", form);
    setSelectedNotificationMode("mail");
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        {!hideDialog && (
          <>
            <DialogTitle id="alert-dialog-title">
              {permission === "default"
                ? "Leisti pranešimus ekrane?"
                : "Ekrano pranešimai neleidžiami"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogContentText}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              {permission === "default" && (
                <Button
                  onClick={() => handleClose(false)}
                  color="primary"
                  variant="outlined"
                >
                  Atšaukti
                </Button>
              )}
              <Button
                onClick={() => handleClose(true)}
                color="primary"
                variant="contained"
                autoFocus
              >
                {permission === "default" ? "Tęsti" : "Supratau"}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}
