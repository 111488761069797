import { Divider, Grid, Typography } from "@mui/material";
import * as adOptions from "../../../../utils/adOptions";
import useFilterType from "../hooks/useFilterType";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import FormikBasicAutocomplete from "../../../SharedComponents/FormikBasicAutocomplete";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikBasicMultipleSelect from "../../../SharedComponents/FormikBasicMultipleSelect";
import FormikAndOrFilter from "./FormikAndOrFilter";
import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import { useFormikContext } from "formik";

function FiltersLand() {
  const formik = useFormikContext();
  const params = useParamsFromUrl();
  const { isSale, isRent } = useFilterType(params);

  const priceOptions = isSale
    ? adOptions.PRICE_OPTIONS
    : adOptions.PRICE_OPTIONS_RENT;

  const infrastructureOptions =
    params.plot_purpose === "garden"
      ? adOptions.INFRASTRUCTURE_CHOICES_GARDEN_PLOT
      : adOptions.INFRASTRUCTURE_CHOICES_HOUSE_PLOT;

  const isForest = formik.values.plot_purpose === "forest";
  const showPlotInfrastructure = !["forest"].includes(
    formik.values.plot_purpose
  );
  const showFertilityScore = formik.values.plot_purpose === "farm";
  const showRecreation = !["forest", "industrial_storage"].includes(
    formik.values.plot_purpose
  );
  const showWaterSewage = ["house", "garden"].includes(
    formik.values.plot_purpose
  );

  function handlePlorPurposeChange(plot_purpose) {
    if (plot_purpose !== "farm") {
      formik.setFieldValue("min_fertility_score", "");
      formik.setFieldValue("max_fertility_score", "");
    }
    if (!["house", "garden"].includes(plot_purpose)) {
      formik.setFieldValue("water_sewage", "");
    }
    if (plot_purpose === "forest") {
      formik.setFieldValue("plot_infrastructure", "");
    }
    if (["forest", "industrial_storage"].includes(plot_purpose)) {
      formik.setFieldValue("recreation", "");
    }
    if (plot_purpose !== "forest") {
      formik.setFieldValue("forest_type", "");
      formik.setFieldValue("forest_mature", "");
      formik.setFieldValue("forest_group", "");
      formik.setFieldValue("is_logging_forest", "");
    }
  }

  function clearAllFilter(values, name) {
    values.length <= 1 && formik.setFieldValue(name + "_all", "");
  }

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pagrindiniai filtrai
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        {/* minimum price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_price"
            label="Kaina (Eur, nuo)"
            options={
              !!+formik.values["max_price"]
                ? priceOptions.filter(
                    (option) => option <= +formik.values["max_price"]
                  )
                : priceOptions
            }
            type="number"
            removeSpinners={true}
          />
        </Grid>
        {/* max price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_price"
            label="Kaina (Eur, iki)"
            options={priceOptions.filter(
              (option) => option >= +formik.values["min_price"]
            )}
            type="number"
            removeSpinners={true}
          />
        </Grid>

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_plot_area"
            label="Plotas (arai; nuo)"
            options={
              !!+formik.values["max_plot_area"]
                ? adOptions.PLOT_AREA_OPTIONS.filter(
                    (option) => option <= +formik.values["max_plot_area"]
                  )
                : adOptions.PLOT_AREA_OPTIONS
            }
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_plot_area"
            label="Plotas (arai; iki)"
            options={adOptions.PLOT_AREA_OPTIONS.filter(
              (option) => option >= +formik.values["min_plot_area"]
            )}
            type="number"
          />
        </Grid>

        {isRent && (
          <>
            <Grid item xs={12}>
              <FormikSelect
                name="min_rent_period"
                label="Orientacinis nuomos terminas"
                options={adOptions.MIN_RENT_PERIOD_CHOICES_LAND}
                enableClear={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikBasicMultipleSelect
                name="rent_type"
                label="Nuomos sąlygos ir pirmenybės"
                options={adOptions.RENT_TYPE_CHOICES_LAND}
                onChange={(value) => clearAllFilter(value, "rent_type")}
              />
            </Grid>
            <FormikAndOrFilter name="rent_type_all" />
          </>
        )}

        {isSale && (
          <>
            <Grid item xs={12}>
              <FormikBasicMultipleSelect
                name="sale_type"
                label="Pardavimo sąlygos ir pirmenybės"
                options={adOptions.SALE_TYPE_CHOICES}
                onChange={(value) => clearAllFilter(value, "sale_type")}
              />
            </Grid>
            <FormikAndOrFilter name="sale_type_all" />
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Sklypas ir aplinka
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12} md={6}>
            <FormikSelect
              name="plot_purpose"
              label="Sklypo paskirtis"
              options={adOptions.PLOT_PURPOSE_CHOICES}
              enableClear={true}
              onChange={handlePlorPurposeChange}
            />
          </Grid>
        </Grid>

        {isForest && (
          <>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="forest_type"
                label="Miško tipas"
                options={adOptions.FOREST_TYPE_CHOICES}
                enableClear={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormikSelect
                name="forest_mature"
                label="Miško branda"
                options={adOptions.FOREST_MATURE_CHOICES}
                enableClear={true}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikBasicMultipleSelect
                name="forest_group"
                label="Miško grupė"
                options={adOptions.FOREST_GROUP_CHOICES}
                onChange={(value) => clearAllFilter(value, "forest_group")}
              />
            </Grid>
            <FormikAndOrFilter name="forest_group_all" />
            <Grid container justifyContent="right" sx={{ px: 1 }}>
              <FormikCheckbox
                name="is_logging_forest"
                label="Rodyti tik mišką, kuriam galimas komercinis kirtimas"
              />
            </Grid>
          </>
        )}

        {showFertilityScore && (
          <>
            <Grid item xs={6}>
              <FormikBasicAutocomplete
                name="min_fertility_score"
                label="Našumo balas (nuo)"
                options={
                  !!+formik.values["max_fertility_score"]
                    ? adOptions.FERTILITY_SCORE_OPTIONS.filter(
                        (option) =>
                          option <= +formik.values["max_fertility_score"]
                      )
                    : adOptions.FERTILITY_SCORE_OPTIONS
                }
              />
            </Grid>
            <Grid item xs={6}>
              <FormikBasicAutocomplete
                name="max_fertility_score"
                label="Našumo balas (iki)"
                options={adOptions.FERTILITY_SCORE_OPTIONS.filter(
                  (option) => option >= +formik.values["min_fertility_score"]
                )}
              />
            </Grid>
          </>
        )}

        {showPlotInfrastructure && (
          <>
            <Grid item xs={12}>
              <FormikBasicMultipleSelect
                name="plot_infrastructure"
                label="Sklypo įrengimas ir infrastruktūra"
                options={infrastructureOptions}
                onChange={(value) =>
                  clearAllFilter(value, "plot_infrastructure")
                }
              />
            </Grid>
            <FormikAndOrFilter name="plot_infrastructure_all" />
          </>
        )}

        {showWaterSewage && (
          <>
            <Grid item xs={12}>
              <FormikBasicMultipleSelect
                name="water_sewage"
                label="Vanduo ir nuotekos"
                options={adOptions.WATER_SEWAGE_CHOICES}
                onChange={(value) => clearAllFilter(value, "water_sewage")}
              />
            </Grid>
            <FormikAndOrFilter name="water_sewage_all" />
          </>
        )}

        {showRecreation && (
          <>
            <Grid item xs={12}>
              <FormikBasicMultipleSelect
                name="recreation"
                label="Rekreacinės erdvės apylinkėse"
                options={adOptions.RECREATION_CHOICES}
                onChange={(value) => clearAllFilter(value, "recreation")}
              />
            </Grid>
            <FormikAndOrFilter name="recreation_all" />
          </>
        )}
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="water_body"
            label="Vandens telkiniai netoliese"
            options={adOptions.WATER_BODY_CHOICES}
            onChange={(value) => clearAllFilter(value, "water_body")}
          />
        </Grid>
        <FormikAndOrFilter name="water_body_all" />
      </Grid>
    </>
  );
}

export default FiltersLand;
