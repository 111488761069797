import { useEffect } from "react";
import { useGlobalContext } from "../../context";
import {
  fetchAdCards,
  fetchAds,
  fetchLikedAdIds,
} from "./fetchOrSendMainAppData";
import useParamsFromUrl from "./hooks/useParamsFromUrl";

function AppDataProvider({ children }) {
  const { state, dispatch, routerState, dispatchRouter, userState } =
    useGlobalContext();
  const params = useParamsFromUrl();

  useEffect(() => {
    // alert("appDataProvider useEffect");
    // console.log("adCardsLoaded from AppDataProvider", state.adCardsLoaded);
    if (!state.adCardsLoaded && !state.adCardsLoading) {
      fetchAdCards(params, dispatch);
    }
    if (!state.adsLoaded && !state.adsLoading) {
      fetchAds(params, dispatch);
    }
    if (
      userState.isAuthenticated &&
      !state.likedAdIdsLoaded &&
      !state.likedAdIdsLoading
    ) {
      fetchLikedAdIds({}, dispatch);
    }
  }, [
    dispatch,
    params,
    state.adCardsLoaded,
    state.adCardsLoading,
    state.adsLoaded,
    state.adsLoading,
    state.likedAdIdsLoaded,
    state.likedAdIdsLoading,
    userState.isAuthenticated,
  ]);

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { appParams: params },
    });
  }, [JSON.stringify(params)]);

  console.log("params from AppDataProvider", params);
  // console.log("routerState", routerState);

  return children;
}

export default AppDataProvider;
