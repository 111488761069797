import * as init from "./init";
import * as schema from "./schema";

export default function getFormikProps(params, handleSubmit) {
  const adType = params.ad_type;

  const validationSchemas = {
    flat_sale: "validationSchemaFlatHouse",
    flat_rent: "validationSchemaFlatHouse",
    house_sale: "validationSchemaFlatHouse",
    house_rent: "validationSchemaFlatHouse",
    premises_sale: "validationSchemaFlatHouse",
    premises_rent: "validationSchemaFlatHouse",
    land_sale: "validationSchemaLand",
    land_rent: "validationSchemaLand",
    garage_sale: "validationSchemaParking",
    garage_rent: "validationSchemaParking",
    parking_sale: "validationSchemaParking",
    parking_rent: "validationSchemaParking",
    apartment_complex: "validationSchemaApartmentComplex",
  };

  const formikProps = {
    initialValues: init.getInitialValues(params),
    validationSchema: schema[validationSchemas[adType]],
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: handleSubmit,
  };

  return formikProps;
}
