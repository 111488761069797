import { useEffect } from "react";

const useKeyPress = (keyCode, action) => {
  useEffect(() => {
    function handleKeyUp(e) {
      if (e.keyCode === keyCode) action();
    }
    window.addEventListener("keyup", handleKeyUp);
    return () => window.removeEventListener("keyup", handleKeyUp);
  }, [action, keyCode]);
};

export default useKeyPress;
