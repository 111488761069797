import React, { useEffect, useState } from "react";
// import { useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { submitNewPassword } from "./fetchOrSendAccountsData";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, List, ListItem, Paper, Stack } from "@mui/material";
// import FormPreview from "../AdForm/utils/FormPreview";
import { useParams, Link as RouterLink } from "react-router-dom";
// import { InputAdornment, IconButton } from "@mui/material";
// import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormikPasswordField from "../SharedComponents/FormikPasswordField";
import { grey } from "@mui/material/colors";
import Footer from "../Layout/Footer/Footer";
import { useGlobalContext } from "../../context";

const initialValues = Object.freeze({
  new_password: "",
  re_new_password: "",
});

const validationSchema = Yup.object({
  new_password: Yup.string()
    .required("Privalomas laukas")
    .min(8, "Slaptažodį turi sudaryti bent 8 simboliai")
    .matches(/[a-z]/, "Slaptažodis turi turėti bent vieną mažąją raidę")
    .matches(/[A-Z]/, "Slaptažodis turi turėti bent vieną didžiąją raidę")
    .matches(/[0-9]/, "Slaptažodis turi turėti bent vieną skaitmenį"),
  re_new_password: Yup.string()
    .required("Privalomas laukas")
    .oneOf([Yup.ref("new_password")], "Slaptažodžiai turi sutapti"),
});

export default function PasswordResetConfirm() {
  // const history = useHistory();
  const { dispatchRouter } = useGlobalContext();
  const [passwordResetError, setPasswordResetError] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
  // const [showPassword, setShowPassword] = useState(false);
  const params = useParams();

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "passwordResetConfirm" },
    });
  }, []);

  async function handleSubmit(formikValues, formik) {
    if (!formik.isValid) return;
    try {
      formikValues = { ...formikValues, ...params };
      await submitNewPassword(formikValues);
      setPasswordResetSuccess(true);
    } catch (error) {
      setPasswordResetError(true);
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: grey[300],
        border: "none",
        pt: 13,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          border: "none",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
        }}
        disableGutters
      >
        <Paper
          sx={{ width: "100%", height: "100%", p: 1, mb: 7 }}
          elevation={1}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {(formik) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(formik.values, formik);
                }}
                noValidate
                sx={{ width: "100%" }}
              >
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  sx={{ my: 2 }}
                >
                  <Avatar sx={{ width: 70, height: 70 }} />

                  {!passwordResetError && !passwordResetSuccess && (
                    <>
                      <Typography variant="h6" color="grey">
                        Slaptažodžio keitimas
                      </Typography>
                      <Stack
                        spacing={3}
                        sx={{
                          display: "flex",
                          justifyContent: "left",
                          my: 3,
                          width: "100%",
                        }}
                      >
                        <FormikPasswordField
                          name="new_password"
                          label="Naujas slaptažodis *"
                          helperText={null}
                          size="small"
                        />
                        <List dense sx={{ border: "none", p: 0 }}>
                          <ListItem>
                            <Typography
                              variant="caption"
                              color={
                                formik.errors.new_password ===
                                  "Slaptažodį turi sudaryti bent 8 simboliai" &&
                                "error"
                              }
                            >
                              Slaptažodį turi sudaryti bent 8 simboliai
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography
                              variant="caption"
                              color={
                                formik.errors.new_password ===
                                  "Slaptažodis turi turėti bent vieną mažąją raidę" &&
                                "error"
                              }
                            >
                              Slaptažodis turi turėti bent vieną mažąją raidę
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography
                              variant="caption"
                              color={
                                formik.errors.new_password ===
                                  "Slaptažodis turi turėti bent vieną didžiąją raidę" &&
                                "error"
                              }
                            >
                              Slaptažodis turi turėti bent vieną didžiąją raidę
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <Typography
                              variant="caption"
                              color={
                                formik.errors.new_password ===
                                  "Slaptažodis turi turėti bent vieną skaitmenį" &&
                                "error"
                              }
                            >
                              Slaptažodis turi turėti bent vieną skaitmenį
                            </Typography>
                          </ListItem>
                        </List>

                        <FormikPasswordField
                          name="re_new_password"
                          label="Pakartokite slaptažodį *"
                          size="small"
                        />
                      </Stack>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                      >
                        Keisti slaptažodį
                      </Button>
                    </>
                  )}
                  {passwordResetError && (
                    <>
                      <Typography variant="h6">
                        Slaptažodžio keitimo klaida
                      </Typography>
                      <Typography variant="body2" sx={{ mt: 2 }}>
                        Slaptažodžio keitimo nuoroda negaliojanti arba
                        slaptažodis jau pakeistas
                      </Typography>
                      <Link
                        component={RouterLink}
                        to="/user-profile"
                        variant="body2"
                        sx={{ mt: 2 }}
                      >
                        Prisijungti
                      </Link>
                    </>
                  )}
                  {passwordResetSuccess && (
                    <>
                      <Stack
                        justifyContent="center"
                        alignItems="center"
                        sx={{ my: 2 }}
                      >
                        <Typography variant="h6" color="success.main">
                          Slaptažodis sėkmingai pakeistas
                        </Typography>
                        <Link
                          component={RouterLink}
                          to="/user-profile"
                          variant="body2"
                          sx={{ mt: 2 }}
                        >
                          Prisijungti
                        </Link>
                      </Stack>
                    </>
                  )}
                </Stack>
                {/* <FormPreview /> */}
              </Form>
            )}
          </Formik>
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}
