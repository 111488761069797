import React from "react";
import { Grid, Typography, IconButton } from "@mui/material";
import { Box } from "@mui/system";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import useAdType from "./useAdType";
import {
  getAddress,
  getPlotArea,
  getPrice,
  getPriceSqm,
  getPlotPurpose,
  getInfrastructure,
  getWaterBody,
  getFertility,
  getForestProp,
} from "./utils";
import MapButton from "./MapButton";

function BasicAdInfoLand({ ad, setShowMap, showMap }) {
  const { isRent, isLand, isFarmLand, isForest, isLoggingForest } =
    useAdType(ad);

  const price = getPrice(ad, isRent);
  const priceSqm = getPriceSqm(ad);
  const address = getAddress(ad);
  const plotArea = getPlotArea(ad, 6, 6, isLand);
  const plotPurpose = getPlotPurpose(ad, 6, 6);
  const infrastructure = getInfrastructure(ad, 6, 6);
  const waterBody = getWaterBody(ad, 6, 6);
  const fertility = getFertility(ad, 6, 6);
  const forestProp = getForestProp(ad, 6, 6, isLoggingForest);

  const forestHasProps = !!(
    ad.properties.forest_type ||
    ad.properties.forest_mature ||
    !!isLoggingForest
  );

  return (
    <>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          justifyContent: "space-between",
          // display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            sx={{ mt: 1, mx: 1 }}
            color="primary"
            variant="body2"
            fontSize={20}
          >
            {price}
          </Typography>
          <Typography sx={{ mt: 1, pt: 0.3 }} variant="body2">
            ({priceSqm} €/a)
          </Typography>
        </Box>
      </Grid>
      <Grid
        container
        xs={12}
        sx={{
          border: "none",
          alignItems: "center",
          px: 1,
          mt: 0.2,
        }}
      >
        <Typography variant="caption">{address} </Typography>
        <MapButton setShowMap={setShowMap} showMap={showMap} />
      </Grid>

      <Grid container sx={{ my: 1, pl: 1, border: "none" }}>
        <>
          {plotArea}
          {plotPurpose}
          {!isForest && infrastructure}
          {!infrastructure && isFarmLand && fertility}
          {isForest && forestHasProps && forestProp}
          {waterBody}
        </>
      </Grid>
    </>
  );
}

export default BasicAdInfoLand;
