import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import {
  HOUSE_TYPE_CHOICES,
  BUILDING_TYPE_CHOICES,
  ENERGY_EFFICIENCY_CHOICES,
  HEATING_CHOICES,
  HOUSE_RENOVATED_CHOICES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function BuildingPanelHouse({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { isMobile } = useCustomMediaQuery();
  const { adFormState } = useGlobalContext();
  const panelName = "buildingPanelHouse";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getBuildingHouseInitialValues(ad, adOptions);
  const validationSchema = schema.buildingHouseValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  function handleConstructionYearChange(e, formik) {
    console.log("handleConstructionYearChange", e);
    const constructionYear = Number(e);
    if (constructionYear >= 2000) {
      formik.setFieldValue("renovation", false);
      formik.setFieldValue("renovation_year", null);
      formik.setFieldValue("building_renovated", []);
      formik.setFieldTouched("renovation", false);
      formik.setFieldTouched("renovation_year", false);
      formik.setFieldTouched("building_renovated", false);
    }
  }

  function handleRenovationChange(e, formik) {
    const renovation = e.target.checked;
    if (renovation) {
      formik.setFieldValue("renovation_year", null);
      formik.setFieldValue("building_renovated", []);
      formik.setFieldTouched("renovation_year", false);
      formik.setFieldTouched("building_renovated", false);
    }
  }

  console.log("ad from BuildingPanelHouse", ad);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1} sx={{ border: "none" }}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikSelect
                name="house_type"
                label="Pastato tipas *"
                options={HOUSE_TYPE_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ border: "none", my: 1 }}>
              <FormikSelect
                name="building_type"
                label="Pastato konstrukcija *"
                options={BUILDING_TYPE_CHOICES}
              />
            </Grid>

            <Grid item xs={6} md={3} sx={{ my: 1 }}>
              <FormikTextField
                name="construction_year"
                label="Statybos metai *"
                type="number"
                inputProps={{
                  min: 1900,
                  max: 2025,
                  step: 1,
                }}
                onChange={(e) => handleConstructionYearChange(e, formik)}
              />
            </Grid>
            <Grid item xs={6} md={3} sx={{ my: 1 }}>
              <FormikTextField
                name="floors"
                label="Aukštų skaičius *"
                type="number"
                inputProps={{
                  min: 0,
                  max: 30,
                  step: 1,
                }}
              />
            </Grid>
            <Grid item xs={6} md={3} sx={{ my: 1 }}>
              <FormikTextField
                name="rooms"
                label="Kambarių skaičius *"
                type="number"
                inputProps={{
                  min: 1,
                  max: 20,
                  step: 1,
                }}
              />
            </Grid>
            <Grid item xs={6} md={3} sx={{ my: 1 }}>
              <FormikTextField
                name="area"
                label="Plotas (kv. m) *"
                type="number"
                inputProps={{
                  min: 1,
                  max: 10000,
                  step: 1,
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="heating"
                label="Šildymas *"
                options={HEATING_CHOICES}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikSelect
                name="energy_efficiency"
                label="Energetinis efektyvumas *"
                options={ENERGY_EFFICIENCY_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField
                name="heating_bill"
                label="Vid. žiemos mėnesio šildymo sąskaita (Eur/mėn.)"
                type="number"
              />
            </Grid>
            {!isMobile && <Grid item sm={6} sx={{ border: "none" }} />}
            {+formik.values.construction_year < 2000 && (
              <>
                <Grid item xs={6} alignItems="center" sx={{ border: "none" }}>
                  <FormikCheckbox
                    name="renovation"
                    label="Pastatas renovuotas"
                    onChange={(e) => handleRenovationChange(e, formik)}
                    sx={{ mt: 1.5 }}
                  />
                </Grid>
                {!!formik.values.renovation && (
                  <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                    <FormikTextField
                      name="renovation_year"
                      label="Renovacijos metai *"
                      type="number"
                      inputProps={{
                        min: 2000,
                        step: 1,
                      }}
                    />
                  </Grid>
                )}
              </>
            )}
            {+formik.values.construction_year < 2000 &&
              formik.values.renovation && (
                <Grid item xs={12} sx={{ my: 1 }}>
                  <FormikMultipleSelect
                    name="building_renovated"
                    label="Renovacijos apimtys *"
                    options={HOUSE_RENOVATED_CHOICES}
                  />
                </Grid>
              )}
            {/* </Grid> */}
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
