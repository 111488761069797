import React, { useEffect } from "react";
import { Grid } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import { fetchFlatOptionsComplex } from "../../fetchOrSendAdDraftData";
import { INSTALLATION_CHOICES_APARTMENT_COMPLEX } from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import FlatStatsApartmentComplex from "./FlatStatsApartmentComplex";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function FlatPanelApartmentComplex({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState, dispatchAdForm } = useGlobalContext();
  const panelName = "flatPanelApartmentComplex";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getFlatApartmentComplexInitialValues(
      ad,
      adOptions,
      adFormState?.flatOptions || []
    );
  const validationSchema = schema.flatApartmentComplexValidationSchema;

  const premisesOptions =
    adFormState?.flatOptions?.filter((x) => x.ad_type === "premises_sale") ||
    [];
  const garageOptions =
    adFormState?.flatOptions?.filter((x) =>
      ["parking_sale", "garage_sale"].includes(x.ad_type)
    ) || [];
  const flatOptions =
    adFormState?.flatOptions?.filter((x) => x.ad_type === "flat_sale") || [];

  // const selectedFlats =
  //   adFormState?.flatOptions?.filter((flat) =>
  //     selectedFlatIds.includes(flat.value)
  //   ) || [];

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  useEffect(() => {
    fetchFlatOptionsComplex(dispatchAdForm, ad.id);
  }, [ad.properties.company_name, ad.properties.project_name]);

  // function handleFlatChange(value) {
  //   setSelectedFlatIds(value);
  // }

  function handleInstallationChange(value, formik) {
    if (value !== "full_or_partial") {
      formik.setFieldValue("installation_price_sqm", null);
      formik.setFieldTouched("installation_price_sqm", false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="flats"
                label="Butai projekte *"
                options={flatOptions}
                // onChange={handleFlatChange}
              />
            </Grid>
            {!!formik.values.flats.length && (
              <Grid
                item
                xs={12}
                sm={6}
                sx={{ my: 1, display: "block", border: "none" }}
              >
                <FlatStatsApartmentComplex />
              </Grid>
            )}
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikSelect
                name="installation"
                label="Apdaila *"
                options={INSTALLATION_CHOICES_APARTMENT_COMPLEX}
                onChange={(value) => handleInstallationChange(value, formik)}
              />
            </Grid>
            {formik.values.installation === "full_or_partial" && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikTextField
                  name="installation_price_sqm"
                  label="Pilnos apdailos kaina nuo (€/m²) *"
                  type="number"
                />
              </Grid>
            )}
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="premises_in_complex"
                label="Patalpos projekte"
                options={premisesOptions}
                disabled={!premisesOptions?.length}
              />
            </Grid>
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="parking_in_complex"
                label="Garažai / parkavimo vietos projekte"
                options={garageOptions}
                disabled={!garageOptions?.length}
              />
            </Grid>
          </Grid>

          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
