import React, { useCallback, useState, useRef, useEffect } from "react";
import { Button, Grid, IconButton, Slider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ReactAvatarEditor from "react-avatar-editor";
import useCurrentWidth from "../../../utils/useCurrentWidth";
import { base64StringtoFile } from "../../ImageCropper/ReusableUtils";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../../utils/defaults";
import { AiOutlineRotateRight } from "react-icons/ai";
import { BiMove } from "react-icons/bi";
import { useGlobalContext } from "../../../context";
// import { updatePhoto } from "../fetchOrSendDashboardData";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import { updatePhoto } from "../../Dashboard/fetchOrSendDashboardData";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";

export default function PhotoEditor({
  photo,
  setEditPhoto,
  setIsUpdatingPhoto,
  setImgSrc,
  isDraft,
}) {
  // const filename = photo.photo.substring(photo.photo.lastIndexOf("/") + 1);
  let windowWidth = useCurrentWidth();
  const editorRef = useRef(null);
  const boxRef = useRef(null);
  const { dispatch, dispatchDashboard, dispatchAdForm } = useGlobalContext();

  const [photoContainerWidth, setPhotoContainerWidth] = useState(null);
  const [photoContainerHeight, setPhotoContainerHeight] = useState(null);
  const [scale, setScale] = useState(photo.scale || 1);
  // const [rotation, setRotation] = useState(photo.rotation || 0);
  const [position, setPosition] = useState({
    x: photo.position_x || 0.5,
    y: photo.position_y || 0.5,
  });
  const [wheelEvent, setWheelEvent] = useState(0);
  const [deltaY, setDeltaY] = useState(0);
  const [cutImage, setCutImage] = useState(null);

  // get width of photo container
  const photoContainerRef = useCallback(
    (node) => {
      if (node !== null) {
        setPhotoContainerWidth(node.getBoundingClientRect().width);
        setPhotoContainerHeight(node.getBoundingClientRect().height);
      }
    },
    [windowWidth]
  );

  // add/remove mouse wheel listener
  useEffect(() => {
    if (!!boxRef.current) {
      boxRef.current.addEventListener("wheel", handleWheel, {
        passive: false,
      });
    }
    // return () => {
    //   // window.removeEventListener("wheel", handleWheel);
    //   boxRef.current.removeEventListener("wheel", handleWheel, {
    //     passive: false,
    //   });
    // };
  }, [boxRef]);

  // zoom in and out with mouse wheel
  useEffect(() => {
    console.log("deltaY", deltaY);
    if (!!wheelEvent && deltaY < 0) {
      setScale(Math.min(scale - (deltaY / 125) * 0.1, 3));
      setWheelEvent(0);
      setDeltaY(0);
    }
    if (!!wheelEvent && deltaY > 0) {
      setScale(Math.max(scale - (deltaY / 125) * 0.1, 1));
      setWheelEvent(0);
      setDeltaY(0);
    }
  }, [wheelEvent, deltaY]);

  function handleWheel(e) {
    e.preventDefault();
    setWheelEvent(wheelEvent + 1);
    setDeltaY(e.deltaY);
    console.log("e.deltaY", e.deltaY);
  }

  function handleScaleChange(event, newValue) {
    setScale(newValue);
    console.log("scale", newValue);
  }

  function handlePositionChange(value) {
    setPosition(value);
    console.log("position changed PhotoEditor", value);
  }

  function handleSaveImage() {
    let url = photo.photo_crop;
    let filename = url.substring(url.lastIndexOf("/") + 1);
    filename = filename.substring(0, filename.lastIndexOf(".")) + ".jpg";
    let imageData = editorRef.current
      .getImageScaledToCanvas()
      .toDataURL("image/jpeg", 1);

    let imageFile = base64StringtoFile(imageData, filename);
    console.log("imageFile from PhotoEditor", imageFile);
    let formData = new FormData();
    formData.append("position_x", position.x);
    formData.append("position_y", position.y);
    formData.append("scale", scale);
    formData.append("photo_crop", imageFile, imageFile.name);
    formData.append("cut_image", !!cutImage);
    setImgSrc(imageData);
    updatePhoto(
      photo.id,
      formData,
      isDraft ? dispatchAdForm : dispatchDashboard,
      setIsUpdatingPhoto,
      dispatch
    );
    setEditPhoto(false);
  }

  function handleCut() {
    let imageData = editorRef.current
      .getImageScaledToCanvas()
      .toDataURL("image/jpeg", 1);
    setCutImage(imageData);
    setScale(1);
  }

  // function handleRotate() {
  //   setRotation(rotation === 270 ? 0 : rotation + 90);
  // }

  console.log("photoContainerWidth", photoContainerWidth);
  console.log("photoContainerHeight", photoContainerHeight);
  // console.log("editProfilePhoto", editProfilePhoto);
  // console.log("rotation", rotation);
  console.log("photo from photoeditor", photo);
  console.log("render photoeditor");
  // alert("isUpdatingPhoto" + isUpdatingPhoto);
  console.log("image from Photoeditor", cutImage || getFullImgUrl(photo.photo));

  return (
    <Box ref={boxRef}>
      <Grid
        container
        sx={{
          border: "none",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            border: "none",
            display: "flex",
            justifyContent: "left",
            width: "50%",
            alignItems: "center",
            // visibility: editProfilePhoto ? "visible" : "hidden",
          }}
        >
          <Typography variant="body2" sx={{ ml: 1, mr: 2 }}>
            Pritraukti
          </Typography>
          <Slider
            size="small"
            value={scale}
            onChange={handleScaleChange}
            // sx={{ width: "10%" }}
            min={1}
            max={3}
            // min={rotation === 0 || rotation === 180 ? 1 : ASPECT}
            // max={rotation === 0 || rotation === 180 ? 3 : 3 * ASPECT}
            step={0.1}
          />
        </Box>
        <Box sx={{ border: "none" }}>
          {/* <IconButton size="small" onClick={handleRotate}>
            <AiOutlineRotateRight fontSize="19px" />
          </IconButton> */}
          <IconButton size="small" onClick={handleCut}>
            <ContentCutIcon fontSize="19px" />
          </IconButton>
        </Box>
      </Grid>
      <Box
        ref={photoContainerRef}
        sx={{ border: "none", width: "100%", position: "relative" }}
      >
        {/* {isUpdatingPhoto && (
          <CircularProgress
            sx={{ position: "absolute", left: "50%", top: "50%" }}
          />
        )} */}
        <Box
          sx={{
            position: "absolute",
            top: 5,
            left: 5,
            borderRadius: 5,
            backgroundColor: "Background",
            display: "flex",
            opacity: 0.6,
          }}
        >
          <BiMove fontSize="30px" color="grey" />
        </Box>
        <ReactAvatarEditor
          ref={editorRef}
          image={cutImage || getFullImgUrl(photo.photo)}
          width={photoContainerWidth}
          height={photoContainerWidth / ASPECT}
          // width={
          //   rotation === 0 || rotation === 180
          //     ? photoContainerWidth
          //     : photoContainerWidth / ASPECT
          // }
          // height={
          //   rotation === 0 || rotation === 180
          //     ? photoContainerWidth / ASPECT
          //     : photoContainerWidth
          // }
          //   height={Math.min(photoContainerWidth, photoContainerHeight)}
          // height={100}
          border={0}
          borderRadius={0}
          color={[255, 255, 255, 0.6]} // RGBA
          scale={scale}
          // rotate={rotation}
          position={position}
          onPositionChange={handlePositionChange}
          crossOrigin="anonymous"
          // disableBoundaryChecks={false}
          disableCanvasRotation={false}
        />
      </Box>
      <Grid
        container
        justifyContent="right"
        alignItems="center"
        sx={{
          pr: 0.5,
          border: "none",
        }}
      >
        <Box sx={{ mt: -0.2, mb: 0.8, border: "none" }}>
          {/* <Button
            size="small"
            variant="outlined"
            sx={{ mr: 0.5, height: 25 }}
            onClick={() => setEditPhoto(false)}
          >
            <Typography variant="caption">Atšaukti</Typography>
          </Button> */}
          <Button
            size="small"
            variant="contained"
            startIcon={<SaveIcon />}
            sx={{ ml: 0.5, height: 25 }}
            onClick={handleSaveImage}
          >
            <Typography variant="caption">Išsaugoti</Typography>
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}
