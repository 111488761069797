export const initialDashboardState = {
  adOptions: null,
  myAds: [],
  myAdsExpandedId: "",
  selectedEnquirer: "",
  adContacts: [],
  myFavorites: [],
  myEnquiryAds: [],
  // myEnquiries: [],
  enquiries: [],
  myCalendarEntries: [],
  myAppointments: [],
  mySearches: [],
  myEnquiryAdsExpandedId: "",
  myId: "",
  chatSocket: null,
  chatSocketMessage: null,
  updatedCalendarEntry: null,
  calendarDate: new Date(),
  myAdsLoaded: false,
  adContactsLoaded: false,
  adOptionsLoaded: false,
  // myEnquiriesLoaded: false,
  enquiriesLoaded: false,
  myEnquiryAdsLoaded: false,
  myAppointmentsLoaded: false,
  myCalendarEntriesLoaded: false,
  myFavoritesLoaded: false,
  myFavoritesFilterValue: "all",
  myAdsFilterValue: "all",
  myEnquiryChatFilterValue: "only relevant",
  mySearchesLoaded: false,
  adDraft: null,
  adDraftLoaded: false,
  // adMessages: [],
};

export function dashboardReducer(dashboardState, action) {
  let ad,
    myEnquiries,
    myEnquiryAds,
    myAppointments,
    myCalendarEntries,
    method,
    payload,
    adId,
    myAds,
    enquiries,
    adContacts;
  switch (action.type) {
    case "CHANGE_DASHBOARD_STATE":
      payload = action.payload;
      console.log("payload from CHANGE_DASHBOARD_STATE", payload);
      dashboardState = { ...dashboardState, ...payload };
      return dashboardState;

    case "CHANGE_FORMIK":
      payload = action.payload;
      let formik = dashboardState.formik;
      formik = { ...formik, ...payload };
      dashboardState = { ...dashboardState, formik };
      return dashboardState;

    case "SWITCH_TAB":
      let tab = action.payload.tab;
      dashboardState = {
        ...dashboardState,
        tab,
      };
      return dashboardState;
    case "FETCH_MY_ADS":
      myAds = action.payload.myAds;
      dashboardState = {
        ...dashboardState,
        myAds,
        myAdsLoaded: true,
      };
      return dashboardState;
    case "SET_AD_OPTIONS":
      let adOptions = action.payload.adOptions;
      dashboardState = {
        ...dashboardState,
        adOptions,
        adOptionsLoaded: true,
      };
      // console.log("dashboardState from reducer", dashboardState);
      // alert("set ad options in reducer");
      return dashboardState;
    case "ADD_AD_PHOTOS":
      let newPhotos = action.payload.photos;
      let currentAd = newPhotos[0].ad;
      let existingPhotos = dashboardState.myAds.find(
        (ad) => ad.id === currentAd
      ).properties.photos;
      existingPhotos.push(...newPhotos);
      dashboardState = { ...dashboardState };
      return dashboardState;
    case "DELETE_AD_PHOTO":
      let deletedPhoto = action.payload.deletedPhoto;
      dashboardState.myAds.forEach((ad) => {
        let filteredPhotos = ad.properties.photos.filter(
          (photo) => photo.id !== deletedPhoto
        );
        ad.properties.photos = filteredPhotos;
      });
      dashboardState = { ...dashboardState };
      return dashboardState;
    case "UPDATE_AD_PHOTO":
      let updatedPhoto = action.payload.updatedPhoto;
      dashboardState.myAds.forEach((ad) => {
        let filteredPhotos = ad.properties.photos.filter(
          (photo) => photo.id !== updatedPhoto.id
        );
        filteredPhotos.push(updatedPhoto);
        filteredPhotos.sort((a, b) => a.place - b.place);
        ad.properties.photos = filteredPhotos;
      });
      dashboardState = { ...dashboardState };
      console.log("dashboardState from UPDATE_AD_PHOTO", dashboardState);
      return dashboardState;
    case "CHANGE_AD_PHOTOS_ORDER":
      let adToReorder = action.payload.adToReorder;
      let reorderedPhotos = action.payload.reorderedPhotos;

      dashboardState.myAds
        .filter((ad) => ad.id === adToReorder)
        .forEach((ad) => {
          ad.properties.photos = reorderedPhotos;
        });
      dashboardState = { ...dashboardState };
      return dashboardState;
    case "SET_MY_ADS_CHAT_FILTER_VALUE":
      let chatFilterValue = action.payload.chatFilterValue;
      adId = action.payload.adId;
      dashboardState.myAds.find((ad) => ad.id === adId).chatFilterValue =
        chatFilterValue;
      dashboardState = { ...dashboardState };
      return dashboardState;
    case "EXPAND_MY_ADS_CARD":
      let myAdsExpandedId = action.payload.myAdsExpandedId;
      dashboardState = {
        ...dashboardState,
        myAdsExpandedId,
      };
      // console.log("myAdsExpandedId in reducer", myAdsExpandedId);
      // alert("EXPAND_MY_ADS_CARD");
      return dashboardState;
    case "CHANGE_AD_CONTACTS":
      let contact = action.payload.contact;
      console.log("contact from reducer", contact);
      let updatedAdContacts = dashboardState.adContacts.filter(
        (enquiry) => enquiry.id !== contact.id
      );
      updatedAdContacts.push(contact);
      dashboardState = { ...dashboardState, adContacts: updatedAdContacts };
      return dashboardState;
    case "FETCH_MY_FAVORITES":
      let myFavorites = action.payload.myFavorites;
      dashboardState = {
        ...dashboardState,
        myFavorites,
        myFavoritesLoaded: true,
      };
      return dashboardState;
    case "SET_MY_FAVORITES_FILTER_VALUE":
      let myFavoritesFilterValue = action.payload.myFavoritesFilterValue;
      dashboardState = {
        ...dashboardState,
        myFavoritesFilterValue,
      };
      return dashboardState;
    case "REMOVE_FROM_FAVORITES":
      let adToRemoveFromFavorites = action.payload.adToRemoveFromFavorites;
      let filteredFavorites = dashboardState.myFavorites.filter(
        (ad) => ad.id !== adToRemoveFromFavorites
      );
      dashboardState = {
        ...dashboardState,
        myFavorites: filteredFavorites,
      };
      return dashboardState;
    case "ADD_TO_FAVORITES":
      let adToAddToFavorites = action.payload.adToAddToFavorites;
      let expandedFavorites = dashboardState.myFavorites;
      expandedFavorites.push(adToAddToFavorites);
      expandedFavorites = expandedFavorites.sort(
        (a, b) => a.properties.price - b.properties.price
      );
      dashboardState = {
        ...dashboardState,
        myFavorites: expandedFavorites,
      };
      return dashboardState;
    case "FETCH_MY_CALENDAR_ENTRIES":
      myCalendarEntries = action.payload.myCalendarEntries;
      dashboardState = {
        ...dashboardState,
        myCalendarEntries,
        myCalendarEntriesLoaded: true,
      };
      return dashboardState;
    case "UPDATE_CALENDAR_ENTRY":
      let updatedCalendarEntry = action.payload.updatedEntry;

      // remove existing calendar entry
      let updatedCalendarEntries = dashboardState.myCalendarEntries.filter(
        (entry) => entry.id !== Number(updatedCalendarEntry.id)
      );
      updatedCalendarEntries.push(updatedCalendarEntry);
      dashboardState = {
        ...dashboardState,
        myCalendarEntries: updatedCalendarEntries,
        // updatedCalendarEntry,
      };
      return dashboardState;
    // case "UPDATE_CALENDAR_ENTRIES": // need to delete when checked
    //   let newMessage = action.payload.chatSocketMessage;

    //   // remove existing calendar entry
    //   let newCalendarEntries = dashboardState.myCalendarEntries.filter(
    //     (entry) => entry.id !== Number(newMessage.id)
    //   );

    //   newCalendarEntries.push(newMessage);
    //   dashboardState = {
    //     ...dashboardState,
    //     chatSocketMessage: newMessage,
    //     myCalendarEntries: newCalendarEntries,
    //   };
    //   alert("UPDATE_CALENDAR_ENTRIES");
    //   return dashboardState;

    case "CLEAR_CALENDAR_ENTRY":
      let entry_id = action.payload?.id;
      console.log("entry_id", entry_id);
      ad = action.payload?.ad;
      let enquirer = action.payload?.enquirer;
      let calendarEntries;
      if (!!entry_id) {
        calendarEntries = dashboardState.myCalendarEntries.filter(
          (entry) => entry.id !== entry_id
        );
      } else {
        calendarEntries = dashboardState.myCalendarEntries.filter(
          (entry) => !(entry.ad === ad && entry.enquirer === enquirer)
        );
      }

      dashboardState = {
        ...dashboardState,
        myCalendarEntries: calendarEntries,
      };
      return dashboardState;

    case "SET_CALENDAR_DATE":
      let calendarDate = action.payload.calendarDate;
      dashboardState = {
        ...dashboardState,
        calendarDate,
      };
      return dashboardState;
    case "SELECT_ENQUIRER":
      let selectedEnquirer = action.payload.selectedEnquirer;
      dashboardState = {
        ...dashboardState,
        selectedEnquirer,
      };
      // console.log("selectedEnquirer in reducer", selectedEnquirer);
      // alert("SELECT_ENQUIRER");
      return dashboardState;
    // delete the following case when ready
    case "SET_AD_CONTACTS":
      adContacts = action.payload.adContacts;
      dashboardState = {
        ...dashboardState,
        adContacts,
        adContactsLoaded: true,
      };
      console.log("adContacts in reducer", adContacts);
      // alert("SET_AD_CONTACTS");
      return dashboardState;
    // case "FETCH_MY_ENQUIRY_ADS":
    //   myEnquiryAds = action.payload.myEnquiryAds;
    //   dashboardState = {
    //     ...dashboardState,
    //     myEnquiryAds: myEnquiryAds,
    //     myEnquiryAdsLoaded: true,
    //   };
    //   // console.log("myEnquiryAds in reducer", myEnquiryAds);
    //   // alert("FETCH_MY_ENQUIRY_ADS");
    //   return dashboardState;
    case "SET_MY_ENQUIRIES":
      myEnquiries = action.payload.myEnquiries;
      dashboardState = {
        ...dashboardState,
        myEnquiries,
        myEnquiriesLoaded: true,
      };
      // console.log("myEnquiries in reducer", myEnquiries);
      // alert("SET_MY_ENQUIRIES");
      return dashboardState;
    // case "SET_MY_ENQUIRIES_CHAT_FILTER_VALUE":
    //   let myEnquiryChatFilterValue = action.payload.chatFilterValue;
    //   dashboardState = { ...dashboardState, myEnquiryChatFilterValue };
    //   return dashboardState;
    case "SET_MY_APPOINTMENTS":
      myAppointments = action.payload.myAppointments;
      dashboardState = {
        ...dashboardState,
        myAppointments,
        myAppointmentsLoaded: true,
      };
      console.log("myAppointments in reducer", myAppointments);
      // alert("SET_MY_APPOINTMENTS");
      return dashboardState;
    case "CLEAR_APPOINTMENT":
      alert("CLEAR_APPOINTMENT");
      let appointmentAd = action.payload.ad;
      let appointmentEnquirer = action.payload.enquirer;
      let appointments = dashboardState.myAppointments.filter(
        (appt) =>
          !(
            Number(appt.ad) === Number(appointmentAd) &&
            Number(appt.enquirer) === Number(appointmentEnquirer)
          )
      );
      dashboardState = {
        ...dashboardState,
        myAppointments: appointments,
      };
      return dashboardState;
    case "EXPAND_MY_ENQUIRY_ADS_CARD":
      let myEnquiryAdsExpandedId = action.payload.myEnquiryAdsExpandedId;
      dashboardState = {
        ...dashboardState,
        myEnquiryAdsExpandedId,
      };
      // console.log("myEnquiryAdsExpandedId in reducer", myEnquiryAdsExpandedId);
      // alert("EXPAND_MY_ENQUIRY_ADS_CARD");
      return dashboardState;
    // case "SET_MY_ID":
    //   let myId = action.payload.myId;
    //   dashboardState = {
    //     ...dashboardState,
    //     myId,
    //   };
    //   // console.log("myEnquiryAdsExpandedId in reducer", myEnquiryAdsExpandedId);
    //   // alert("EXPAND_MY_ENQUIRY_ADS_CARD");
    //   return dashboardState;
    // case "UPDATE_CHAT_SOCKET":
    //   let chatSocket = action.payload.chatSocket;
    //   dashboardState = {
    //     ...dashboardState,
    //     chatSocket,
    //   };
    //   return dashboardState;
    case "UPDATE_CHAT_MESSAGES":
      let chatMessage = action.payload.chatMessage;
      let updatedEnquiries = dashboardState.enquiries;
      updatedEnquiries.push(chatMessage);
      dashboardState = {
        ...dashboardState,
        enquiries: updatedEnquiries,
        chatMessage,
      };
      return dashboardState;

    case "CRUD_MY_AD":
      adId = action.payload.adId;
      let updatedAd = action.payload.updatedAd;
      method = action.payload.method;

      myAds = dashboardState.myAds.filter((ad) => ad.id !== adId);
      if (method === "PATCH" && updatedAd.properties.status !== "deleted") {
        myAds.push(updatedAd);
      }
      dashboardState = { ...dashboardState, myAds };

      return dashboardState;

    // delete when ready
    case "UPDATE_MY_AD":
      let adToUpdate = action.payload.adToUpdate;
      let updatedAds = dashboardState.myAds.filter(
        (ad) => ad.id !== adToUpdate.id
      );
      updatedAds.push(adToUpdate);
      dashboardState = {
        ...dashboardState,
        myAds: updatedAds,
      };
      return dashboardState;

    case "CRUD_APPOINTMENT":
      let appointmentId = action.payload.appointmentId;
      let appointmentObject = action.payload.appointmentObject;
      method = action.payload.method;
      myAppointments = dashboardState.myAppointments;

      // get appointments (GET method)
      if (method === "get") {
        myAppointments = [];
      }
      // delete appointment from existing array (PATCH, DELETE methods)
      if (!!appointmentId) {
        myAppointments = myAppointments.filter(
          (appt) => appt.id !== appointmentId
        );
      }
      // add new appointment to the array (GET, POST, PATCH methods)
      if (!!appointmentObject) {
        myAppointments = myAppointments.concat(appointmentObject);
      }
      // update dashboardState
      dashboardState = {
        ...dashboardState,
        myAppointments,
        myAppointmentsLoaded: true,
      };
      console.log(
        "myAppointments from dashboardReducer",
        dashboardState.myAppointments,
        "appointmentId",
        appointmentId
      );
      return dashboardState;

    case "CRUD_CALENDAR_ENTRY":
      let calendarEntryId = action.payload.calendarEntryId;
      let calendarEntryObject = action.payload.calendarEntryObject;
      method = action.payload.method;
      myCalendarEntries = dashboardState.myCalendarEntries;

      // get calendar entries (GET method)
      if (method === "get") {
        myCalendarEntries = [];
      }
      // delete calendar entry from existing array (PATCH, DELETE methods)
      if (!!calendarEntryId) {
        myCalendarEntries = myCalendarEntries.filter(
          (entry) => entry.id !== calendarEntryId
        );
      }
      // add new calendar entry to the array (GET, POST, PATCH methods)
      if (!!calendarEntryObject) {
        myCalendarEntries = myCalendarEntries.concat(calendarEntryObject);
      }
      // update dashboardState
      dashboardState = {
        ...dashboardState,
        myCalendarEntries,
        myCalendarEntriesLoaded: true,
      };
      return dashboardState;

    // case "LIST_AD_CONTACTS":
    //   method = action.payload.method;
    //   adContacts = dashboardState.adContacts;

    //   // update dashboardState
    //   dashboardState = {
    //     ...dashboardState,
    //     adContacts,
    //     adContactsLoaded: true,
    //   };
    //   return dashboardState;

    // case "LIST_CREATE_AD_MESSAGE":
    //   let messageObject = action.payload.messageObject;
    //   let messageArray = action.payload.messageObject;
    //   let enquirerId = action.payload.params?.enquirer_id;
    //   method = action.payload.method;
    //   // adContacts = dashboardState.adContacts;
    //   let adMessages = dashboardState.adMessages;

    //   // get ad messages (GET method)
    //   if ((method === "get") & !enquirerId) {
    //     // adContacts = messageArray.filter((message) => !!message.name);
    //     adMessages = messageArray;
    //   }
    //   // add previous messages to the array (GET method)
    //   if (method === "get" && !!enquirerId) {
    //     adMessages = adMessages.concat(messageObject);
    //   }
    //   // add new appointment ad message to the array (POST method)
    //   if (method === "post" && !!messageObject) {
    //     adMessages = adMessages.concat(messageObject);
    //   }
    //   // update dashboardState
    //   dashboardState = {
    //     ...dashboardState,
    //     adMessages,
    //     // adContacts,
    //     adMessagesLoaded: true,
    //   };
    //   return dashboardState;

    case "CRUD_ENQUIRY_MESSAGE":
      let enquiryObject = action.payload.enquiryObject;
      let enquiryArray = action.payload.enquiryObject;
      let enquirerId = action.payload.params?.enquirer_id;

      method = action.payload.method;
      enquiries = dashboardState.enquiries;

      // get ad messages (GET method)
      if (method === "get" && !enquirerId) {
        enquiries = enquiryArray;
      }
      // add previous messages to the array (GET method)
      if (method === "get" && !!enquirerId) {
        enquiries = enquiries.concat(enquiryObject);
      }

      // add new appointment ad message to the array (POST method)
      if (method === "post" && !!enquiryObject) {
        enquiries = enquiries.concat(enquiryObject);
      }

      if (method === "patch") {
        enquiries = enquiries.map((enquiry) =>
          enquiry.id === enquiryObject.id ? enquiryObject : enquiry
        );
      }

      // update dashboardState
      dashboardState = {
        ...dashboardState,
        enquiries,
        enquiriesLoaded: true,
      };
      return dashboardState;

    case "CRUD_SAVED_SEARCH":
      let savedSearchId = action.payload.savedSearchId;
      const idToOverwrite = action.payload.idToOverwrite;
      let savedSearchObject = action.payload.savedSearchObject;
      method = action.payload.method;
      let mySearches = dashboardState.mySearches.filter(
        (search) => search.id !== (idToOverwrite || savedSearchId)
      );

      if (method === "get") {
        mySearches = savedSearchObject;
      }

      if (["post", "patch"].includes(method)) {
        mySearches = mySearches.concat(savedSearchObject);
      }

      dashboardState = {
        ...dashboardState,
        mySearches,
        mySearchesLoaded: true,
      };

      return dashboardState;

    // case "FETCH_MY_SEARCHES":
    //   let mySearches = action.payload.mySearches;
    //   dashboardState = {
    //     ...dashboardState,
    //     mySearches,
    //     mySearchesLoaded: true,
    //   };
    //   return dashboardState;

    // case "UPDATE_SAVED_SEARCH":
    //   let updatedSearch = action.payload.updatedSearch;
    //   let updatedSearches = dashboardState.mySearches.map((search) =>
    //     search.id === updatedSearch.id ? updatedSearch : search
    //   );
    //   dashboardState = {
    //     ...dashboardState,
    //     mySearches: updatedSearches,
    //   };
    //   return dashboardState;

    // case "DELETE_SAVED_SEARCH":
    //   let deletedSearchId = action.payload.deletedSearchId;
    //   let filteredSearches = dashboardState.mySearches.filter(
    //     (search) => search.id !== deletedSearchId
    //   );
    //   dashboardState = {
    //     ...dashboardState,
    //     mySearches: filteredSearches,
    //   };
    //   return dashboardState;
    // case "SAVE_NEW_SEARCH":
    //   let savedSearch = action.payload.savedSearch;
    //   let newSearches = dashboardState.mySearches;
    //   newSearches.push(savedSearch);
    //   dashboardState = {
    //     ...dashboardState,
    //     mySearches: newSearches,
    //   };
    //   return dashboardState;

    case "SUBMIT_ENQUIRY":
      ad = action.payload.ad;
      const enquiry = action.payload.enquiry;
      enquiries = dashboardState?.enquiries || [];
      enquiries.push(enquiry);
      adContacts = dashboardState?.adContacts || [];
      adContacts.push(enquiry);
      myEnquiryAds = dashboardState?.myEnquiryAds || [];
      myEnquiryAds.push(ad);
      dashboardState = {
        ...dashboardState,
        enquiriesLoaded: true,
        myEnquiryAdsLoaded: true,
        adContactsLoaded: true,
      };
      return dashboardState;
    case "LOGOUT_USER":
      return { ...initialDashboardState };

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
