import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmationDialog({
  open: openProp,
  setAgree,
  setOpenDialog,
  title,
  contentText,
  agreeButtonText,
  disagreeButtonText,
  showDisagreeButton,
  alert,
}) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openProp);
  }, [openProp]);

  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  const handleClose = (agree) => {
    setOpen(false);
    setAgree(agree);
    setOpenDialog(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {contentText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showDisagreeButton && (
            <Button
              onClick={() => handleClose(false)}
              color="primary"
              variant="outlined"
            >
              {disagreeButtonText}
            </Button>
          )}
          <Button
            onClick={() => handleClose(true)}
            color={!!alert ? "error" : "primary"}
            variant="contained"
            autoFocus
          >
            {agreeButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
