import { useLocation } from "react-router-dom";
import { AD_TYPE_URL_PARAMS } from "../../../utils/defaults";

export default function useParamsFromUrl() {
  const queryString = require("query-string");
  const location = useLocation();
  let adTypeLabel = location.pathname.replaceAll("/", "");
  adTypeLabel = !!adTypeLabel ? adTypeLabel : "butai";
  const urlString = location.search;

  const filterParams = queryString.parse(urlString);
  const ad_type = AD_TYPE_URL_PARAMS[adTypeLabel?.toLowerCase()];
  let params = { ...filterParams, ad_type };

  return params;
}
