import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
import FormikDispatcher from "../../utils/FormikDispatcher";
// import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";

export default function PricePanelRentLand({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "pricePanelRentLand";

  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getPriceRentLandInitialValues(ad, adOptions);
  const validationSchema = schema.priceRentLandValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  // const [minRentPeriod, setMinRentPeriod] = useState(
  //   formik.values.min_rent_period
  // );
  // const [isLongTermRent, setIsLongTermRent] = useState(
  //   !minRentPeriod || minRentPeriod >= 30
  // );

  // function handleMinPeriodChange(e) {
  //   let wasLongTermRent = !minRentPeriod || minRentPeriod >= 30;
  //   let isLongTermRent = !e || e >= 30;
  //   setIsLongTermRent(isLongTermRent);
  //   if (wasLongTermRent !== isLongTermRent) {
  //     setMinRentPeriod(e);
  //     formik.setFieldValue("price", "".trim());
  //     formik.setFieldTouched("price", false);
  //     formik.setFieldValue("rent_type", []);
  //     formik.setFieldTouched("rent_type", false);
  //   }
  // }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container sx={{ border: "none" }}>
            <Grid
              container
              sx={{ my: 1, display: "flex", alignItems: "start" }}
            >
              <Grid item xs={6} sx={{ px: 0.5 }}>
                <FormikTextField
                  name="price"
                  label="Kaina už mėnesį (Eur/mėn.) *"
                />
              </Grid>
              <Grid item xs={6} sx={{ px: 0.5 }}>
                <FormikSelect
                  name="min_rent_period"
                  label="Min. trukmė"
                  options={adOptions["MIN_RENT_PERIOD_CHOICES_LAND"]}
                />
              </Grid>
              <Grid item xs={12} sx={{ my: 2, px: 0.5 }}>
                <FormikMultipleSelect
                  name="rent_type"
                  label="Nuomos sąlygos"
                  options={adOptions["RENT_TYPE_CHOICES_LAND"]}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
