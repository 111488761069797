export function getAppointmentStatus(
  isEnquirer,
  existingAppointmentDate,
  newAppointmentDate
) {
  const agent = isEnquirer ? "enquirer" : "owner";
  let action;
  const existingAppointmentDateString = !!existingAppointmentDate
    ? existingAppointmentDate.toISOString()
    : "";
  const newAppointmentDateString = !!newAppointmentDate
    ? newAppointmentDate.toISOString()
    : "";
  if (!existingAppointmentDateString && newAppointmentDateString)
    action = "proposed";
  if (
    !!existingAppointmentDateString &&
    !!newAppointmentDateString &&
    existingAppointmentDateString !== newAppointmentDateString
  )
    action = "changed";
  if (!!existingAppointmentDateString && !newAppointmentDateString)
    action = "canceled";
  if (existingAppointmentDateString === newAppointmentDateString)
    action = "confirmed";
  const cancelation =
    action === "confirmed" && !newAppointmentDateString ? " cancelation" : "";
  return `${agent} ${action} appointment${cancelation}`;
}
