import React, { useState, useContext, useReducer } from "react";
import { adFormReducer, initialAdFormState } from "./reducers/adFormReducer";
import {
  dashboardReducer,
  initialDashboardState,
} from "./reducers/dashboardReducer";
import { initialMainAppState, mainAppReducer } from "./reducers/mainAppReducer";
import {
  initialNotificationState,
  notificationReducer,
} from "./reducers/notificationReducer";
import { initialRouterState, routerReducer } from "./reducers/routerReducer";
import { initialUserState, userReducer } from "./reducers/userReducer";
import { loanReducer, initialLoanState } from "./reducers/loanReducer";

const AppContext = React.createContext();

export const AppContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(mainAppReducer, initialMainAppState);
  const [userState, dispatchUser] = useReducer(userReducer, initialUserState);
  const [dashboardState, dispatchDashboard] = useReducer(
    dashboardReducer,
    initialDashboardState
  );
  const [notificationState, dispatchNotification] = useReducer(
    notificationReducer,
    initialNotificationState
  );
  const [adFormState, dispatchAdForm] = useReducer(
    adFormReducer,
    initialAdFormState
  );
  const [routerState, dispatchRouter] = useReducer(
    routerReducer,
    initialRouterState
  );
  const [loanState, dispatchLoan] = useReducer(loanReducer, initialLoanState);

  const [adId, setAdId] = useState(null);
  const handleMouseOverAd = (id) => setAdId(id);

  // const [isAuthenticated, setIsAuthenticated] = useState(false);
  // const handleAuthentication = (isAuthenticated) =>
  //   setIsAuthenticated(isAuthenticated);

  // const [currentUser, setCurrentUser] = useState(null);
  // const handleUser = (currentUser) => setCurrentUser(currentUser);

  // const [notifications, setNotifications] = useState([]);
  // const handleNotifications = (notifications) =>
  //   setNotifications(notifications);

  const [likedAds, setLikedAds] = useState([]);
  const handleLike = (likedAds) => setLikedAds(likedAds);

  return (
    <AppContext.Provider
      value={{
        dispatch,
        dispatchUser,
        dispatchDashboard,
        dispatchNotification,
        dispatchAdForm,
        dispatchRouter,
        dispatchLoan,
        state,
        userState,
        dashboardState,
        notificationState,
        adFormState,
        routerState,
        loanState,
        adId,
        // isAuthenticated,
        // currentUser,
        // notifications,
        likedAds,
        handleMouseOverAd,
        // handleAuthentication,
        // handleUser,
        // handleNotifications,
        handleLike,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export const useGlobalContext = () => {
  return useContext(AppContext);
};
