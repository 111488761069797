import { InputAdornment } from "@mui/material";
import React from "react";
import FormikTextField from "../../SharedComponents/FormikTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGlobalContext } from "../../../context";
// import FormPreview from "../../AdForm/utils/FormPreview";
import { getMonthlyPaymentOrDuration } from "./Price";
import { PV } from "../../../utils/financialFunctions";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

function LoanSize() {
  const { loanState, dispatchLoan } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const price = loanState.price;
  const loanSize = Math.round(price * (1 - loanState.downPercent / 100));
  let maxLoanSize = price * (1 - loanState.minDownPercent / 100);
  if (loanState.fixMonthlyPayment) {
    maxLoanSize = Math.min(
      maxLoanSize,
      PV(
        loanState.interestRate / 12 / 100,
        loanState.maxDuration * 12,
        -loanState.monthlyPayment,
        0,
        0
      )
    );
  }

  const initialValues = { loanSize };
  const validationSchema = Yup.object({
    loanSize: Yup.number()
      .positive("Turi būti teigiamas skaičius")
      .required("Turi būti skaičius")
      .max(maxLoanSize, "Paskola viršija maksimalią ribą"),
  });

  function hanldeSubmit(formikValues) {
    let loanSize = Math.round(+formikValues.loanSize);
    let monthlyPayment, duration;
    ({ monthlyPayment, duration, loanSize } = getMonthlyPaymentOrDuration({
      loanState,
      loanSize,
    }));
    let downpayment = price - loanSize;
    let downPercent = (1 - loanSize / price) * 100;

    dispatchLoan({
      type: "CHANGE_LOAN_STATE",
      payload: {
        loanSize,
        downpayment,
        downPercent,
        monthlyPayment,
        duration,
      },
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={hanldeSubmit}
    >
      {(formik) => (
        <>
          <FormikTextField
            sx={{
              width: isMobile ? "100%" : "13ch",
            }}
            autoComplete="off"
            size="small"
            name="loanSize"
            label="Paskola"
            type="number"
            onChange={async (newValue) => {
              formik.setFieldValue("loanSize", newValue, false);
              await formik.validateField("loanSize");
              formik.submitForm();
            }}
            InputProps={{
              sx: { fontSize: "14px" },
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputProps: { min: 0, max: price, step: 500 },
            }}
          />
          {/* <FormPreview /> */}
        </>
      )}
    </Formik>
  );
}

export default LoanSize;
