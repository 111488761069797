import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useAdType from "./BasicAdInfo/useAdType";

const AccordionDescription = ({ ad }) => {
  const [expanded, setExpanded] = useState(false);
  const { isApartmentComplex } = useAdType(ad);

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      sx={{ width: "100%" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="button" color={expanded ? "primary" : "default"}>
          {!isApartmentComplex ? "Papildomas aprašymas" : "Apie projektą"}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "block" }}>
        <Typography variant="body2" style={{ whiteSpace: "pre-line" }}>
          {/* {JSON.stringify(ad.properties.description, null, 2)} */}
          {ad.properties.description}
        </Typography>
      </AccordionDetails>
    </Accordion>
    // </div>
  );
};

export default AccordionDescription;
