import React from "react";
import Grid from "@mui/material/Grid";
import CardMedia from "@mui/material/CardMedia";
import LinearProgress from "@mui/material/LinearProgress";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../../../utils/defaults";
import { Divider, Stack } from "@mui/material";
import AdCardTitleInfo from "../AdCardInfo/AdCardTitleInfo";
import BasicAdCardInfo from "../AdCardInfo/BasicAdCardInfo";
import { getFullImgUrl } from "../../../../utils/getFullImgUrl";
import AdStatusText from "./AdStatusText";
import AdTitle from "../../../IndividualAd/AdTitle";
import { useGlobalContext } from "../../../../context";
import { getDefaultPhotoUrl } from "../../../../utils/getDefaultPhotoUrl";

export default function AdCardFormatMediumImg({ ad }) {
  const { routerState } = useGlobalContext();

  console.log("routerState", routerState);

  return (
    <Box sx={{ display: "flex", alignItems: "stretch" }}>
      <Stack
        sx={{
          width: "40%",
          aspectRatio: String(ASPECT),
          display: "block",
          border: "none",
          position: "relative",
        }}
      >
        <RouterLink to={`/ad/${ad.id}`} sx={{ textDecoration: "none" }}>
          <AdStatusText ad={ad} />
          <CardMedia
            image={
              getFullImgUrl(ad.properties.photos[0]?.photo_thumbnail) ||
              getDefaultPhotoUrl()
            }
            sx={{ height: "100%" }}
          />
        </RouterLink>
      </Stack>

      <Box sx={{ width: "60%", p: 0 }}>
        <Grid
          container
          sx={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          {routerState.from === "dashboard" && (
            <>
              <Grid container sx={{ px: 0.5, py: 1 }}>
                <AdTitle ad={ad} />
              </Grid>
              <Divider sx={{ mx: 1 }} />
            </>
          )}
          <Grid container sx={{ display: "flex", flexDirection: "row" }}>
            <AdCardTitleInfo ad={ad} />
          </Grid>

          <Grid container sx={{ px: 1, my: 1, flexGrow: 1 }}>
            <BasicAdCardInfo ad={ad} />
          </Grid>

          {ad.properties.is_exact && ad.properties?.preference_score > 0 && (
            <LinearProgress
              variant="determinate"
              value={(+ad.properties?.preference_score * 100) / 5}
              color="success"
              sx={{ opacity: 0.8, height: "3px" }}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
}
