import React, { useState } from "react";
import { Box, TextField } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory, useParams } from "react-router-dom";
import humanizeDate from "../../../../utils/humanizeDate";
import humanizeStatus from "../../../../utils/humanizeStatus";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import AppointmentDialog from "./AppointmentDialog";
import AppointmentAgreeButton from "./AppointmentAgreeButton";

export default function AppointmentBox({ enquiry }) {
  let history = useHistory();
  const { dashboardState, userState } = useGlobalContext();
  let { tabName } = useParams();
  const { isMobile } = useCustomMediaQuery();
  const [openAppointmentDialog, setOpenAppointmentDialog] = useState(false);
  const isEnquirer = userState.userId === enquiry?.enquirer;
  const agent = isEnquirer ? "enquirer" : "owner";
  const counterAgent = isEnquirer ? "owner" : "enquirer";

  // const appointment = useMemo(
  //   () =>
  //     dashboardState.myAppointments.find(
  //       (appt) =>
  //         Number(appt.ad) === Number(enquiry.ad) &&
  //         Number(appt.enquirer) === Number(enquiry.enquirer)
  //     ) || null,
  //   [enquiry, dashboardState.myAppointments]
  // );
  const appointment =
    dashboardState.myAppointments.find(
      (appt) =>
        Number(appt.ad) === Number(enquiry.ad) &&
        Number(appt.enquirer) === Number(enquiry.enquirer)
    ) || null;
  const appointmentDateTime = !!appointment?.appointment
    ? new Date(appointment.appointment)
    : null;

  function getAppointmentBoxLabel() {
    if (!appointment?.appointment_status) return "Apžiūros laikas";
    let appointmentLabel = humanizeStatus(
      appointment.appointment_status,
      agent
    );
    return appointmentLabel;
  }

  function getAppointmentBoxTextValue() {
    let appointmentDate =
      appointment?.appointment_status === `${counterAgent} canceled appointment`
        ? appointment[`appointment_${agent}`]
        : appointment?.appointment || "";
    return humanizeDate(appointmentDate);
  }

  function getShowAppointmentAgreeButton() {
    const showButtonGroup = [
      `${counterAgent} proposed appointment`,
      `${counterAgent} changed appointment`,
      `${counterAgent} canceled appointment`,
    ].includes(appointment?.appointment_status);
    return showButtonGroup;
  }

  function getLineThrough() {
    const lineThrough =
      appointment?.appointment_status === `${counterAgent} canceled appointment`
        ? "line-through"
        : "none";
    return lineThrough;
  }

  function handleCalendarClick(event) {
    event.stopPropagation();
    history.replace(`/dashboard/${tabName}/${enquiry.ad}/${enquiry.enquirer}`);
    setOpenAppointmentDialog(true);
  }

  console.log("appointment from AppointmentBox", appointment);

  return (
    <>
      <Box
        sx={{
          width: isMobile ? "60%" : "40%",
          display: "flex",
          justifyContent: "left",
          border: "none",
          alignItems: "center",
          mt: 0.5,
        }}
      >
        <TextField
          // noWrap
          variant="outlined"
          size="small"
          label={getAppointmentBoxLabel()}
          onClick={handleCalendarClick}
          value={getAppointmentBoxTextValue()}
          InputProps={{
            readOnly: true,
            style: { fontSize: 14, textDecoration: getLineThrough() },
          }}
          InputLabelProps={{ style: { fontSize: 14 } }}
          disabled={
            !enquiry.enquirer_active ||
            !enquiry.owner_active ||
            !!enquiry.enquirer_suspended ||
            ["expired", "deleted", "blocked"].includes(enquiry.ad_status)
          }
        />
        {getShowAppointmentAgreeButton() && (
          <AppointmentAgreeButton
            existingAppointment={appointment}
            setOpenAppointmentDialog={setOpenAppointmentDialog}
          />
        )}
      </Box>
      {openAppointmentDialog && (
        <AppointmentDialog
          openAppointmentDialog={openAppointmentDialog}
          setOpenAppointmentDialog={setOpenAppointmentDialog}
          initialAppointmentDateTime={appointmentDateTime}
        />
      )}
    </>
  );
}
