import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import MyOrdersTableRow from "./MyOrdersTableRow";
import { useGlobalContext } from "../../../context";

// function createData(name, calories, fat, carbs, protein, price) {
//   return {
//     name,
//     calories,
//     fat,
//     carbs,
//     protein,
//     price,
//     history: [
//       {
//         date: "2020-01-05",
//         customerId: "11091700",
//         amount: 3,
//       },
//       {
//         date: "2020-01-02",
//         customerId: "Anonymous",
//         amount: 1,
//       },
//     ],
//   };
// }

// const rows = [
//   createData("Skelbimas ID:", 159, 6.0, 24, 4.0, 3.99),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3, 4.99),
//   createData("Eclair", 262, 16.0, 24, 6.0, 3.79),
//   createData("Cupcake", 305, 3.7, 67, 4.3, 2.5),
//   createData("Gingerbread", 356, 16.0, 49, 3.9, 1.5),
// ];

export default function MyOrdersTable() {
  const { dashboardState } = useGlobalContext();

  //   const myOrders = dashboardState.myOrders;
  let orderAds = dashboardState.myOrders.reduce((acc, current) => {
    const isDuplicate = acc.find((order) => order.ad === current.ad);
    if (!isDuplicate) {
      acc.push(current);
    }
    return acc;
  }, []);
  orderAds.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  return (
    <TableContainer component={Paper} sx={{ mt: 2 }}>
      <Table aria-label="collapsible table">
        <TableBody>
          {orderAds.map((orderAd) => (
            <MyOrdersTableRow
              key={orderAd.id}
              orderAd={orderAd}
              quantity={
                dashboardState.myOrders.filter(
                  (order) => order.ad === orderAd.ad
                ).length
              }
              sum={
                dashboardState.myOrders
                  .filter((order) => order.ad === orderAd.ad)
                  .reduce((acc, current) => acc + current.payment.amount, 0)
                  .toFixed(2) || 0
              }
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
