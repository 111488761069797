import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { useField } from "formik";

function RenderValue({ selectedValues, options, handleDelete }) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {options
        .filter((option) => selectedValues.includes(option.value.toString()))
        .map((option) => (
          <Chip
            sx={{
              m: 0.5,
              borderRadius: "3px",
              height: "100%",
              py: 0.45,
            }}
            size="medium"
            key={option.value}
            label={
              <Typography
                variant="body2"
                sx={{
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                }}
              >
                {option.secondary_label || option.label}
              </Typography>
            }
            variant="outlined"
            color="primary"
            onDelete={() => {}} // workaround to ensure blur on delete
            deleteIcon={
              <CancelIcon
                onMouseDown={(event) => {
                  event.stopPropagation();
                  handleDelete(event, option);
                }}
              />
            }
          />
        ))}
    </Box>
  );
}

export default function FormikBasicMultipleSelect({
  name,
  label,
  options,
  onChange,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  const errorText = meta.touched && meta.error;

  const [selectedValues, setSelectedValues] = useState(
    !!field.value ? field.value.split(",") : []
  );

  useEffect(() => {
    setSelectedValues(!!field.value ? field.value.split(",") : []);
  }, [field.value]);

  function handleChange(e) {
    const selectedValues = e.target.value;
    setSelectedValues(selectedValues);
    helpers.setValue(selectedValues.toString());
    if (!!onChange) {
      onChange(selectedValues);
    }
  }

  function handleDelete(e, optionToDelete) {
    const filteredValues = selectedValues.filter(
      (value) => value.toString() !== optionToDelete.value.toString()
    );
    setSelectedValues(filteredValues);
    helpers.setValue(filteredValues.toString());
    if (!!onChange) {
      onChange(filteredValues);
    }
  }

  function handleBlur(e) {
    field.onBlur(e);
    helpers.setTouched(true);
  }

  return (
    <FormControl
      fullWidth
      error={!!errorText}
      size={field.value.length ? "small" : "medium"}
      sx={{ mt: 1 }}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        name={name}
        value={selectedValues}
        onChange={handleChange}
        onBlur={handleBlur}
        label={label}
        renderValue={(selectedValues) => (
          <RenderValue
            selectedValues={selectedValues}
            options={options}
            handleDelete={handleDelete}
          />
        )}
        MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
        {...props}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={option.value}
              // sx={{ height: "2.5rem" }}
              sx={{
                minHeight: "2.5rem",
                whiteSpace: "unset",
                wordBreak: "break-word",
              }}
            >
              <Checkbox
                sx={{ border: "none", m: 0 }}
                size="small"
                checked={selectedValues.includes(option.value)}
              />
              <ListItemText
                primary={option.label}
                secondary={option.secondary_label}
              />
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}
