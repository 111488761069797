import * as utils from "./utils";

function useDescription(ad) {
  const flat = ["Parduodamas butas", "Nuomojamas butas"].includes(
    ad.properties.ad_type
  ) && {
    buildingDescription: utils.getBuildingDescription(ad),
    renovation: utils.getRenovation(ad),
    heating: utils.getHeating(ad),
    energyEfficiency: utils.getEnergyEfficiency(ad),
    heatingBill: utils.getHeatingBill(ad),
    flatDescription: utils.getflatDescription(ad),
    installation: utils.getInstallation(ad),
    flatRenovation: utils.getFlatRenovation(ad),
    equipment: utils.getEquipment(ad),
    premises: utils.getPremises(ad),
    otherFeatures: utils.getOtherFeatures(ad),
    partialAmenities: utils.getPartialAmenities(ad),
    parking: utils.getParking(ad),
    security: utils.getSecurity(ad),
    recreation: utils.getRecreation(ad),
    auction: utils.getAuction(ad),
    exchange: utils.getExchange(ad),

    preferences: utils.getPreferences(ad),
    nonnegotiable: utils.getNonnegotiable(ad),
    noIntermediaries: utils.getNoIntermediaries(ad),
    allowedTenants: utils.getAllowedTenants(ad),
    minRentPeriod: utils.getMinRentPeriod(ad),
    preferLongerContracts: utils.getPreferLongerContracts(ad),
    depositRequired: utils.getDepositRequired(ad),
    formalContract: utils.getFormalContract(ad),
    noRentAgency: utils.getNoRentAgency(ad),
  };

  const house = ["Parduodamas namas", "Nuomojamas namas"].includes(
    ad.properties.ad_type
  ) && {
    houseDescription: utils.getHouseDescription(ad),
    buildingDescription: utils.getBuildingDescription(ad),
    houseRenovation: utils.getHouseRenovation(ad),
    heating: utils.getHeating(ad),
    heatingBill: utils.getHeatingBill(ad),
    energyEfficiency: utils.getEnergyEfficiency(ad),
    installation: utils.getInstallation(ad),
    equipment: utils.getEquipment(ad),
    premises: utils.getPremises(ad),
    partialAmenities: utils.getPartialAmenities(ad),
    plot: utils.getPlot(ad),
    plotCommunications: utils.getPlotCommunications(ad),
    plotInfrastructure: utils.getPlotInfrastructure(ad),
    waterSewage: utils.getWaterSewage(ad),
    recreation: utils.getRecreation(ad),
    security: utils.getSecurity(ad),
    parking: utils.getParking(ad),
    auction: utils.getAuction(ad),
    exchange: utils.getExchange(ad),

    preferences: utils.getPreferences(ad),
    nonnegotiable: utils.getNonnegotiable(ad),
    noIntermediaries: utils.getNoIntermediaries(ad),
    minRentPeriod: utils.getMinRentPeriod(ad),
    preferLongerContracts: utils.getPreferLongerContracts(ad),
    allowedTenants: utils.getAllowedTenants(ad),
    depositRequired: utils.getDepositRequired(ad),
    formalContract: utils.getFormalContract(ad),
    noRentAgency: utils.getNoRentAgency(ad),
  };

  const land = ["Parduodamas sklypas", "Nuomojamas sklypas"].includes(
    ad.properties.ad_type
  ) && {
    plot: utils.getPlot(ad),
    plotPurpose: utils.getPlotPurpose(ad),
    landPlotCommunications: utils.getLandPlotCommunications(ad),
    plotInfrastructure: utils.getPlotInfrastructure(ad),
    waterSewage: utils.getWaterSewage(ad),
    recreation: utils.getRecreation(ad),
    waterBodies: utils.getWaterBodies(ad),
    forestType: utils.getForestType(ad),
    forestGroup: utils.getForestGroup(ad),
    isLoggingForest: utils.getIsLoggingForest(ad),
    fertilityScore: utils.getFertilityScore(ad),

    auction: utils.getAuction(ad),
    exchange: utils.getExchange(ad),
    preferences: utils.getPreferences(ad),
    nonnegotiable: utils.getNonnegotiable(ad),
    noIntermediaries: utils.getNoIntermediaries(ad),
    allowedTenants: utils.getAllowedTenants(ad),
    minRentPeriod: utils.getMinRentPeriod(ad),
    preferLongerContracts: utils.getPreferLongerContracts(ad),
    depositRequired: utils.getDepositRequired(ad),
    formalContract: utils.getFormalContract(ad),
    noRentAgency: utils.getNoRentAgency(ad),
  };

  const premises = ["Parduodamos patalpos", "Nuomojamos patalpos"].includes(
    ad.properties.ad_type
  ) && {
    buildingDescription: utils.getBuildingDescriptionPremises(ad),
    heating: utils.getHeating(ad),
    energyEfficiency: utils.getEnergyEfficiency(ad),
    heatingBill: utils.getHeatingCost(ad),
    premisesPurpose: utils.getPremisesPurpose(ad),
    installation: utils.getInstallationPremises(ad),
    equipment: utils.getEquipment(ad),
    premises: utils.getPremises(ad),
    otherFeatures: utils.getOtherFeatures(ad),
    plotArea: utils.getPlotAreaPremises(ad),
    parking: utils.getParking(ad),
    security: utils.getSecurity(ad),
    infrastructure: utils.getInfrastructurePremises(ad),
    minRentPeriod: utils.getMinRentPeriod(ad),
  };

  const parking = [
    "Parduodamas garažas",
    "Parduodama parkavimo vieta",
    "Nuomojamas garažas",
    "Nuomojama parkavimo vieta",
  ].includes(ad.properties.ad_type) && {
    parkingDescription: utils.getParkingDescription(ad),
    parkingType: utils.getParkingType(ad),
    parkingRoof: utils.getParkingRoof(ad),
    parkingInstallation: utils.getParkingInstallation(ad),
    electricalImprovements: utils.getElectricalImprovements(ad),
    security: utils.getSecurity(ad),
    minRentPeriod: utils.getMinRentPeriod(ad),
  };

  const complex = ["Naujas daugiabučių projektas"].includes(
    ad.properties.ad_type
  ) && {
    apartmentComplexDescription: utils.getApartmentComplexDescription(ad),
    completionDate: utils.getCompletionDate(ad),
    buildingType: utils.getBuildingType(ad),
    heating: utils.getHeating(ad),
    energyEfficiency: utils.getEnergyEfficiency(ad),
    roomsRange: utils.getRoomsRange(ad),
    areaRange: utils.getAreaRange(ad),
    priceRange: utils.getPriceRange(ad),
    installationComplex: utils.getInstallationComplex(ad),
  };

  return { flat, house, land, premises, parking, complex };
}

export default useDescription;
