import { InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import FormikTextField from "../../SharedComponents/FormikTextField";
import { useGlobalContext } from "../../../context";
import { Formik } from "formik";
import * as Yup from "yup";
// import FormPreview from "../../AdForm/utils/FormPreview";
import { Finance } from "financejs";
import { NPER, PV } from "../../../utils/financialFunctions";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

export function getMonthlyPaymentOrDuration({
  loanState,
  loanSize,
  interestRate,
}) {
  const finance = new Finance();
  let monthlyPayment = loanState.monthlyPayment;
  let maxDuration = loanState.maxDuration;
  let duration = loanState.duration;
  interestRate = interestRate || loanState.interestRate;
  if (loanState.fixMonthlyPayment) {
    const maxLoanSize = PV(
      interestRate / 12 / 100,
      maxDuration * 12,
      -monthlyPayment,
      0,
      0
    );
    loanSize = Math.min(loanSize, maxLoanSize);
    duration = Math.min(
      maxDuration,
      NPER(interestRate / 12 / 100, -monthlyPayment, loanSize, 0, 0) / 12
    );
  } else {
    monthlyPayment = Math.round(
      finance.PMT(interestRate / 100 / 12, duration * 12, -loanSize)
    );
  }
  return { monthlyPayment, duration, loanSize };
}

function Price({ ad }) {
  const { loanState, dispatchLoan } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();

  const price = ad.properties.price;
  const downpayment = (price * loanState.downPercent) / 100;
  const interestRate = loanState.interestRate;
  const loanSize = price - downpayment;
  const { monthlyPayment, duration } = getMonthlyPaymentOrDuration({
    loanState,
    interestRate,
    loanSize,
  });

  const initialValues = { price: ad.properties.price };
  const validationSchema = Yup.object({
    price: Yup.number()
      .positive("Turi būti teigiamas skaičius")
      .required("Turi būti skaičius"),
  });

  useEffect(() => {
    dispatchLoan({
      type: "CHANGE_LOAN_STATE",
      payload: {
        price,
        downpayment,
        interestRate,
        loanSize,
        monthlyPayment,
        duration,
        fixMonthlyPayment: false,
      },
    });
  }, [ad]);

  function handleSubmit(formikValues, formik) {
    const price = +formikValues.price;
    const downpayment = (price * loanState.downPercent) / 100;
    const interestRate = loanState.interestRate;
    const loanSize = price - downpayment;
    const { monthlyPayment, duration } = getMonthlyPaymentOrDuration({
      loanState,
      interestRate,
      loanSize,
    });
    formik.validateForm().then((errors) => {
      if (Object.keys(errors).length === 0) {
        dispatchLoan({
          type: "CHANGE_LOAN_STATE",
          payload: { price, downpayment, loanSize, monthlyPayment, duration },
        });
      }
    });
  }

  console.log("loanState", loanState);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {(formik) => (
        <>
          <FormikTextField
            sx={{
              width: isMobile ? "100%" : "14ch",
            }}
            autoComplete="off"
            size="small"
            name="price"
            label="Būsto kaina"
            onChange={() => formik.handleSubmit()}
            type="number"
            InputProps={{
              sx: { fontSize: "14px" },
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputProps: { min: 0, step: 500 },
            }}
          />
          {/* <FormPreview /> */}
        </>
      )}
    </Formik>
  );
}

export default Price;
