import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { useGlobalContext } from "../../../../context";

export default function FlatStatsApartmentComplex() {
  const formik = useFormikContext();
  const { adFormState } = useGlobalContext();

  const selectedFlatIds = formik.values.flats.map((flat) => flat.flats) || [];

  const selectedFlats =
    adFormState?.flatOptions?.filter((flat) =>
      selectedFlatIds.includes(flat.value)
    ) || [];

  const rooms = selectedFlats.map(
    (flat) => +flat?.secondary_label?.split(",")[1].replace("k.", "")
  );
  const roomsFrom = Math.min(...rooms);
  const roomsTo = Math.max(...rooms);
  const roomsStr =
    roomsFrom === roomsTo ? roomsFrom : `${roomsFrom}-${roomsTo}`;

  const areas = selectedFlats.map(
    (flat) => +flat?.secondary_label?.split(",")[2].replace("m²", "")
  );
  const areaFrom = Math.min(...areas);
  const areaTo = Math.max(...areas);
  const areaStr = areaFrom === areaTo ? areaFrom : `${areaFrom}-${areaTo}`;

  const prices = selectedFlats.map(
    (flat) =>
      +flat?.secondary_label
        ?.split(",")[4]
        .trim()
        .split(" ")[0]
        .replace("€", "")
  );
  const priceFrom = Math.min(...prices);
  const priceTo = Math.max(...prices);
  const priceStr =
    priceFrom === priceTo ? priceFrom : `${priceFrom}-${priceTo}`;

  const pricesSqm = selectedFlats.map(
    (flat) =>
      +flat?.secondary_label
        ?.split(",")[4]
        .trim()
        .split(" ")[1]
        .replace("€/m²", "")
        .replace("(", "")
        .replace(")", "")
  );
  const priceSqmFrom = Math.min(...pricesSqm);
  const priceSqmTo = Math.max(...pricesSqm);
  const priceSqmStr =
    priceSqmFrom === priceSqmTo
      ? priceSqmFrom
      : `${priceSqmFrom}-${priceSqmTo}`;

  useEffect(() => {
    formik.setFieldValue("rooms_from", roomsFrom);
    formik.setFieldValue("rooms_to", roomsTo);
    formik.setFieldValue("area_from", areaFrom);
    formik.setFieldValue("area_to", areaTo);
    formik.setFieldValue("price_from", priceFrom);
    formik.setFieldValue("price_to", priceTo);
    formik.setFieldValue("price_sqm_from", priceSqmFrom);
    formik.setFieldValue("price_sqm_to", priceSqmTo);
  }, [formik.values.flats.toString()]);

  return (
    !!selectedFlatIds.length && (
      <>
        <Typography variant="body1" sx={{ ml: 2, mb: 1 }}>
          Kambarių skaičius: {roomsStr} kamb.
        </Typography>
        <Typography variant="body1" sx={{ ml: 2, my: 1 }}>
          Butų plotai: {areaStr} m²
        </Typography>
        <Typography variant="body1" sx={{ ml: 2, my: 1 }}>
          Butų kainos: {priceStr} €
        </Typography>
        <Typography variant="body1" sx={{ ml: 2, my: 1 }}>
          Kaina už m²: {priceSqmStr} €/m²
        </Typography>
      </>
    )
  );
}
