import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import {
  BUILDING_TYPE_CHOICES,
  ENERGY_EFFICIENCY_CHOICES,
  HEATING_CHOICES,
  QUARTER_CHOICES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function BuildingPanelApartmentComplex({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "buildingPanelApartmentComplex";
  const initialValues =
    (!edit && adFormState.formik[panelName]) ||
    init.getBuildingApartmentComplexInitialValues(ad, adOptions);
  const validationSchema = schema.buildingApartmentComplexValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  // useEffect(() => {
  //   if (+formik.values.number_of_buildings === 1) {
  //     formik.setFieldValue("floors_from", null);
  //     formik.setFieldValue("floors_to", null);
  //     formik.setFieldTouched("floors_from", false);
  //     formik.setFieldTouched("floors_to", false);
  //   } else if (+formik.values.number_of_buildings > 1) {
  //     formik.setFieldValue("floors", null);
  //     formik.setFieldTouched("floors", false);
  //   }
  // }, [formik.values.number_of_buildings]);

  function handleNumberOfBuildingsChange(value, formik) {
    if (+value === 1) {
      formik.setFieldValue("floors_from", null);
      formik.setFieldValue("floors_to", null);
      formik.setFieldTouched("floors_from", false);
      formik.setFieldTouched("floors_to", false);
    } else if (+value > 1) {
      formik.setFieldValue("floors", null);
      formik.setFieldTouched("floors", false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField
                name="company_name"
                label="Projekto vystytojas *"
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField
                name="project_name"
                label="Projekto pavadinimas"
              />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="number_of_buildings"
                label="Pastatų skaičius *"
                type="number"
                onChange={(value) =>
                  handleNumberOfBuildingsChange(value, formik)
                }
              />
            </Grid>
            {(!formik.values.number_of_buildings ||
              +formik.values.number_of_buildings === 1) && (
              <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                <FormikTextField
                  formik={formik}
                  name="floors"
                  label="Aukštų skaičius *"
                  type="number"
                  inputProps={{
                    min: 0,
                    max: 30,
                    step: 1,
                  }}
                />
              </Grid>
            )}
            {+formik.values.number_of_buildings > 1 && (
              <Grid container item spacing={1} xs={12} sm={6}>
                <Grid item xs={6} sx={{ my: 1 }}>
                  <FormikTextField
                    name="floors_from"
                    label="Aukštų skaičius nuo *"
                    type="number"
                  />
                </Grid>
                <Grid item xs={6} sx={{ my: 1 }}>
                  <FormikTextField
                    name="floors_to"
                    label="Aukštų skaičius iki *"
                    type="number"
                  />
                </Grid>
              </Grid>
            )}
            <Grid item container xs={12} sm={6} spacing={1}>
              <Grid item xs={6} sm={6} sx={{ my: 1 }}>
                <FormikTextField
                  name="construction_year"
                  label="Statybos pabaigos metai *"
                  type="number"
                  inputProps={{
                    min: 1900,
                    max: 2025,
                    step: 1,
                  }}
                />
              </Grid>
              <Grid item xs={6} sm={6} sx={{ my: 1 }}>
                <FormikSelect
                  name="quarter"
                  label="Ketvirtis *"
                  options={QUARTER_CHOICES}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ border: "none", my: 1 }}>
              <FormikSelect
                name="building_type"
                label="Pastato konstrukcija *"
                options={BUILDING_TYPE_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikSelect
                name="energy_efficiency"
                label="Energetinis efektyvumas *"
                options={ENERGY_EFFICIENCY_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="heating"
                label="Šildymas *"
                options={HEATING_CHOICES}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
