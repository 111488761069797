import React, { useCallback, useState } from "react";
import { Paper } from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import useCurrentWidth from "../../../utils/useCurrentWidth";
import PhotoUploader from "./PhotoUploader";
import PhotoViewer from "./PhotoViewer";
import PhotoEditor from "./PhotoEditor";

function ProfilePhoto({ ad, logo, edit }) {
  const { userState } = useGlobalContext();
  let windowWidth = useCurrentWidth();

  const [photoContainerWidth, setPhotoContainerWidth] = useState(null);
  const [editProfilePhoto, setEditProfilePhoto] = useState(false);

  // get width of photo container
  const photoContainerRef = useCallback(
    (node) => {
      if (node !== null) {
        setPhotoContainerWidth(
          Math.round(node.getBoundingClientRect().width) - 1.5
        );
      }
    },
    [windowWidth]
  );

  console.log("ad from ProfilePhoto", ad);

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          width: "100%",
          px: 4,
          py: 1,
          border: "solid 1px lightgrey",
          borderRadius: "5px",
        }}
      >
        <Box
          ref={photoContainerRef}
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            border: "none",
          }}
        >
          {!logo && !userState.currentUser?.photo && (
            <PhotoUploader
              ad={ad}
              photoContainerWidth={photoContainerWidth}
              logo={logo}
              edit={edit}
            />
          )}
          {!logo && !!userState.currentUser?.photo && !editProfilePhoto && (
            <PhotoViewer
              setEditProfilePhoto={setEditProfilePhoto}
              photoContainerWidth={photoContainerWidth}
              logo={logo}
              edit={edit}
            />
          )}
          {!logo && editProfilePhoto && (
            <PhotoEditor
              setEditProfilePhoto={setEditProfilePhoto}
              photoContainerWidth={photoContainerWidth}
              logo={logo}
              edit={edit}
            />
          )}
          {logo && !ad?.properties?.company_logo && (
            <PhotoUploader
              ad={ad}
              photoContainerWidth={photoContainerWidth}
              logo={logo}
              edit={edit}
            />
          )}
          {logo && !!ad?.properties?.company_logo && !editProfilePhoto && (
            <PhotoViewer
              setEditProfilePhoto={setEditProfilePhoto}
              photoContainerWidth={photoContainerWidth}
              logo={logo}
              edit={edit}
            />
          )}
          {logo && editProfilePhoto && (
            <PhotoEditor
              setEditProfilePhoto={setEditProfilePhoto}
              photoContainerWidth={photoContainerWidth}
              logo={logo}
              edit={edit}
            />
          )}
        </Box>
      </Paper>
    </>
  );
}

export default ProfilePhoto;
