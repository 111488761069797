import React from "react";
import { InputAdornment } from "@mui/material";
import { NPER, PV } from "../../../utils/financialFunctions";
import FormikTextField from "../../SharedComponents/FormikTextField";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGlobalContext } from "../../../context";
// import FormPreview from "../../AdForm/utils/FormPreview";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { Finance } from "financejs";

function InterestRate() {
  const { loanState, dispatchLoan } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const finance = new Finance();

  const initialValues = {
    interestRate: loanState.interestRate,
  };
  const validationSchema = Yup.object({
    interestRate: Yup.number()
      .positive("Turi būti teigiamas skaičius")
      .required("Turi būti skaičius")
      .max(10, "Įveskite skaičių tarp 0 ir 10 proc."),
    // .min(0, "Įveskite skaičių tarp 0 ir 10 proc."),
  });

  function handleSubmit(formikValues) {
    let interestRate = !!formikValues.interestRate
      ? +formikValues.interestRate
      : "";
    // if monthly payment not fixed, monthly payment changes as interest rate changes
    if (!loanState.fixMonthlyPayment) {
      let monthlyPayment = Math.round(
        finance.PMT(
          interestRate / 100 / 12,
          loanState.duration * 12,
          -loanState.loanSize
        )
      );
      dispatchLoan({
        type: "CHANGE_LOAN_STATE",
        payload: {
          interestRate,
          monthlyPayment,
        },
      });
    }

    // if monthly payment is fixed, duration and eventually loan size changes as interest rate changes
    if (!!loanState.fixMonthlyPayment) {
      const maxLoanSize = PV(
        interestRate / 12 / 100,
        loanState.maxDuration * 12,
        -loanState.monthlyPayment,
        0,
        0
      );
      let loanSize = Math.min(loanState.loanSize, maxLoanSize);
      let duration = Math.min(
        loanState.maxDuration,
        NPER(
          interestRate / 12 / 100,
          -loanState.monthlyPayment,
          loanSize,
          0,
          0
        ) / 12
      );
      if (Math.round(duration) >= Math.round(loanState.maxDuration)) {
        duration = loanState.maxDuration;
        loanSize = PV(
          interestRate / 12 / 100,
          duration * 12,
          -loanState.monthlyPayment,
          0,
          0
        );
      }
      let downpayment = loanState.price - loanSize;
      let downPercent = (1 - loanSize / loanState.price) * 100;
      dispatchLoan({
        type: "CHANGE_LOAN_STATE",
        payload: {
          interestRate,
          duration,
          loanSize,
          downpayment,
          downPercent,
        },
      });
    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => (
        <>
          <FormikTextField
            sx={{
              width: isMobile ? "100%" : "13ch",
            }}
            autoComplete="off"
            name="interestRate"
            label="Palūkanų norma"
            size="small"
            onChange={async (newValue) => {
              new formik.setFieldValue(
                "interestRate",
                !!newValue ? +newValue : "",
                false
              );
              await formik.validateField("interestRate");
              console.log(
                "interestRate, formikValue",
                newValue,
                formik.values.interestRate
              );
              formik.submitForm();
            }}
            type="number"
            InputProps={{
              sx: { fontSize: "14px" },
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: {
                min: 0,
                max: 10,
                step: 0.1,
              },
            }}
          />
        </>
      )}
    </Formik>
  );
}

export default InterestRate;
