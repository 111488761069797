import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { registerUser } from "./fetchOrSendAccountsData";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Box, Divider, List, ListItem, Paper, Stack } from "@mui/material";
import FormikTextField from "../SharedComponents/FormikTextField";
import FormikPasswordField from "../SharedComponents/FormikPasswordField";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "./GoogleLoginButton";
import FacebookLoginButton from "./FacebookLoginButton";
import { grey } from "@mui/material/colors";
import { Link as RouterLink } from "react-router-dom";
import Footer from "../Layout/Footer/Footer";
import { useGlobalContext } from "../../context";
// import FormPreview from "../AdForm/utils/FormPreview";

const initialValues = Object.freeze({
  email: "",
  username: "",
  password: "",
  re_password: "",
});

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Turi būti tinkamas el. pašto adresas")
    .required("Privalomas laukas"),
  username: Yup.string()
    .required("Privalomas laukas")
    .max(30, "Maks. 30 simbolių"),
  password: Yup.string()
    .required("Privalomas laukas")
    .min(8, "Slaptažodį turi sudaryti bent 8 simboliai")
    .matches(/[a-z]/, "Slaptažodis turi turėti bent vieną mažąją raidę")
    .matches(/[A-Z]/, "Slaptažodis turi turėti bent vieną didžiąją raidę")
    .matches(/[0-9]/, "Slaptažodis turi turėti bent vieną skaitmenį"),
  re_password: Yup.string()
    .required("Privalomas laukas")
    .oneOf([Yup.ref("password")], "Slaptažodžiai turi sutapti"),
});

export default function Register() {
  const [activationLinkSent, setActivationLinkSent] = useState(false);
  const { dispatchRouter } = useGlobalContext();

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "register" },
    });
  }, []);

  async function handleSubmit(formikValues, formik) {
    if (!formik.isValid) return;
    try {
      formikValues = {
        ...formikValues,
        email: formikValues.email.toLowerCase(),
      };
      await registerUser(formikValues);
      setActivationLinkSent(true);
    } catch (error) {
      if (error?.response?.status === 400) {
        formik.setErrors({
          email: "Toks el. paštas jau užregistruotas",
        });
      }
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: grey[300],
        border: "none",
        pt: 13,
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          border: "none",
          alignItems: "center",
          justifyContent: "center",
          p: 0.5,
        }}
        disableGutters
      >
        {!activationLinkSent ? (
          <Paper
            elevation={1}
            sx={{
              width: "100%",
              height: "100%",
              p: 1,
              mb: 7,
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              // onSubmit={handleSubmit}
              // onSubmit={(values, formikHelpers, errors) =>
              //   handleSubmit(values, formikHelpers, errors)
              // }
              enableReinitialize={true}
            >
              {(formik) => (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(formik.values, formik);
                  }}
                  noValidate
                  sx={{ width: "100%" }}
                >
                  <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ my: 2 }}
                  >
                    <Avatar sx={{ width: 70, height: 70 }} />
                    <Typography variant="h6" color="grey">
                      Sukurkite paskyrą
                    </Typography>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                      <Grid item xs={12}>
                        <GoogleOAuthProvider
                          clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                        >
                          <GoogleLoginButton />
                        </GoogleOAuthProvider>
                      </Grid>
                      <Grid item xs={12}>
                        <FacebookLoginButton />
                      </Grid>
                    </Grid>

                    <Box sx={{ width: "100%", mt: 1 }}>
                      <Divider sx={{ my: 2 }}>
                        <Typography variant="subtitle2">
                          Arba sukurkite paskyrą tiesiogiai
                        </Typography>
                      </Divider>
                    </Box>

                    <Stack
                      spacing={2}
                      sx={{
                        display: "flex",
                        justifyContent: "left",
                        my: 1,
                        width: "100%",
                      }}
                    >
                      <FormikTextField
                        name="email"
                        label="El. paštas *"
                        size="small"
                      />
                      <FormikTextField
                        name="username"
                        label="Vartotojo vardas *"
                        inputProps={{ maxLength: 30 }}
                        size="small"
                      />

                      <FormikPasswordField
                        name="password"
                        label="Slaptažodis *"
                        helperText={null}
                        size="small"
                      />
                      <List dense sx={{ border: "none", p: 0 }}>
                        <ListItem>
                          <Typography
                            variant="caption"
                            color={
                              formik.errors.password ===
                                "Slaptažodį turi sudaryti bent 8 simboliai" &&
                              "error"
                            }
                          >
                            Slaptažodį turi sudaryti bent 8 simboliai
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography
                            variant="caption"
                            color={
                              formik.errors.password ===
                                "Slaptažodis turi turėti bent vieną mažąją raidę" &&
                              "error"
                            }
                          >
                            Slaptažodis turi turėti bent vieną mažąją raidę
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography
                            variant="caption"
                            color={
                              formik.errors.password ===
                                "Slaptažodis turi turėti bent vieną didžiąją raidę" &&
                              "error"
                            }
                          >
                            Slaptažodis turi turėti bent vieną didžiąją raidę
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography
                            variant="caption"
                            color={
                              formik.errors.password ===
                                "Slaptažodis turi turėti bent vieną skaitmenį" &&
                              "error"
                            }
                          >
                            Slaptažodis turi turėti bent vieną skaitmenį
                          </Typography>
                        </ListItem>
                      </List>

                      <FormikPasswordField
                        name="re_password"
                        label="Pakartokite slaptažodį *"
                        size="small"
                      />
                    </Stack>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ mt: 2 }}
                    >
                      Sukurti paskyrą
                    </Button>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      spacing={1}
                      xs={12}
                      sx={{ mt: 1 }}
                    >
                      <Grid item>
                        <Typography variant="subtitle2">
                          Jau turite paskyrą?
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Link
                          component={RouterLink}
                          to={"/login"}
                          variant="subtitle2"
                        >
                          Prisijunkite čia
                        </Link>
                      </Grid>
                    </Grid>
                  </Stack>
                  {/* <FormPreview /> */}
                </Form>
              )}
            </Formik>
          </Paper>
        ) : (
          <Paper sx={{ p: 1 }}>
            <Stack justifyContent="center" alignItems="center" sx={{ my: 2 }}>
              <Avatar sx={{ width: 70, height: 70 }} />
              <Typography variant="h6">Išsiųsta aktyvavimo nuoroda</Typography>
              <Typography variant="body1" sx={{ mt: 4 }}>
                Jūsų nurodytu el. pašto adresu išsiųsta paskyros aktyvavimo
                nuoroda. Patikrinkite savo el. paštą.
              </Typography>
            </Stack>
          </Paper>
        )}
      </Container>

      <Footer />
    </Box>
  );
}
