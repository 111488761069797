import { useState, useEffect, useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useGlobalContext } from "../context";
import { debounceSubsequent } from "../utils/debounce";
import { fetchLikedAdIds } from "../components/MainPage/fetchOrSendMainAppData";

export default function useHandleLikeClick(adId) {
  const { state, userState, dispatch, dispatchUser, dispatchDashboard } =
    useGlobalContext();
  const isAuthenticated = userState.isAuthenticated;
  const history = useHistory();
  const location = useLocation();
  const [userLikes, setUserLikes] = useState(false);

  useEffect(() => {
    setUserLikes(!!state?.likedAds?.includes(adId));
  }, [state.likedAds, adId]);

  const debouncedFunction = debounceSubsequent((ad_id) => {
    console.log("timer debounced ad_id", ad_id);
    if (isAuthenticated) {
      setUserLikes((userLikes) => !userLikes);
      fetchLikedAdIds({ ad_id }, dispatch);
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: { myFavoritesLoaded: false },
      });
    } else {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { openLoginDialog: true },
      });

      // history.push({
      //   pathname: "/login",
      //   state: { from: location.pathname + location.search },
      // });
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleLikeClick = useCallback(debouncedFunction, [
    isAuthenticated,
    dispatch,
    history,
    location,
  ]);

  return { userLikes, handleLikeClick };
}
