import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../../context";
import { useHistory } from "react-router";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { crudSavedSearch } from "../fetchOrSendDashboardData";
import SearchNameEditor from "./SearchNameEditor";
import reloadAds from "../../../utils/getUrlFromParams";

function MySearch({ search }) {
  const { dashboardState, dispatch, dispatchDashboard, notificationState } =
    useGlobalContext();

  let history = useHistory();
  let { isMobile } = useCustomMediaQuery();
  const [editMode, setEditMode] = useState(false);
  const [searchName, setSearchName] = useState(null);
  const [checked, setChecked] = useState(search.notify);

  const searchNames = dashboardState.mySearches
    .map((search) => search.name)
    .toString();

  useEffect(() => {
    setSearchName(null);
  }, [searchNames]);

  useEffect(() => {
    setChecked(search.notify);
  }, [search.notify]);

  function handleImplementSearch() {
    const savedSearch = { ...search, run_search: true };
    crudSavedSearch(dispatchDashboard, "patch", savedSearch);
    const params = JSON.parse(search.filter_params);
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: {
        townOptions: [],
        townOptionsLoaded: false,
        suburbOptions: [],
        suburbOptionsLoaded: false,
        streetOptions: [],
        streetOptionsLoaded: false,
      },
    });
    reloadAds(params, dispatch, history);
  }

  function handleDeleteSearch() {
    crudSavedSearch(dispatchDashboard, "delete", search);
  }

  function handleEditName() {
    setEditMode(!editMode);
  }

  function handleCheckBoxChange(e) {
    let notify = e.target.checked;
    setChecked(notify);
    const savedSearch = { ...search, notify };
    crudSavedSearch(dispatchDashboard, "patch", savedSearch);
  }

  if (search.id === 134) {
    console.log("search from MySearch", [search.name, search.notify]);
  }

  return (
    <>
      <Paper sx={{ my: 0.5, mx: 0.5, p: 0.5 }}>
        <Grid
          container
          xs={12}
          sx={{
            border: "none",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            pl: 1,
            flexWrap: "nowrap",
          }}
        >
          {editMode ? (
            <SearchNameEditor
              search={search}
              setEditMode={setEditMode}
              setSearchName={setSearchName}
            />
          ) : (
            <Typography variant="body2" sx={{ border: "none" }}>
              {searchName || search.name}
            </Typography>
          )}
          <Stack direction="row" sx={{ alignItems: "center", display: "flex" }}>
            <IconButton
              fontSize="small"
              // sx={{ ml: 1, border: "groove" }}
              onClick={handleEditName}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            {!!notificationState?.searchNotificationSettings
              ?.send_notifications && (
              <Tooltip title={isMobile ? "Gauti pranešimus" : ""}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label={
                    !isMobile && (
                      <Typography variant="body2">Gauti pranešimus</Typography>
                    )
                  }
                  labelPlacement="start"
                  sx={{ border: "none", mr: 0, ml: 1 }}
                />
              </Tooltip>
            )}
            <Button
              // variant="outlined"
              onClick={handleImplementSearch}
              sx={{
                color: !!search.unseen_results_count
                  ? "primary.main"
                  : "text.primary",
                borderColor: !!search.unseen_results_count
                  ? "primary.main"
                  : "text.primary",
                // mx: 1,
                border: "none",
              }}
            >
              <Typography variant="body2">
                Nauji{!isMobile && " skelbimai"}:
              </Typography>
              <Typography variant="body2" sx={{ ml: 1 }}>
                {search.unseen_results_count}
              </Typography>
            </Button>
            <IconButton size="small" onClick={handleDeleteSearch}>
              <CancelIcon fontSize="medium" />
            </IconButton>
          </Stack>
        </Grid>
      </Paper>
    </>
  );
}

export default MySearch;
