import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useGlobalContext } from "../../../context";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
// import FormPreview from "../../AdForm/utils/FormPreview";
import { deleteUserAccount } from "../fetchOrSendAccountsData";
import { useHistory } from "react-router-dom";
import {
  logOutUser,
  removeTokens,
} from "../../Layout/Header/UserMenu/UserMenu";
import FormikTextField from "../../SharedComponents/FormikTextField";
import * as Yup from "yup";

export default function DeleteUserDialog({ open, setOpen }) {
  const {
    // userState,
    dispatchUser,
    dispatch,
    dispatchDashboard,
    dispatchAdForm,
    dispatchRouter,
    dispatchNotification,
    userState,
  } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const history = useHistory();

  const initialValues = {
    confirmation: "",
  };

  // Define the validation schema
  const validationSchema = Yup.object().shape({
    confirmation: Yup.string()
      .transform((value) => value.toLowerCase())
      .required("Patvirtinimas yra privalomas")
      .oneOf(
        ["patvirtinu"],
        'Įrašykite "PATVIRTINU", jei norite ištrinti skelbimą'
      ),
  });

  async function handleSubmit(values) {
    try {
      await deleteUserAccount();
      removeTokens();
      logOutUser(
        dispatchUser,
        dispatch,
        dispatchDashboard,
        dispatchAdForm,
        dispatchRouter,
        dispatchNotification,
        history
      );
      setOpen(false);
      const snackbar = userState.snackbar;
      snackbar.open = true;
      snackbar.message = "Paskyra ištrinta sėkmingai";
      snackbar.severity = "success";
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { snackbar },
      });
    } catch (error) {
      console.error("Error deleting user account:", error);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullScreen={isMobile}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>Ar tikrai norite ištrinti savo paskyrą?</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <>
            <DialogContent>
              <Typography variant="body1">
                Jūsų paskyra bus negrįžtamai ištrinta. Jūsų skelbimai taip pat
                bus iš karto ištrinti, o su paskyra susijusi informacija bus
                ištrinta laikantis portalo Privatumo politikos.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Jei norite tęsti, žemiau įrašykite žodį "PATVIRTINU".
              </Typography>

              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="confirmation"
                    label="Patvirtinimas *"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Netrinti
              </Button>
              <Button
                // type="submit"
                variant="contained"
                onClick={() => handleSubmit(formik.values)}
                disabled={
                  !formik.dirty || Object.values(formik.errors).length > 0
                }
                color="error"
              >
                Trinti paskyrą
              </Button>
            </DialogActions>
            {/* <FormPreview /> */}
          </>
        )}
      </Formik>
    </Dialog>
  );
}
