import React, { useMemo } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import MyAd from "./MyAd/MyAd";
import { useGlobalContext } from "../../../context";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import MyAdsFilterSelectBox from "./MyAdsFilterSelectBox";
import { AD_FILTER_OPTIONS } from "../../../utils/defaults";
import { blue } from "@mui/material/colors";
import Spinner from "../../../utils/Spinner";
import { useParams } from "react-router-dom";

function MyAds() {
  const { dashboardState } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const { tabName } = useParams();

  const myAds = useMemo(() => {
    const ads =
      dashboardState.myAdsFilterValue === "all"
        ? dashboardState.myAds
        : dashboardState.myAds.filter(
            (ad) => ad.properties.ad_type === dashboardState.myAdsFilterValue
          );
    ads.sort((a, b) => a.properties.price - b.properties.price);
    return ads;
  }, [dashboardState.myAds, dashboardState.myAdsFilterValue]);

  const adTypeString =
    dashboardState.myAdsFilterValue !== "all"
      ? ` (${AD_FILTER_OPTIONS.find(
          (option) => option.value === dashboardState.myAdsFilterValue
        ).label.toLowerCase()})`
      : "";

  console.log("dashboardState", dashboardState);
  console.log("MyAds render");
  console.log("tabNAme from MyAds", tabName);

  return (
    <>
      {!dashboardState.myAdsLoaded && (
        <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
          <Spinner />
        </Box>
      )}
      {tabName === "my-ads" && dashboardState.myAdsLoaded && (
        <Container maxWidth="md" disableGutters sx={{ px: 0.5 }}>
          <Paper
            elevation={1}
            sx={{
              mt: 2,
              p: 1,
              position: "relative",
              backgroundColor: blue[100],
              border: "solid 1px lightgrey",
            }}
          >
            <Box
              container
              justifyContent="space-between"
              alignItems="center"
              sx={{ display: "flex", flexWrap: "wrap" }}
            >
              {/* <Grid
                container
                item
                xs={12}
                md={7}
                alignItems="center"
                justifyContent="space-between"
              > */}
              <Typography
                variant={isMobile ? "body2" : "body1"}
                sx={{ m: 1, whiteSpace: "nowrap" }}
                // noWrap
              >
                {myAds.length
                  ? `Mano skelbimai: ${myAds.length}`
                  : `Jūs neturite skelbimų`}
              </Typography>
              {/* </Grid> */}
              {/* <Grid
                container
                item
                xs={12}
                md={5}
                justifyContent="right"
                sx={{ border: "none" }}
              > */}
              <MyAdsFilterSelectBox />
              {/* </Grid> */}
            </Box>
          </Paper>

          {!!myAds?.length && (
            <Grid xs={12} container justifyContent="center">
              {myAds.map((ad) => (
                <MyAd key={ad.id} ad={ad} />
              ))}
            </Grid>
          )}
        </Container>
      )}
    </>
  );
}

export default MyAds;
