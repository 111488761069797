import React, { useState, useEffect, useRef } from "react";
import { useGlobalContext } from "../../../context";
import Card from "@mui/material/Card";
import AdCardFormatMediumImg from "./AdCardFormats/AdCardFormatMediumImg";
import AdCardFormatSmallImg from "./AdCardFormats/AdCardFormatSmallImg";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import AdCardFormatLargeImg from "./AdCardFormats/AdCardFormatLargeImg";
import { green } from "@mui/material/colors";

const AdCard = React.memo(({ ad }) => {
  const { state, dispatch, routerState, dispatchRouter } = useGlobalContext();
  const { isMobile, isSmall, isMedium } = useCustomMediaQuery();
  const cardRef = useRef(null);
  const [raised, setRaised] = useState(false);
  const isDashboard = routerState.from === "dashboard";
  const isMainApp = routerState.from === "mainApp";
  const isAdForm = routerState.from === "createAd";
  const ownerAds = routerState.from === "ownerAds";

  useEffect(() => {
    setRaised(
      routerState.from === "mainApp" && ad.id === routerState.hoverOverAd
    );

    if (routerState.from === "mainApp" && routerState.hoverOverAd === ad.id) {
      cardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [ad.id, routerState.hoverOverAd, routerState.from]);

  function handleMouseOverAd() {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: {
        mouseOverAd: ad.id,
        hoverOverAd: null,
        markerPopupOpen: ad.id,
      },
    });
  }

  console.log(
    "mouseOverAd",
    routerState.mouseOverAd,
    "hoverOverAd",
    routerState.hoverOverAd,
    "raised",
    raised
  );

  function handleTogglePopup() {
    console.log("handleTogglePopup", isMobile, routerState.mouseOverAd, ad.id);
    if (isMobile && routerState.mouseOverAd !== ad.id) {
      handleMouseOverAd();
    }
    const markerPopupOpen =
      ad.id === routerState.markerPopupOpen ? false : ad.id;
    console.log("markerPopupOpen", markerPopupOpen);
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { markerPopupOpen },
    });
  }

  console.log("render AdCard");

  return (
    <Card
      ref={cardRef}
      onMouseEnter={() => {
        if (routerState.from === "mainApp") {
          // setRaised(true);
          handleMouseOverAd();
        }
      }}
      onClick={handleTogglePopup}
      // raised={raised}
      sx={{
        width: "100%",
        transition: "all 0.0s ease-in-out",
        backgroundColor: raised ? green[50] : "white",
        boxShadow: raised ? 5 : 1,
        "&:hover": {
          backgroundColor: routerState.from === "mainApp" && green[50],
          boxShadow: routerState.from === "mainApp" && 5,
        },
      }}
    >
      {(isDashboard || isAdForm || ownerAds) && isMobile && (
        <AdCardFormatLargeImg ad={ad} />
      )}
      {(isDashboard || isAdForm || ownerAds) && !isMobile && (
        <AdCardFormatMediumImg ad={ad} />
      )}

      {isMainApp && state.showMap && (
        <>
          {isMobile ? (
            <AdCardFormatSmallImg ad={ad} />
          ) : isSmall ? (
            <AdCardFormatMediumImg ad={ad} />
          ) : isMedium ? (
            <AdCardFormatLargeImg ad={ad} />
          ) : (
            <AdCardFormatMediumImg ad={ad} />
          )}
        </>
      )}

      {isMainApp && !state.showMap && (
        <>
          {isMobile ? (
            <AdCardFormatLargeImg ad={ad} />
          ) : isSmall ? (
            <AdCardFormatMediumImg ad={ad} />
          ) : (
            <AdCardFormatLargeImg ad={ad} />
          )}
        </>
      )}
    </Card>
  );
});

export default AdCard;
