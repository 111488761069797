import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import useFilterType from "../hooks/useFilterType";
import DrawerOrMenu from "./DrawerOrMenu";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import { useGlobalContext } from "../../../../context";
import { useHistory } from "react-router-dom";
import reloadAds from "../../../../utils/getUrlFromParams";
import { emptyFilterParams } from "../utils/utils";
import { emptyPrefParams } from "../../../../utils/defaults";

export const AD_TYPE_LABELS = [
  { value: "flat_sale", label: "Butai" },
  { value: "house_sale", label: "Namai" },
  { value: "land_sale", label: "Sklypai" },
  { value: "premises_sale", label: "Patalpos" },
  { value: "garage_sale", label: "Garažai" },
  { value: "parking_sale", label: "Parkavimo vietos" },
  { value: "flat_rent", label: "Butai" },
  { value: "house_rent", label: "Namai" },
  { value: "land_rent", label: "Sklypai" },
  { value: "premises_rent", label: "Patalpos" },
  { value: "garage_rent", label: "Garažai" },
  { value: "parking_rent", label: "Parkavimo vietos" },
  { value: "apartment_complex", label: "Nauji daugiabučiai" },
];

const tabLabels = { 0: "sale", 1: "rent" };

export default function AdTypeFilter({ anchorEl, setAnchorEl }) {
  const params = useParamsFromUrl();
  const { isSale } = useFilterType(params);
  const { dispatch } = useGlobalContext();
  const history = useHistory();

  const [tab, setTab] = useState(isSale ? 0 : 1);
  const [adType, setAdType] = useState(params.ad_type || "flat_sale");

  function handleTabChange(e, value) {
    const tabLabel = tabLabels[value];
    const filteredAdTypes = AD_TYPE_LABELS.map((item) => item.value).filter(
      (adType) => adType.includes(tabLabel)
    );
    const newAdType =
      filteredAdTypes.find((item) =>
        item.includes(adType.replace("_rent", "").replace("_sale", ""))
      ) || "flat_rent";

    setAdType(newAdType);
    setTab(value);
  }

  function handleAdTypeChange(ad_type) {
    setAnchorEl(null);
    if (typeof ad_type === "object") {
      ad_type = null;
    }
    let newAdType = ad_type || adType;
    if (newAdType !== (params.ad_type || "flat_sale")) {
      let locationParams = {
        municipality: params.municipality || "",
        town: params.town || "",
        suburb: params.suburb || "",
        street: params.street || "",
      };
      let newParams = {
        ...params,
        ...emptyFilterParams,
        ...emptyPrefParams,
        ...locationParams,
        ad_type: newAdType,
      };
      delete newParams.page;
      reloadAds(newParams, dispatch, history);
    }
  }

  return (
    <DrawerOrMenu anchorEl={anchorEl} handleAdTypeChange={handleAdTypeChange}>
      <Grid container sx={{ px: 1 }}>
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            centered
            variant="fullWidth"
          >
            <Tab label="Parduoda" />
            <Tab label="Nuomoja" />
          </Tabs>
          <Divider />
        </Box>

        <Grid container justifyContent="left" sx={{ mt: 4 }}>
          {AD_TYPE_LABELS.filter(
            (option) =>
              (tabLabels[tab] === "rent" && option.value.includes("rent")) ||
              (tabLabels[tab] === "sale" && !option.value.includes("rent"))
          ).map((option) => (
            <Grid
              key={option.value}
              container
              xs={4}
              justifyContent="center"
              sx={{ border: "none", my: 1 }}
            >
              <Button
                variant={option.value === adType ? "outlined" : "text"}
                color={option.value === adType ? "primary" : "inherit"}
                onClick={() => handleAdTypeChange(option.value)}
              >
                <Typography variant="body2">{option.label}</Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </DrawerOrMenu>
  );
}
