import React, { useState } from "react";
import Dropzone from "react-dropzone";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import "./dropzone.css";
import "react-image-crop/dist/ReactCrop.css";
import { Box } from "@mui/system";
import { blue } from "@mui/material/colors";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { useGlobalContext } from "../../../context";
import { uploadAdPhotos } from "../../Dashboard/fetchOrSendDashboardData";
// import DropZoneInfoCard from "./DropZoneInfoCard";
import DropZoneWarningCard from "./DropZoneWarningCard";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

const MAX_FILE_SIZE = 1024 * 1024 * 5;
export const MAX_FILES = 20;

function getClassName(
  className,
  isDragActive,
  isDragReject,
  isDragAccept,
  isFileTooLarge
) {
  if (isDragReject || !isDragActive) return className;
  return `${className} ${className}-active`;
}

export default function DropZoneComponent({ ad, isDraft }) {
  const { state, dispatch, dispatchDashboard, dispatchAdForm } =
    useGlobalContext();
  let existingFilesCount = ad.properties.photos?.length;
  const [rejectedFiles, setRejectedFiles] = useState([]);
  const [possibleDuplicates, setPossibleDuplicates] = useState([]);
  const { isMobile } = useCustomMediaQuery();

  function handleDrop(acceptedFiles, rejectedFiles) {
    // prevent rejection of all uploaded files if their number exceeds the limit
    console.log("rejected files from handleDrop", rejectedFiles);
    let extraFiles = rejectedFiles.filter(
      (item) => item.errors[0].code === "too-many-files"
    );
    if (!!extraFiles.length) {
      let availableSlotsCount = MAX_FILES - existingFilesCount;
      acceptedFiles = extraFiles
        .slice(0, availableSlotsCount)
        .map((item) => item.file);
      let acceptedFileNames = acceptedFiles.map((file) => file.name);
      rejectedFiles = rejectedFiles.filter(
        (item) => !acceptedFileNames.includes(item.file.name)
      );
    }

    // check for possible duplicates (with the same names as existing ones)
    let existingFilenames = ad.properties.photos.map((photo) => {
      let fullFilename = photo?.photo?.substring(
        photo?.photo?.lastIndexOf("/") + 1
      );
      let filename = fullFilename.substring(
        0,
        fullFilename.lastIndexOf("_full")
      );
      let extension = fullFilename.substring(fullFilename.lastIndexOf("."));
      return filename + extension;
    });
    let possibleDuplicates = acceptedFiles
      .filter((file) => existingFilenames.includes(file.name))
      .map((file) => file.name);

    // console.log("existingFilenames", existingFilenames);
    console.log("possibleDuplicates", possibleDuplicates);

    setPossibleDuplicates(possibleDuplicates);
    setRejectedFiles(rejectedFiles);
    console.log("acceptedFiles", acceptedFiles);
    console.log("rejectedFiles", rejectedFiles);
    let formData = new FormData();
    acceptedFiles.forEach((file, index) => {
      // console.log("file", file);
      formData.append(`filename_${index + 1}`, file);
    });
    formData.append("ad", ad.id);
    if (acceptedFiles.length) {
      uploadAdPhotos(
        formData,
        ad.id,
        !!isDraft ? dispatchAdForm : dispatchDashboard,
        dispatch
      );
    }
  }

  return (
    <>
      <Backdrop
        open={!!state.photosLoading}
        sx={{
          backgroundColor: "rgba(0, 0, 0, 0.07)",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid
        container
        alignItems="stretch"
        spacing={1}
        sx={{ display: "flex", border: "none", mt: 0 }}
      >
        <Grid item xs={12} sx={{ border: "none", mt: 1 }}>
          <Paper sx={{ width: "100%", height: "100%" }}>
            <Dropzone
              onDrop={handleDrop}
              accept="image/jpg, image/jpeg, image/png, image/bmp, image/gif, image/ppm, image/tiff"
              // accept=".jpg, .jpeg, .png, .bmp, .gif, .ppm, .tiff"
              // accept="image/*"
              minSize={0}
              maxSize={MAX_FILE_SIZE}
              maxFiles={MAX_FILES - existingFilesCount}
              multiple
              disabled={MAX_FILES === existingFilesCount}
              onDropRejected={(files) => {
                console.log("onDropRejected", files);
              }}
            >
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
                isDragAccept,
                rejectedFiles,
              }) => {
                const isFileTooLarge =
                  rejectedFiles &&
                  rejectedFiles.length > 0 &&
                  rejectedFiles[0].size > MAX_FILE_SIZE;

                return (
                  <Box
                    className={getClassName(
                      "dropzone",
                      isDragActive,
                      isDragReject,
                      isDragAccept,
                      isFileTooLarge
                    )}
                    {...getRootProps()}
                    sx={{ p: 0 }}
                  >
                    <input className="dropzone-input" {...getInputProps()} />
                    <Grid
                      container
                      // className="text-center"
                      sx={{
                        border: "none",
                        backgroundColor: blue[50],
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {isDragActive && (
                        <Grid
                          container
                          className="dropzone-content"
                          xs={12}
                          sx={{ border: "none" }}
                          justifyContent="center"
                        >
                          <Typography
                            variant="body1"
                            color="success"
                            // sx={{ color: "success" }}
                          >
                            Paleiskite nuotraukas
                          </Typography>
                        </Grid>
                      )}
                      {!isDragActive && (
                        <Stack
                          sx={{
                            border: "none",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <AddAPhotoIcon fontSize="large" color="info" />
                          {existingFilesCount !== MAX_FILES ? (
                            <>
                              <Typography variant="body1">
                                Įkelkite nuotraukas
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Spustelkite{" "}
                                {isMobile ? "" : "arba vilkite nuotraukas"} čia
                              </Typography>
                            </>
                          ) : (
                            <Typography variant="body1">
                              Įkeltas maks. nuotraukų skaičius
                            </Typography>
                          )}
                        </Stack>
                      )}
                      {/* {isDragReject && (
                        <Typography
                          variant="body1"
                          color="error"
                          className="dropzone-content"
                        >
                          Netinkamas failo formatas
                        </Typography>
                      )} */}
                      {/* {isFileTooLarge && (
                  <p className="dropzone-content">Per didelis failas</p>
                )} */}
                    </Grid>
                  </Box>
                );
              }}
            </Dropzone>
          </Paper>
        </Grid>

        {/* <Grid item xs={12} sm={6} md={4} sx={{ border: "none", mt: 1 }}>
          <DropZoneInfoCard photos={ad.properties.photos} />
        </Grid> */}
      </Grid>
      <DropZoneWarningCard
        rejectedFiles={rejectedFiles}
        setRejectedFiles={setRejectedFiles}
        possibleDuplicates={possibleDuplicates}
        setPossibleDuplicates={setPossibleDuplicates}
      />
    </>
  );
}
