import React from "react";
import { Grid, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import CancelIcon from "@mui/icons-material/Cancel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const errorObject = {
  "file-invalid-type": "Netinkamas failo formatas",
  "file-too-large": "Per didelis failas",
  "too-many-files": "Viršytas maks. nuotraukų skaičius",
};

function DropZoneWarningCard({
  rejectedFiles,
  setRejectedFiles,
  possibleDuplicates,
  setPossibleDuplicates,
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {(!!rejectedFiles.length || !!possibleDuplicates.length) && (
        <Grid
          container
          xs={12}
          // sm={6}
          // md={4}
          sx={{
            border: "none",
            display: "flex",
            mt: 2,
            pl: isMobile ? 0 : 0.5,
          }}
        >
          <Paper
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              position: "relative",
            }}
          >
            <IconButton
              size="small"
              sx={{ position: "absolute", top: 0, right: 0 }}
              onClick={() => {
                setRejectedFiles([]);
                setPossibleDuplicates([]);
              }}
            >
              <CancelIcon fontSize="small" color="action" />
            </IconButton>
            <Grid container>
              {!!rejectedFiles.length && (
                <Grid container xs={12} sm={6} sx={{ border: "none" }}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      ml: 1,
                    }}
                  >
                    <ErrorOutlineIcon fontSize="small" sx={{ color: "red" }} />
                    <Typography variant="body2" sx={{ my: 1, ml: 1 }}>
                      Atmesti failai
                    </Typography>
                  </Box>

                  <Grid container>
                    {rejectedFiles.map((file) => {
                      return (
                        <>
                          <Grid
                            container
                            xs={12}
                            sx={{
                              border: "none",
                              px: 1,
                              // lineHeight: "1rem",
                              position: "relative",
                              display: "flex",
                              flexWrap: "nowrap",
                            }}
                          >
                            <Tooltip
                              title={
                                file.file.name.length > 20 ? file.file.name : ""
                              }
                            >
                              <Typography
                                variant="caption"
                                color="red"
                                noWrap
                                sx={{
                                  border: "none",
                                  maxWidth: "20ch",
                                  mr: 1,
                                }}
                              >
                                {file.file.name}
                              </Typography>
                            </Tooltip>
                            <Typography variant="caption" noWrap>
                              {errorObject[file.errors[0].code]}
                            </Typography>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
              {!!possibleDuplicates.length && (
                <Grid container xs={12} sm={6} sx={{ border: "none" }}>
                  <Box
                    sx={{
                      border: "none",
                      width: "100%",
                      display: "flex",
                      justifyContent: "left",
                      alignItems: "center",
                      ml: 1,
                    }}
                  >
                    <ErrorOutlineIcon
                      fontSize="small"
                      sx={{ color: "orange" }}
                    />
                    <Typography variant="body2" sx={{ my: 1, ml: 1 }}>
                      Galimai besidubliuojančios nuotraukos
                    </Typography>
                  </Box>
                  <Grid container>
                    {possibleDuplicates.map((filename) => {
                      return (
                        <>
                          <Grid
                            container
                            xs={12}
                            sx={{
                              border: "none",
                              px: 1,
                              // lineHeight: "1rem",
                              position: "relative",
                              display: "flex",
                              flexWrap: "nowrap",
                            }}
                          >
                            <Typography
                              variant="caption"
                              noWrap
                              color="orange"
                              sx={{
                                border: "none",
                                // maxWidth: "20ch",
                                mr: 1,
                              }}
                            >
                              {filename}
                            </Typography>
                          </Grid>
                        </>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
      )}
    </>
  );
}

export default DropZoneWarningCard;
