export const initialNotificationState = {
  notifications: [],
  newNotification: null,
  checkNotification: null,
  notificationSocket: null,
  searchNotificationSettings: {
    send_notifications: false,
    check_intervals: "1_days",
    notification_modes: "mail",
    firebase_token: "",
  },
  searchNotificationSettingsLoaded: false,
};

export function notificationReducer(notificationState, action) {
  let notifications, notificationsToDelete;

  switch (action.type) {
    case "CHANGE_NOTIFICATION_STATE":
      let payload = action.payload;
      notificationState = { ...notificationState, ...payload };
      return notificationState;
    // case "SET_NOTIFICATIONS":
    //   let notifications = action.payload.notifications;
    //   notificationState = {
    //     ...notificationState,
    //     notifications,
    //   };
    //   return notificationState;

    // case "UPDATE_NOTIFICATION_SOCKET":
    //   let notificationSocket = action.payload.notificationSocket;
    //   notificationState = {
    //     ...notificationState,
    //     notificationSocket,
    //   };
    //   return notificationState;

    // case "UPDATE_NOTIFICATIONS":
    //   let newNotification = action.payload.newNotification;
    //   notificationState.notifications.unshift(newNotification);
    //   notificationState = {
    //     ...notificationState,
    //     newNotification,
    //   };
    //   console.log("newNotification in reducer", newNotification);
    //   alert("UPDATE_NOTIFICATIONS");
    //   return notificationState;

    case "ADD_NEW_NOTIFICATION":
      let newNotification = action.payload.newNotification;
      notifications = notificationState.notifications;
      notifications.unshift(newNotification);
      notificationState = { ...notificationState, notifications };
      return notificationState;

    case "CLEAR_NOTIFICATIONS":
      notificationsToDelete = action.payload.notifications_to_delete;
      notifications = notificationState.notifications.filter(
        (notification) => !notificationsToDelete.includes(notification.id)
      );
      notificationState = { ...notificationState, notifications };
      return notificationState;

    case "LOGOUT_USER":
      notificationState = { ...initialNotificationState };
      return notificationState;

    // case "CHANGE_SEARCH_NOTIFICATION_SETTINGS":
    //   let searchNotificationSettings =
    //     action.payload.searchNotificationSettings;
    //   notificationState = {
    //     ...notificationState,
    //     searchNotificationSettings,
    //     searchNotificationSettingsLoaded: true,
    //   };
    //   console.log("notificationState", notificationState);
    //   return notificationState;
    default:
      throw new Error(`Unknown router action type: ${action.type}`);
  }
}
