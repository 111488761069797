import React, { useState } from "react";
import axiosInstance from "../../utils/axios";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const initialCheckBoxState = {
  downloadAddresses: false,
  uploadAddressesToSpaces: false,
  createNewLocationNames: false,
  createPlaceNameTowns: false,
  createPlaceNameStreets: false,
  createNewAddresses: false,
  geocodeMunicipalities: false,
  geocodeTowns: false,
  geocodeStreets: false,
  geocodeAddresses: false,
  updateSuburbs: false,
  updateSearchVectors: false,
  updateFullNames: false,
  // addNames: true,
  // geocodeAddresses: true,
  // updatePlaceNames: false,
  // updateGeoFeatures: false,
};

const nameLabelArray = [
  { name: "downloadAddresses", label: "Užkrauti adresus į failus" },
  { name: "uploadAddressesToSpaces", label: "Perkelti failus į DO Spaces" },
  {
    name: "createNewLocationNames",
    label:
      "Sukurti naujus įrašus duomenų bazės modeliuose Municipality, Town, Street, House, PostOffice, PostCode",
  },
  {
    name: "createPlaceNameTowns",
    label: "Sukurti naujus savivaldybių ir miestų įrašus PlaceName modelyje",
  },
  {
    name: "createPlaceNameStreets",
    label: "Sukurti naujus gatvių įrašus PlaceName modelyje",
  },
  {
    name: "createNewAddresses",
    label: "Sukurti naujus modelio Address įrašus",
  },
  {
    name: "geocodeMunicipalities",
    label: "Atnaujinti savivaldybių geolokaciją",
  },
  {
    name: "geocodeTowns",
    label: "Atnaujinti gyvenviečių geolokaciją",
  },
  {
    name: "geocodeStreets",
    label: "Atnaujinti gatvių geolokaciją",
  },
  {
    name: "geocodeAddresses",
    label: "Atnaujinti adresų geolokaciją",
  },
  {
    name: "updateSuburbs",
    label: "Atnaujinti priemiesčius ir rajonus",
  },
  {
    name: "updateSearchVectors",
    label:
      "Atnaujinti tekstinės paieškos laukus vietovardžių modelyje PlaceName",
  },
  {
    name: "updateFullNames",
    label: "Atnaujinti pilnus vietovardžių pavadinimus",
  },
];

function AddressManagement() {
  const [addressCheckboxState, setAddressCheckboxState] =
    useState(initialCheckBoxState);

  function updateAddresses() {
    axiosInstance.defaults.timeout = 0; // turn of timeout because it will take a lot of time to wait for response
    axiosInstance
      .post("api/management/update-addresses/", addressCheckboxState)
      .then((res) => {
        console.log("server response: successfully updated addresses", res);
      })
      .catch((err) => {
        console.error("Error occured while trying to update addresses", err);
      });
  }

  const handleChange = (event) => {
    setAddressCheckboxState({
      ...addressCheckboxState,
      [event.target.name]: event.target.checked,
    });
  };

  console.log("addressCheckboxState", addressCheckboxState);

  return (
    <Paper elevation={1} sx={{ p: 2, my: 2 }}>
      <Grid container justifyContent="center" sx={{ border: "none" }}>
        <Typography variant="h6">Adresų bazės atnaujinimas</Typography>
      </Grid>
      <FormGroup column>
        {nameLabelArray.map((checkBox) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={addressCheckboxState[checkBox.name]}
                  onChange={handleChange}
                  name={checkBox.name}
                  color="primary"
                />
              }
              label={checkBox.label}
            />
          );
        })}
      </FormGroup>

      <Button
        variant="outlined"
        style={{ marginLeft: "50px" }}
        onClick={updateAddresses}
      >
        Atnaujinti adresus
      </Button>
    </Paper>
  );
}

export default AddressManagement;
