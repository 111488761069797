import React, { useState } from "react";
import {
  Checkbox,
  Chip,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { useField } from "formik";

function RenderValue({ selectedValues, options, handleDelete }) {
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
      {selectedValues.map((selectedValue) => {
        let selectedOption = options.find(
          (option) => option.value === selectedValue
        );
        let chipLabel =
          selectedOption?.secondary_label || selectedOption?.label;
        return (
          chipLabel && (
            <Chip
              sx={{
                m: 0.5,
                borderRadius: "3px",
                height: "100%",
                py: 0.45,
              }}
              size="medium"
              key={selectedValue}
              label={
                <Typography
                  variant="body2"
                  sx={{
                    overflowWrap: "break-word",
                    whiteSpace: "normal",
                  }}
                >
                  {chipLabel}
                </Typography>
              }
              variant="outlined"
              color="primary"
              onDelete={() => {}} // workaround to ensure blur on delete
              deleteIcon={
                <CancelIcon
                  onMouseDown={(event) => {
                    event.stopPropagation();
                    handleDelete(event, selectedValue);
                  }}
                />
              }
            />
          )
        );
      })}
    </Box>
  );
}

export default function FormikMultipleSelect({
  formik,
  name,
  label,
  onChange,
  options,
  ...props
}) {
  const [field, meta, helpers] = useField(name);
  const errorText = meta.touched && meta.error;

  const [selectedOptions, setSelectedOptions] = useState(
    field.value.map((value) => value[name])
  );

  function handleChange(e) {
    let selectedOptions = e.target.value;
    setSelectedOptions(selectedOptions);
    let formikValues = selectedOptions.map((value) => ({ [name]: value }));
    helpers.setValue(formikValues);
    if (onChange) {
      onChange(e.target.value);
    }
  }

  function handleDelete(e, valueToDelete) {
    let filteredValues = selectedOptions.filter(
      (option) => option !== valueToDelete
    );
    setSelectedOptions(filteredValues);
    let formikValues = filteredValues.map((value) => ({ [name]: value }));
    helpers.setValue(formikValues);
  }

  function handleBlur(e) {
    field.onBlur(e);
    helpers.setTouched(true);
  }

  return (
    <FormControl
      fullWidth
      error={!!errorText}
      size={field.value.length ? "small" : "medium"}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        name={name}
        value={selectedOptions}
        onChange={handleChange}
        onBlur={handleBlur}
        label={label}
        renderValue={(selectedValues) => (
          <RenderValue
            selectedValues={selectedValues}
            options={options}
            handleDelete={handleDelete}
          />
        )}
        MenuProps={{
          PaperProps: { sx: { maxHeight: 200 } },
          // MenuListProps: { dense: true },
        }}
        {...props}
      >
        {options.map((option, index) => {
          return (
            <MenuItem
              key={index}
              value={option.value}
              // sx={{ height: "2.5rem" }}
              sx={{
                minHeight: "2.5rem",
                whiteSpace: "unset",
                wordBreak: "break-word",
              }}
            >
              <Checkbox
                sx={{ border: "none", m: 0 }}
                size="small"
                checked={selectedOptions.includes(option.value)}
              />
              <ListItemText
                primary={option.label}
                secondary={option.secondary_label}
              />
            </MenuItem>
          );
        })}
      </Select>
      <FormHelperText>{errorText}</FormHelperText>
    </FormControl>
  );
}
