import React from "react";
import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { CONTACT_FILTER_OPTIONS } from "../../../../utils/defaults";
import { useGlobalContext } from "../../../../context";

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default function ContactListHeader() {
  const { dashboardState, dispatchDashboard } = useGlobalContext();
  let { adId } = useParams();
  const chatFilterValue =
    dashboardState.myAds.find((ad) => ad.id === Number(adId))
      ?.chatFilterValue || "only relevant";

  function handleContactFilterChange(e) {
    // setChatFilterValue(e.target.value);
    // // let form = { chat_filter: e.target.value };
    dispatchDashboard({
      type: "SET_MY_ADS_CHAT_FILTER_VALUE",
      payload: { chatFilterValue: e.target.value, adId: Number(adId) },
    });
    // fetchAdContacts(userState.userId, dispatchDashboard, form);
  }

  return (
    <Grid
      container
      xs={12}
      sx={{
        border: "none",
        py: 0,
        // backgroundColor: blue[100],
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
      }}
    >
      <Box sx={{ ml: 0.5, display: "flex", alignItems: "center" }}>
        {/* <IconButton
          size="medium"
          sx={{ m: "auto" }}
          onClick={() => {
            history.replace(`/dashboard/my-ads`);
          }}
        >
          <ExpandLessIcon fontSize="medium" />
        </IconButton> */}
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{ ml: 3, my: "10px" }}
        >
          Mano pokalbiai
        </Typography>
      </Box>
      <FormControl
        size="small"
        sx={{
          m: 0.5,
        }}
      >
        <Select
          value={chatFilterValue}
          variant="outlined"
          onChange={handleContactFilterChange}
          MenuProps={menuProps}
          inputProps={{
            sx: { alignItems: "center", display: "flex", borderRadius: "3px" },
          }}
          sx={{ height: 30 }}
        >
          {CONTACT_FILTER_OPTIONS.map((option, key) => (
            <MenuItem key={key} dense value={option.value}>
              <Typography variant="caption">{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}
