import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import {
  CONDITION_CHOICES,
  EQUIPMENT_CHOICES,
  FLAT_RENOVATED_CHOICES,
  INSTALLATION_CHOICES,
  PARTIAL_AMENITIES_CHOICES_HOUSE,
  PREMISES_CHOICES_HOUSE,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function EquipmentPanelHouse({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "equipmentPanelHouse";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getEquipmentHouseInitialValues(ad, adOptions);
  const validationSchema = schema.equipmentHouseValidationSchema;

  function handleInstallationChange(value, formik) {
    if (value !== "full") {
      formik.setFieldValue("condition", null);
      formik.setFieldTouched("condition", false);
    }
  }
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            {formik.values.ad_type === "house_sale" && (
              <>
                <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                  <FormikSelect
                    name="installation"
                    label="Baigtumas *"
                    options={INSTALLATION_CHOICES}
                    onChange={(value) =>
                      handleInstallationChange(value, formik)
                    }
                  />
                </Grid>
                {formik.values.installation === "full" && (
                  <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                    <FormikSelect
                      name="condition"
                      label="Būklė *"
                      options={CONDITION_CHOICES}
                    />
                  </Grid>
                )}
              </>
            )}
            {formik.values.ad_type === "house_sale" &&
              !!formik.values.installation &&
              formik.values.installation === "partial" && (
                <Grid item xs={12} sx={{ my: 1 }}>
                  <FormikMultipleSelect
                    name="flat_renovated"
                    label={"Viduje įrengta"}
                    options={FLAT_RENOVATED_CHOICES}
                  />
                </Grid>
              )}
            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="equipment"
                label="Papildoma įranga "
                options={EQUIPMENT_CHOICES}
              />
            </Grid>

            <Grid item xs={12} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="premises"
                label="Papildomos patalpos"
                options={PREMISES_CHOICES_HOUSE}
              />
            </Grid>
            {formik.values.ad_type === "house_sale" && (
              <Grid item xs={12} sx={{ my: 1 }}>
                <FormikMultipleSelect
                  name="partial_amenities"
                  label="Daliniai patogumai"
                  options={PARTIAL_AMENITIES_CHOICES_HOUSE}
                />
              </Grid>
            )}
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
