import { ListItem, Typography } from "@mui/material";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

export default function StyledListItem({ children }) {
  return (
    <ListItem
      dense={true}
      disableGutters
      sx={{ display: "flex", alignItems: "start", pl: 0 }}
    >
      <ArrowRightIcon />
      <Typography variant="body2" sx={{ mt: 0.2 }}>
        {children}
      </Typography>
    </ListItem>
  );
}
