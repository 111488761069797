import { useGlobalContext } from "../../../../context";
import { useParams } from "react-router-dom";

export default function useCalendarEntry(
  memo,
  appointmentDate,
  privateCalendarEntry
) {
  const { dashboardState, userState } = useGlobalContext();
  const { adId, enquirerId } = useParams();

  // if (!adId || !enquirerId) return { calendarEntry: null };

  const enquiry =
    dashboardState.adContacts.find(
      (enquiry) => enquiry.ad === +adId && enquiry.enquirer === +enquirerId
    )?.id || null;

  const appointment =
    dashboardState.myAppointments.find(
      (appointment) =>
        appointment.ad === +adId && appointment.enquirer === +enquirerId
    )?.id || null;

  const newCalendarEntry = {
    user: userState.userId,
    ad: Number(adId),
    enquirer: Number(enquirerId),
    memo: "",
    enquiry: null,
    appointment: null,
    private: false,
    calendar_date: null,
  };

  let existingCalendarEntry =
    privateCalendarEntry ||
    dashboardState.myCalendarEntries.find(
      (entry) =>
        entry.ad === +adId &&
        entry.enquirer === +enquirerId &&
        entry.user === userState.userId &&
        entry.private === false
    );

  let calendarEntry = existingCalendarEntry || newCalendarEntry;

  calendarEntry = {
    ...calendarEntry,
    enquiry,
    appointment,
  };

  if (appointmentDate !== undefined) {
    calendarEntry = {
      ...calendarEntry,
      calendar_date: appointmentDate,
    };
  }

  if (memo !== undefined) {
    calendarEntry = {
      ...calendarEntry,
      memo,
    };
  }

  console.log("calendarEntry from useCalendarEntry", calendarEntry);

  return { calendarEntry };
}
