import React, { useEffect, useState } from "react";
import { useGlobalContext } from "../../context";
import { Avatar, Typography } from "@mui/material";
import { initialNotificationState } from "../../reducers/notificationReducer";
import { getFullImgUrl } from "../../utils/getFullImgUrl";

function stringToColor(string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

function stringAvatar(name, styleProps) {
  let nameArray = name.split(" ");
  return {
    sx: {
      bgcolor: stringToColor(name),
      border: "solid 1px ",
      ...styleProps,
    },
    children:
      nameArray.length > 1
        ? `${nameArray[0][0]}${nameArray[1][0]}`
        : `${nameArray[0][0]}`,
  };
}

function CustomAvatar({ userId, name, styleProps }) {
  const { userState } = useGlobalContext();
  const [avatarData, setAvatarData] = useState(null);

  console.log("custom avatar userid, name", userId, name);

  useEffect(() => {
    if (!!userId && !!userState?.contactAvatars?.length) {
      console.log("contactAvatars", userState.contactAvatars[0]);
      // alert("stop");
      let avatarData =
        userId === userState.userId
          ? userState.currentUser
          : userState.contactAvatars.find((contact) => contact.id === userId);
      if (avatarData?.avatar_photo) {
        avatarData.avatar_photo = getFullImgUrl(avatarData.avatar_photo);
      }
      if (avatarData?.photo_crop) {
        avatarData.photo_crop = getFullImgUrl(avatarData.photo_crop);
      }
      avatarData = { ...avatarData, name };
      setAvatarData(avatarData);
    }
    if (!userId && !!userState?.currentUser) {
      setAvatarData(userState.currentUser);
    }
  }, [name, userId, userState.contactAvatars, userState.currentUser]);

  // console.log("userState from avatar", userState);
  console.log("avatardata", avatarData, userState.contactAvatars);
  console.log("userState", userState);

  return (
    <>
      {avatarData ? (
        avatarData?.avatar_photo ? (
          <Avatar
            src={avatarData.avatar_photo}
            sx={{ border: "solid 1px grey", ...styleProps }}
          />
        ) : (
          <Avatar
            {...stringAvatar(
              avatarData?.name || avatarData?.username || "",
              styleProps
            )}
          />
        )
      ) : (
        <Avatar sx={{ ...styleProps }} />
      )}
    </>
  );
}

export default CustomAvatar;
