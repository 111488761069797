import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { Box, Button, Divider, Menu, MenuList } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory } from "react-router-dom";
import NotificationDataProvider from "./NotificationDataProvider";
import ChatMessageNotification from "./MessageNotification/ChatMessageNotification";
import AppointmentMessageNotification from "./AppointmentNotification/AppointmentMessageNotification";
import { deleteNotifications } from "../fetchHeaderData";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

function Notification() {
  const { userState, dashboardState, notificationState, dispatchNotification } =
    useGlobalContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const { isMobile } = useCustomMediaQuery();

  let history = useHistory();
  const notificationCount = notificationState.notifications?.length || 0;

  function handleOpenNotificationsMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClickItem(pushUrl) {
    history.push(pushUrl);
    setAnchorEl(null);
  }

  function handleDismissAll() {
    deleteNotifications(
      dispatchNotification,
      notificationState.notifications[0].id,
      true // dismiss all
    );
    setAnchorEl(null);
  }

  console.log("notificationState", notificationState);
  console.log("dashboardState", dashboardState);
  console.log("render Notification component");

  return (
    <NotificationDataProvider>
      {notificationState.notificationsLoaded &&
        dashboardState.adContactsLoaded &&
        userState.contactAvatarsLoaded && (
          <IconButton
            onClick={handleOpenNotificationsMenu}
            size="medium"
            sx={{ mx: 0.5 }}
          >
            <Badge color="secondary" badgeContent={notificationCount}>
              <MailIcon
                fontSize="medium"
                color={!!notificationCount ? "primary" : "disabled"}
              />
            </Badge>
          </IconButton>
        )}
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        variant="menu"
        getContentAnchorEl={null}
        // keepMounted // check if it is ok
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        // sx={{
        //   maxHeight: "500px",
        //   width: "1000px",
        //   border: "none",
        // }}
        PaperProps={{
          sx: { maxHeight: "500px", minWidth: isMobile && "90vw" },
        }}
      >
        <MenuList dense>
          {notificationState.notifications.map((notification) => (
            <Box key={notification.id}>
              {notification.type === "chat_message" &&
                !!dashboardState.adContacts?.length && (
                  <ChatMessageNotification
                    notification={notification}
                    handleClickItem={handleClickItem}
                  />
                )}
              {notification.type === "appointment_message" && (
                <AppointmentMessageNotification
                  notification={notification}
                  handleClickItem={handleClickItem}
                />
              )}
              <Divider />
            </Box>
          ))}
        </MenuList>
        <Box
          sx={{
            display: "flex",
            justifyContent: "right",
            width: "100%",
            border: "none",
            px: 1,
            mt: -1,
          }}
        >
          <Button variant="outlined" size="small" onClick={handleDismissAll}>
            Neberodyti
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={() => setAnchorEl(null)}
            sx={{ ml: 1 }}
          >
            Uždaryti
          </Button>
        </Box>
        {/* Add this line */}
      </Menu>
    </NotificationDataProvider>
  );
}

export default Notification;
