export function getInitialValues(params) {
  return {
    municipality: params?.municipality || "",
    town: params?.town || "",
    suburb: params?.suburb || "",
    street: params?.street || "",
    min_price: params?.min_price || "",
    max_price: params?.max_price || "",
    price_from: params?.price_from || "",
    price_to: params?.price_to || "",
    min_size: params?.min_size || "",
    max_size: params?.max_size || "",
    area_from: params?.area_from || "",
    area_to: params?.area_to || "",
    min_rooms: params?.min_rooms || "",
    max_rooms: params?.max_rooms || "",
    rooms_from: params?.rooms_from || "",
    rooms_to: params?.rooms_to || "",
    min_floor: params?.min_floor || "",
    max_floor: params?.max_floor || "",
    floor_from: params?.floor_from || "",
    floor_to: params?.floor_to || "",
    floors_from: params?.floors_from || "",
    floors_to: params?.floors_to || "",
    min_floors: params?.min_floors || "",
    max_floors: params?.max_floors || "",
    min_year: params?.min_year || "",
    max_year: params?.max_year || "",
    max_heating_bill: params?.max_heating_bill || "",
    min_plot_area: params?.min_plot_area || "",
    max_plot_area: params?.max_plot_area || "",
    min_garage_size: params?.min_garage_size || "",
    max_garage_size: params?.max_garage_size || "",

    not_highest_floor: params?.not_highest_floor || "",
    include_renovated: params?.include_renovated || "",
    renovated_only: params?.renovated_only || "",
    efficient_heating: params?.efficient_heating || "",
    long_term_rent_only: params?.long_term_rent_only || "",

    sale_type: params?.sale_type || "",
    building_type: params?.building_type || "",
    heating: params?.heating || "",
    installation: params?.installation || "",
    condition: params?.condition || "",
    equipment: params?.equipment || "",
    premises: params?.premises || "",
    other_features: params?.other_features || "",
    partial_amenities: params?.partial_amenities || "",
    parking: params?.parking || "",
    security: params?.security || "",
    recreation: params?.recreation || "",

    min_rent_period: params?.min_rent_period || "",
    rent_type: params?.rent_type || "",

    house_type: params?.house_type || "",
    water_sewage: params?.water_sewage || "",
    plot_infrastructure: params?.plot_infrastructure || "",

    plot_purpose: params?.plot_purpose || "",
    forest_type: params?.forest_type || "",
    forest_mature: params?.forest_mature || "",
    forest_group: params?.forest_group || "",
    is_logging_forest: params?.is_logging_forest || "",
    min_fertility_score: params?.min_fertility_score || "",
    max_fertility_score: params?.max_fertility_score || "",
    water_body: params?.water_body || "",

    premises_purpose: params?.premises_purpose || "",

    garage_type: params?.garage_type || "",
    garage_installation: params?.garage_installation || "",

    list_date: params?.list_date || "",
    owner_type: params?.owner_type || "",

    rent_type_all: params?.rent_type_all || "",
    sale_type_all: params?.sale_type_all || "",
    heating_all: params?.heating_all || "",
    equipment_all: params?.equipment_all || "",
    premises_all: params?.premises_all || "",
    other_features_all: params?.other_features_all || "",
    partial_amenities_all: params?.partial_amenities_all || "",
    parking_all: params?.parking_all || "",
    security_all: params?.security_all || "",
    recreation_all: params?.recreation_all || "",
    forest_group_all: params?.forest_group_all || "",
    plot_infrastructure_all: params?.plot_infrastructure_all || "",
    water_sewage_all: params?.water_sewage_all || "",
    water_body_all: params?.water_body_all || "",
    garage_installation_all: params?.garage_installation_all || "",
  };
}
