// export const queryParams = {
//   ad_type: "flat_sale",
//   municipality: "",
//   town: "",
//   suburb: "",
//   street: "",
//   min_price: "",
//   max_price: "",
//   min_rooms: "",
//   max_rooms: "",
//   min_size: "",
//   max_size: "",
//   shape_type: "",
//   shape_coords: "",
//   radius: "",
//   place_coords: "",
//   favorites_only: false,
//   distanceToMyPlaces: 0,
//   valueToPriceRatio: 0,
//   rentReturn: 0,
//   basicShops: 0,
//   leisure: 0,
//   min_floor: "",
//   max_floor: "",
//   not_highest_floor: "",
//   min_year: "",
//   max_year: "",
//   include_renovated: "",
//   renovated_only: "",
//   building_type: [],
//   heating: [],
//   heating_all: false,
//   efficient_heating: "",
//   installation: [],
//   condition: [],
//   equipment: [],
//   equipment_all: false,
//   premises: [],
//   premises_all: false,
//   other_features: [],
//   other_features_all: false,
//   partial_amenities: [],
//   partial_amenities_all: false,
//   sale_type: [],
//   sale_type_all: false,
//   list_date: [],
//   ad_owner: [],

//   ordering: "price",
//   page: 1, // can we have "" instead of 1?
//   ads_per_page: 20,
// };

export const initialMainAppState = {
  // queryParams,
  initialAppLoad: true,
  loading: true,
  adCards: [],
  loadingAdCards: false,
  ads: [],
  loadinAds: false,
  resultsPerPage: +localStorage.getItem("resultsPerPage") || 20,
  resultMessage: "",
  resultCount: 0,
  totalPages: 0,
  preferencesSelected: false,
  placesSelected: false,
  previousScrollPosition: 0,
  mapCenter: [54.686741, 25.290675], // Gedimino pilis
  geom: null,
  mapZoom: 15,
  mySearches: [],
  municipalityArray: [],
  municipalityOptionsLoaded: false,
  selectedMunicipalities: [],
  townOptions: [],
  townOptionsLoaded: false,
  selectedTowns: [],
  suburbOptions: [],
  suburbOptionsLoaded: false,
  locationOptions: [],
  locationOptionsLoaded: false,
  selectedLocationIds: "",
  selectedSuburbs: [],
  streetOptions: [],
  streetOptionsLoaded: false,
  selectedStreets: [],
  showMap: localStorage.getItem("showMap") === "true" ? true : false,
  ownerAdsFilterValue: "all",
};

export function mainAppReducer(state, action) {
  let ad;
  switch (action.type) {
    // case "CHANGE_AD_TYPE":
    //   let adParams = { ...state.queryParams, ad_type: action.ad_type, page: 1 };
    //   state = {
    //     ...state,
    //     queryParams: adParams,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("CHANGE_AD_TYPE");
    //   return state;
    case "CHANGE_APP_STATE":
      let payload = action.payload;
      state = {
        ...state,
        ...payload,
      };
      return state;

    // case "SAVE_OPTIONS":
    //   // TODO: delete when ready!
    //   alert("SAVE_OPTIONS");
    //   state = {
    //     ...state,
    //     ...action.payload,
    //   };
    //   return state;
    // case "CHANGE_SIZE_ROOM":
    //   let sizeRoomParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     min_size: action.payload.min_size === 0 ? "" : action.payload.min_size,
    //     max_size: action.payload.max_size,
    //     min_rooms: action.payload.min_rooms,
    //     max_rooms: action.payload.max_rooms,

    //     // min_rooms:
    //     //   action.payload.min_rooms === 1 ? "" : action.payload.min_rooms,
    //     // max_rooms:
    //     //   action.payload.max_rooms === 6 ? "" : action.payload.max_rooms,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: sizeRoomParams,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("CHANGE_SIZE_ROOM");
    //   return state;
    // case "CHANGE_PRICE":
    //   let priceParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     min_price: action.payload.min_price,
    //     max_price: action.payload.max_price,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: priceParams,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("CHANGE_PRICE");
    //   return state;
    // case "CHANGE_OTHER_CRITERIA":
    //   let otherCriteriaParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     min_floor: action.payload.min_floor,
    //     max_floor: action.payload.max_floor,
    //     not_highest_floor: action.payload.not_highest_floor,
    //     min_year: action.payload.min_year,
    //     max_year: action.payload.max_year,
    //     include_renovated: action.payload.include_renovated,
    //     renovated_only: action.payload.renovated_only,
    //     building_type: action.payload.building_type,
    //     heating: action.payload.heating,
    //     heating_all: action.payload.heating_all,
    //     efficient_heating: action.payload.efficient_heating,
    //     installation: action.payload.installation,
    //     condition: action.payload.condition,
    //     equipment: action.payload.equipment,
    //     equipment_all: action.payload.equipment_all,
    //     premises: action.payload.premises,
    //     premises_all: action.payload.premises_all,
    //     other_features: action.payload.other_features,
    //     other_features_all: action.payload.other_features_all,
    //     partial_amenities: action.payload.partial_amenities,
    //     partial_amenities_all: action.payload.partial_amenities_all,
    //     sale_type: action.payload.sale_type,
    //     sale_type_all: action.payload.sale_type_all,
    //     list_date: action.payload.list_date,
    //     ad_owner: action.payload.ad_owner,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: otherCriteriaParams,
    //   };
    //   // console.log(state);
    //   // alert("state");
    //   return state;
    // case "CHANGE_PREFERENCES":
    //   let preferenceParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     distanceToMyPlaces: action.payload.distanceToMyPlaces,
    //     valueToPriceRatio: action.payload.valueToPriceRatio,
    //     rentReturn: action.payload.rentReturn,
    //     basicShops: action.payload.basicShops,
    //     leisure: action.payload.leisure,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: preferenceParams,
    //     preferencesSelected: action.payload.preferencesSelected,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("CHANGE_PREFERENCES");
    //   return state;
    // case "CHANGE_LOCATION":
    //   let locationParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     ...action.location,
    //     // municipality: action.municipality,
    //     // town: action.town,
    //     // suburb: action.suburb,
    //     // street: action.street,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: locationParams,
    //   };
    //   // console.log("state in useReducer after CHANGE_LOCATION", state);
    //   // alert("CHANGE_LOCATION");
    //   return state;
    // case "TOGGLE_FAVORITES":
    //   let favoritesParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     favorites_only: action.favorites_only,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: favoritesParams,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("TOGGLE_FAVORITES");
    //   return state;
    // case "CHANGE_POLYGON":
    //   let polygonParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     type: action.payload.polygon_type,
    //     coordinates: action.payload.coordinates,
    //     radius: action.payload.radius,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: polygonParams,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("CHANGE_POLYGON");
    //   return state;
    // case "CHANGE_PLACES":
    //   let placesParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     place_coords: action.payload.place_coords,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: placesParams,
    //     placesSelected: action.payload.placesSelected,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("CHANGE_PLACES");
    //   return state;
    // case "CHANGE_SORT":
    //   let sortParams = {
    //     ...state.queryParams,
    //     page: 1,
    //     sortBy: action.sortBy,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: sortParams,
    //   };
    //   // console.log("state in useReducer", state);
    //   // alert("CHANGE_SORT");
    //   return state;
    // case "CHANGE_LOADING_STATE":
    //   let loadingState = {
    //     ...action.payload,
    //   };
    //   state = {
    //     ...state,
    //     ...loadingState,
    //   };
    //   return state;
    // case "FETCH_DATA":
    //   let fetchedData = action.payload.fetchedData;
    //   let newParams = action.payload.queryParams;
    //   let queryParams = {
    //     ...state.queryParams,
    //     // page: 1,
    //     ...newParams,
    //   };
    //   state = {
    //     ...state,
    //     queryParams,
    //     ...fetchedData,
    //     previousScrollPosition: 0,
    //   };
    //   return state;
    // case "NAVIGATE":
    //   let navigateParams = {
    //     ...state.queryParams,
    //     page: action.page,
    //   };
    //   state = {
    //     ...state,
    //     queryParams: navigateParams,
    //   };
    //   // console.log("state in useReducer after navigation", state);
    //   // alert("NAVIGATE");
    //   return state;
    // case "PREVIOUS_SCROLL_POSITION":
    //   state = {
    //     ...state,
    //     previousScrollPosition: action.currentScrollPosition,
    //   };
    //   // console.log("state in useReducer after fixing scroll position", state);
    //   alert("scrollPosition");
    //   return state;
    case "SAVE_INFRASTRUCTURE_DATA":
      ad = { ...state.ad, ...action.payload };
      state = { ...state, ad };
      return state;
    // case "FETCH_MY_SEARCHES":
    //   let mySearches = action.payload.mySearches;
    //   state = {
    //     ...state,
    //     mySearches,
    //   };
    //   // console.log("mySearches in reducer", mySearches);
    //   alert("FETCH_MY_SEARCHES");
    //   return state;
    // case "RUN_SAVED_SEARCH":
    //   let updatedQueryParams = { ...queryParams, ...action.queryParams };
    //   state = {
    //     ...state,
    //     queryParams: updatedQueryParams,
    //   };
    //   return state;
    case "UPDATE_AD":
      let adToUpdate = action.payload.adToUpdate;
      let updatedAds = state.ads.filter((ad) => ad.id !== adToUpdate.id);
      updatedAds.push(adToUpdate);
      state = {
        ...state,
        ads: updatedAds,
      };
      return state;
    case "UPDATE_NOTE":
      let noteObj = action.payload.noteObj;
      if (state?.ad?.id === noteObj.ad) {
        ad = state.ad;
        ad.properties.note = noteObj;
        state = { ...state, ad };
      }
      if (state.ads.map((ad) => ad.id).includes(noteObj.ad)) {
        let ad = state.ads.find((ad) => ad.id === noteObj.ad);
        state.ads.pop(ad);
        ad.properties.note = noteObj;
        state.ads.push(ad);
      }
      return state;
    case "CHANGE_AD_PROPERTIES":
      let propertiesToUpdate = action.payload;
      ad = state.ad;
      console.log("mainAppState from reducer", state);
      ad.properties = { ...ad.properties, ...propertiesToUpdate };
      state = { ...state, ad, adCardsLoaded: false, adsLoaded: false, ads: [] };
      return state;
    case "CHANGE_AD_HISTORY":
      const adId = action.payload.adId;
      const adActions = action.payload.adActions;
      ad = state.ads.find((ad) => ad.id === adId);
      if (!!ad) {
        ad.properties.ad_actions = adActions;
        state.ad = ad;
      }
      return state;
    case "LOGOUT_USER":
      state = { ...initialMainAppState };
      return state;

    default:
      throw new Error(`Unknown action type: ${action.type}`);
  }
}
