import React, { useEffect, useState } from "react";
import { FormControl, MenuItem, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import { crudMyAd } from "../../fetchOrSendDashboardData";
import { useGlobalContext } from "../../../../context";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";
import ConfirmationDialog from "../../../../utils/ConfirmationDialog";
import { AD_STATUS_OPTIONS } from "../../../../utils/defaults";

function AdStatusSelector({ ad, expandedAd }) {
  const { dispatch, dispatchDashboard } = useGlobalContext();
  const { isSale } = useAdType(ad);
  const { isMobile } = useCustomMediaQuery();
  let statusOptions = AD_STATUS_OPTIONS.filter((option) =>
    isSale ? option.status !== "rented" : option.status !== "sold"
  );
  if (
    ["expired", "deleted", "blocked"].includes(ad.properties.status) === false
  ) {
    statusOptions = statusOptions.filter(
      (option) => !["expired", "deleted", "blocked"].includes(option.status)
    );
  }
  const [selectedOption, setSelectedOption] = useState(
    statusOptions.find((option) => option.status === ad.properties.status)
  );
  const [oldOption, setOldOption] = useState(selectedOption);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [agree, setAgree] = useState(false);
  // const expired =
  //   new Date(ad.properties.expires_at) < new Date() &&
  //   selectedOption.status === "inactive";

  useEffect(() => {
    if (!openConfirmationDialog) {
      if (agree) {
        const data = { status: selectedOption.status };
        crudMyAd(dispatch, dispatchDashboard, ad.id, data, "PATCH");
        setOldOption(selectedOption);
      } else if (selectedOption.status !== oldOption.status) {
        setSelectedOption(oldOption);
      }
    }
  }, [agree, openConfirmationDialog]);

  function handleStatusChange(e) {
    const selectedOption = statusOptions.find(
      (option) => option.status === e.target.value
    );
    setSelectedOption(selectedOption);
    setOpenConfirmationDialog(true);
  }

  const contentText = `Skelbimas ${
    selectedOption.status === "inactive" ? "ne" : ""
  }bus rodomas vartotojams. ${
    selectedOption.status !== "inactive"
      ? "Vartotojai matys pasikeitusią skelbimo būseną."
      : ""
  }`;
  console.log("selectedOption from AdStatusSelector", selectedOption);
  console.log("ad from adStatusSelector", ad);

  return (
    <FormControl
      size="small"
      sx={{
        m: 0.5,
        position: "absolute",
        bottom: 0,
        left: 0,
        visibility: expandedAd && isMobile ? "hidden" : "visible",
      }}
    >
      <Select
        key={selectedOption.status}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={selectedOption.status}
        variant="outlined"
        disabled={["expired", "blocked"].includes(selectedOption.status)}
        onChange={handleStatusChange}
        MenuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          // getContentAnchorEl: null,
        }}
        inputProps={{
          sx: {
            py: "3px",
            pl: 0.5,
            alignItems: "center",
            display: "flex",
            backgroundColor: "white",
            borderRadius: "3px",
          },
        }}
      >
        {statusOptions.map((option) => (
          <MenuItem dense key={option.status} value={option.status}>
            <FiberManualRecordIcon
              fontSize="small"
              color={option.color}
              sx={{ mr: 0.5 }}
            />
            <Typography variant="caption">{option.label}</Typography>
          </MenuItem>
        ))}
      </Select>
      <ConfirmationDialog
        open={openConfirmationDialog}
        setAgree={setAgree}
        setOpenDialog={setOpenConfirmationDialog}
        title={`Ar norite keisti skelbimo būseną į: "${selectedOption?.shortLabel}"?`}
        contentText={contentText}
        agreeButtonText="Taip"
        disagreeButtonText="Atšaukti"
        showDisagreeButton={true}
      />
    </FormControl>
  );
}

export default AdStatusSelector;
