import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import Box from "@mui/material/Box";
import { SwapVerticalCircleOutlined } from "@mui/icons-material";
import {
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
  ViberShareButton,
  ViberIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailShareButton,
  EmailIcon,
  XIcon,
} from "react-share";
import { Stack } from "@mui/material";
import { getAdTitle } from "../AdTitle";
import { getAddress } from "../BasicAdInfo/utils";

// const actions = [
//   { icon: <SwapVerticalCircleOutlined />, name: "Save" },
//   { icon: <SwapVerticalCircleOutlined />, name: "Load" },
//   { icon: <SwapVerticalCircleOutlined />, name: "Print" },
// ];

export default function ShareAd({ ad }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const sharePageUrl = `${window.location.origin}/api/ads/share/${ad.id}`;

  console.log("ad from ShareAd", ad, `${getAdTitle(ad)} (${getAddress(ad)}):`);

  return (
    <>
      <IconButton aria-describedby={id} onClick={handleClick} size="small">
        <ShareOutlinedIcon fontSize="small" color="inherit" />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ mt: 1 }}
      >
        <Stack spacing={0.5} direction="row" sx={{ pt: 1, px: 1 }}>
          <FacebookShareButton
            url={sharePageUrl}
            // title={`${getAdTitle(ad)} (${getAddress(ad)}):`}
          >
            <FacebookIcon size={32} round={false} />
          </FacebookShareButton>
          <FacebookMessengerShareButton
            url={sharePageUrl}
            appId="319178954179708"
          >
            <FacebookMessengerIcon size={32} round={false} />
          </FacebookMessengerShareButton>
          <WhatsappShareButton
            url={sharePageUrl}
            title={`${getAdTitle(ad)} (${getAddress(ad)}):`}
          >
            <WhatsappIcon size={32} round={false} />
          </WhatsappShareButton>
          <ViberShareButton>
            <ViberIcon size={32} round={false} />
          </ViberShareButton>
          <TelegramShareButton>
            <TelegramIcon size={32} round={false} />
          </TelegramShareButton>
          <TwitterShareButton>
            <XIcon size={32} round={false} />
          </TwitterShareButton>
          <EmailShareButton>
            <EmailIcon size={32} round={false} />
          </EmailShareButton>
          {/* {actions.map((action) => (
            <IconButton key={action.name} onClick={action.handler}>
              {action.icon}
            </IconButton>
          ))} */}
        </Stack>
      </Popover>
    </>
  );
}
