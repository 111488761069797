import React, { useEffect } from "react";
import { Box, Container, Paper, Stack } from "@mui/material";
import { useGlobalContext } from "../../../context";
import {
  crudAppointment,
  crudCalendarEntry,
  fetchSelectedAds,
  listAdContacts,
  listCreateEnquiryMessage,
} from "../fetchOrSendDashboardData";
import MyEnquiriesChatDrawer from "./MyEnquiryChat/MyEnquiriesChatDrawer";
// import AdCard from "./MyEnquiryChat/AdCard";
import { useParams } from "react-router-dom";
import Spinner from "../../../utils/Spinner";
import AdCard from "../../MainPage/MainListing/AdCard";

export default function MyEnquiries() {
  const { dashboardState, dispatchDashboard } = useGlobalContext();
  const { tabName } = useParams();

  let { adId } = useParams();
  const ad = dashboardState.myEnquiryAds.find((ad) => ad.id === Number(adId));

  // fetch myEnquiryAds, enquiries, appointments
  useEffect(() => {
    if (
      !dashboardState.myEnquiryAdsLoaded &&
      !dashboardState.selectedAdsLoading
    )
      fetchSelectedAds(dispatchDashboard, "my_enquiry_ads");
    if (!dashboardState.adContactsLoaded && !dashboardState.adContactsLoading)
      listAdContacts(dispatchDashboard);
    if (!dashboardState.adContactsLoaded && !dashboardState.adContactsLoading)
      listAdContacts(dispatchDashboard);
    if (
      !dashboardState.myAppointmentsLoaded &&
      !dashboardState.myAppointmentsLoading
    )
      crudAppointment(dispatchDashboard, null, "get");
    if (!dashboardState.enquiriesLoaded && !dashboardState.enquiriesLoading) {
      listCreateEnquiryMessage(dispatchDashboard, "get");
    }
    if (!dashboardState.myFavoritesLoaded && !dashboardState.selectedAdsLoading)
      fetchSelectedAds(dispatchDashboard, "my_favorites");
    if (
      !dashboardState.myCalendarEntriesLoaded &&
      !dashboardState.myCalendarEntriesLoading
    )
      crudCalendarEntry(dispatchDashboard, null, "get");
  }, [
    dashboardState.enquiriesLoaded,
    // dashboardState.enquiriesLoading,
    dashboardState.adContactsLoaded,
    dashboardState.adContactsLoading,
    // dashboardState.myFavoritesLoaded,
    // dashboardState.selectedAdsLoading,
    dashboardState.myAppointmentsLoaded,
    // dashboardState.myAppointmentsLoading,
    dashboardState.myEnquiryAdsLoaded,
    dashboardState.myCalendarEntriesLoaded,
    // dashboardState.myCalendarEntriesLoading,
    dispatchDashboard,
  ]);

  return (
    <>
      {/* {(!dashboardState?.enquiriesLoaded ||
        !dashboardState.adContactsLoaded) && (
        <Box sx={{ height: "90vh", width: "100%", display: "flex" }}>
          <Spinner />
        </Box>
      )} */}

      {(!dashboardState.enquiriesLoaded ||
        !dashboardState.adContactsLoaded) && (
        <Box sx={{ height: "75vh", width: "100%", display: "flex" }}>
          <Spinner />
        </Box>
      )}

      {tabName === "my-enquiries" &&
        !!dashboardState?.enquiriesLoaded &&
        !!dashboardState?.adContactsLoaded && (
          <Container
            maxWidth="md"
            disableGutters
            sx={{ display: "block", border: "none", px: 0.5 }}
          >
            <Stack>
              <Paper
                elevation={1}
                sx={{
                  border: "none",
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  position: "relative",
                  overflowX: "hidden",
                  my: 2,
                }}
              >
                <MyEnquiriesChatDrawer />
              </Paper>
              {ad && (
                <Box>
                  <AdCard ad={ad} />
                </Box>
              )}
            </Stack>
          </Container>
        )}
    </>
  );
}
