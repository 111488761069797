import React, { useState } from "react";
import { Box, Grid, TextField } from "@mui/material";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import ProfilePhoto from "../../../Accounts/UserProfile/ProfilePhoto";
import { OWNER_TYPE_CHOICES } from "../../../../utils/adOptions";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
import { useGlobalContext } from "../../../../context";
// import FormPreview from "../../utils/FormPreview";
import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import FormikDispatcher from "../../utils/FormikDispatcher";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input";
import CustomPhoneTextField from "./CustomPhoneTextField";

function OwnerDetailsPanel({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { isMobile } = useCustomMediaQuery();
  const { adFormState } = useGlobalContext();
  const panelName = "ownerDetailsPanel";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getOwnerDetailsInitialValues(ad, adOptions);
  const validationSchema = schema.ownerDetailsValidationSchema;
  const [phoneValue, setPhoneValue] = useState(
    !!ad.properties.phone ? ad.properties.phone : "+370"
  );

  function handleOwnerChange(value, formik) {
    if (value !== "owner") {
      formik.setFieldValue("company_name", null);
      formik.setFieldValue("project_name", null);
      formik.setFieldTouched("company_name", false);
      formik.setFieldTouched("project_name", false);
    }
  }

  function handlePhoneChange(value, formik) {
    const newPhoneValue = !!value ? value : "+370";
    setPhoneValue(newPhoneValue);
    formik.setFieldValue("phone", newPhoneValue);
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField name="name" label="Vardas *" />
            </Grid>
            {formik.values.ad_type !== "apartment_complex" && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikSelect
                  name="owner_type"
                  label="Skelbėjo tipas *"
                  options={OWNER_TYPE_CHOICES}
                  onChange={(value) => handleOwnerChange(value, formik)}
                />
              </Grid>
            )}
            {/* {formik.values.ad_type === "apartment_complex" && !isMobile && (
              <Grid item xs={6} />
            )} */}

            {["intermediary", "developer"].includes(formik.values.owner_type) &&
              formik.values.ad_type !== "apartment_complex" && (
                <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                  <FormikTextField
                    name="company_name"
                    label="Įmonės pavadinimas"
                  />
                </Grid>
              )}

            {formik.values.owner_type === "developer" &&
              formik.values.ad_type !== "apartment_complex" && (
                <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                  <FormikTextField
                    name="project_name"
                    label="Projekto pavadinimas"
                  />
                </Grid>
              )}

            {/* {["intermediary", "developer"].includes(
              formik.values.owner_type
            ) && (
              <Grid container xs={12} spacing={1} sx={{ ml: 0.2 }}>
                {formik.values.ad_type !== "apartment_complex" && (
                  <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                    <FormikTextField
                      name="company_name"
                      label="Įmonės pavadinimas"
                    />
                  </Grid>
                )}
                {["developer"].includes(formik.values.owner_type) &&
                  formik.values.ad_type !== "apartment_complex" && (
                    <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                      <FormikTextField
                        name="project_name"
                        label="Projekto pavadinimas"
                      />
                    </Grid>
                  )}
              </Grid>
            )} */}

            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <Input
                international
                value={phoneValue || ""}
                onChange={(value) => handlePhoneChange(value, formik)}
                onBlur={() => formik.setFieldTouched("phone", true)}
                // placeholder="Telefonas"
                defaultCountry="LT"
                countryCallingCodeEditable={false}
                error={formik.touched.phone && formik.errors.phone}
                inputComponent={CustomPhoneTextField}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField name="phone" label="Telefonas *" />
            </Grid> */}
            {/* <Grid
              item
              xs={12}
              sm={6}
              sx={{
                border: "none",
                mt: isMobile ? 0 : 2,
                mb: isMobile ? 1 : 0,
              }}
            >
              <FormikCheckbox
                name="show_phone"
                label="Rodyti telefono Nr. skelbime"
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikTextField name="email" label="El. paštas" />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                border: "none",
                mt: isMobile ? 0 : 2,
                mb: isMobile ? 1 : 0,
              }}
            >
              <FormikCheckbox
                name="show_email"
                label="Rodyti el. paštą skelbime"
              />
            </Grid> */}

            <Grid container item spacing={1} sx={{ border: "none" }}>
              <Grid item xs={12} sm={6} sx={{ mt: 1 }}>
                <FormikCheckbox
                  name="show_owner_photo"
                  label="Rodyti skelbėjo nuotrauką"
                />
                {!!formik.values.show_owner_photo && (
                  <Grid item xs={12}>
                    <ProfilePhoto ad={ad} edit={edit} />
                  </Grid>
                )}
              </Grid>
              {["intermediary", "developer"].includes(
                formik.values.owner_type
              ) && (
                <Grid item xs={12} sm={6} sx={{ border: "none", mt: 1 }}>
                  <FormikCheckbox
                    name="show_company_logo"
                    label="Rodyti įmonės logotipą"
                  />
                  {!!formik.values.show_company_logo && (
                    <ProfilePhoto ad={ad} logo={true} edit={edit} />
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default OwnerDetailsPanel;
