import React from "react";
import { Grid, Typography } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import {
  FOREST_GROUP_CHOICES,
  FOREST_MATURE_CHOICES,
  FOREST_TYPE_CHOICES,
  PLOT_PURPOSE_CHOICES,
  INFRASTRUCTURE_CHOICES_GARDEN_PLOT,
  INFRASTRUCTURE_CHOICES_HOUSE_PLOT,
  WATER_SEWAGE_CHOICES,
  RECREATION_CHOICES,
  WATER_BODY_CHOICES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import FormikRadioGroup from "../../../SharedComponents/FormikRadioGroup";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function EnvironmentPanelLand({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "environmentPanelLand";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getEnvironmentLandInitialValues(ad, adOptions);
  const validationSchema = schema.environmentLandValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  function handlePlotPurposeChange(value, formik) {
    if (value !== "forest") {
      formik.setFieldValue("is_logging_forest", null);
      formik.setFieldTouched("is_logging_forest", false);
      formik.setFieldValue("forest_type", null);
      formik.setFieldTouched("forest_type", false);
      formik.setFieldValue("forest_mature", null);
      formik.setFieldTouched("forest_mature", false);
      formik.setFieldValue("forest_group", []);
      formik.setFieldTouched("forest_group", false);
    }
    if (value !== "farm") {
      formik.setFieldValue("fertility_score", null);
      formik.setFieldTouched("fertility_score", false);
    }
    if (!["house", "garden"].includes(value)) {
      formik.setFieldValue("water_sewage", []);
      formik.setFieldTouched("water_sewage", false);
    }
    if (["farm", "industrial_storage", "forest"].includes(value)) {
      formik.setFieldValue("recreation", []);
      formik.setFieldTouched("recreation", false);
    }
  }

  // useEffect(() => {
  //   if (
  //     !!formik.values.plot_purpose &&
  //     formik.values.plot_purpose !== "forest"
  //   ) {
  //     formik.setFieldValue("is_logging_forest", null);
  //     formik.setFieldTouched("is_logging_forest", false);
  //   }
  // }, [formik.values.plot_purpose]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1} xs={12}>
            <Grid item xs={6} sx={{ my: 1 }}>
              <FormikSelect
                name="plot_purpose"
                label="Sklypo paskirtis *"
                options={PLOT_PURPOSE_CHOICES}
                onChange={(value) => handlePlotPurposeChange(value, formik)}
              />
            </Grid>
            <Grid item xs={6} md={3} sx={{ my: 1 }}>
              <FormikTextField
                name="plot_area"
                label="Sklypo plotas (arai) *"
                type="number"
              />
            </Grid>
            {formik.values.plot_purpose === "farm" && (
              <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                <FormikTextField name="fertility_score" label="Našumo balas" />
              </Grid>
            )}

            {formik.values.plot_purpose === "forest" && (
              <>
                <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                  <FormikSelect
                    name="forest_type"
                    label="Miško tipas"
                    options={FOREST_TYPE_CHOICES}
                  />
                </Grid>
                <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                  <FormikSelect
                    name="forest_mature"
                    label="Miško branda"
                    options={FOREST_MATURE_CHOICES}
                  />
                </Grid>

                <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                  <FormikMultipleSelect
                    name="forest_group"
                    label="Miško grupė"
                    options={FOREST_GROUP_CHOICES}
                  />
                </Grid>
                <Grid
                  container
                  item
                  xs={12}
                  sm={6}
                  sx={{ mt: 2, alignItems: "start" }}
                >
                  <Typography variant="body1" sx={{ mr: 1, mt: 1 }}>
                    Galimas komercinis kirtimas *
                  </Typography>
                  <FormikRadioGroup
                    name="is_logging_forest"
                    label1="Ne"
                    label2="Taip"
                  />
                </Grid>
              </>
            )}

            {formik.values.plot_purpose !== "forest" && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikMultipleSelect
                  name="plot_infrastructure"
                  label="Sklypo įrengimas ir infrastruktūra"
                  options={
                    formik.values.plot_purpose === "garden"
                      ? INFRASTRUCTURE_CHOICES_GARDEN_PLOT
                      : INFRASTRUCTURE_CHOICES_HOUSE_PLOT
                  }
                />
              </Grid>
            )}
            {["house", "garden"].includes(formik.values.plot_purpose) && (
              <Grid item xs={12} md={6} sx={{ my: 1 }}>
                <FormikMultipleSelect
                  name="water_sewage"
                  label="Vanduo ir nuotekos"
                  options={WATER_SEWAGE_CHOICES}
                />
              </Grid>
            )}
            {!["forest", "farm", "industrial_storage"].includes(
              formik.values.plot_purpose
            ) && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikMultipleSelect
                  name="recreation"
                  label="Viešos rekreacinės erdvės netoliese"
                  options={RECREATION_CHOICES}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="water_body"
                label="Vandens telkiniai netoliese"
                options={WATER_BODY_CHOICES}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
