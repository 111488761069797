import React from "react";
import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import CancelIcon from "@mui/icons-material/Cancel";
import { updateUserData } from "../fetchOrSendAccountsData";
import { crudCompanlyLogo } from "../../AdForm/fetchOrSendAdDraftData";
import usePhotoData from "./usePhotoData";
import { grey } from "@mui/material/colors";

function PhotoViewer({ setEditProfilePhoto, photoContainerWidth, logo, edit }) {
  const { userState, dispatchUser } = useGlobalContext();
  const { ad, dispatchX, cropUrl, width, height } = usePhotoData(
    logo,
    edit,
    photoContainerWidth
  );

  console.log("ad from PhotoViewer", ad);

  function handlePhotoDelete() {
    let formData = new FormData();
    formData.append("position_x", 0.5);
    formData.append("position_y", 0.5);
    formData.append("scale", 1);
    formData.append("rotation", 0);
    formData.append("photo", "");
    formData.append("photo_crop", "");
    formData.append("avatar_photo", "");
    formData.append("delete_photo", true);
    formData.append("logo", "");
    formData.append("logo_thumbnail", "");
    logo
      ? crudCompanlyLogo(ad.id, dispatchX, formData, "DELETE")
      : updateUserData(
          userState.currentUser.id,
          dispatchUser,
          formData,
          () => {}
        );
  }

  return (
    <>
      <Box
        sx={{
          border: "none",
          display: "flex",
          // justifyContent: "right",
          width: "100%",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Typography sx={{ visibility: "hidden" }}>A</Typography>
        <IconButton
          size="small"
          onClick={handlePhotoDelete}
          sx={{ position: "absolute", right: -25, top: -5 }}
        >
          <CancelIcon color="action" />
        </IconButton>
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          border: "none",
          py: 1.2,
        }}
      >
        <Box
          sx={{
            border: "solid grey 1px",
            borderRadius: logo ? "3px" : "50%",
            width,
            height,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,.05)",
          }}
        >
          <Box
            component="img"
            src={cropUrl}
            alt={logo ? "logo image" : "avatar image"}
            sx={{
              width,
              height,
              objectFit: "cover",
              borderRadius: logo ? "3px" : "50%",
              border: "solid grey 1px",
            }}
            onClick={() => setEditProfilePhoto(true)}
          />
        </Box>
      </Box>
      <Box
        sx={{
          flexGrow: 0,
          border: "none",
          display: "flex",
          justifyContent: "space-around",
          width: "100%",
          // py: 0.5,
        }}
      >
        <Button
          size="small"
          variant="outlined"
          component="label"
          color="inherit"
          onClick={() => setEditProfilePhoto(true)}
          sx={{
            borderColor: grey[500],
            color: grey[700],
          }}
        >
          Redaguoti {logo ? "logotipą" : "nuotrauką"}
        </Button>
      </Box>
    </>
  );
}

export default PhotoViewer;
