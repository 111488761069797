import React, { useEffect, useState } from "react";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import AllowNotificationsDialog from "./AllowNotificationsDialog";
import {
  crudSavedSearch,
  crudSearchNotificationSettings,
} from "../fetchOrSendDashboardData";
import {
  INTERVAL_OPTIONS,
  NOTIFICATION_OPTIONS,
} from "../../../utils/defaults";

export default function SearchNotification() {
  const {
    userState,
    notificationState,
    dispatchNotification,
    dispatchDashboard,
  } = useGlobalContext();
  const [sendNotifications, setSendNotifications] = useState(
    notificationState?.searchNotificationSettings?.send_notifications || false
  );
  const [selectedNotificationMode, setSelectedNotificationMode] = useState(
    Array.isArray(
      notificationState?.searchNotificationSettings?.notification_modes
    )
      ? notificationState.searchNotificationSettings.notification_modes
          .map((m) => m.notification_mode)
          .sort()
          .toString()
      : "mail"
  );
  const [selectedInterval, setSelectedInterval] = useState(
    notificationState?.searchNotificationSettings?.check_intervals || "1_days"
  );
  const [openAllowNotificationsDialog, setOpenAllowNotificationsDialog] =
    useState(false);

  const form = {
    user: userState.userId,
    send_notifications: sendNotifications,
    notification_modes: selectedNotificationMode,
    check_intervals: selectedInterval,
  };

  useEffect(() => {
    if (
      userState.isAuthenticated &&
      !notificationState.searchNotificationSettingsLoaded
    ) {
      checkNotificationPermissions(form);
    }
  }, [userState.isAuthenticated]);

  function handleSendNotifications() {
    const sendNotificationsNew = !sendNotifications;
    form.send_notifications = sendNotificationsNew;
    checkNotificationPermissions(form);
    setSendNotifications(sendNotificationsNew);
    crudSavedSearch(
      dispatchDashboard,
      "get",
      {},
      { allow_individual_notifications: sendNotificationsNew }
    );
  }

  function handleNotificationModeChange(e) {
    const selectedNotificationMode = e.target.value;
    form.notification_modes = selectedNotificationMode;
    checkNotificationPermissions(form);
    setSelectedNotificationMode(selectedNotificationMode);
  }

  function handleIntervalChange(e) {
    const selectedInterval = e.target.value;
    form.check_intervals = selectedInterval;
    checkNotificationPermissions(form);
    setSelectedInterval(selectedInterval);
  }

  function checkNotificationPermissions(form) {
    if (
      !form.send_notifications ||
      form.notification_modes === "mail" ||
      Notification.permission === "granted"
    ) {
      crudSearchNotificationSettings(dispatchNotification, "post", form);
    } else {
      setOpenAllowNotificationsDialog(true);
    }
  }

  return (
    <>
      <AllowNotificationsDialog
        openAllowNotificationsDialog={openAllowNotificationsDialog}
        setOpenAllowNotificationsDialog={setOpenAllowNotificationsDialog}
        setSelectedNotificationMode={setSelectedNotificationMode}
        form={form}
      />
      <Grid container sx={{ border: "none" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2" sx={{ p: 1 }}>
            Gauti pranešimus apie naujus skelbimus
          </Typography>
          <Switch
            checked={sendNotifications}
            onChange={handleSendNotifications}
          />
        </Box>
        {sendNotifications && (
          <Box>
            <Grid container item spacing={1} sx={{ ml: 0 }}>
              <Grid item>
                <FormControl size="small">
                  <Select
                    variant="outlined"
                    value={selectedNotificationMode}
                    onChange={handleNotificationModeChange}
                  >
                    {NOTIFICATION_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value} dense>
                        <Typography variant="body2">{option.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <FormControl size="small">
                  <Select
                    variant="outlined"
                    value={selectedInterval}
                    onChange={handleIntervalChange}
                  >
                    {INTERVAL_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value} dense>
                        <Typography variant="body2">{option.label}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </>
  );
}
