import {
  Autocomplete,
  Box,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useGlobalContext } from "../../../../context";
import { getAdTitle } from "../../../IndividualAd/AdTitle";
import { getAddress } from "../../../IndividualAd/BasicAdInfo/utils";
import CustomAvatar from "../../../Layout/CustomAvatar";

export default function RelatedContacts({
  selectedOption,
  setSelectedOption,
  entryType,
}) {
  // const [selectedOption, setSelectedOption] = useState(null);
  const [showAutocomplete, setShowAutocomplete] = useState(true);
  const { dashboardState, userState } = useGlobalContext();
  const textFieldRef = useRef(null);
  const currentUser = userState.userId;

  const options = useMemo(() => {
    let relevantAds = [];
    if (entryType === "private") {
      relevantAds = relevantAds.concat(dashboardState.myAds);
    }

    console.log("myAds from NewCalendarEntry", relevantAds);

    relevantAds = relevantAds
      .concat(dashboardState.myFavorites, dashboardState.myEnquiryAds)
      .filter(
        (ad) =>
          (entryType === "private" || ad.properties.owner !== currentUser) &&
          !["expired", "deleted", "blocked"].includes(ad.properties.status)
      )
      .map((ad) => ({
        category: "Aktualūs skelbimai",
        photo: ad.properties?.photos[0]?.photo_thumbnail,
        title: `${getAdTitle(ad)} (skelb. ID: ${ad.id})`,
        subtitle: getAddress(ad),
        value: `${getAdTitle(ad)} (skelb. ID: ${ad.id}) ${getAddress(ad)}`,
        adId: ad.id,
        enquirerId: currentUser,
      }));

    // remove duplicates
    relevantAds = Array.from(new Set(relevantAds.map((ad) => ad.adId))).map(
      (id) => relevantAds.find((ad) => ad.adId === id)
    );

    console.log("relevantAds", dashboardState.myFavorites, relevantAds);

    let enquirers = dashboardState.adContacts
      .filter(
        (contact) => contact.owner === currentUser && contact.enquirer_active
      )
      .map((contact) => ({
        category: "Interesantai",
        photo: null,
        title: `${contact.name} (ID: ${contact.enquirer})`,
        subtitle: `Interesantas (skelb. ID: ${contact.ad})`,
        value: `${contact.name} (ID: ${contact.enquirer}) Interesantas (skelb. ID: ${contact.ad})`,
        adId: contact.ad,
        enquirerId: contact.enquirer,
        userId: contact.enquirer,
        name: contact.name,
      }));

    let owners = dashboardState.adContacts
      .filter(
        (contact) =>
          contact.enquirer === currentUser &&
          contact.owner_active &&
          !["expired", "deleted", "blocked"].includes(contact.ad_status)
      )
      .map((contact) => ({
        category: "Skelbėjai",
        photo: null,
        title: `${contact.owner_name} (ID: ${contact.owner})`,
        subtitle: `Skelbėjas (skelbimo ID: ${contact.ad})`,
        value: `${contact.owner_name} (ID: ${contact.owner}) Skelbėjas (skelbimo ID: ${contact.ad})`,
        adId: contact.ad,
        enquirerId: contact.enquirer,
        userId: contact.owner,
        name: contact.owner_name,
      }));

    let options = relevantAds.concat(enquirers).concat(owners);

    // sort by category and value
    options.sort(
      (a, b) =>
        a.category.localeCompare(b.category) ||
        b.value.toLowerCase() - a.value.toLowerCase()
    );

    return options;
  }, [
    currentUser,
    dashboardState.adContacts,
    dashboardState.myAds,
    dashboardState.myEnquiryAds,
    dashboardState.myFavorites,
    entryType,
  ]);

  const getOptionComponent = (option) =>
    !!option && (
      <>
        {option.category === "Aktualūs skelbimai" ? (
          <Box
            component="img"
            src={option.photo}
            sx={{ width: "50px", height: "auto", mr: 1 }}
          />
        ) : (
          <CustomAvatar
            userId={option.userId}
            name={option.name}
            styleProps={{
              height: "30px",
              width: "30px",
              mr: 1,
              fontSize: 14,
            }}
          />
        )}
        <Stack>
          <Typography variant="body2" sx={{ mr: 0.5 }}>
            {option.title}
          </Typography>
          <Typography variant="caption">{option.subtitle}</Typography>
        </Stack>
      </>
    );

  useEffect(() => {
    let optionExists = options.find(
      (option) =>
        option?.adId === selectedOption?.adId &&
        option?.enquirerId === selectedOption?.enquirerId
    );
    if (!optionExists) {
      setShowAutocomplete(true);
      setSelectedOption(null);
    }
  }, [entryType, options]);

  console.log("selectedOption", selectedOption);

  return showAutocomplete ? (
    <Autocomplete
      sx={{ width: "100%" }}
      openOnFocus
      noOptionsText="Nėra pasiūlymų"
      autoHighlight
      // onClose={() => setShowFilter(false)}
      options={options}
      value={selectedOption}
      onChange={(event, value) => {
        setSelectedOption(value);
        // setAdId(value.adId);
        // setEnquirerId(value.enquirerId);
        setShowAutocomplete(false);
      }}
      groupBy={(option) => option.category}
      getOptionLabel={(option) => option.value}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={textFieldRef}
          label={`Susijęs skelbimas arba kontaktas${
            entryType !== "private" ? " *" : ""
          }`}
        />
      )}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            {...props}
            sx={{ border: "none", pl: 0, my: 0, alignItems: "center" }}
          >
            {getOptionComponent(option)}
          </Box>
        );
      }}
    />
  ) : (
    <Chip
      variant="outlined"
      sx={{
        borderRadius: 1,
        height: 56,
        display: "flex",
        justifyContent: "space-between",
        pl: 1,
        pr: 0.5,
      }}
      icon={
        <Box sx={{ display: "flex", overflow: "hidden", alignItems: "center" }}>
          {getOptionComponent(selectedOption)}
        </Box>
      }
      onDelete={() => {
        setSelectedOption(null);
        // setAdId("");
        // setEnquirerId("");
        setShowAutocomplete(true);
      }}
    />
  );
}
