import axios from "axios";
import axiosInstance from "../../utils/axios";
import { getContactAvatarData } from "../Accounts/fetchOrSendAccountsData";

export function registerAdView(adId, visitor_id) {
  axiosInstance
    .post(`api/ads/${adId}/ad-view/`, { visitor_id })
    .then((res) => {
      console.log("server response: ad view results", res);
    })
    .catch(function (error) {
      console.log("server response: failed to register ad view", error);
    });
}

export function fetchAd(dispatch, adId) {
  dispatch({
    type: "CHANGE_APP_STATE",
    payload: { adLoading: true },
  });
  axiosInstance
    .get(`api/ads/${adId}/`)
    .then((res) => {
      console.log("server response: individual ad data loaded", res.data);
      let ad = res.data;
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { ad, adLoaded: true },
      });
    })
    .catch((error) =>
      console.log("server response: Failed to fetch individual ad data", error)
    )
    .finally(() => {
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { adLoading: false, adLoaded: true },
      });
    });
}

export function fetchAdHistory(dispatch, ad, setAd) {
  const adId = ad.id;
  const url = `api/ads/${adId}/ad-history/`;
  axiosInstance
    .get(url)
    .then((res) => {
      console.log("server response: ad history loaded", res.data);
      let adActions = res.data;
      ad.properties.ad_actions = adActions;
      setAd(ad);
      dispatch({
        type: "CHANGE_AD_HISTORY",
        payload: { adId, adActions },
      });
    })
    .catch((error) =>
      console.log("server response: Failed to load ad history", error)
    )
    .finally(() => {
      // dispatch({
      //   type: "CHANGE_APP_STATE",
      //   payload: { adLoading: false },
      // });
    });
}

export function fetchInfrastructureData(form, dispatch) {
  const baseUrl =
    process.env.NODE_ENV === "production"
      ? "https://www.kurnamai.lt"
      : "http://localhost:8000";
  const addressUrl = baseUrl + "/api/ads/infrastructure/address/";
  const buildingUrl = baseUrl + "/api/ads/infrastructure/building/";
  // const addressUrl = "/api/ads/infrastructure/address/";
  // const buildingUrl = "/api/ads/infrastructure/building/";
  const requestA = axios.get(addressUrl, { params: form });
  const requestB = axios.get(buildingUrl, { params: form });

  axios
    .all([requestA, requestB])
    .then(
      axios.spread((...res) => {
        const resA = res[0];
        const resB = res[1];
        console.log(
          "server response: infrastructure loaded",
          resA.data,
          resB.data
        );
        const infrastructure = resA.data.features.concat(resB.data.features);
        dispatch({
          type: "SAVE_INFRASTRUCTURE_DATA",
          payload: { infrastructure },
        });
      })
    )
    .catch((errors) => {
      console.log(
        "server response: Failed to fetch infrastructure data",
        errors
      );
    });
}

export function createNote(adId, params, dispatch) {
  axiosInstance
    .post(`api/ads/${adId}/note/`, params)
    .then((res) => {
      console.log("server response: note created", res.data);
      dispatch({
        type: "UPDATE_NOTE",
        payload: { noteObj: res.data },
      });
    })
    .catch((error) =>
      console.log("server response: Failed to create note", error)
    );
}

export function updateNote(adId, noteId, params, dispatch) {
  axiosInstance
    .patch(`api/ads/${adId}/note/${noteId}/`, params)
    .then((res) => {
      console.log("server response: note updated", res.data);
      dispatch({
        type: "UPDATE_NOTE",
        payload: { noteObj: res.data },
      });
    })
    .catch((error) =>
      console.log("server response: Failed to update note", error)
    );
}

export function submitInitialEnquiry(
  ad,
  form,
  dispatchDashboard,
  dispatchUser
) {
  dispatchDashboard({
    type: "CHANGE_DASHBOARD_STATE",
    payload: {
      enquiriesLoading: true,
      myEnquiryAdsLoading: true,
      adContactsLoading: true,
    },
  });
  axiosInstance
    .post("api/enquiries/create/", form)
    .then((res) => {
      console.log("server response: enquiry successfully submitted", res.data);
      getContactAvatarData(dispatchUser);
      dispatchDashboard({
        type: "SUBMIT_ENQUIRY",
        payload: { ad, enquiry: res.data },
      });
    })
    .catch(function (error) {
      console.log(
        "server response: Failed to submit enquiry",
        error,
        error.detail
      );
    })
    .finally(() => {
      dispatchDashboard({
        type: "CHANGE_DASHBOARD_STATE",
        payload: {
          enquiriesLoading: false,
          myEnquiryAdsLoading: false,
          adContactsLoading: false,
        },
      });
    });
}

export function fetchFlatOptionsComplex(dispatch, adId) {
  axiosInstance
    .get(`api/ads/${adId}/flat-options-complex/`)
    .then((res) => {
      console.log(
        "server response: fetched flat options in apartment complex",
        res.data
      );
      let flatOptions = res.data;
      dispatch({
        type: "CHANGE_APP_STATE",
        payload: { flatOptions },
      });
    })
    .catch(function (error) {
      console.log(
        "server response: Failed to fetch flat options in apartment complex data",
        error
      );
    });
}
