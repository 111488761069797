import React from "react";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";
import AdCardInfoFlatHouse from "./AdCardInfoFlatHouse";
import AdCardInfoLand from "./AdCardInfoLand";
import AdCardInfoPremises from "./AdCardInfoPremises";
import AdCardInfoParking from "./AdCardInfoParking";
import AdCardInfoApartmentComplex from "./AdCardInfoApartmentComplex";

export default function BasicAdCardInfo({ ad }) {
  const {
    isFlat,
    isHouse,
    isLand,
    isPremises,
    isParking,
    isGarage,
    isApartmentComplex,
  } = useAdType(ad);

  return (
    <>
      {(isFlat || isHouse) && <AdCardInfoFlatHouse ad={ad} />}
      {isLand && <AdCardInfoLand ad={ad} />}
      {isPremises && <AdCardInfoPremises ad={ad} />}
      {(isParking || isGarage) && <AdCardInfoParking ad={ad} />}
      {isApartmentComplex && <AdCardInfoApartmentComplex ad={ad} />}
    </>
  );
}
