import { Divider, Grid, Typography } from "@mui/material";
import {
  LIST_DATE_CHOICES,
  OWNER_TYPE_CHOICES,
} from "../../../../utils/adOptions";
import FormikSelect from "../../../SharedComponents/FormikSelect";

function FiltersAd() {
  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Skelbimas
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormikSelect
            name="list_date"
            label="Paskelbta prieš (max)"
            options={LIST_DATE_CHOICES}
            enableClear={true}
          />
        </Grid>
        <Grid item xs={6}>
          <FormikSelect
            name="owner_type"
            label="Skelbėjas"
            options={OWNER_TYPE_CHOICES}
            enableClear={true}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default FiltersAd;
