import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import { useGlobalContext } from "../../../../context";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import { getAdTitle } from "../../../IndividualAd/AdTitle";
import { crudMyAd } from "../../fetchOrSendDashboardData";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import * as Yup from "yup";
// import FormPreview from "../../../AdForm/utils/FormPreview";

export default function DeleteAdDialog({ open, setOpen, ad }) {
  const { dispatch, dispatchDashboard, userState, dispatchUser } =
    useGlobalContext();
  const { isMobile } = useCustomMediaQuery();

  const initialValues = {
    confirmation: "",
  };

  const validationSchema = Yup.object().shape({
    confirmation: Yup.string()
      .transform((value) => value.toLowerCase())
      .required("Patvirtinimas yra privalomas")
      .oneOf(
        ["patvirtinu"],
        'Įrašykite "PATVIRTINU", jei norite ištrinti skelbimą'
      ),
  });

  async function handleSubmit(values) {
    values = { ...values, status: "deleted" };
    console.log("formik values from DeleteAdDialog", values);
    crudMyAd(dispatch, dispatchDashboard, ad.id, values, "PATCH");
    setOpen(false);
    const snackbar = userState.snackbar;
    snackbar.open = true;
    snackbar.message = "Skelbimas ištrintas sėkmingai";
    snackbar.severity = "success";
    dispatchUser({
      type: "CHANGE_USER_STATE",
      payload: { snackbar },
    });
  }

  console.log("ad from DeleteAdDialog", ad);

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      fullScreen={isMobile}
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle>
        Ar tikrai norite ištrinti skelbimą (skelbimo ID: {ad.id})?
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => (
          <>
            <DialogContent>
              <Typography variant="body1">
                Skelbimas, kuriuo {getAdTitle(ad).toLowerCase()}, bus
                negrįžtamai ištrintas.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Jei norite tęsti, žemiau įrašykite žodį "PATVIRTINU".
              </Typography>

              <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12} sm={6}>
                  <FormikTextField
                    name="confirmation"
                    label="Patvirtinimas *"
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Netrinti
              </Button>
              <Button
                // type="submit"
                variant="contained"
                onClick={() => handleSubmit(formik.values)}
                disabled={
                  !formik.dirty || Object.values(formik.errors).length > 0
                }
                color="error"
              >
                Ištrinti skelbimą
              </Button>
            </DialogActions>
            {/* <FormPreview /> */}
          </>
        )}
      </Formik>
    </Dialog>
  );
}
