import { Grid } from "@mui/material";
import { useGlobalContext } from "../../../../context";

import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import { Form, Formik } from "formik";
import FormikMultipleAutocomplete from "../../../SharedComponents/FormikMultipleAutocomplete";
// import FormPreview from "../../../AdForm/utils/FormPreview";

export default function StreetFilter({ formikRef, handleSubmit }) {
  const { state } = useGlobalContext();
  const params = useParamsFromUrl();
  const initialValues = { street: params.street || "" };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      // validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      <Form autoComplete="off" noValidate style={{ width: "100%" }}>
        <Grid item xs={12} sx={{ border: "none" }}>
          <FormikMultipleAutocomplete
            name="street"
            label="Gatvė"
            options={state.streetOptions}
          />
        </Grid>
        {/* <FormPreview /> */}
      </Form>
    </Formik>
  );
}
