import React from "react";
import { useField } from "formik";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
  Grid,
  FormHelperText,
} from "@mui/material";

const FormikRadioGroup = ({ label1, label2, ...props }) => {
  const [field, meta] = useField(props);
  const errorText = meta.touched && meta.error;

  return (
    <FormControl
      sx={{
        border: "none",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <RadioGroup row {...field}>
        <FormControlLabel
          value={false}
          control={<Radio size="small" />}
          label={<Typography variant="body2">{label1}</Typography>}
        />
        <FormControlLabel
          value={true}
          control={<Radio size="small" />}
          label={<Typography variant="body2">{label2}</Typography>}
        />
      </RadioGroup>
      {/* <Grid item xs={12}> */}
      <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
      {/* </Grid> */}
    </FormControl>
  );
};

export default FormikRadioGroup;
