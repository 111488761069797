import React, { useState } from "react";
import axiosInstance from "../../utils/axios";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const initialCheckBoxState = {
  run_osm2pgsql: false,
  saveAddressesToDB: false,
  saveBuildingsToDB: false,
  saveBusStopsToDB: false,
  savePlacesToDB: false,
  saveBoundariesToDB: false,
  saveLandUsesToDB: false,
  saveStreetsToDB: false,
  saveFootwaysToDB: false,
  // retrieveAndSaveCycleWays: false,
  uploadOsmModelsToSpaces: false,
  downloadDataFromSpacesToProductionDB: false,
  updateOrCreateOsmInstancesInProductionDB: false,
};

const nameLabelArray = [
  // { name: "downloadMapData", label: "Užkrauti OSM duomenis iš Geofabric" },
  {
    name: "run_osm2pgsql",
    label: "Užkrauti Lietuvos OSM duomenis į duomenų bazę vietiniame serveryje",
  },
  {
    name: "saveAddressesToDB",
    label: "Užkrauti adresų duomenis, atnaujinti OsmAddress modelį",
  },
  {
    name: "saveBuildingsToDB",
    label: "Užkrauti pastatus, atnaujinti OsmBuilding modelį",
  },
  {
    name: "saveBusStopsToDB",
    label: "Užkrauti autobusų stotelių duomenis, atnaujinti OsmBusStop modelį",
  },
  {
    name: "savePlacesToDB",
    label: "Užkrauti vietoves, atnaujinti OsmPlace modelį",
  },
  {
    name: "saveBoundariesToDB",
    label: "Užkrauti vietovių ribas, atnaujinti OsmBoundary modelį",
  },
  {
    name: "saveLandUsesToDB",
    label: "Užkrauti žemės naudmenis, atnaujinti OsmLandUse modelį",
  },
  {
    name: "saveStreetsToDB",
    label: "Užkrauti gatves, atnaujinti OsmStreet modelį",
  },
  {
    name: "saveFootwaysToDB",
    label: "Užkrauti pėščiųjų takų duomenis, atnaujinti OsmFootWay modelį",
  },
  // {
  //   name: "retrieveAndSaveCycleWays",
  //   label:
  //     "Užkrauti dviračių takų duomenis, atnaujinti OsmCycleWay modelį (nenaudojama)",
  // },
  {
    name: "uploadOsmModelsToSpaces",
    label: "Perkelti OSM modelius iš vietinio serverio į DigitalOcean Spaces",
  },
  {
    name: "downloadDataFromSpacesToProductionDB",
    label:
      "Perkelti OSM duomenis duomenis iš DO Spaces į produkcijos duomenų bazę (run on production server)",
  },
  {
    name: "updateOrCreateOsmInstancesInProductionDB",
    label:
      "Atnaujinti OSM modelius produkcijos duomenų bazėje (run on production server)",
  },
];

function MapManagement() {
  const [checkboxState, setCheckboxState] = useState(initialCheckBoxState);

  function updateMap() {
    axiosInstance.defaults.timeout = 0; // turn of timeout because it will take a lot of time to wait for response
    axiosInstance
      .post("api/management/update-map/", checkboxState)
      .then((res) => {
        console.log("server response: successfully updated maps", res);
      })
      .catch((err) => {
        console.error("Error occured while trying to update map", err);
      });
  }

  const handleChange = (event) => {
    setCheckboxState({
      ...checkboxState,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Paper elevation={1} sx={{ p: 2, my: 2 }}>
      <Grid container justifyContent="center">
        <Typography variant="h6">Žemėlapių atnaujinimas</Typography>
      </Grid>
      <FormGroup column>
        {nameLabelArray.map((checkBox) => {
          return (
            <FormControlLabel
              key={checkBox.name}
              control={
                <Checkbox
                  checked={checkboxState[checkBox.name]}
                  onChange={handleChange}
                  name={checkBox.name}
                  color="primary"
                />
              }
              label={checkBox.label}
            />
          );
        })}
      </FormGroup>
      <Button
        variant="outlined"
        style={{ marginLeft: "50px" }}
        onClick={updateMap}
      >
        Atnaujinti žemėlapį
      </Button>
    </Paper>
  );
}

export default MapManagement;
