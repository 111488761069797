import axiosInstance from "../../utils/axios";

export function crudAdDraft(dispatchAdForm, method, form, draftAdId) {
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { adDraftLoading: true },
  });
  let url =
    method === "POST" || method === "GET"
      ? "api/ads/draft/"
      : `api/ads/${draftAdId}/change/`;

  axiosInstance({ method, url, data: form })
    .then((res) => {
      console.log(
        "server response: successful CRUD operation on ad draft",
        res.data
      );
      const adDraftData = res?.data || null;
      if (method === "DELETE") {
        dispatchAdForm({ type: "DELETE_AD_DRAFT" });
      } else
        dispatchAdForm({
          type: "CRUD_AD_DRAFT",
          payload: { adDraftData, method },
        });
    })
    .catch((error) =>
      console.log("server response: failed CRUD operation on ad draft", error)
    )
    .finally(() => {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { adDraftLoading: false },
      });
    });
}

export function fetchMunicipalityOptions(dispatchAdForm) {
  const url = "api/addresses/municipality-options/";
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { municipalityOptionsLoading: true },
  });
  axiosInstance
    .get(url)
    .then((res) => {
      console.log("server response: fetched municipality options", res.data);
      let municipalityOptions = res.data.map((x) => {
        return { value: x.id, label: x.name, town: x.town };
      });
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { municipalityOptions, municipalityOptionsLoaded: true },
      });
    })
    .catch((error) => {
      console.log(
        "server respponse: failed to fetch municipality options data",
        error
      );
    })
    .finally(() => {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { municipalityOptionsLoading: false },
      });
    });
}

export function fetchTownOptions(dispatchAdForm, form) {
  const url = "api/addresses/town-options/";
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { townOptionsLoading: true },
  });
  return axiosInstance
    .get(url, { params: form })
    .then((res) => {
      console.log("server response: fetched town data", res.data);
      const townOptions = res.data.map((x) => {
        return { value: x.id, label: x.name };
      });
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { townOptions, townOptionsLoaded: true },
      });
      return townOptions;
    })
    .catch((error) => {
      console.log("server response: Failed to fetch town options data", error);
      return Promise.reject(error);
    })
    .finally(() => {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { townOptionsLoading: false },
      });
    });
}

export function fetchSuburbOptions(dispatchAdForm, form) {
  const url = "api/addresses/suburb-options/";
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { suburbOptionsLoading: true },
  });
  axiosInstance
    .get(url, { params: form })
    .then((res) => {
      console.log("server response: loaded suburb options", res.data);
      const suburbOptions = res.data.map((x) => {
        return { value: x.id, label: x.name };
      });
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { suburbOptions, suburbOptionsLoaded: true },
      });
    })
    .catch((error) => {
      console.log(
        "server response: Failed to fetch suburb options data",
        error
      );
    })
    .finally(() => {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { suburbOptionsLoading: false },
      });
    });
}

export function fetchStreetOptions(dispatchAdForm, form) {
  const url = "api/addresses/street-options/";
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { streetOptionsLoading: true },
  });
  axiosInstance
    .get(url, { params: form })
    .then((res) => {
      console.log("server response: fetched street data", res.data);
      const streetOptions = res.data.map((x) => {
        return { value: x.id, label: x.name };
      });
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { streetOptions, streetOptionsLoaded: true },
      });
    })
    .catch((error) => {
      console.log(
        "server response: Failed to fetch street options data",
        error
      );
    })
    .finally(() => {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { streetOptionsLoading: false },
      });
    });
}

export function fetchGeoLocation(formik, form) {
  const url = "api/addresses/geom/";
  axiosInstance
    .get(url, { params: form })
    .then((res) => {
      if (!!res.data?.length) {
        console.log("server response: fetch geolocation data", res.data);
        let geom = res.data[0].geom;
        formik.setFieldValue("loc_x", Number(geom[0].toFixed(7)));
        formik.setFieldValue("loc_y", Number(geom[1].toFixed(7)));
      }
    })
    .catch((error) => {
      console.log("server response: Failed to fetch geolocation data", error);
    });
}

export function crudCompanlyLogo(adId, dispatchX, formData, method) {
  dispatchX({ type: "CHANGE_AD_PROPERTIES", payload: { logoLoading: true } });

  const url =
    method === "POST"
      ? `api/ads/${adId}/logo/`
      : `api/ads/${adId}/logo/${adId}/`;
  const headers = {
    Authorization: localStorage.getItem("access_token")
      ? "JWT " + localStorage.getItem("access_token")
      : null,
    "Content-Type":
      !!formData.get("logo") ||
      !!formData.get("logo_thumbnail") ||
      !!formData.get("delete_logo")
        ? "multipart/form-data"
        : "application/json",
  };

  axiosInstance({ method, url, headers, data: formData })
    .then((res) => {
      console.log(
        "server response: successful CRUD operation on company logo",
        res
      );
      dispatchX({
        type: "CHANGE_AD_PROPERTIES",
        payload: {
          logo: res.data,
          company_logo: res.data.logo_thumbnail,
          logoLoaded: true,
        },
      });
      console.log("dispatchX", dispatchX);
    })
    .catch((error) =>
      console.log("server response: failed CRUD operation company logo", error)
    )
    .finally(() => {
      dispatchX({
        type: "CHANGE_AD_PROPERTIES",
        payload: { logoLoading: false },
      });
    });
}

// export function createCompanyLogo(adId, dispatchX, formData) {
//   const url = `api/ads/${adId}/logo/`;
//   axiosInstance
//     .post(url, formData, {
//       headers: {
//         Authorization: localStorage.getItem("access_token")
//           ? "JWT " + localStorage.getItem("access_token")
//           : null,
//         "Content-Type":
//           !!formData?.logo || !!formData?.delete_logo
//             ? "multipart/form-data"
//             : "application/json",
//       },
//     })
//     .then((res) => {
//       console.log("server response: uploading company logo", res);
//       dispatchX({
//         type: "CHANGE_AD_PROPERTIES",
//         payload: {
//           logo: res.data,
//           company_logo: res.data.logo_thumbnail,
//         },
//       });
//     })
//     .catch((err) => {
//       console.log(
//         "server response: error while uploading company logo",
//         err,
//         err?.response?.data?.detail
//       );
//     });
// }

// export function updateCompanyLogo(adId, dispatchX, formData, setIsUpdated) {
//   const url = `api/ads/${adId}/logo/${adId}/`;
//   axiosInstance
//     .patch(url, formData, {
//       headers: {
//         Authorization: localStorage.getItem("access_token")
//           ? "JWT " + localStorage.getItem("access_token")
//           : null,
//         "Content-Type":
//           !!formData?.logo || !!formData?.delete_logo
//             ? "multipart/form-data"
//             : "application/json",
//       },
//     })
//     .then((res) => {
//       console.log("server response: updating company logo", res);
//       dispatchX({
//         type: "CHANGE_AD_PROPERTIES",
//         payload: { logo: res.data, company_logo: res.data.logo_thumbnail },
//       });
//       setIsUpdated(true);
//     })
//     .catch((err) => {
//       setIsUpdated(true);
//       console.log(
//         "server response: error while updating company logo",
//         err,
//         err?.response?.data?.detail
//       );
//     });
// }

export function fetchFlatOptionsComplex(dispatchAdForm, draftId) {
  const url = `api/ads/${draftId}/flat-options-complex/`;
  axiosInstance
    .get(url)
    .then((res) => {
      console.log(
        "server response: fetched flat options in apartment complex",
        res.data
      );
      let flatOptions = res.data;
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { flatOptions, flatOptionsLoaded: true },
      });
    })
    .catch(function (error) {
      console.log(
        "server response: Failed to fetch flat options in apartment complex data",
        error
      );
    });
}

export function fetchPaymentPlans(dispatchAdForm) {
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { paymentPlansLoading: true },
  });
  const url = "api/payments/payment-plans/";
  axiosInstance
    .get(url)
    .then((res) => {
      console.log(
        "server response: fetched payment plans for ad checkout",
        res
      );
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { paymentPlans: res.data, paymentPlansLoaded: true },
      });
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { paymentPlansLoading: false },
      });
    });
}

export function fetchPaymentMethods(dispatchAdForm) {
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { paymentMethodsLoading: true },
  });
  const url = "api/payments/payment-methods/";
  axiosInstance
    .get(url)
    .then((res) => {
      console.log(
        "server response: fetched payment methods for ad checkout",
        res
      );
      const paymentMethods =
        res.data.paymentMethods.paymentInitiation.setup.LT.paymentMethods;
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { paymentMethods, paymentMethodsLoaded: true },
      });
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      dispatchAdForm({
        type: "CHANGE_AD_FORM_STATE",
        payload: { paymentMethodsLoading: false },
      });
    });
}

export function initiatePayment(values) {
  const url = "api/payments/initiate-payment/";
  return axiosInstance
    .post(url, values)
    .then((res) => {
      console.log("server response: initiated payment", res);
      if (!!res.data.paymentUrl) {
        window.location.href = res.data.paymentUrl;
      }
      // return res.data;
    })
    .catch((err) => {
      console.log("server response: failed to initiate payment", err);
      return Promise.reject(err);
    });
}

export async function validatePayment(
  orderToken,
  dispatch,
  dispatchAdForm,
  dispatchDashboard
) {
  dispatchAdForm({
    type: "CHANGE_AD_FORM_STATE",
    payload: { paymentValidating: true },
  });
  const url = "api/payments/validate-payment/";
  try {
    const res = await axiosInstance.post(url, { orderToken });
    console.log("server response: validated payment", res);
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { paymentValidated: true, adDraft: null, ad: res.data.ad },
    });
    dispatchDashboard({
      type: "CHANGE_DASHBOARD_STATE",
      payload: { myAdsLoaded: false },
    });
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { adsLoaded: false, adCardsLoaded: false },
    });
    return res.data;
  } catch (err) {
    console.log("server response: failed to validate payment", err);
    return Promise.reject(err);
  } finally {
    dispatchAdForm({
      type: "CHANGE_AD_FORM_STATE",
      payload: { paymentValidating: false },
    });
  }
}
