import React from "react";
import { Typography, List } from "@mui/material";
import useDescription from "./useDescription";

function AccordionDetailHouse({ ad }) {
  const { house } = useDescription(ad);

  return (
    house && (
      <>
        <Typography variant="subtitle2">Pastatas </Typography>
        <List dense>
          {house.houseDescription}
          {house.buildingDescription}
          {house.houseRenovation}
          {house.heating}
          {house.energyEfficiency}
          {house.heatingBill}
        </List>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Įrengimas
        </Typography>
        <List dense style={{ border: "none" }}>
          {ad.properties.ad_type !== "Nuomojamas namas" && (
            <>{house.installation}</>
          )}
          {house.equipment}
          {house.premises}
          {house.partialAmenities}
        </List>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Sklypas ir aplinka
        </Typography>
        <List dense>
          {house.plot}
          {house.plotCommunications}
          {house.waterSewage}
          {house.plotInfrastructure}
          {house.security}
          {house.parking}
          {house.recreation}
        </List>

        {ad.properties.sale_type.length > 0 && (
          <Typography variant="subtitle2" sx={{ mt: 2 }}>
            Pardavimo sąlygos
            <List dense>
              {house.auction}
              {house.exchange}
              {house.preferences}
              {house.nonnegotiable}
              {house.noIntermediaries}
            </List>
          </Typography>
        )}

        {ad.properties.rent_type.length > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Nuomos sąlygos
            </Typography>
            <List dense>
              {house.minRentPeriod}
              {house.preferLongerContracts}
              {house.allowedTenants}
              {house.depositRequired}
              {house.formalContract}
              {house.noRentAgency}
            </List>
          </>
        )}
      </>
    )
  );
}

export default AccordionDetailHouse;
