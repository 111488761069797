import { Divider, Grid, Typography } from "@mui/material";
import * as adOptions from "../../../../utils/adOptions";
import useFilterType from "../hooks/useFilterType";
import { useFormikContext } from "formik";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import FormikBasicAutocomplete from "../../../SharedComponents/FormikBasicAutocomplete";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikBasicMultipleSelect from "../../../SharedComponents/FormikBasicMultipleSelect";
import FormikAndOrFilter from "./FormikAndOrFilter";

function FiltersParking() {
  const formik = useFormikContext();
  const params = useParamsFromUrl();
  const { isSale, isRent } = useFilterType(params);

  const priceOptions = isSale
    ? adOptions.PRICE_OPTIONS_PARKING
    : adOptions.RENT_PRICE_OPTIONS_PARKING;

  const isParking = ["parking_sale", "parking_rent"].includes(params.ad_type);
  const garageTypeOptions = isParking
    ? adOptions.PARKING_TYPE_CHOICES
    : adOptions.GARAGE_TYPE_CHOICES;
  const garageInstallationOptions = isParking
    ? adOptions.PARKING_INSTALLATION_CHOICES
    : adOptions.GARAGE_INSTALLATION_CHOICES;

  function clearAllFilter(values, name) {
    values.length <= 1 && formik.setFieldValue(name + "_all", "");
  }
  // useEffect(() => {
  //   if (!formik.values.min_year && !!params.include_renovated)
  //     setParams({ ...params, include_renovated: "" });
  // }, [formik.values.min_year]);

  // useEffect(() => {
  //   if (+params?.min_rent_period < 30 && !!params.long_term_rent_only)
  //     setParams({ ...params, long_term_rent_only: "" });
  // }, [params.min_rent_period]);

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pagrindiniai filtrai
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        {/* minimum price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_price"
            label="Kaina (Eur, nuo)"
            options={
              !!+formik.values["max_price"]
                ? priceOptions.filter(
                    (option) => option <= +formik.values["max_price"]
                  )
                : priceOptions
            }
            type="number"
            removeSpinners={true}
          />
        </Grid>
        {/* max price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_price"
            label="Kaina (Eur, iki)"
            options={priceOptions.filter(
              (option) => option >= +formik.values["min_price"]
            )}
            type="number"
            removeSpinners={true}
          />
        </Grid>

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_size"
            label="Plotas (m², nuo)"
            options={
              !!+formik.values["max_size"]
                ? adOptions.SIZE_OPTIONS_PARKING.filter(
                    (option) => option <= +formik.values["max_size"]
                  )
                : adOptions.SIZE_OPTIONS_PARKING
            }
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_size"
            label="Plotas (m², iki)"
            options={adOptions.SIZE_OPTIONS_PARKING.filter(
              (option) => option >= +formik.values["min_size"]
            )}
            type="number"
          />
        </Grid>

        {isRent && (
          <>
            <Grid item xs={12}>
              <FormikSelect
                name="min_rent_period"
                label="Orientacinis nuomos terminas"
                options={adOptions.MIN_RENT_PERIOD_CHOICES}
                enableClear={true}
              />
            </Grid>
          </>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            {isParking ? "Parkavimo vieta" : "Garažas"}
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="garage_type"
            label={isParking ? "Parkavimo vietos tipas" : "Garažo tipas"}
            options={garageTypeOptions}
          />
        </Grid>

        <Grid item xs={12} sx={{ border: "none" }}>
          <FormikBasicMultipleSelect
            name="garage_installation"
            label={
              isParking ? "Parkavimo vietos įrengimas" : "Garažo įrengimas"
            }
            options={garageInstallationOptions}
            onChange={(value) => clearAllFilter(value, "garage_installation")}
          />
        </Grid>
        <FormikAndOrFilter name="garage_installation_all" />

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="security"
            label="Saugumas"
            options={adOptions.SECURITY_CHOICES_GARAGE}
            onChange={(value) => clearAllFilter(value, "security")}
          />
        </Grid>
        <FormikAndOrFilter name="security_all" />
      </Grid>
    </>
  );
}

export default FiltersParking;
