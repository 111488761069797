import React, { useState, useEffect } from "react";
import { Button, Grid, Tooltip, Typography } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import humanizeDate from "../../../../utils/humanizeDate";
import humanizeStatus from "../../../../utils/humanizeStatus";
import CheckIcon from "@mui/icons-material/Check";
import {
  crudAppointment,
  crudCalendarEntry,
} from "../../fetchOrSendDashboardData";
import { useHistory } from "react-router-dom";
import useAppointment from "../../MyAds/Appointment/useAppointment";
import useCalendarEntry from "../../MyAds/Appointment/useCalendarEntry";
import useAppointmentMessage from "../../MyAds/Appointment/useAppointmentMessage";

export default function CalendarAgreeButton({
  entry,
  existingAppointment,
  setShowCalendarAgreeButton,
}) {
  const { dispatchDashboard } = useGlobalContext();
  const history = useHistory();
  const [acceptChange, setAcceptChange] = useState(false);

  let appointmentDateTime = !!existingAppointment?.appointment
    ? new Date(existingAppointment.appointment)
    : null;

  const { appointment } = useAppointment(appointmentDateTime);
  const { calendarEntry } = useCalendarEntry(undefined, appointmentDateTime);
  const { appointmentMessage } = useAppointmentMessage("", appointment);

  const crudAppointmentMethod = !!appointment?.appointment ? "patch" : "delete";
  const crudCalendarMethod = !!appointment?.appointment
    ? !!calendarEntry?.id
      ? "patch"
      : "post"
    : !!calendarEntry?.id && crudAppointmentMethod !== "delete" // when Appointment is deleted, CalendarEntry is automatically deleted too
    ? "delete"
    : "skip";

  const appointmentChangeText = `${
    existingAppointment?.appointment_status.includes("owner")
      ? entry.owner_name
      : entry.enquirer_name
  } ${humanizeStatus(existingAppointment.appointment_status, "both")}${
    !!existingAppointment?.appointment
      ? `: ${humanizeDate(existingAppointment.appointment)}`
      : ""
  }`;

  useEffect(() => {
    if (acceptChange) {
      crudAppointment(
        dispatchDashboard,
        appointment,
        crudAppointmentMethod,
        appointmentMessage
      );
      if (crudCalendarMethod !== "skip")
        crudCalendarEntry(dispatchDashboard, calendarEntry, crudCalendarMethod);
      setShowCalendarAgreeButton(false);
      setAcceptChange(false);
      if (crudAppointmentMethod === "delete") {
        dispatchDashboard({
          type: "CRUD_CALENDAR_ENTRY",
          payload: {
            calendarEntryId: calendarEntry.id,
            calendarEntryObject: null,
            method: "delete",
          },
        });
      }
      return () => {
        history.replace(`/dashboard/my-calendar`);
      };
    }
  }, [
    acceptChange,
    appointment,
    appointmentMessage,
    calendarEntry,
    crudAppointmentMethod,
    crudCalendarMethod,
    dispatchDashboard,
    history,
    setShowCalendarAgreeButton,
  ]);

  function handleAcceptAppointmentChange(event) {
    history.replace(
      `/dashboard/my-calendar/${existingAppointment.ad}/${existingAppointment.enquirer}`
    );
    setAcceptChange(true);
  }

  console.log(
    "calendarEntry from CalendarAgreeButton",
    calendarEntry,
    appointment,
    appointmentMessage
  );

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "left",
        alignItems: "center",
        px: 1,
        mb: 1,
        flexWrap: "nowrap",
      }}
    >
      <Typography variant="body2" color="error.dark" sx={{ mr: 1 }}>
        {appointmentChangeText}
      </Typography>
      <Tooltip title="Patvirtinti">
        <Button
          startIcon={<CheckIcon fontSize="small" />}
          size="small"
          variant="contained"
          onClick={handleAcceptAppointmentChange}
          color="primary"
          sx={{ minWidth: "15px" }}
        >
          OK
          {/* <CheckIcon sx={{ fontSize: "15px" }} /> */}
        </Button>
      </Tooltip>
    </Grid>
  );
}
