export const defaultPhotoUrl =
  "http://localhost:8000/media/photos/no_photo_available/no_photo_available_image.jpg";

export const DEFAULT_ASPECT_RATIO = Number(16 / 9);

export const AD_TYPE_URL_PARAMS = {
  butai: "flat_sale",
  namai: "house_sale",
  sklypai: "land_sale",
  patalpos: "premises_sale",
  garazai: "garage_sale",
  "parkavimo-vietos": "parking_sale",
  "butu-nuoma": "flat_rent",
  "namu-nuoma": "house_rent",
  "sklypu-nuoma": "land_rent",
  "patalpu-nuoma": "premises_rent",
  "garazu-nuoma": "garage_rent",
  "parkavimo-vietu-nuoma": "parking_rent",
  "nauji-daugiabuciu-projektai": "apartment_complex",
};

export const CITIES = [
  "Alytus",
  "Kaunas",
  "Klaipėda",
  "Neringa",
  "Palanga",
  "Panevėžys",
  "Šiauliai",
  "Vilnius",
];

export const AD_STATUS_OPTIONS = [
  {
    status: "active",
    label: "Aktyvus (rodomas)",
    color: "success",
    shortLabel: "Aktyvus",
  },
  {
    status: "inactive",
    label: "Neaktyvus (nerodomas)",
    color: "disabled",
    shortLabel: "Neaktyvus",
  },
  {
    status: "reserved",
    label: "Rezervuota (rodomas)",
    color: "warning",
    shortLabel: "Rezervuota",
  },
  {
    status: "sold",
    label: "Parduota (rodomas)",
    color: "error",
    shortLabel: "Parduota",
  },
  {
    status: "rented",
    label: "Išnuomota (rodomas)",
    color: "error",
    shortLabel: "Išnuomota",
  },
  {
    status: "expired",
    label: "Skelbimas nebegalioja",
    color: "disabled",
    shortLabel: "Skelbimas nebegalioja",
  },
  {
    status: "deleted",
    label: "Skelbimas ištrintas",
    color: "disabled",
    shortLabel: "Skelbimas ištrintas",
  },
  {
    status: "blocked",
    label: "Blokuotas",
    color: "error",
    shortLabel: "Skelbimas užblokuotas",
  },
];

export const emptyPrefParams = {
  distance: "",
  relative_value: "",
  gross_rent_return: "",
  basic_shops: "",
  other_shops: "",
  services: "",
  leisure: "",
  primary_education: "",
  higher_education: "",
  healthcare: "",
  accommodation: "",
  tourism: "",
  public: "",
  offices: "",
  bus_stops: "",
  street_network: "",
  footway_network: "",
  residential: "",
  natural: "",
  water: "",
  agricultural: "",
  admin_commercial: "",
  industrial: "",
};

export const PREFERENCE_ARRAY = [
  { param: "distance", label: "Arti man svarbių vietų" },

  { param: "relative_value", label: "Gera kaina (už gaunamą vertę)" },
  { param: "gross_rent_return", label: "Aukšta tikėtina nuomos grąža" },

  { param: "primary_education", label: "Arti mokyklų, darželių" },
  { param: "higher_education", label: "Arti universitetų, kolegijų" },
  { param: "healthcare", label: "Arti sveikatos priežiūros įstaigų" },
  { param: "basic_shops", label: "Arti prekybos centrų" },
  { param: "other_shops", label: "Arti kitų prekybos vietų" },
  { param: "services", label: "Arti paslaugų teikimo vietų" },
  { param: "leisure", label: "Arti laisvalaikio ir pramogų centrų" },
  { param: "public", label: "Arti viešųjų įstaigų" },
  { param: "offices", label: "Arti biurų kompleksų" },
  { param: "tourism", label: "Arti turizmo objektų" },
  { param: "accommodation", label: "Arti apgyvendinimo vietų" },

  { param: "bus_stops", label: "Geras susiekimas viešuoju transportu" },
  { param: "street_network", label: "Išvystytas gatvių tinklas" },
  { param: "footway_network", label: "Išvystytas pėsčiųjų takų tinklas" },

  { param: "natural", label: "Arti gamtos" },
  { param: "water", label: "Arti vandens telkinių" },
  { param: "residential", label: "Arti gyvenamųjų zonų" },
  { param: "admin_commercial", label: "Arti administracinių-komercinių zonų" },
  { param: "industrial", label: "Arti pramoninių zonų" },
  { param: "agricultural", label: "Arti žemės ūkio zonų" },
];

export const FEATURE_OPTIONS = [
  { value: "basic_shops", label: "Prekybos centrai" },
  { value: "primary_education", label: "Mokyklos, darželiai" },
  { value: "healthcare", label: "Sveikatos priežiūra" },
  { value: "bus_stops", label: "Viešasis transportas" },
  { value: "leisure", label: "Laisvalaikis ir pramogos" },
  { value: "offices", label: "Biurų kompleksai" },
  { value: "public", label: "Viešosios įstaigos" },
  { value: "higher_education", label: "Universitetai, kolegijos" },
  { value: "services", label: "Paslaugų teikėjai" },
  { value: "other_shops", label: "Kita prekyba" },
  { value: "tourism", label: "Turizmo objektai" },
  { value: "accommodation", label: "Apgyvendinimo vietos" },
];

export const DISTANCE_OPTIONS = [
  { value: 0.5, label: "0,5 km atstumu" },
  { value: 1, label: "1 km atstumu" },
  { value: 1.5, label: "1,5 km atstumu" },
  { value: 2, label: "2 km atstumu" },
  { value: 2.5, label: "2,5 km atstumu" },
  { value: 3, label: "3 km atstumu" },
];

export const AD_FILTER_OPTIONS = [
  { value: "all", label: "Visi" },
  { value: "Parduodamas butas", label: "Parduodami butai" },
  { value: "Nuomojamas butas", label: "Nuomojami butai" },
  { value: "Parduodamas namas", label: "Parduodami namai" },
  { value: "Nuomojamas namas", label: "Nuomojami namai" },
  { value: "Parduodamas sklypas", label: "Parduodami sklypai" },
  { value: "Nuomojamas sklypas", label: "Nuomojami sklypai" },
  { value: "Parduodamos patalpos", label: "Parduodamos patalpos" },
  { value: "Nuomojamos patalpos", label: "Nuomojamos patalpos" },
  { value: "Parduodamas garažas", label: "Parduodami garažai" },
  { value: "Nuomojamas garažas", label: "Nuomojami garažai" },
  {
    value: "Parduodama parkavimo vieta",
    label: "Parduodamos parkavimo vietos",
  },
  { value: "Nuomojama parkavimo vieta", label: "Nuomojamos parkavimo vietos" },
  {
    value: "Naujas daugiabučių projektas",
    label: "Nauji daugiabučių projektai",
  },
];

export const NOTIFICATION_OPTIONS = [
  { value: "mail", label: "El. paštu" },
  { value: "push_notifications", label: "Ekrano pranešimais" },
  {
    value: "mail,push_notifications",
    label: "El. paštu ir ekrano pranešimais",
  },
];

export const INTERVAL_OPTIONS = [
  // { value: "10_seconds", label: "Kas 10 sekundžių" },
  // { value: "1_minutes", label: "Kartą per minutę" },
  // { value: "1_hours", label: "Kartą per valandą" },
  { value: "12_hours", label: "Du kartus per dieną" },
  { value: "1_days", label: "Kartą per dieną" },
  { value: "7_days", label: "Kartą per savaitę" },
];

export const CHAT_STATUS_MENU_OPTIONS = [
  { value: "irrelevant", label: "Neaktualus" },
  { value: "blocked", label: "Blokuoti" },
  { value: "report", label: "Pranešti" },
];

export const CONTACT_FILTER_OPTIONS = [
  { value: "only relevant", label: "Tik aktualūs" },
  { value: "all", label: "Visi" },
  { value: "only blocked", label: "Tik užblokuoti" },
];

export const ENQUIRY_FILTER_OPTIONS = [
  { value: "only relevant", label: "Tik aktualios" },
  { value: "all", label: "Visos" },
  { value: "only blocked", label: "Tik užblokuotos" },
];
