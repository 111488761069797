import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import {
  PARKING_CHOICES_HOUSE,
  PLOT_INFRASTRUCTURE_CHOICES,
  RECREATION_CHOICES,
  SECURITY_CHOICES_HOUSE,
  WATER_BODY_CHOICES,
  WATER_SEWAGE_CHOICES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function EnvironmentPanelHouse({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { adFormState } = useGlobalContext();
  const panelName = "environmentPanelHouse";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getEnvironmentHouseInitialValues(ad, adOptions);
  const validationSchema = schema.environmentHouseValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={6} md={3} sx={{ my: 1 }}>
              <FormikTextField
                name="plot_area"
                label="Sklypo plotas (arai) *"
                type="number"
              />
            </Grid>

            <Grid item xs={12} md={9} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="water_sewage"
                label="Vanduo ir nuotekos"
                options={WATER_SEWAGE_CHOICES}
              />
            </Grid>

            <Grid item xs={12} sx={{ my: 1, px: 0.5 }}>
              <FormikMultipleSelect
                name="plot_infrastructure"
                label="Sklypo gerbūvis"
                options={PLOT_INFRASTRUCTURE_CHOICES}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="parking"
                label="Automobilio parkavimas"
                options={PARKING_CHOICES_HOUSE}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="security"
                label="Saugumas"
                options={SECURITY_CHOICES_HOUSE}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="recreation"
                label="Viešos poilsio erdvės netoliese"
                options={RECREATION_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="water_body"
                label="Vandens telkiniai netoliese"
                options={WATER_BODY_CHOICES}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
