import {
  Box,
  Container,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { useEffect } from "react";
import Footer from "../Layout/Footer/Footer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useGlobalContext } from "../../context";

export default function AboutUs() {
  const { dispatchRouter } = useGlobalContext();

  useEffect(() => {
    dispatchRouter({
      type: "CHANGE_ROUTER_STATE",
      payload: { from: "aboutUs" },
    });
  }, [dispatchRouter]);

  return (
    <Box
      sx={{
        border: "none",
        backgroundColor: grey[300],
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        pt: 13,
      }}
    >
      <Container maxWidth="md" disableGutters sx={{ p: 0.5 }}>
        <Paper sx={{ p: 1 }}>
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ mt: 2 }}
            color="primary.dark"
          >
            Kas mes ir ko mes siekiame
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body1">
                <b>KurNamai.lt</b> – pirmoji Lietuvoje išmani būsto paieškos
                platforma
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant="body1">
                <b>KurNamai.lt</b> – tai daug daugiau nei dar vienas
                internetinis NT skelbimų portalas. Tiek būsto ieškantiems, tiek
                NT skelbimų savininkams siekiame suteikti unikalias naudas,
                kurių nesuteikia konkurentai
              </Typography>
            </ListItem>

            <ListItem>
              <Typography variant="body1">
                Lietuvos NT rinkos dalyviai puikiai žino, kad NT skelbimų rinka
                Lietuvoje jau daugelį metų “užpelkėjusi” ir neužtikrina
                klientams tinkamo paslaugų kokybės ir kainos santykio. Mes
                žinome, kaip į šią rinką grąžinti sveiką konkurenciją, ir
                tvirtai tikime, kad su Jūsų pagalba pasieksime, jog šalies NT
                skelbimų rinkoje bus teikiamos vis pažangesnės paslaugos daug
                konkurencingesnėmis kainomis
              </Typography>
            </ListItem>
          </List>
        </Paper>

        <Paper sx={{ p: 1, mt: 2 }}>
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ mt: 2 }}
            color="primary.dark"
          >
            Nauda ieškantiems būsto
          </Typography>
          <List>
            <ListItem>
              <Typography variant="body1">
                Žinome, kokios ilgos ir varginančios gali būti būsto paieškos,
                ir kokia didelė neteisingų sprendimų kaina, todėl ieškantiems
                būsto norime sudaryti geriausias galimybes surasti svajonių
                būstą ir priimti teisingą sprendimą:
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Moderni, intuityvi ir neperkrauta informaciniu triukšmu
                vartotojo sąsaja
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Galinga, bet paprasta naudoti paieškos sistema ir detaliausi
                rinkoje skelbimų atrankos filtrai
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Pagaliau įgyvendinta realiai veikianti ir patogi objektų paieška
                žemėlapyje
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Unikalus išmanusis pagalbininkas, padedantis reitinguoti būstus
                pagal asmeninius poreikius
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Pažangūs mašininio mokymosi algoritmai, padedantys nustatyti
                tipinių kainų rėžius ir lengviau surasti geriausios vertės
                pasiūlymus
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Investuotojams, ieškantiems investicijoms tinkamo būsto, mūsų
                taikomi įrankiai padės įvertinti tikėtiną nuomos grąžą ir
                lengviau identifikuoti patraukliausius investicinius objektus
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Galimybė pamatyti aktualius objektus (mokyklas, transporto
                stoteles ir kt.), esančius netoliese nuo dominančio objekto
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Paprastas tiesioginis komunikavimas su skelbimų savininkais
                (chat) ir apžiūrų kalendorius
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Interaktyvi ir lanksti paskolų skaičiuoklė
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Rinkos skaidrumą ir sąžiningumą didinanti skelbimų kainų ir
                būklės istorija
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Ir daug kitų platformos ypatumų, palengvinančių būsto paiešką
              </Typography>
            </ListItem>
          </List>
        </Paper>

        <Paper sx={{ p: 1, mt: 2 }}>
          <List>
            <Typography
              variant="h6"
              textAlign="center"
              sx={{ mt: 2 }}
              color="primary.dark"
            >
              Nauda skelbimų savininkams
            </Typography>
            <ListItem>
              <Typography variant="body1">
                Sėkmingas būsto pardavimas ar išnuomavimas nėra raketų mokslas
                bei neturėtų būti neįveikiama užduotis net ir pirmą būstą
                parduodančiam asmeniui, o taip pat neturėtų būti neadekvačiai
                brangus procesas. Todėl mes siekiame efektyvinti ir atpiginti
                informacijos apie parduodamų ar nuomojamų NT objektų sklaidą:
              </Typography>
            </ListItem>

            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Apsaugosime nuo kūrybinių kančių aprašinėjant objektą: skelbimo
                vedlys užtikrins, kad detalią informaciją apie skelbimą įvesti
                ir suformuoti skelbimą užtruks vos kelias minutes, visa suvesta
                informacija bus automatiškai susisteminta, o skelbimas atrodys
                dalykiškas ir profesionalus (ir, žinoma, galėsite pridėti bet
                kokią papildomą informaciją savo nuožiūra)
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Nebereikės vargti skelbime vardinant visas aplinkines mokyklas,
                darželius, stoteles ir kitus netoliese esančius miesto
                infrastruktūros objektus, nes ši informacija taps prieinama
                vartotojams automatiškai
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Itin paprastas NT objekto nuotraukų talpinimas, redagavimas ir
                karpymas
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Galimybė palyginti prašomą kainą su panašių objektų kainomis
                padės pardavėjui ar nuomotojui padės nustatyti tinkamą NT
                objekto kainą ir išvengti vertinimo brangių klaidų
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Tiek pirmą būstą parduodantys, tiek daugelį skelbimų valdantys
                profesionalūs rinkos dalyviai įvertins patogų tiesioginių
                užklausų (chat) išdėstymą ir valdymą bei apžiūrų laiko derinimą
                su potencialiais klientais
              </Typography>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <CheckCircleIcon color="success" />
              </ListItemIcon>
              <Typography variant="body2">
                Galiausiai ne mažiau svarbu tai, kad teikdami pažangias
                paslaugas už adekvačią kainą prisidėsime prie skaidresnės ir
                sąžiningesnės NT skelbimų rinkos Lietuvoje formavimosi
              </Typography>
            </ListItem>
          </List>
        </Paper>

        <Paper sx={{ p: 1, mt: 2 }}>
          <Typography
            variant="h6"
            textAlign="center"
            sx={{ mt: 2 }}
            color="primary.dark"
          >
            Kontaktai
          </Typography>

          <Stack sx={{ m: 2, mt: 1 }}>
            <Typography variant="body1" fontWeight="bold">
              MB "Ekostatas"
            </Typography>
            <Typography variant="body1">Įm. kodas 303352006</Typography>
            <Typography variant="body1">
              Reg. adr. Verkių g. 1-22, Vilnius
            </Typography>
            <Typography variant="body1">
              El. paštas: info@kurnamai.lt
            </Typography>
          </Stack>
        </Paper>
      </Container>
      <Footer />
    </Box>
  );
}
