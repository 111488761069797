import React, { useState } from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Notes from "./Notes";
import Spinner from "../../../utils/Spinner";
import { useGlobalContext } from "../../../context";

const AccordionNotes = ({ ad }) => {
  const [expanded, setExpanded] = useState(false);
  const { state } = useGlobalContext();

  return (
    <Accordion
      expanded={expanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
      sx={{
        width: "100%",
        // height: expanded ? "500px" : "auto",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="button" color={expanded ? "primary" : "default"}>
          Užrašinė
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ height: "100%" }}>
        {!!state.adsLoading ? <Spinner /> : <Notes ad={ad} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionNotes;
