import { w3cwebsocket } from "websocket";
import { useGlobalContext } from "../../../../context";
import axiosInstance from "../../../../utils/axios";
import { useEffect, useRef, useState } from "react";
import {
  getContactAvatarData,
  refreshAccessToken,
} from "../../../Accounts/fetchOrSendAccountsData";

export default function useNotificationSocket() {
  const {
    userState,
    dispatchUser,
    dispatchNotification,
    dashboardState,
    dispatchDashboard,
  } = useGlobalContext();
  const userId = userState.userId;

  const dashboardStateRef = useRef(dashboardState);
  const socketRef = useRef(null);
  console.log("notification socketRef.current", socketRef.current);
  const [timer, setTimer] = useState(1000);

  useEffect(() => {
    dashboardStateRef.current = dashboardState;
  }, [dashboardState]);

  useEffect(() => {
    if (!!userState.isAuthenticated) {
      refreshAccessToken().then((accessToken) => {
        connectNotificationSocket(accessToken);
      });
    }
    return () => {
      socketRef?.current?.close();
    };
  }, [userState.isAuthenticated]);

  function handleChatMessage(newNotification) {
    if (
      !dashboardStateRef.current.adContacts.find(
        (contact) =>
          contact.ad === newNotification.enquiry.ad &&
          contact.enquirer === newNotification.enquiry.enquirer
      )
    ) {
      dispatchDashboard({
        type: "CHANGE_AD_CONTACTS",
        payload: { contact: newNotification.enquiry },
      });
      getContactAvatarData(dispatchUser);
    }
    dispatchNotification({
      type: "ADD_NEW_NOTIFICATION",
      payload: { newNotification },
    });
    dispatchDashboard({
      type: "UPDATE_CHAT_MESSAGES",
      payload: { chatMessage: newNotification.enquiry },
    });
  }

  function handleDeleteChatMessage(newNotification) {
    // console.log(
    //   "newNotification from handleDeleteChatMessage",
    //   newNotification
    // );
    const enquiryObject = newNotification.enquiry;
    dispatchDashboard({
      type: "CRUD_ENQUIRY_MESSAGE",
      payload: { enquiryObject, method: "patch" },
    });
    dispatchNotification({
      type: "CLEAR_NOTIFICATIONS",
      payload: { notifications_to_delete: [newNotification.id] },
    });
  }

  function handleAppointmentMessage(newNotification) {
    handleChatMessage(newNotification);
    let existingAppointment =
      dashboardStateRef.current.myAppointments.find(
        (appt) =>
          appt.ad === newNotification.enquiry.ad &&
          appt.enquirer === newNotification.enquiry.enquirer
      ) || null;

    console.log(
      "new appointment notification",
      newNotification,
      "existingAppointment id",
      existingAppointment?.id
    );
    let method = !!existingAppointment
      ? !!newNotification.appointment
        ? "patch"
        : "delete"
      : "post";
    dispatchDashboard({
      type: "CRUD_APPOINTMENT",
      payload: {
        appointmentId: existingAppointment?.id || null,
        appointmentObject: newNotification?.appointment || null,
        method,
      },
    });
  }

  function connectNotificationSocket(accessToken) {
    let wsUrl;
    if (process.env.NODE_ENV === "production") {
      wsUrl = `wss://frontend-test-pp8b8.ondigitalocean.app/ws/notification/${userId}/?token=${encodeURIComponent(
        accessToken
      )}`;
      // wsUrl = `wss://kurnamai.lt/ws/notification/${userId}/`;
    } else {
      wsUrl = `ws://localhost:8000/ws/notification/${userId}/?token=${encodeURIComponent(
        accessToken
      )}`;
    }
    document.cookie = "access_token=" + accessToken + ";path=/;";
    socketRef.current = new w3cwebsocket(wsUrl);
    socketRef.current.onopen = (e) => {
      document.cookie =
        "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

      if (socketRef.current.readyState === 1) {
        socketRef.current.send("ping");
      }
    };
    socketRef.current.onmessage = (e) => {
      if (e.data !== "pong") {
        let newNotification = JSON.parse(e.data);
        console.log("got newNotification!", newNotification);
        switch (newNotification["type"]) {
          case "chat_message":
            handleChatMessage(newNotification);
            break;
          case "delete_chat_message":
            handleDeleteChatMessage(newNotification);
            break;
          case "appointment_message":
            handleAppointmentMessage(newNotification);
            break;

          default:
            break;
        }
      }
    };
    socketRef.current.onclose = (e) => {
      console.log("closing notification socket...", e);
      if (e.code !== 1000) {
        refreshAccessToken().then((accessToken) => {
          setTimeout(connectNotificationSocket(accessToken), timer);
          setTimer(timer * 2);
        });
      }
    };
    socketRef.current.onerror = (e) => {
      console.log("WebSocket Client Error", e);
    };
  }
}
