import React from "react";
import { useGlobalContext } from "../../../context";
import AdTypeFilterChip from "../MainFilters/AdTypeFilter/AdTypeFilterChip";
import PriceFilterChip from "../MainFilters/PriceFilter/PriceFilterChip";
import RoomFilterChip from "../MainFilters/RoomFilter/RoomFilterChip";
import MunicipalityFilterChip from "../MainFilters/MunicipalityFilter/MunicipalityFilterChip";
import TownFilterChip from "../MainFilters/TownFilter/TownFilterChip";
import StreetFilterChip from "../MainFilters/StreetFilter/StreetFilterChip";
import {
  AppBar,
  Toolbar,
  ButtonGroup,
  Button,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PlaceIcon from "@mui/icons-material/Place";

import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import DetailedFilterChip from "../MainFilters/DetailedFilter/DetailedFilterChip";
import SuburbFilterChip from "../MainFilters/SuburbFilter/SuburbFilterChip";
import { grey } from "@mui/material/colors";

function MainAppToolbar() {
  const { isMediumOrSmaller, isSmallOrMobile } = useCustomMediaQuery();
  const { dispatch, state } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();

  function handleShowMap(showMap) {
    localStorage.setItem("showMap", showMap);
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { showMap },
    });
  }

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        mt: 7,
        // backgroundColor: "rgba(245, 245, 245, 0.7)",
        backgroundColor: "white",
        backdropFilter: "blur(10px)", // blur the content behind the AppBar
        borderBottom: "1px solid lightgrey",
      }}
    >
      <Toolbar
        variant="dense"
        disableGutters
        sx={{
          // mt: 0.5,
          // mb: -0.5,
          // mt: 0,
          border: "none",
          justifyContent: "space-between",
          alignItems: "center",
          display: "flex",
        }}
      >
        <Box
          sx={{
            border: "none",
            display: "flex",
            alignItems: "center",
            flexWrap: "nowrap",
            pl: 1,
          }}
        >
          <AdTypeFilterChip />
          {!isMediumOrSmaller && (
            <Stack direction="row" sx={{ px: 4 }}>
              <MunicipalityFilterChip />
              <TownFilterChip />
              <SuburbFilterChip />
              <StreetFilterChip />
            </Stack>
          )}
          {!isSmallOrMobile && (
            <Stack direction="row" sx={{ pr: 4 }}>
              <RoomFilterChip />
              <PriceFilterChip />
            </Stack>
          )}
          <DetailedFilterChip />
        </Box>
        {/* {!isMediumOrSmaller && (
          <Grid
            container
            lg={5}
            justifyContent="center"
            sx={{ border: "none", display: "flex" }}
          >
            <MunicipalityFilterChip />
            <TownFilterChip />
            <SuburbFilterChip />
            <StreetFilterChip />
          </Grid>
        )} */}
        <Box sx={{ border: "none", mr: 1, justifyContent: "right" }}>
          <ButtonGroup size="small" variant="outlined">
            <Button
              variant="outlined"
              onClick={() => handleShowMap(false)}
              startIcon={
                !isMobile && (
                  <FormatListBulletedIcon
                    fontSize="small"
                    color={!state.showMap ? "primary" : "action"}
                  />
                )
              }
              sx={{
                color: !state.showMap ? "primary.main" : "#4a4a4a",
                borderColor: !state.showMap ? "primary.light" : grey[400],
                "&:hover": {
                  borderColor: !state.showMap ? "primary.light" : grey[400],
                  backgroundColor: state.showMap && grey[200],
                },
              }}
            >
              {isMobile ? (
                <FormatListBulletedIcon
                  fontSize="small"
                  color={!state.showMap ? "primary" : "action"}
                />
              ) : (
                <Typography variant="caption" sx={{ mt: 0.2 }}>
                  Sąrašas
                </Typography>
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => handleShowMap(true)}
              startIcon={
                !isMobile && (
                  <PlaceIcon
                    fontSize="small"
                    color={state.showMap ? "primary" : "action"}
                  />
                )
              }
              sx={{
                color: state.showMap ? "primary.main" : "#4a4a4a",
                borderColor: state.showMap ? "primary.light" : grey[400],
                borderLeftColor: "primary.light",
                "&:hover": {
                  borderColor: state.showMap ? "primary.light" : grey[400],
                  backgroundColor: !state.showMap && grey[200],
                  borderLeftColor: "primary.light",
                },
              }}
            >
              {isMobile ? (
                <PlaceIcon
                  fontSize="small"
                  color={state.showMap ? "primary" : "action"}
                />
              ) : (
                <Typography variant="caption" sx={{ mt: 0.2 }}>
                  Žemėlapis
                </Typography>
              )}
            </Button>
          </ButtonGroup>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default MainAppToolbar;
