import React, { useEffect } from "react";
import { useGlobalContext } from "../../../context";
import {
  crudSavedSearch,
  crudSearchNotificationSettings,
} from "../fetchOrSendDashboardData";

export default function MySearchesDataProvider({ children }) {
  const {
    userState,
    notificationState,
    dispatchNotification,
    dashboardState,
    dispatchDashboard,
  } = useGlobalContext();

  useEffect(() => {
    if (userState.isAuthenticated) {
      if (
        !notificationState.searchNotificationSettingsLoaded &&
        !notificationState.searchNotificationSettingsLoading
      ) {
        crudSearchNotificationSettings(dispatchNotification, "get");
      }
      if (
        !dashboardState.mySearchesLoaded &&
        !dashboardState.mySearchesLoading
      ) {
        crudSavedSearch(dispatchDashboard, "get");
      }
    }
  }, [
    dashboardState.mySearchesLoaded,
    notificationState.searchNotificationSettingsLoaded,
    userState.isAuthenticated,
  ]);
  return (
    <>
      {notificationState.searchNotificationSettingsLoaded &&
        dashboardState.mySearchesLoaded &&
        children}
    </>
  );
}
