import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
// import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import { Collapse } from "@mui/material";
import ContactListHeader from "./ContactListHeader";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useHistory, useParams } from "react-router-dom";
import { blue } from "@mui/material/colors";
import ContactList from "./ContactList";
import ChatHeader from "./ChatHeader";
import { AppBar, Drawer, DrawerHeader } from "../../MyAds/MyAdChat/utils";

import { useGlobalContext } from "../../../../context";
import MessageBoard from "../../SharedComponents/MessageBoard";
import MessageBar from "../../SharedComponents/MessageBar";

export default function MyEnquiriesChatDrawer() {
  let { adId, extension } = useParams();
  const history = useHistory();
  const { dashboardState } = useGlobalContext();
  const adExists = !!dashboardState.myEnquiryAds.find(
    (ad) => ad.id === Number(adId)
  );
  const [open, setOpen] = React.useState(!extension);

  return (
    <Box sx={{ display: "block", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          maxHeight: 800,
          width: "100%",
          border: "none",
        }}
      >
        {/* <CssBaseline /> */}
        <AppBar
          position="absolute"
          open={open}
          sx={{ bgcolor: blue[100], zIndex: 2 }}
        >
          <Toolbar disableGutters sx={{ border: "none", pl: 4, pr: 1 }}>
            <IconButton
              size="medium"
              onClick={() => {
                history.replace(`/dashboard/my-enquiries`);
              }}
              edge="start"
              sx={{ visibility: "hidden" }}
            >
              <ExpandLessIcon fontSize="medium" />
            </IconButton>
            {adExists && !!extension ? <ChatHeader /> : <ContactListHeader />}
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={open}
          sx={{ border: "none", zIndex: 1 }}
        >
          <DrawerHeader />
          <Divider />

          <ContactList openDrawer={open} setOpenDrawer={setOpen} />
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 0, maxHeight: 800, overflow: "auto" }}
        >
          <DrawerHeader />
          <Collapse in={!open}>
            <MessageBoard />
          </Collapse>
        </Box>
      </Box>
      {!!extension && adExists && <MessageBar />}
    </Box>
  );
}
