import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../../context";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "leaflet-draw/dist/leaflet.draw-src.js";
import { Button } from "@mui/material";
import { grey } from "@mui/material/colors";
import reloadAds from "../../../../utils/getUrlFromParams";
import { FeatureGroup, useMap } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

function PolygonSelector() {
  const map = useMap();
  const { state, dispatch } = useGlobalContext();
  const [editedRadius, setEditedRadius] = useState("");
  const [editedShapeCoords, setEditedShapeCoords] = useState("");

  const params = useParamsFromUrl();
  const history = useHistory();

  const initialPolygon = !!params?.shape_type
    ? {
        shape_type: params?.shape_type,
        shape_coords: params?.shape_coords,
        radius: params.shape_type === "circle" ? params.radius : "",
      }
    : "";
  const [polygon, setPolygon] = useState(initialPolygon);
  const coordinates =
    initialPolygon?.shape_type === "polygon" ||
    initialPolygon?.shape_type === "rectangle"
      ? JSON.parse(initialPolygon.shape_coords).coordinates[0].map((pos) =>
          pos.reverse()
        )
      : initialPolygon?.shape_type === "circle"
      ? JSON.parse(initialPolygon.shape_coords).coordinates.reverse()
      : [];

  const editControlRef = useRef(null);
  const featureGroupRef = useRef(null);

  useEffect(() => {
    // to deal with bug in leaflet-draw
    L.Draw.Polyline.prototype._onTouch = L.Util.falseFn;
  }, []);

  // useEffect(() => {
  //   console.log("state mapBounds", state.mapBounds);
  //   if (!!state.mapBounds) {
  //     // map.fitBounds(state.mapBounds);
  //     // map.setZoom(state.mapZoom);
  //     // alert("fitbounds");
  //     let mapCenter = map.getCenter();
  //     map.setView(mapCenter, state.mapZoom);
  //   }
  // }, [map, state.mapBounds]);

  // add preexisting shape to featureGroupRef from params
  useEffect(() => {
    if (!!featureGroupRef.current) {
      featureGroupRef.current.clearLayers();
      if (params.shape_type === "polygon")
        L.polygon(coordinates).addTo(featureGroupRef.current);
      if (params.shape_type === "rectangle")
        L.rectangle(
          coordinates.filter((x, index) => [0, 2].includes(index))
        ).addTo(featureGroupRef.current);
      if (params.shape_type === "circle")
        L.circle(coordinates, { radius: params.radius }).addTo(
          featureGroupRef.current
        );
    }
  }, [featureGroupRef.current]);

  // activate drawing/editing mode
  useEffect(() => {
    if (!!editControlRef.current?._toolbars) {
      !!polygon.shape_type
        ? editControlRef.current._toolbars.edit._modes.edit.handler.enable()
        : editControlRef.current._toolbars.draw._modes.polygon.handler.enable();
    }
  }, [editControlRef.current?._toolbars, polygon.shape_type]);

  // set map center and zoom after drawing/editing toolbar is loaded
  useEffect(() => {
    if (
      !!editControlRef.current?._toolbars?.draw?._actionButtons?.length ||
      !!editControlRef.current?._toolbars?.edit?._actionButtons?.length
    ) {
      const storedMapCenter = JSON.parse(localStorage.getItem("mapCenter"));
      const storedMapZoom = JSON.parse(localStorage.getItem("mapZoom"));
      if (storedMapCenter && storedMapZoom) {
        map.setView(storedMapCenter, storedMapZoom);
      }
    }
  }, [
    editControlRef.current?._toolbars?.draw?._actionButtons,
    editControlRef.current?._toolbars?.edit?._actionButtons,
    map,
  ]);

  // get editControlRef variable
  function enableDraw(e) {
    editControlRef.current = e;
  }

  // disable pane dragging when drawing circle or rectangle
  function handleDrawStart(e) {
    let layerType = e.layerType;
    if ((layerType === "circle") | (layerType === "rectangle")) {
      e.target.dragging.disable();
    }
  }

  function handleCreatedShape(e) {
    let shapeType = e.layerType,
      layer = e.layer,
      radius = "";
    let shapeCoords = JSON.stringify(layer.toGeoJSON().geometry);
    if (shapeType === "circle") radius = layer.getRadius();
    let polygon = { shape_type: shapeType, shape_coords: shapeCoords, radius };
    setPolygon(polygon);
  }

  function handleEditedShape(e) {
    e.layers.eachLayer((layer) => {
      setEditedRadius(layer?._mRadius ? layer._mRadius : "");
      setEditedShapeCoords(JSON.stringify(layer.toGeoJSON().geometry));
    });
  }

  // change polygon variable after completing editing
  useEffect(() => {
    if (!!editedRadius || !!editedShapeCoords) {
      setPolygon({
        ...polygon,
        radius: editedRadius,
        shape_coords: editedShapeCoords,
      });
    }
  }, [editedRadius, editedShapeCoords]);

  function handleDeleteShape() {
    // need to  set timeout to avoid bug with circle case
    setTimeout(() => {
      featureGroupRef.current.clearLayers();
    }, 100);

    setPolygon({ shape_type: "", shape_coords: "", radius: "" });
  }

  // pass down 'accept' action from MapDialog component
  useEffect(() => {
    if (!!state?.mapDialogOkClick && !!state.drawPolygonMode) {
      let newPolygon = polygon;
      let layer = featureGroupRef.current.getLayers()[0];
      if (!!layer) {
        editControlRef.current._toolbars.edit._modes.edit.handler.save();
        let radius = layer?._mRadius ? layer._mRadius : "";
        let shape_coords = JSON.stringify(layer.toGeoJSON().geometry);
        newPolygon = { ...polygon, shape_coords, radius };
      }
      changeParams(newPolygon);
    }
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { mapDialogOkClick: false },
    });
  }, [state.mapDialogOkClick]);

  function changeParams(polygon) {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { drawPolygonMode: false },
    });
    let newParams = { ...params, ...polygon };
    delete newParams.page;
    reloadAds(newParams, dispatch, history);
  }

  return (
    <>
      <FeatureGroup ref={featureGroupRef}>
        <EditControl
          position="topright"
          onMounted={enableDraw}
          onCreated={handleCreatedShape}
          onEdited={handleEditedShape}
          onDrawStart={handleDrawStart}
          draw={{
            polygon: !!polygon?.shape_type
              ? false
              : {
                  allowIntersection: false,
                  showArea: true,
                },
            rectangle: !!polygon?.shape_type ? false : true,
            circle: !!polygon?.shape_type ? false : true,
            marker: false,
            polyline: false,
            circlemarker: false,
            workAround: Math.random(),
          }}
          edit={{ edit: !!polygon?.shape_type, remove: false }}
        />
        {!!polygon?.shape_type && (
          <Button
            variant="outlined"
            onClick={handleDeleteShape}
            sx={{
              position: "absolute",
              bgcolor: grey[50],
              border: "solid darkgray 2px",
              zIndex: 1000,
              maxWidth: "30px",
              minWidth: "30px",
              maxHeight: "33px",
              minHeight: "33px",
              borderRadius: "4px",
              right: 10,
              top: 45,
              "&:hover": {
                backgroundColor: grey[200],
                border: "solid darkgray 2px",
              },
            }}
          >
            <DeleteForeverIcon
              fontSize="small"
              sx={{ color: "ButtonText", opacity: 0.7 }}
            />
          </Button>
        )}
      </FeatureGroup>
    </>
  );
}

export default PolygonSelector;
