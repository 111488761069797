import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import React, { useCallback } from "react";
import { useGlobalContext } from "../../../context";

function VerticalPhotoList({ activeSlide, open, setOpen }) {
  const { state } = useGlobalContext();
  const photos = state.ad.properties.photos;

  const listRef = useCallback((node) => {
    if (node !== null) {
      node.scrollIntoView();
    }
  }, []);

  return (
    <Dialog fullScreen open={open}>
      <DialogContent sx={{ border: "none", p: 0 }}>
        <div>
          {photos.map((photo, index) => (
            <Box
              ref={activeSlide === index ? listRef : null}
              key={index}
              component="img"
              sx={{ width: "100%" }}
              src={photo.photo}
              alt={index}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => setOpen(false)}>
          Uždaryti
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default VerticalPhotoList;
