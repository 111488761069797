import React from "react";
import { FormControl, Grid, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CONTACT_FILTER_OPTIONS } from "../../../../utils/defaults";
import { useGlobalContext } from "../../../../context";

const menuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "right",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "right",
  },
};

export default function ContactListHeader() {
  const { dashboardState, dispatchDashboard } = useGlobalContext();
  const chatFilterValue =
    dashboardState.myEnquiryChatFilterValue || "only relevant";

  function handleEnquiryFilterChange(e) {
    dispatchDashboard({
      type: "CHANGE_DASHBOARD_STATE",
      payload: { myEnquiryChatFilterValue: e.target.value },
    });
  }

  return (
    <Grid
      container
      xs={12}
      sx={{
        py: 0,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "nowrap",
      }}
    >
      <Box sx={{ ml: 0.5, display: "flex", alignItems: "center" }}>
        <Typography
          variant="subtitle1"
          color="initial"
          sx={{ ml: -6, my: "10px" }}
        >
          Mano užklausų pokalbiai
        </Typography>
      </Box>
      <FormControl
        size="small"
        sx={{
          m: 0.5,
        }}
      >
        <Select
          value={chatFilterValue}
          variant="outlined"
          onChange={handleEnquiryFilterChange}
          MenuProps={menuProps}
          inputProps={{
            sx: { alignItems: "center", display: "flex", borderRadius: "3px" },
          }}
          sx={{ height: 30 }}
        >
          {CONTACT_FILTER_OPTIONS.map((option, key) => (
            <MenuItem key={key} dense value={option.value}>
              <Typography variant="caption">{option.label}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  );
}
