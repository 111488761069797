import { useEffect } from "react";
import { useGlobalContext } from "../../../../context";
import { fetchMunicipalityOptions } from "../../fetchOrSendMainAppData";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

export default function MunicipalityFilterDataProvider({ children }) {
  const { dispatch, state } = useGlobalContext();

  const params = useParamsFromUrl();

  useEffect(() => {
    if (!state.municipalityOptionsLoaded && !state.municipalityOptionsLoading) {
      fetchMunicipalityOptions(dispatch);
    }
  }, [
    dispatch,
    params.municipality,
    state.municipalityOptionsLoaded,
    state.municipalityOptionsLoading,
  ]);

  return children;
}
