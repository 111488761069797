import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Stack,
  Button,
  Box,
  TextareaAutosize,
  styled,
  TextField,
} from "@mui/material";
import { useGlobalContext } from "../../../context";
import { createNote, updateNote } from "../fetchOrSendAdData";
import { blue } from "@mui/material/colors";
import { te } from "date-fns/locale";

const StyledTextareaAutosize = styled(TextareaAutosize)({
  fontSize: "14px",
  width: "100%",
  height: "100%",
  minHeight: "250px",
  maxHeight: "350px",
  padding: "8px",
  flexGrow: 1,
  flexShrink: 1,
  resize: "none",
  borderRadius: "5px",
  border: "solid lightgrey 1px",
  "&:focus": {
    outline: "none",
    // border: "solid 2px",
    // borderColor: blue[600],
    boxShadow: `0 0 0 1px ${blue[500]}`,
  },
});

export default function Notes({ ad }) {
  const { dispatch, userState, dispatchUser } = useGlobalContext();
  const textAreaRef = useRef(null);
  const [note, setNote] = useState(ad?.properties?.note?.note || "");
  const [noteEdited, setNoteEdited] = useState(false);
  console.log("note from Notes component", ad?.properties?.note?.note, note);

  useEffect(() => {
    setNote(ad?.properties?.note?.note || "");
    setNoteEdited(false);
  }, [ad?.properties?.note?.note]);

  function handleClick(e) {
    if (!userState.isAuthenticated) {
      dispatchUser({
        type: "CHANGE_USER_STATE",
        payload: { openLoginDialog: true },
      });
      textAreaRef.current.blur();
    }
  }

  function handleSaveNote() {
    !!ad.properties?.note?.id
      ? updateNote(ad.id, ad.properties.note.id, { note }, dispatch)
      : createNote(ad.id, { note }, dispatch);
    setNoteEdited(false);
  }

  function handleCancelNote() {
    setNote(ad.properties?.note?.note || "");
    setNoteEdited(false);
  }

  function handleNoteChange(e) {
    setNote(e.target.value);
    setNoteEdited(true);
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
        border: "none",
      }}
    >
      <StyledTextareaAutosize
        ref={textAreaRef}
        value={note}
        onChange={handleNoteChange}
        spellCheck={false}
        placeholder="Asmeninės pastabos. Jas matysite tik jūs."
        onClick={handleClick}
      />
      {/* <TextField
        multiline
        // maxRows={11}
        value={note}
        onChange={handleNoteChange}
        spellCheck={false}
        placeholder="Asmeninės pastabos. Jas matysite tik jūs."
        sx={{
          fontSize: "14px",
          wdth: "100%",
          // maxWidth: "100%",
          // height: "100%",
          minHeight: "250px",
          maxHeight: "350px",
          padding: "8px",
          flexGrow: 1,
          flexShrink: 1,
          resize: "none",
          borderRadius: "5px",
          // border: "1px solid",
          "&:focus": {
            outline: "none",
            border: "2px solid",
            borderColor: blue[600],
          },
        }}
      /> */}
      {noteEdited && note !== ad.properties?.note?.note && (
        <Stack
          direction="row"
          sx={{
            justifyContent: "right",
            alignItems: "center",
            border: "none",
            my: 1,
          }}
          spacing={1}
        >
          <Typography variant="body2">Išsaugoti pakeitimus?</Typography>
          <Button variant="outlined" size="small" onClick={handleCancelNote}>
            Atmesti
          </Button>
          <Button variant="contained" size="small" onClick={handleSaveNote}>
            Išsaugoti
          </Button>
        </Stack>
      )}
    </Box>
  );
}
