import React, { useEffect, useRef } from "react";
import { Circle, FeatureGroup, Polygon, Rectangle } from "react-leaflet";
import { useGlobalContext } from "../../../../context";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

function SelectedShape() {
  const { dispatch } = useGlobalContext();
  const shapeRef = useRef(null);
  const params = useParamsFromUrl();

  const polygonPositions =
    params?.shape_type === "polygon"
      ? JSON.parse(params.shape_coords).coordinates[0].map((pos) =>
          pos.reverse()
        )
      : null;
  const rectangleBounds =
    params?.shape_type === "rectangle"
      ? JSON.parse(params.shape_coords)
          .coordinates[0].map((pos) => pos.reverse())
          .filter((x, index) => [0, 2].includes(index))
      : null;
  const circleCenter =
    params?.shape_type === "circle"
      ? JSON.parse(params.shape_coords).coordinates.reverse()
      : null;
  const circleRadius = params?.radius || null;

  // get and save shape bounds
  useEffect(() => {
    let shapeBounds = "";
    if (!!params?.shape_type && !!shapeRef.current)
      shapeBounds = shapeRef.current.getBounds();
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { shapeBounds },
    });
  }, [dispatch, params?.shape_type]);

  return (
    <>
      {!!params?.shape_type && (
        <FeatureGroup ref={shapeRef}>
          {params.shape_type === "polygon" && (
            <Polygon positions={polygonPositions} />
          )}
          {params.shape_type === "rectangle" && (
            <Rectangle bounds={rectangleBounds} />
          )}
          {params.shape_type === "circle" && (
            <Circle center={circleCenter} radius={+circleRadius} />
          )}
        </FeatureGroup>
      )}
    </>
  );
}

export default SelectedShape;
