import React, { useEffect, useRef } from "react";
import { useGlobalContext } from "../../../context";
import AdList from "../MainListing/AdList";
import BasicMapComponent from "../MainMap/BasicMapComponent";
import PageNavigation from "../ResultControlPanel/PageNavigation";
import { Collapse, Stack } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";

import ResultControlPanel from "../ResultControlPanel/ResultControlPanel";
import { debounce } from "../../../utils/debounce";
import useCurrentWidth from "../../../hooks/useCurrentWidth";
import { grey } from "@mui/material/colors";
import Footer from "../../Layout/Footer/Footer";

export default function AppLayoutSmallScreen() {
  const { dispatch, state } = useGlobalContext();
  const initialScrollPosition = state?.previousScrollPosition || 0;
  const { width, height } = useCurrentWidth();
  const SMALL_MAP_HEIGHT = height * 0.45;

  const adContainerRef = useRef(null);
  const mapContainerRef = useRef(null);

  useEffect(() => {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { mapContainerRef },
    });
  }, [dispatch, width, height]);

  // set ad container scroll position upon loading
  useEffect(() => {
    if (!!adContainerRef?.current) {
      adContainerRef.current.scrollTop = initialScrollPosition;
    }
  }, [adContainerRef?.current]);

  // save debounced scroll position in state
  const getScrollPosition = debounce((scrollPosition) => {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { previousScrollPosition: scrollPosition },
    });
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        border: "none",
        flexGrow: 1,
        overflow: "hidden",
      }}
    >
      <Collapse
        in={state.showMap || state.drawPlacesMode}
        style={{ minHeight: "unset" }}
      >
        <Box
          ref={mapContainerRef}
          id="map-box"
          sx={{
            position: "relative",
            height: `${SMALL_MAP_HEIGHT}px`,
            width: "100%",
          }}
        >
          <BasicMapComponent mapDialog={false} />
        </Box>
      </Collapse>

      <Stack
        ref={adContainerRef}
        sx={{
          backgroundColor: grey[300],
          overflow: "auto",
          px: 0.5,
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
        onScroll={() => getScrollPosition(adContainerRef.current.scrollTop)}
      >
        <ResultControlPanel />
        <Stack sx={{ position: "relative", border: "none", flexGrow: 1 }}>
          {!!state?.adCardsLoading && (
            <CircularProgress
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          )}
          {!!state.adCardsLoaded && !state?.adCardsLoading && (
            <>
              <AdList />
              <PageNavigation />
            </>
          )}
          {!!state.adCardsLoaded && !state.adCardsLoading && <Footer />}
        </Stack>
      </Stack>
    </Box>
  );
}
