import React, { useEffect, useState } from "react";
import { Divider, Grid, Slider, Typography } from "@mui/material";
import useFilterType from "../hooks/useFilterType";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

const marks = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6+" },
];

export default function RoomFilter({
  roomParams,
  setRoomParams,
  setRoomParamsHaveChanged,
}) {
  const params = useParamsFromUrl();
  const { isFlat, isHouse, isApartmentComplex } = useFilterType(params);
  const minRooms = isApartmentComplex ? "rooms_from" : "min_rooms";
  const maxRooms = isApartmentComplex ? "rooms_to" : "max_rooms";
  const initialRoomParams = {
    [minRooms]: params[minRooms] || null,
    [maxRooms]: params[maxRooms] || null,
  };

  const [roomRange, setRoomRange] = useState([
    Number(params[minRooms]) || 1,
    Number(params[maxRooms]) || 6,
  ]);

  useEffect(() => {
    // console.log(
    //   "initialRoomParams",
    //   +initialRoomParams[minRooms],
    //   roomParams[minRooms],
    //   +initialRoomParams[maxRooms],
    //   roomParams[maxRooms]
    // );
    setRoomParamsHaveChanged(
      +initialRoomParams[minRooms] !== (+roomParams[minRooms] || 0) ||
        +initialRoomParams[maxRooms] !== (+roomParams[maxRooms] || 0)
    );
  }, []);

  function handleRoomRangeChange(event, newRoomRange) {
    const newRoomParams = {
      [minRooms]: newRoomRange[0] === 1 ? null : newRoomRange[0],
      [maxRooms]: newRoomRange[1] === 6 ? null : newRoomRange[1],
    };
    setRoomParamsHaveChanged(
      +initialRoomParams[minRooms] !== +newRoomParams[minRooms] ||
        +initialRoomParams[maxRooms] !== +newRoomParams[maxRooms]
    );
    setRoomParams(newRoomParams);
    setRoomRange(newRoomRange);
  }

  return (
    <>
      {(isFlat || isHouse || isApartmentComplex) && (
        <Grid container sx={{ border: "none" }}>
          <Grid item xs={12}>
            <Divider textAlign="center" sx={{ my: 1 }}>
              <Typography variant="subtitle1" color="primary">
                Kambarių skaičius
              </Typography>
            </Divider>
          </Grid>

          <Grid
            container
            sx={{ border: "none", display: "block", px: 0.5, mx: 2 }}
          >
            <Slider
              value={roomRange}
              onChange={handleRoomRangeChange}
              marks={marks}
              min={1}
              max={6}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
