import { AD_TYPE_URL_PARAMS } from "./defaults";

export default function getUrlFromParams(params, dispatch, history) {
  const nonZeroParams = Object.entries(params).filter((entry) => !!entry[1]);
  const filterParams = Object.fromEntries(nonZeroParams);

  // generate adTypeLabel
  const { ad_type, ...otherParams } = filterParams;
  const adTypeLabel =
    Object.keys(AD_TYPE_URL_PARAMS).find(
      (key) => AD_TYPE_URL_PARAMS[key] === ad_type
    ) || "butai";

  // generate queryString
  const queryString = require("query-string");
  let urlString = "";
  if (!!Object.keys(otherParams).length) {
    urlString =
      "?" +
      queryString.stringify(otherParams, {
        arrayFormat: "comma",
      });
  }

  const urlObject = { pathname: `/${adTypeLabel}/`, search: urlString };

  if (!!history) {
    history.push(urlObject);
  }

  if (!!dispatch) {
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { adCardsLoaded: false, adsLoaded: false },
    });
  }
  return urlObject;
}
