import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Grid } from "@mui/material";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import Input from "react-phone-number-input";
import CustomPhoneTextField from "../../../AdForm/Step1AdInfo/OwnerDetailsPanels/CustomPhoneTextField";
// import FormPreview from "../../../AdForm/utils/FormPreview";

export default function EnquirerCredentials({
  enquirerCredentials,
  setEnquirerCredentials,
  setEnquirerCredentialsErrors,
}) {
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Privalomas laukas")
      .max(50, "Maks. 50 simbolių"),
    phone: Yup.string()
      .max(20, "Maks. 20 simbolių")
      .test("is-valid-phone", "Netinkamas telefono numeris", (value) => {
        if (value && value.startsWith("+370")) {
          return value.length === 12;
        }
        return true;
      })
      .nullable(),
    // phone: Yup.string().max(50, "Maks. 50 simbolių"),
    // email: Yup.string().email("Turi būti tinkamas el. pašto adresas"),
  });

  const [values, setValues] = useState(enquirerCredentials);
  const [errors, setErrors] = useState({});
  const [phoneValue, setPhoneValue] = useState(
    String(enquirerCredentials.phone)
  );

  useEffect(() => {
    setEnquirerCredentials(values);
    setEnquirerCredentialsErrors(errors);
  }, [values, errors, setEnquirerCredentials, setEnquirerCredentialsErrors]);

  function handlePhoneChange(value, formik) {
    const newPhoneValue = !!value ? value : "";
    setPhoneValue(newPhoneValue);
    formik.setFieldValue("phone", newPhoneValue);
  }

  console.log("phoneValue from EnquirerCredentials", phoneValue);

  return (
    <Formik
      initialValues={enquirerCredentials}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      validateOnChange={true}
      onSubmit={() => {}}
    >
      {(formik) => {
        setValues(formik.values);
        setErrors(formik.errors);
        return (
          <Form autoComplete="off" noValidate style={{ width: "100%" }}>
            <Grid container item spacing={1} xs={12} sx={{ border: "none" }}>
              <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                <FormikTextField name="name" label="Vardas *" />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ mt: 3 }}>
                {/* <FormikTextField name="phone" label="Telefonas" /> */}
                <Input
                  international
                  value={phoneValue || ""}
                  onChange={(value) => handlePhoneChange(value, formik)}
                  onBlur={() => formik.setFieldTouched("phone", true)}
                  // placeholder="Telefonas"
                  defaultCountry="LT"
                  countryCallingCodeEditable={false}
                  error={formik.touched.phone && formik.errors.phone}
                  inputComponent={CustomPhoneTextField}
                  variant="outlined"
                  label="Telefonas"
                />
              </Grid>
            </Grid>
            {/* <Grid container item xs={12} sx={{ mt: 3 }}>
              <FormikTextField name="email" label="El. paštas" />
            </Grid> */}
            {/* <FormPreview /> */}
          </Form>
        );
      }}
    </Formik>
  );
}
