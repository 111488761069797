import { useParams } from "react-router-dom";
import { useGlobalContext } from "../../../../../context";
import { deleteNotifications } from "../../fetchHeaderData";

export default function useClearMessageNotifications() {
  const { adId, enquirerId, extension } = useParams();
  const { notificationState, userState, dispatchNotification } =
    useGlobalContext();
  const userId = userState.userId;

  const messageNotificationId = notificationState.notifications.find(
    (notification) =>
      notification.type === "chat_message" &&
      notification.recipient === userId &&
      notification.enquiry.ad === +adId &&
      notification.enquiry.enquirer === +enquirerId
  )?.id;

  if (
    !!extension &&
    !!messageNotificationId &&
    !notificationState.notificationsDeleting
  ) {
    deleteNotifications(dispatchNotification, messageNotificationId);
  }
}
