import React from "react";
import { Box, MenuItem, Stack, Typography } from "@mui/material";
import { useGlobalContext } from "../../../../../context";
import CustomAvatar from "../../../CustomAvatar";
import { formatDistanceStrict } from "date-fns";
import ltLocale from "date-fns/locale/lt";
import { getFullImgUrl } from "../../../../../utils/getFullImgUrl";
import { getDefaultPhotoUrl } from "../../../../../utils/getDefaultPhotoUrl";

export default function ChatMessageNotification({
  notification,
  handleClickItem,
}) {
  const { dashboardState } = useGlobalContext();

  const dateNow = new Date();
  const getDuration = (d) =>
    formatDistanceStrict(0, d, { locale: ltLocale, addSuffix: true });
  const duration = dateNow - new Date(notification.date);
  const humanizedDuration = getDuration(duration);

  const photoUrl = getFullImgUrl(
    dashboardState.myAds
      .concat(dashboardState.myEnquiryAds)
      .find((ad) => ad.id === notification.enquiry.ad)?.properties?.photos[0]
      ?.photo_thumbnail
  );

  const isEnquirer = notification.enquiry.reply;
  const tab = isEnquirer ? "my-enquiries" : "my-ads";
  const pushUrl = `/dashboard/${tab}/${notification.enquiry.ad}/${notification.enquiry.enquirer}/chat`;
  const adContact = dashboardState.adContacts.find(
    (contact) =>
      contact.ad === notification.enquiry.ad &&
      contact.enquirer === notification.enquiry.enquirer
  );
  const sender = adContact[isEnquirer ? "owner" : "enquirer"];
  const senderName = adContact[isEnquirer ? "owner_name" : "name"];

  return (
    <MenuItem
      onClick={() => handleClickItem(pushUrl)}
      sx={{ maxWidth: "450px" }}
    >
      <Stack sx={{ width: "100%" }}>
        <Box
          sx={{
            border: "none",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={photoUrl || getDefaultPhotoUrl()}
            alt="ad photo"
            sx={{
              width: "15%",
              border: "none",
            }}
          />
          <Stack
            sx={{
              overflow: "hidden",
              width: "85%",
              height: "100%",
              justifyContent: "space-around",
              ml: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexGrow: 0,
                  overflow: "hidden",
                }}
              >
                <CustomAvatar
                  userId={sender}
                  name={senderName}
                  styleProps={{ height: "20px", width: "20px", mr: 1 }}
                />
                <Typography
                  variant="subtitle2"
                  sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
                >
                  {senderName} parašė žinutę
                </Typography>
              </Box>
            </Box>
            <Typography
              variant="body2"
              sx={{
                width: "100%",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {notification.enquiry.message}
            </Typography>
          </Stack>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "right", mb: -1 }}>
          <Typography variant="caption" color="GrayText">
            {humanizedDuration}
          </Typography>
        </Box>
      </Stack>
    </MenuItem>
  );
}
