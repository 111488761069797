import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useCalendarEntry from "../../MyAds/Appointment/useCalendarEntry";
import RelatedContacts from "./RelatedContacts";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { useGlobalContext } from "../../../../context";
import EnquirerCredentials from "./EnquirerCredentials";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import useAppointment from "../../MyAds/Appointment/useAppointment";
import { useHistory } from "react-router-dom";
import useAppointmentMessage from "../../MyAds/Appointment/useAppointmentMessage";
import DateTimePickerComponent from "../../../../utils/DateTimePickerComponent";
import {
  crudAppointment,
  crudCalendarEntry,
  listCreateEnquiryMessage,
} from "../../fetchOrSendDashboardData";
import { submitInitialEnquiry } from "../../../IndividualAd/fetchOrSendAdData";

export default function NewCalendarEntryDialog({
  openNewEntryDialog,
  setOpenNewEntryDialog,
}) {
  const { dashboardState, dispatchDashboard, userState, dispatchUser } =
    useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const history = useHistory();
  let initialAppointmentDateTime = dashboardState.calendarDate;
  initialAppointmentDateTime.setHours(0, 0, 0, 0);
  const [appointmentDateTime, setAppointmentDateTime] = useState(
    initialAppointmentDateTime
  );
  const [appointmentDateTimeChanged, setAppointmentDateTimeChanged] =
    useState(false);
  const [calendarError, setCalendarError] = useState(false);
  const [memo, setMemo] = useState();
  const [entryType, setEntryType] = useState("private");
  const [relatedContact, setRelatedContact] = useState(null);
  const [messageChecked, setMessageChecked] = useState(false);
  const [message, setMessage] = useState("");
  const [enquirerCredentials, setEnquirerCredentials] = useState({
    name: userState?.currentUser?.name || "",
    phone: userState?.currentUser?.phone || "",
    // email: userState?.currentUser?.email || "",
  });
  const [enquirerCredentialsErrors, setEnquirerCredentialsErrors] = useState(
    {}
  );

  const { calendarEntry } = useCalendarEntry(memo, appointmentDateTime);
  calendarEntry.private = entryType === "private";
  if (entryType === "private" && calendarEntry.enquirer === userState.userId) {
    calendarEntry.enquirer = null;
  }

  const { appointment } = useAppointment(calendarEntry.calendar_date);
  const { appointmentMessage } = useAppointmentMessage(message, appointment);
  if (!!appointmentMessage) {
    Object.assign(appointmentMessage, enquirerCredentials);
  }
  const isEnquirer = !appointmentMessage?.reply;

  const isFirstEnquiry = !dashboardState.adContacts.find(
    (enquiry) =>
      enquiry.ad === calendarEntry.ad &&
      enquiry.enquirer === calendarEntry.enquirer
  );

  useEffect(() => {
    const adId = relatedContact?.adId;
    const enquirerId = relatedContact?.enquirerId;
    history.replace(`/dashboard/my-calendar/`);
    if (!!adId && !!enquirerId) {
      history.replace(`/dashboard/my-calendar/${adId}/${enquirerId}`);
    }
    return () => {
      history.replace(`/dashboard/my-calendar`);
    };
  }, [entryType, history, relatedContact]);

  function handleXClose(event, reason) {
    // event.stopPropagation();
    if (!!reason && reason === "backdropClick") return;
    setOpenNewEntryDialog(false);
  }

  function handleOkClose() {
    let method = !!calendarEntry.id ? "patch" : "post";
    crudCalendarEntry(dispatchDashboard, calendarEntry, method);
    if (!calendarEntry.private) {
      const isInitialEnquiry =
        isEnquirer &&
        !dashboardState.myEnquiryAds.find((ad) => ad.id === calendarEntry.ad);
      crudAppointment(
        dispatchDashboard,
        appointment,
        method,
        appointmentMessage,
        isInitialEnquiry
      );
      if (isInitialEnquiry) {
        let ad = dashboardState.myFavorites.find(
          (ad) => ad.id === calendarEntry.ad
        );
        submitInitialEnquiry(
          ad,
          appointmentMessage,
          dispatchDashboard,
          dispatchUser
        );
      }
    }

    setOpenNewEntryDialog(false);
  }

  return (
    <Dialog
      open={openNewEntryDialog}
      onClose={handleXClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>Naujas kalendoriaus įrašas</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container sx={{ border: "none" }}>
            <Grid xs={12} item sx={{ mt: 3 }}>
              <FormControl fullWidth>
                <InputLabel>Įrašo tipas</InputLabel>
                <Select
                  value={entryType}
                  label="Įrašo tipas"
                  onChange={(e) => setEntryType(e.target.value)}
                >
                  <MenuItem value="private">Asmeninis įrašas</MenuItem>
                  <MenuItem value="appointment">
                    Apžiūra (pranešti kontaktui)
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sx={{ mt: 3 }}>
              <RelatedContacts
                selectedOption={relatedContact}
                setSelectedOption={setRelatedContact}
                entryType={entryType}
              />
            </Grid>

            {!calendarEntry.private && !!calendarEntry.id && (
              <Grid
                item
                xs={12}
                alignItems="start"
                sx={{ display: "flex", mt: 1, border: "none" }}
              >
                <PriorityHighIcon
                  color="warning"
                  sx={{ fontSize: 16, mt: 0.2 }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    border: "none",
                    flexWrap: "wrap",
                  }}
                >
                  Kalendoriaus įrašas apie apžiūrą jau egzistuoja. Jis bus
                  pakeistas.
                </Typography>
              </Grid>
            )}

            <DateTimePickerComponent
              initialAppointmentDateTime={initialAppointmentDateTime}
              appointmentDateTime={appointmentDateTime}
              setAppointmentDateTime={setAppointmentDateTime}
              setCalendarError={setCalendarError}
              setAppointmentDateTimeChanged={setAppointmentDateTimeChanged}
            />

            {!calendarEntry.private &&
              !!calendarEntry.ad &&
              (!calendarEntry.id || !!appointmentDateTimeChanged) && (
                <Grid
                  item
                  alignItems="start"
                  sx={{ border: "none", display: "flex", mt: 1 }}
                >
                  <PriorityHighIcon
                    color="warning"
                    sx={{ fontSize: 16, mt: 0.2 }}
                  />
                  <Typography variant="body2">
                    Apie {!!calendarEntry.id ? "pakeistą" : "pasiūlytą"} laiką
                    bus pranešta kontaktui
                  </Typography>
                </Grid>
              )}

            <Grid item xs={12} sx={{ mt: 3 }}>
              <TextField
                label="Asmeninė pastaba mano kalendoriuje"
                value={calendarEntry.memo}
                onChange={(e) => setMemo(e.target.value)}
                fullWidth
                multiline
                rows={2}
                maxRows={4}
                width="200px"
                inputProps={{ maxLength: 200, spellCheck: false }}
              />
            </Grid>

            {!calendarEntry.private && !!calendarEntry.ad && isFirstEnquiry && (
              <EnquirerCredentials
                enquirerCredentials={enquirerCredentials}
                setEnquirerCredentials={setEnquirerCredentials}
                setEnquirerCredentialsErrors={setEnquirerCredentialsErrors}
              />
            )}

            {!calendarEntry.private &&
              !!calendarEntry.ad &&
              (!calendarEntry.id || appointmentDateTimeChanged) && (
                <Grid container xs={12} justifyContent="right" sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={messageChecked}
                        onChange={(event) =>
                          setMessageChecked(event.target.checked)
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">
                        Rašyti papildomą žinutę kontaktui
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </Grid>
              )}

            {!calendarEntry.private &&
              !!calendarEntry.ad &&
              (!calendarEntry.id || appointmentDateTimeChanged) &&
              messageChecked && (
                <TextField
                  label="Žinutė kontaktui"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  fullWidth
                  multiline
                  maxRows={4}
                  rows={2}
                  width="200px"
                />
              )}
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleXClose} color="primary" variant="outlined">
          Atšaukti
        </Button>
        <Button
          onClick={handleOkClose}
          disabled={
            !!calendarError ||
            !appointmentDateTime ||
            !!Object.keys(enquirerCredentialsErrors).length ||
            (entryType === "appointment" && !relatedContact)
          }
          color="primary"
          variant="contained"
          autoFocus
        >
          Gerai
        </Button>
      </DialogActions>
    </Dialog>
  );
}
