export default function useAdType(ad) {
  const isHouse = ["Parduodamas namas", "Nuomojamas namas"].includes(
    ad.properties.ad_type
  );
  const isFlat = ["Parduodamas butas", "Nuomojamas butas"].includes(
    ad.properties.ad_type
  );
  const isPremises = ["Parduodamos patalpos", "Nuomojamos patalpos"].includes(
    ad.properties.ad_type
  );
  const isLand = ["Parduodamas sklypas", "Nuomojamas sklypas"].includes(
    ad.properties.ad_type
  );

  const isGarage = ["Parduodamas garažas", "Nuomojamas garažas"].includes(
    ad.properties.ad_type
  );

  const isParking = [
    "Parduodama parkavimo vieta",
    "Nuomojama parkavimo vieta",
  ].includes(ad.properties.ad_type);

  const isApartmentComplex =
    ad.properties.ad_type === "Naujas daugiabučių projektas";

  const isSale = [
    "Parduodamas namas",
    "Parduodamas butas",
    "Parduodamas sklypas",
    "Parduodamos patalpos",
    "Parduodamas garažas",
    "Parduodama parkavimo vieta",
    "Naujas daugiabučių projektas",
  ].includes(ad.properties.ad_type);
  const isRent = [
    "Nuomojamas namas",
    "Nuomojamas butas",
    "Nuomojamas sklypas",
    "Nuomojamos patalpos",
    "Nuomojamas garažas",
    "Nuomojama parkavimo vieta",
  ].includes(ad.properties.ad_type);

  const isFarmLand = ad.properties?.plot_purpose === "Žemės ūkio";
  const isForest = ad.properties?.plot_purpose === "Miškų ūkio";
  const isLoggingForest =
    ad.properties?.is_logging_forest === true
      ? "Galimas"
      : ad.properties?.is_logging_forest === false
      ? "Negalimas"
      : null;

  return {
    isFlat,
    isHouse,
    isPremises,
    isLand,
    isFarmLand,
    isForest,
    isLoggingForest,
    isGarage,
    isParking,
    isApartmentComplex,
    isSale,
    isRent,
  };
}
