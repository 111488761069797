import React from "react";
import LocationSearchAutocomplete from "./LocationSearchAutocomplete";

function LocationSearch({ openSearch, setOpenSearch }) {
  return (
    <LocationSearchAutocomplete
      openSearch={openSearch}
      setOpenSearch={setOpenSearch}
    />
  );
}

export default LocationSearch;
