import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useField, useFormikContext } from "formik";

function FormikAndOrFilter({ name, ...props }) {
  const [field, meta, helpers] = useField(name);
  const formik = useFormikContext();
  const mainFieldValue = formik.values[name.replace("_all", "")];

  function handleChange(e) {
    const newValue = e.target.value === "true" || "";
    helpers.setValue(newValue);
    // setValue(newValue);
  }

  return (
    <>
      {mainFieldValue?.split(",")?.length > 1 && (
        <Grid container alignItems="center" justifyContent="right">
          <Typography variant="body1" fontSize="small" sx={{ mr: 2 }}>
            Turi atitikti:
          </Typography>
          <FormControl component="fieldset" size="small">
            <RadioGroup {...field} name={name} onChange={handleChange} row>
              <FormControlLabel
                value={""}
                control={<Radio size="small" />}
                label={
                  <Typography variant="body1" fontSize="small">
                    Bent vieną kriterijų
                  </Typography>
                }
              />
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label={
                  <Typography variant="body1" fontSize="small">
                    Visus kriterijus
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
    </>
  );
}

export default FormikAndOrFilter;
