import React, { useEffect, useState } from "react";
import { Grid, Button, Typography, Paper, Stack } from "@mui/material";
import { Box } from "@mui/system";
import "react-image-crop/dist/ReactCrop.css";
import DropZoneComponent from "./DropZoneComponent";
import DragDropPhotoList from "./DragDropPhotoList";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { grey } from "@mui/material/colors";
import { useGlobalContext } from "../../../context";
import { fetchPaymentMethods } from "../fetchOrSendAdDraftData";
// import AdFormAppBar from "../AdFormAppBar";

function FormStep2({ ad, handleBack, handleNext }) {
  const [disableDrag, setDisableDrag] = useState(false);
  const { isMobile } = useCustomMediaQuery();
  const { dispatchAdForm, adFormState } = useGlobalContext();

  // it has to rerender whenever photos in dashboardState change!
  const photos = ad.properties.photos.sort((a, b) => a.place - b.place);
  console.log("photos from FormStep2", photos);

  useEffect(() => {
    if (!adFormState.paymentMethodsLoading && !adFormState.paymentMethodsLoaded)
      fetchPaymentMethods(dispatchAdForm);
  }, [
    adFormState.paymentMethodsLoaded,
    adFormState.paymentMethodsLoading,
    dispatchAdForm,
  ]);

  function handleMouseDown(e) {
    e.stopPropagation(); // to prevent sliding in the background
    setDisableDrag(false);
  }

  return (
    <>
      {/* <AdFormAppBar
        handlePanelChange={handleBack}
        handleInitiateSubmit={handleNext}
      /> */}

      <Box sx={{ border: "none" }} onMouseDown={handleMouseDown}>
        <DropZoneComponent ad={ad} isDraft={true} />

        <Paper sx={{ mt: 2, p: 1, backgroundColor: grey[50] }}>
          {/* <Grid
            container
            sx={{
              border: "solid",
              display: "flex",
              alignItems: "end",
              // mt: 1,
            }}
          > */}
          {!!ad.properties.photos.length ? (
            <Stack spacing={1} sx={{ mt: 1, ml: 1, mb: -2 }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Įkelta nuotraukų: {ad.properties.photos.length}.{" "}
              </Typography>
              <Typography variant="body2">
                Galima įkelti iki 20 nuotraukų (maks. failo dydis 10 MB).
              </Typography>
              {ad.properties.photos.length > 1 && (
                <Typography variant="body2">
                  Galite keisti nuotraukų eiliškumą vilkdami jas
                  {isMobile ? "" : " pele"}.
                </Typography>
              )}
            </Stack>
          ) : (
            <Stack spacing={1} sx={{ mt: 1, ml: 1, mb: -2 }}>
              <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                Nuotraukų neįkelta
              </Typography>
              <Typography variant="body2">
                Galima įkelti iki 20 nuotraukų (maks. failo dydis 10 MB).
              </Typography>
            </Stack>
          )}
          {/* </Grid> */}

          <DragDropPhotoList
            ad={ad}
            disableDrag={disableDrag}
            setDisableDrag={setDisableDrag}
            isDraft={true}
          />
          <Grid
            container
            sx={{ display: "flex", justifyContent: "space-between", mt: 0 }}
          >
            <Button variant="outlined" size="medium" onClick={handleBack}>
              Atgal
            </Button>
            <Button variant="contained" size="medium" onClick={handleNext}>
              Pirmyn
            </Button>
          </Grid>
        </Paper>
      </Box>
    </>
  );
}

export default FormStep2;
