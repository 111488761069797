import React, { useState } from "react";
import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { Box } from "@mui/system";
import EditIcon from "@mui/icons-material/Edit";
import EditDialog from "../MyAdEditor/EditDialog";

import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";
import {
  getAddress,
  getPrice,
  getPriceRange,
  getPriceSqm,
  getPriceSqmRange,
} from "../../../IndividualAd/BasicAdInfo/utils";
import { fetchAd } from "../../../IndividualAd/fetchOrSendAdData";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

export default function BasicAdInfo({ ad }) {
  const { dispatch, state } = useGlobalContext();
  const { isSmallOrMobile } = useCustomMediaQuery();

  const [openEditDialog, setOpenEditDialog] = useState(false);

  const { isRent, isPremises, isApartmentComplex } = useAdType(ad);
  const price = getPrice(ad, isRent);
  const priceSqm = getPriceSqm(ad);
  const address = getAddress(ad);
  const priceRange = getPriceRange(ad, isRent);
  const priceSqmRange = getPriceSqmRange(ad, isRent);

  function handleEditDialogClick() {
    setOpenEditDialog(true);
    fetchAd(dispatch, ad.id);
  }

  console.log("adLoading from BasicAdInfo", state.adLoading);

  return (
    <>
      <Grid
        container
        xs={12}
        sx={{
          display: "flex",
          border: "none",
          alignItems: "center",
          justifyContent: "space-between",
          mt: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            border: "none",
          }}
        >
          <Typography color="primary" variant="h6">
            {isPremises || isApartmentComplex ? priceRange : price}
          </Typography>
          <Typography sx={{ border: "none", ml: 1, mt: 0.5 }} variant="body2">
            ({isPremises || isApartmentComplex ? priceSqmRange : priceSqm} €/m²)
          </Typography>
        </Box>
        {isSmallOrMobile && isApartmentComplex ? (
          <IconButton
            size="small"
            sx={{ border: "solid lightgrey 1px", mr: 1 }}
            onClick={() => alert("delete button clicked")}
          >
            <EditIcon fontsize="small" />
          </IconButton>
        ) : (
          <Button
            variant="outlined"
            size="small"
            startIcon={<EditIcon color="action" />}
            sx={{
              mx: 0.5,
              width: "120px",
              border: "solid lightgrey 1px",
            }}
            color="inherit"
            onClick={handleEditDialogClick}
          >
            <Typography variant="caption">Redaguoti</Typography>
          </Button>
        )}
        <Backdrop
          open={openEditDialog && state.adLoading}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="primary" />
        </Backdrop>
        {openEditDialog && state?.ad?.id === ad.id && (
          <EditDialog
            open={openEditDialog}
            setOpen={setOpenEditDialog}
            ad={state.ad}
          />
        )}
      </Grid>
      <Grid container sx={{ border: "none", mt: 0 }}>
        <Typography variant="caption"> {address}</Typography>
      </Grid>
    </>
  );
}
