import React from "react";
import { Paper } from "@mui/material";
import useAdType from "./useAdType";
import BasicAdInfoFlatHouse from "./BasicAdInfoFlatHouse";
import BasicAdInfoLand from "./BasicAdInfoLand";
import BasicAdInfoPremises from "./BasicAdInfoPremises";
import BasicAdInfoParking from "./BasicAdInfoParking";
import BasicAdInfoApartmentComplex from "./BasicAdInfoApartmentComplex";

function BasicAdInfo({ ad, setShowMap, showMap }) {
  const {
    isFlat,
    isHouse,
    isLand,
    isPremises,
    isParking,
    isGarage,
    isApartmentComplex,
  } = useAdType(ad);

  console.log("isApartmentComplex", isApartmentComplex);
  return (
    <Paper sx={{ width: "100%" }}>
      {(isFlat || isHouse) && (
        <BasicAdInfoFlatHouse
          ad={ad}
          showMap={showMap}
          setShowMap={setShowMap}
        />
      )}
      {isLand && (
        <BasicAdInfoLand ad={ad} showMap={showMap} setShowMap={setShowMap} />
      )}
      {isPremises && (
        <BasicAdInfoPremises
          ad={ad}
          showMap={showMap}
          setShowMap={setShowMap}
        />
      )}
      {(isParking || isGarage) && (
        <BasicAdInfoParking ad={ad} showMap={showMap} setShowMap={setShowMap} />
      )}
      {isApartmentComplex && (
        <BasicAdInfoApartmentComplex
          ad={ad}
          showMap={showMap}
          setShowMap={setShowMap}
        />
      )}
    </Paper>
  );
}

export default BasicAdInfo;
