import { useState, useEffect } from "react";
import useCustomMediaQuery from "./useCustomMediaQuery";

const getWidth = () =>
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth;

const getHeight = () =>
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export default function useCurrentWidth() {
  // save current window width in the state object
  let [width, setWidth] = useState(getWidth());
  let [height, setHeight] = useState(getHeight());
  const { isMobile } = useCustomMediaQuery();

  // in this case useEffect will execute only once because
  // it does not have any dependencies.
  useEffect(() => {
    // timeoutId for debounce mechanism
    let timeoutId = null;
    const resizeListener = () => {
      // prevent execution of previous setTimeout
      clearTimeout(timeoutId);
      // change width from the state object after 50 milliseconds
      timeoutId = setTimeout(() => setWidth(getWidth()), 50);
      timeoutId = setTimeout(() => setHeight(getHeight()), 50);
    };
    // set resize listener
    window.addEventListener("resize", resizeListener);

    // clean up function
    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    setWidth(getWidth());
    setHeight(getHeight());
  }, [isMobile, window.orientation]);

  return { width, height };
}
