import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { useGlobalContext } from "../../context";
import { Facebook } from "@mui/icons-material";
import {
  fetchUserData,
  getContactAvatarData,
  loginWithFacebook,
} from "./fetchOrSendAccountsData";
import { crudSavedSearch } from "../Dashboard/fetchOrSendDashboardData";

function FacebookLoginButton() {
  const { userState, dispatchUser, dispatchDashboard } = useGlobalContext();
  const [isFbSdkLoaded, setIsFbSdkLoaded] = useState(false);

  useEffect(() => {
    // Load the Facebook SDK
    const loadFbSdk = () => {
      return new Promise((resolve) => {
        window.fbAsyncInit = function () {
          console.log("Initializing Facebook SDK");
          window.FB.init({
            appId: process.env.REACT_APP_FACEBOOK_APP_ID,
            xfbml: true,
            version: "v20.0",
          });
          window.FB.AppEvents.logPageView();
          setIsFbSdkLoaded(true); // Set the state to true once the SDK is initialized
          console.log("Facebook SDK initialized");
          resolve();
        };

        if (document.getElementById("facebook-jssdk")) {
          console.log("Facebook SDK script already loaded");
          // If the script is already loaded, manually call fbAsyncInit
          window.fbAsyncInit();
        } else {
          (function (d, s, id) {
            var js,
              fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) {
              console.log("Facebook SDK script already loaded");
              return;
            }
            js = d.createElement(s);
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode.insertBefore(js, fjs);
            console.log("Facebook SDK script added to the document");
          })(document, "script", "facebook-jssdk");
        }
      });
    };

    loadFbSdk();
  }, []);

  const handleFacebookLogin = () => {
    if (isFbSdkLoaded) {
      window.FB.login(
        (response) => {
          console.log("Facebook login response", response);
          if (response.authResponse) {
            loginWithFacebook(response.authResponse.accessToken, dispatchUser);
          } else {
            console.log("Failed to acquire Facebook access token");
          }
        },
        { scope: "public_profile,email" }
      );
    } else {
      console.log("Facebook SDK not loaded yet");
    }
  };

  useEffect(() => {
    if (userState.isAuthenticated && userState.userId) {
      fetchUserData(userState.userId, dispatchUser);
      getContactAvatarData(dispatchUser);
      crudSavedSearch(dispatchDashboard, "get");
    }
  }, [userState.userId]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        border: "none",
      }}
    >
      <Button
        variant="outlined"
        onClick={handleFacebookLogin}
        startIcon={<Facebook />}
        fullWidth
        sx={{
          borderColor: "lightgrey",
          "&:hover": {
            borderColor: "lightgrey",
          },
        }}
      >
        Jungtis su Facebook
      </Button>
    </Box>
  );
}

export default FacebookLoginButton;
