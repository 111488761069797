import React, { useState, useEffect, useRef } from "react";
import {
  Autocomplete,
  Chip,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useGlobalContext } from "../../../context";
import NewCalendarEntryDialog from "./NewCalendarEntry/NewCalendarEntryDialog";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CustomAvatar from "../../Layout/CustomAvatar";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";

function getAddress(entry) {
  let address = `${entry.street} ${!!entry?.house ? ` ${entry.house}` : ""}${
    !!entry.flat ? `-${entry.flat}` : ""
  }, ${entry.town}${!!entry.suburb && `, ${entry.suburb}`}
    `;
  return address;
}

export default function CalendarFilter({
  showFilter,
  setShowFilter,
  selectedFilterValue,
  setSelectedFilterValue,
}) {
  const { dashboardState, userState } = useGlobalContext();

  const [openNewEntryDialog, setOpenNewEntryDialog] = useState(false);
  const [calendarFilterOptions, setCalendarFilterOptions] = useState([]);

  const textFieldRef = useRef(null);

  useEffect(() => {
    setUpCalendarFilterOptions();
  }, [dashboardState.myCalendarEntries]);

  function setUpCalendarFilterOptions() {
    // prepare adOptions
    let ads = dashboardState.myCalendarEntries.map((entry) => entry.ad);
    let filteredEntries = dashboardState.myCalendarEntries.filter(
      ({ ad }, index) => !!ad && !ads.includes(ad, index + 1)
    );
    let adOptions = filteredEntries.map((entry) => {
      let title = `${entry.ad_type} (skelbimo ID: ${entry.ad})`;
      let subtitle = getAddress(entry);
      return {
        ad: entry.ad,
        owner: "",
        enquirer: "",
        image: entry.photo,
        type: "ad",
        category: "Skelbimai",
        title,
        subtitle,
        value: `${title} ${subtitle}`,
      };
    });
    console.log("adOptions", adOptions);

    // prepare ownerOptions
    let owners = dashboardState.myCalendarEntries.map((entry) => entry.owner);
    filteredEntries = dashboardState.myCalendarEntries.filter(
      ({ owner }, index) =>
        !!owner &&
        owner !== userState.userId &&
        !owners.includes(owner, index + 1)
    );
    let ownerOptions = filteredEntries.map((entry) => {
      let title = entry.owner_name;
      let subtitle = "Skelbimo savininkas";
      return {
        ad: "",
        owner: entry.owner,
        enquirer: "",
        type: "owner",
        category: "Skelbimų savininkai",
        title,
        subtitle,
        value: `${title} ${subtitle}`,
      };
    });
    console.log("ownerOptions", ownerOptions);

    // prepare enquirerOptions
    let enquirers = dashboardState.myCalendarEntries.map(
      (entry) => entry.enquirer
    );
    filteredEntries = dashboardState.myCalendarEntries.filter(
      ({ enquirer }, index) =>
        !!enquirer &&
        enquirer !== userState.userId &&
        !enquirers.includes(enquirer, index + 1)
    );
    let enquirerOptions = filteredEntries.map((entry) => {
      let title = entry.enquirer_name;
      let subtitle = "Interesantas";
      return {
        ad: "",
        owner: "",
        enquirer: entry.enquirer,
        type: "enquirer",
        category: "Interesantai",
        title,
        subtitle,
        value: `${title} ${subtitle}`,
      };
    });
    console.log("enquirerOptions", enquirerOptions);

    // concatenate options to set up calendarFilterOptions
    let options = adOptions.concat(ownerOptions, enquirerOptions);
    options.sort((a, b) => a.value.localeCompare(b.value));
    setCalendarFilterOptions(options);
  }

  useEffect(() => {
    if (showFilter) {
      console.log("textFieldRef", textFieldRef.current);
      textFieldRef.current.focus();
    }
  }, [showFilter]);

  function handleFilterClick() {
    setShowFilter(true);
  }

  function handleFilterDelete() {
    setSelectedFilterValue(null);
  }

  console.log("selectedFilterValue from CalendarFilter", selectedFilterValue);

  return (
    <>
      {showFilter ? (
        <Autocomplete
          openOnFocus
          autoHighlight
          onClose={() => setShowFilter(false)}
          options={calendarFilterOptions}
          value={selectedFilterValue}
          onChange={(event, value) => {
            setSelectedFilterValue(value);
            console.log("selectedFilterValue", value);
          }}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.value}
          size="small"
          sx={{ width: "100%" }}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={textFieldRef}
              // label="Filtras"
            />
          )}
          renderOption={(props, option) => {
            return (
              <Box
                component="li"
                {...props}
                sx={{ border: "none", pl: 0, my: 0 }}
              >
                <Stack>
                  <Typography variant="body2">{option.title}</Typography>
                  <Typography variant="caption">{option.subtitle}</Typography>
                </Stack>
              </Box>
            );
          }}
        />
      ) : (
        <>
          <Chip
            color={!!selectedFilterValue ? "primary" : "default"}
            icon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  border: "none",
                }}
              >
                {!!selectedFilterValue ? (
                  selectedFilterValue?.image ? (
                    <Box
                      component="img"
                      sx={{
                        height: "25px",
                      }}
                      src={getFullImgUrl(selectedFilterValue?.image)}
                      alt="ad thumbnail"
                    />
                  ) : (
                    <CustomAvatar
                      userId={
                        selectedFilterValue.enquirer ||
                        selectedFilterValue.owner
                      }
                      name={selectedFilterValue.title}
                      styleProps={{
                        height: "25px",
                        width: "25px",
                        mr: 1,
                        fontSize: 14,
                      }}
                    />
                  )
                ) : (
                  <FilterAltIcon fontSize="small" sx={{ border: "none" }} />
                )}
              </Box>
            }
            label={selectedFilterValue ? "" : "Filtras"}
            variant="outlined"
            onClick={handleFilterClick}
            onDelete={handleFilterDelete}
            deleteIcon={null}
            sx={{
              borderRadius: 1,
              display: "flex",
              "& .MuiChip-deleteIcon": {
                display: selectedFilterValue ? "block" : "none",
              },
            }}
          />
          {/* <NewCalendarEntryDialog
            openNewEntryDialog={openNewEntryDialog}
            setOpenNewEntryDialog={setOpenNewEntryDialog}
          />{" "} */}
        </>
      )}
    </>
  );
}
