import { Divider, Grid, Typography } from "@mui/material";
import * as adOptions from "../../../../utils/adOptions";
import useFilterType from "../hooks/useFilterType";
import { useFormikContext } from "formik";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import FormikBasicAutocomplete from "../../../SharedComponents/FormikBasicAutocomplete";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikCheckbox from "../../../SharedComponents/FormikCheckBox";
import FormikBasicMultipleSelect from "../../../SharedComponents/FormikBasicMultipleSelect";
import FormikAndOrFilter from "./FormikAndOrFilter";

function FiltersPremises() {
  const formik = useFormikContext();
  const params = useParamsFromUrl();
  const { isSale, isRent } = useFilterType(params);

  const priceOptions = isSale
    ? adOptions.PRICE_OPTIONS
    : adOptions.PRICE_OPTIONS_RENT;

  function handleMinYearChange(value) {
    !value && formik.setFieldValue("include_renovated", "");
  }

  function handleInstallationChange(value) {
    value !== "full" && formik.setFieldValue("condition", "");
  }

  function clearAllFilter(values, name) {
    values.length <= 1 && formik.setFieldValue(name + "_all", "");
  }

  return (
    <>
      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pagrindiniai filtrai
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        {/* minimum price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="price_from"
            label="Kaina (Eur, nuo)"
            options={
              !!+formik.values["price_to"]
                ? priceOptions.filter(
                    (option) => option <= +formik.values["price_to"]
                  )
                : priceOptions
            }
            type="number"
            removeSpinners={true}
          />
        </Grid>
        {/* max price setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="price_to"
            label="Kaina (Eur, iki)"
            options={priceOptions.filter(
              (option) => option >= +formik.values["price_from"]
            )}
            type="number"
            removeSpinners={true}
          />
        </Grid>

        {isRent && (
          <>
            <Grid item xs={12}>
              <FormikSelect
                name="min_rent_period"
                label="Orientacinis nuomos terminas"
                options={adOptions.MIN_RENT_PERIOD_CHOICES}
                enableClear={true}
              />
            </Grid>
          </>
        )}

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="area_from"
            label="Plotas (m², nuo)"
            options={
              !!+formik.values["area_to"]
                ? adOptions.SIZE_OPTIONS.filter(
                    (option) => option <= +formik.values["area_to"]
                  )
                : adOptions.SIZE_OPTIONS
            }
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="area_to"
            label="Plotas (m², iki)"
            options={adOptions.SIZE_OPTIONS.filter(
              (option) => option >= +formik.values["area_from"]
            )}
            type="number"
          />
        </Grid>

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="floor_from"
            label="Aukštas (nuo)"
            options={
              !!+formik.values["floor_to"]
                ? adOptions.FLOOR_OPTIONS.filter(
                    (option) => option <= +formik.values["floor_to"]
                  )
                : adOptions.FLOOR_OPTIONS
            }
            type="number"
          />
        </Grid>
        {/* max floor setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="floor_to"
            label="Aukštas (iki)"
            options={adOptions.FLOOR_OPTIONS.filter(
              (option) => option >= +formik.values["floor_to"]
            )}
            type="number"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Pastatas
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        {/* minimum construction year setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_year"
            label={`Statybos ${
              params.include_renovated ? "(renovacijos)" : ""
            } metai (nuo)`}
            options={
              !!+formik.values["max_year"]
                ? adOptions.YEAR_OPTIONS.filter(
                    (option) => option <= +formik.values["max_year"]
                  )
                : adOptions.YEAR_OPTIONS
            }
            onChange={handleMinYearChange}
            type="number"
          />
        </Grid>
        {/* max construction year setting field */}
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_year"
            label={`Statybos ${
              params.include_renovated ? "(renovacijos)" : ""
            } metai (iki)`}
            options={adOptions.YEAR_OPTIONS.filter(
              (option) => option >= +formik.values["min_year"]
            )}
            type="number"
          />
        </Grid>
        {!!formik.values.min_year && (
          <Grid
            container
            item
            justifyContent="right"
            sx={{ border: "none", px: 1 }}
          >
            <FormikCheckbox
              name="include_renovated"
              label="Įtraukti ir senesnės statybos renovuotus pastatus"
            />
          </Grid>
        )}
        <Grid container justifyContent="right" sx={{ px: 1 }}>
          <FormikCheckbox
            name="renovated_only"
            label="Rodyti tik renovuotus pastatus"
          />
        </Grid>

        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_floors"
            label="Aukštų sk. (nuo)"
            options={
              !!+formik.values["max_floors"]
                ? adOptions.FLOORS_OPTIONS.filter(
                    (option) => option <= +formik.values["max_floors"]
                  )
                : adOptions.FLOORS_OPTIONS
            }
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_floors"
            label="Aukštų sk. (iki)"
            options={adOptions.FLOORS_OPTIONS.filter(
              (option) => option >= +formik.values["min_floors"]
            )}
            type="number"
          />
        </Grid>

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="building_type"
            label="Pastato konstrukcija"
            options={adOptions.BUILDING_TYPE_CHOICES}
          />
        </Grid>
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="heating"
            label="Šildymas"
            options={adOptions.HEATING_CHOICES}
            onChange={(value) => clearAllFilter(value, "heating")}
          />
        </Grid>
        <FormikAndOrFilter name="heating_all" />
        <Grid container justifyContent="right" sx={{ px: 1 }}>
          <FormikCheckbox
            name="efficient_heating"
            label="Energetinis efektyvumas geresnis negu vidutinis"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormikBasicAutocomplete
            name="max_heating_bill"
            label={"Vid. 1 m² šildymo kaštai žiemą (€/m², iki)"}
            options={adOptions.HEATING_BILL_OPTIONS_PREMISES}
            type="number"
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Patalpos
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ border: "none" }}>
          <FormikBasicMultipleSelect
            name="premises_purpose"
            label="Patalpų paskirtis"
            options={adOptions.PREMISES_PURPOSE_CHOICES}
          />
        </Grid>

        {isSale && (
          <>
            <Grid item xs={6}>
              <FormikSelect
                name="installation"
                label="Baigtumas"
                options={adOptions.INSTALLATION_CHOICES}
                enableClear={true}
                onChange={handleInstallationChange}
              />
            </Grid>
            {formik.values.installation === "full" && (
              <Grid item xs={6}>
                <FormikSelect
                  name="condition"
                  label="Būklė"
                  options={adOptions.CONDITION_CHOICES}
                  enableClear={true}
                />
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} sx={{ border: "none" }}>
          <FormikBasicMultipleSelect
            name="equipment"
            label="Įranga"
            options={adOptions.EQUIPMENT_CHOICES_PREMISES}
            onChange={(value) => clearAllFilter(value, "equipment")}
          />
        </Grid>
        <FormikAndOrFilter name="equipment_all" />

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="premises"
            label="Papildomos patalpos"
            options={adOptions.PREMISES_CHOICES_PREMISES}
            onChange={(value) => clearAllFilter(value, "premises")}
          />
        </Grid>
        <FormikAndOrFilter name="premises_all" />

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="other_features"
            label="Kiti ypatumai"
            options={adOptions.OTHER_FEATURES_CHOICES_PREMISES}
            onChange={(value) => clearAllFilter(value, "other_features")}
          />
        </Grid>
        <FormikAndOrFilter name="other_features_all" />
      </Grid>

      <Grid item xs={12}>
        <Divider textAlign="center" sx={{ my: 2 }}>
          <Typography variant="subtitle1" color="primary">
            Aplinka
          </Typography>
        </Divider>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="min_plot_area"
            label="Sklypo plotas (arai, nuo)"
            options={
              !!+formik.values["max_plot_area"]
                ? adOptions.PLOT_AREA_OPTIONS.filter(
                    (option) => option <= +formik.values["max_plot_area"]
                  )
                : adOptions.PLOT_AREA_OPTIONS
            }
            type="number"
          />
        </Grid>
        <Grid item xs={6}>
          <FormikBasicAutocomplete
            name="max_plot_area"
            label="Sklypo plotas (arai; iki)"
            options={adOptions.PLOT_AREA_OPTIONS.filter(
              (option) => option >= +formik.values["min_plot_area"]
            )}
            type="number"
          />
        </Grid>
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="plot_infrastructure"
            label="Komunikacijos ir infrastruktūra"
            options={adOptions.INFRASTRUCTURE_CHOICES_PREMISES}
          />
        </Grid>
        <FormikAndOrFilter name="plot_infrastructure_all" />

        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="parking"
            label="Automobilio parkavimas"
            options={adOptions.PARKING_CHOICES_PREMISES}
            onChange={(value) => clearAllFilter(value, "parking")}
          />
        </Grid>
        <FormikAndOrFilter name="parking_all" />
        <Grid item xs={12}>
          <FormikBasicMultipleSelect
            name="security"
            label="Saugumas"
            options={adOptions.SECURITY_CHOICES_PREMISES}
            onChange={(value) => clearAllFilter(value, "security")}
          />
        </Grid>
        <FormikAndOrFilter name="security_all" />
      </Grid>
    </>
  );
}

export default FiltersPremises;
