import React from "react";
import { useHistory } from "react-router-dom";
import { useGlobalContext } from "../../../../context";
import "leaflet/dist/leaflet.css";
import "leaflet-draw";
import "leaflet-draw/dist/leaflet.draw-src.css";
import "leaflet-draw/dist/leaflet.draw-src.js";
import { Box, Chip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import reloadAds from "../../../../utils/getUrlFromParams";
import { useMap } from "react-leaflet";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import { LiaDrawPolygonSolid } from "react-icons/lia";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";

function PolygonSelectorChip() {
  const { state, dispatch } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const map = useMap();

  const params = useParamsFromUrl();
  const history = useHistory();

  const polygonChipX = !!params?.shape_type;
  // const polygonChipLabel = `${
  //   polygonChipX ? "Keisti ribas" : "Apibrėžti ribas"
  // }`;
  const polygonChipLabel = "Teritorija";

  function deletePolygon() {
    let polygon = { shape_type: "", shape_coords: "", radius: "" };
    let newParams = { ...params, ...polygon };
    delete newParams.page;

    reloadAds(newParams, dispatch, history);
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { drawPolygonMode: false },
    });
  }

  function handlePolygonSelect() {
    let mapBounds = map.getBounds();
    let mapZoom = map.getZoom();
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { mapBounds, mapZoom, drawPolygonMode: true },
    });
  }

  return (
    <>
      {/* {isMediumOrSmaller && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            zIndex: 1000,
          }}
        >
          <Button
            sx={{
              minWidth: 150,
              justifyContent: polygonChipX ? "left" : "center",
            }}
            onClick={handlePolygonSelect}
          >
            <Typography
              variant="body2"
              noWrap
              sx={{
                textTransform: "none",
                color: polygonChipX ? "primary" : "text.primary",
              }}
            >
              {polygonChipLabel}
            </Typography>
          </Button>
          {polygonChipX && (
            <IconButton
              size="small"
              // color="primary"
              sx={{ color: "primary.light" }}
              onClick={() => deletePolygon()}
            >
              <CancelIcon />
            </IconButton>
          )}
        </Box>
      )} */}
      <Chip
        variant="filled"
        clickable
        sx={{
          display: "flex",
          position: "absolute",
          zIndex: 1000,
          borderRadius: "3px",
          border: "solid lightgrey 1px",
          bgcolor: grey[50],
          color: polygonChipX ? "primary.light" : "default",
          borderColor: polygonChipX ? "primary.light" : "default",
          top: 10,
          right: 10,
          "& .MuiChip-label": { px: "8px" },
          // width: 110,
          "& .MuiChip-deleteIcon": {
            color: "primary.light",
            "&:hover": {
              color: "primary.dark",
            },
          },
          "&:hover": {
            backgroundColor: grey[200],
          },
        }}
        label={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ mt: 0.5, mr: isMobile && !polygonChipX ? 0 : 0.5 }}>
              <LiaDrawPolygonSolid fontSize={18} />
            </Box>
            {!isMobile && (
              <Typography variant="caption">{polygonChipLabel}</Typography>
            )}
          </Box>
        }
        disabled={state?.drawPolygonMode || state?.drawPlacesMode}
        onClick={handlePolygonSelect}
        onDelete={polygonChipX ? deletePolygon : null}
      />
    </>
  );
}

export default PolygonSelectorChip;
