import React, { useState, useRef } from "react";
import { Box, Button, Chip, DialogTitle } from "@mui/material";
import { useHistory } from "react-router-dom";
import DetailedFilter from "./DetailedFilter";
import reloadAds from "../../../../utils/getUrlFromParams";
import { useGlobalContext } from "../../../../context";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import DialogOrMenu from "./DialogOrMenu";
import { emptyFilterParams, nonCountableFilters } from "../utils/utils";
import { blue, grey } from "@mui/material/colors";

function DetailedFilterChip() {
  const { state, dispatch } = useGlobalContext();
  const history = useHistory();
  const params = useParamsFromUrl();
  const [anchorEl, setAnchorEl] = useState(null);
  const formikRef = useRef(null);

  const nonEmptyFilterParams = Object.keys(emptyFilterParams).filter(
    (key) => !!params[key]
  );
  const nonCountableFilterParams = Object.keys(nonCountableFilters).filter(
    (key) => !!params[key]
  );
  const filterCount =
    nonEmptyFilterParams.length - nonCountableFilterParams.length;

  function handleChipClick(e) {
    setAnchorEl(state.leftMenuRef?.current || e.currentTarget);
  }

  function handleXClick() {
    const newParams = { ...params, ...emptyFilterParams };
    delete newParams.page;
    reloadAds(newParams, dispatch, history);
    setAnchorEl(null);
    dispatch({
      type: "CHANGE_APP_STATE",
      payload: { townOptions: [], suburbOptions: [], streetOptions: [] },
    });
  }

  function handleSubmit(formikValues) {
    if (formikRef?.current?.dirty) {
      const newParams = { ...params, ...formikValues };
      delete newParams.page;
      reloadAds(newParams, dispatch, history);
    }
    setAnchorEl(null);
  }

  console.log("leftMenuRef", state?.leftMenuRef?.current);

  return (
    <>
      <Chip
        label={`Visi filtrai ${!!filterCount ? `(${filterCount})` : ""}`}
        onClick={handleChipClick}
        onDelete={!!filterCount ? handleXClick : null}
        variant="outlined"
        color={!!filterCount || anchorEl ? "primary" : "default"}
        sx={{
          borderRadius: 1,
          mr: 1,
          fontSize: { xs: "small", sm: "small" },
        }}
      />
      {anchorEl && (
        <DialogOrMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          formikRef={formikRef}
        >
          <DialogTitle
            sx={{
              backgroundColor: blue[100],
              display: "flex",
              justifyContent: "center",
            }}
          >
            Detali paieška
          </DialogTitle>
          <Box
            sx={{
              flexGrow: 1,
              overflowY: "auto",
              borderBottom: "1px solid lightgray",
              px: 1,
              backgroundColor: grey[50],
            }}
          >
            <DetailedFilter formikRef={formikRef} handleSubmit={handleSubmit} />
          </Box>
          <Box
            sx={{
              my: 2,
              pr: 3,
              display: "flex",
              justifyContent: "right",
            }}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setAnchorEl(null)}
              sx={{ mr: 1 }}
            >
              Atšaukti
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => formikRef.current.submitForm()}
            >
              Gerai
            </Button>
          </Box>
        </DialogOrMenu>
      )}
    </>
  );
}

export default DetailedFilterChip;
