import React, { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import { useGlobalContext } from "../../../context";

export default function FormikDispatcher({ panelName, edit }) {
  const formik = useFormikContext();
  const formikRef = useRef(null);
  formikRef.current = formik;

  const { dispatchDashboard, dispatchAdForm } = useGlobalContext();
  const dispatch = !!edit ? dispatchDashboard : dispatchAdForm;

  useEffect(() => {
    return () => {
      if (!edit) {
        dispatch({
          type: "CHANGE_FORMIK",
          payload: { [panelName]: formikRef.current.values },
        });
      }
      if (edit) {
        dispatch({
          type: "CHANGE_FORMIK",
          payload: { [panelName]: formikRef.current },
        });
        dispatch({
          type: "CHANGE_DASHBOARD_STATE",
          payload: { formikLoaded: true },
        });
      }
    };
  }, []);

  return <></>;
}
