import React from "react";
import Grid from "@mui/material/Grid";
import {
  getArea,
  getSize,
  getType,
} from "../../../IndividualAd/BasicAdInfo/utils";

const AdCardInfoParking = ({ ad }) => {
  const area = getArea(ad, 6, 6);
  const size = getSize(ad, 6, 6);
  const type = getType(ad, 12, 12);

  return (
    <Grid
      container
      sx={{ flexGrow: 1, display: "flex", alignContent: "space-around" }}
    >
      {area}
      {size}
      {type}
    </Grid>
  );
};

export default AdCardInfoParking;
