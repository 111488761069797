import React from "react";
import { Grid } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import {
  CONDITION_CHOICES,
  EQUIPMENT_CHOICES,
  FLAT_RENOVATED_CHOICES,
  INSTALLATION_CHOICES,
  OTHER_FEATURES_CHOICES,
  PARTIAL_AMENITIES_CHOICES,
  PREMISES_CHOICES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
// import FormPreview from "../../utils/FormPreview";
import { useGlobalContext } from "../../../../context";
import FormikDispatcher from "../../utils/FormikDispatcher";
import { FLATS_IN_FLOOR_OPTIONS } from "../../../../utils/adOptions";

function FlatPanel({ ad, handleSubmit, edit, formikRef, initiateSubmit }) {
  const { adFormState } = useGlobalContext();
  const panelName = "flatPanel";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getFlatInitialValues(ad, adOptions);
  const validationSchema = schema.flatValidationSchema;

  function handleInstallationChange(value, formik) {
    if (value !== "full") {
      formik.setFieldValue("condition", null);
      formik.setFieldValue("flat_renovated", []);
      formik.setFieldTouched("condition", false);
      formik.setFieldTouched("flat_renovated", false);
    }
  }

  function handleConditionChange(value, formik) {
    if (value === "newly_installed") {
      formik.setFieldValue("flat_renovated", []);
      formik.setFieldTouched("flat_renovated", false);
    }
  }

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="rooms"
                label="Kambarių skaičius *"
                type="number"
                inputProps={{
                  min: 1,
                  max: 100,
                  step: 1,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="area"
                label="Plotas (kv. m) *"
                type="number"
                inputProps={{
                  min: 1,
                  max: 10000,
                  step: 1,
                }}
              />
            </Grid>

            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="floor"
                label="Aukštas *"
                type="number"
                inputProps={{
                  min: 0,
                  max: 30,
                  step: 1,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikSelect
                name="flats_in_floor"
                label="Butų sk. laiptinės aukšte"
                options={FLATS_IN_FLOOR_OPTIONS}
                enableClear={true}
              />
              {/* <FormikTextField
                name="flats_in_floor"
                label="Butų sk. laiptinės aukšte"
                type="number"
                inputProps={{
                  min: 1,
                  max: 50,
                  step: 1,
                }}
              /> */}
            </Grid>
            <Grid container item spacing={1}>
              {formik.values.ad_type === "flat_sale" && (
                <>
                  <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                    <FormikSelect
                      name="installation"
                      label="Baigtumas *"
                      options={INSTALLATION_CHOICES}
                      onChange={(value) =>
                        handleInstallationChange(value, formik)
                      }
                    />
                  </Grid>
                  {formik.values.installation === "full" && (
                    <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                      <FormikSelect
                        name="condition"
                        label="Būklė *"
                        options={CONDITION_CHOICES}
                        onChange={(value) =>
                          handleConditionChange(value, formik)
                        }
                      />
                    </Grid>
                  )}
                </>
              )}
              {formik.values.installation === "full" &&
                formik.values.condition !== "newly_installed" &&
                !!formik.values.condition && (
                  <Grid container item>
                    <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                      <FormikMultipleSelect
                        name="flat_renovated"
                        label="Būste atnaujinta"
                        options={FLAT_RENOVATED_CHOICES}
                      />
                    </Grid>
                  </Grid>
                )}
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="equipment"
                label="Įranga "
                options={EQUIPMENT_CHOICES}
              />
            </Grid>

            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="premises"
                label="Papildomos patalpos"
                options={PREMISES_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="other_features"
                label="Kiti ypatumai "
                options={OTHER_FEATURES_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="partial_amenities"
                label="Daliniai patogumai"
                options={PARTIAL_AMENITIES_CHOICES}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}

export default FlatPanel;
