import { CITIES } from "../../../utils/defaults";

export function getValue(label, options) {
  // console.log("getValue label", label, "getValue options", options);
  let result = options.find((option) => option.label === label)?.value;
  // console.log("getValue result", label, result);
  return result || "";
}

export function getArray(input, options) {
  let result = [];
  if (!!input?.length) {
    let objKey = Object.keys(input[0])[0];
    // console.log("getArray input", input, "options", options);
    result = input.map((item) => ({
      [objKey]: getValue(Object.values(item)[0], options),
    }));
  }
  // console.log("getArray output", result);
  return result;
}

export function isCity(municipalityOptions, municipalityId) {
  let isCity = !!CITIES.includes(
    municipalityOptions.find((muni) => muni.value === municipalityId)?.label
  );
  return isCity;
}
