import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Stack, TextField } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { crudCalendarEntry } from "../../fetchOrSendDashboardData";
import DateTimePickerComponent from "../../../../utils/DateTimePickerComponent";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import useCalendarEntry from "../../MyAds/Appointment/useCalendarEntry";

export default function PrivateCalendarEntryChangeDialog({
  openPrivateEntryChangeDialog,
  setOpenPrivateEntryChangeDialog,
  initialAppointmentDateTime,
  initialCalendarEntry,
}) {
  const { dispatchDashboard } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  let today = new Date();
  today.setHours(0, 0, 0, 0);

  const [calendarError, setCalendarError] = useState(false);

  const [appointmentDateTime, setAppointmentDateTime] = useState(
    initialAppointmentDateTime
  );
  const [appointmentDateTimeChanged, setAppointmentDateTimeChanged] =
    useState(false);

  const [memo, setMemo] = useState();
  const { calendarEntry } = useCalendarEntry(
    memo,
    appointmentDateTime,
    initialCalendarEntry
  );

  function handleDelete() {
    setAppointmentDateTime(null);
    setMemo("");
  }

  function handleXClose(event, reason) {
    event.stopPropagation();
    if (!!reason && reason === "backdropClick") return;
    setOpenPrivateEntryChangeDialog(false);
  }

  function handleOkClose(event) {
    event.stopPropagation();
    if (initialCalendarEntry.memo !== memo || appointmentDateTimeChanged) {
      const method = !memo && !appointmentDateTime ? "delete" : "patch";
      crudCalendarEntry(dispatchDashboard, calendarEntry, method);
    }
    setOpenPrivateEntryChangeDialog(false);
  }

  return (
    <Dialog
      open={openPrivateEntryChangeDialog}
      onClose={handleXClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <DialogTitle>Keiskite kalendoriaus įrašą</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Grid container>
            <DateTimePickerComponent
              initialAppointmentDateTime={initialAppointmentDateTime}
              appointmentDateTime={appointmentDateTime}
              setAppointmentDateTime={setAppointmentDateTime}
              setCalendarError={setCalendarError}
              setAppointmentDateTimeChanged={setAppointmentDateTimeChanged}
            />

            <Grid item xs={12} sx={{ mt: 3 }}>
              <TextField
                label="Asmeninė pastaba mano kalendoriuje"
                disabled={!appointmentDateTime}
                value={calendarEntry.memo}
                onChange={(e) => setMemo(e.target.value)}
                fullWidth
                multiline
                rows={2}
                // maxRows={4}
                width="200px"
                inputProps={{ maxLength: 200, spellCheck: false }}
              />
            </Grid>
          </Grid>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mx: 2, mb: 2 }}>
        <Grid container sx={{ justifyContent: "space-between" }}>
          <Button variant="outlined" color="error" onClick={handleDelete}>
            Trinti
          </Button>
          <Stack spacing={1} direction="row">
            <Button onClick={handleXClose} color="primary" variant="outlined">
              Atšaukti
            </Button>
            <Button
              onClick={handleOkClose}
              disabled={calendarError}
              color="primary"
              variant="contained"
              autoFocus
            >
              Gerai
            </Button>
          </Stack>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
