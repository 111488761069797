import { useEffect } from "react";
import { useGlobalContext } from "../../../../context";
import { fetchSuburbOptions } from "../../fetchOrSendMainAppData";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";

export default function SuburbFilterDataProvider({ children }) {
  const { dispatch, state } = useGlobalContext();
  const params = useParamsFromUrl();

  useEffect(() => {
    let selectedTown = params?.town?.split(",");
    if (selectedTown?.length === 1) {
      if (!state.suburbOptionsLoaded && !state.suburbOptionsLoading) {
        fetchSuburbOptions(params, dispatch);
      }
    }
  }, [dispatch, params, state.suburbOptionsLoaded, state.suburbOptionsLoading]);

  return children;
}
