import React from "react";
import { Typography, List } from "@mui/material";
import useDescription from "./useDescription";

const AccordionDetailPremises = ({ ad }) => {
  const { premises } = useDescription(ad);

  return (
    premises && (
      <>
        <Typography variant="subtitle2">Pastatas </Typography>
        <List dense>
          {premises.buildingDescription}
          {premises.renovation}
          {premises.heating}
          {premises.energyEfficiency}
          {premises.heatingBill}
        </List>

        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          Patalpos
        </Typography>
        <List dense>
          {premises.premisesPurpose}
          {ad.properties.ad_type === "Parduodamos patalpos" && (
            <>
              {premises.installation}
              {ad.properties.installation === "Pilna apdaila" &&
                premises.premisesRenovation}
            </>
          )}
          {premises.equipment}
          {premises.premises}
          {premises.otherFeatures}
          {premises.partialAmenities}
        </List>

        {(premises.parking || premises.security || premises.infrastructure) && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Aplinka
            </Typography>
            <List dense>
              {premises.plotArea}
              {premises.infrastructure}
              {premises.security}
              {premises.parking}
            </List>
          </>
        )}

        {ad.properties.min_rent_period > 0 && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Nuomos sąlygos
            </Typography>
            <List dense>{premises.minRentPeriod}</List>
          </>
        )}
      </>
    )
  );
};

export default AccordionDetailPremises;
