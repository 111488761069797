import React, { useRef, useState } from "react";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
// import useKeyPress from "../../Layout/useKeyPress";
// import { useGlobalContext } from "../../../context";
import { getFullImgUrl } from "../../../utils/getFullImgUrl";
import { getDefaultPhotoUrl } from "../../../utils/getDefaultPhotoUrl";
import { DEFAULT_ASPECT_RATIO as ASPECT } from "../../../utils/defaults";

// export function thumbnail(url) {
//   let dotPosition = url.lastIndexOf(".");
//   return (
//     url.slice(0, dotPosition) + ".resized.thumbnail" + url.slice(dotPosition)
//   );
// }

export default function PhotoList({
  ad,
  activeSlide,
  setActiveSlide,
  setShowMap,
}) {
  // const { state } = useGlobalContext();
  let photos = ad.properties.photos;
  if (photos.length === 0) photos = [0];
  const stackRef = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  console.log("photos from photo list", photos);

  return (
    <>
      <Stack
        ref={stackRef}
        direction="row"
        sx={{
          display: "flex",
          overflowX: "auto",
          justifyContent: "flex-start",
          width: "100%",
        }}
      >
        {photos.map((photo, index) => {
          // let indexOfLastDot = photo.photo.lastIndexOf(".");
          // let photoThumb =
          //   photo.photo.slice(0, indexOfLastDot) +
          //   ".thumbnail" +
          //   photo.photo.slice(indexOfLastDot);
          if (index === activeSlide && !!stackRef?.current) {
            const numPhotos = photos.length;
            const scrollWidth = stackRef.current.scrollWidth;
            stackRef.current.scrollTo({
              left: ((index <= 1 ? 0 : index - 1) / numPhotos) * scrollWidth,
              behavior: "smooth",
            });
          }
          console.log(
            "photo_thumbnail from PhotoList",
            getFullImgUrl(photo?.photo_thumbnail)
          );
          return (
            <Box
              component="img"
              key={index}
              src={getFullImgUrl(photo.photo_thumbnail) || getDefaultPhotoUrl()}
              alt={index}
              onClick={(e) => {
                setActiveSlide(index);
                setShowMap(false);
              }}
              onLoad={(e) => setIsLoaded(true)}
              sx={{
                width: "24.8%",
                height: "auto",
                aspectRatio: String(ASPECT),
                objectFit: "cover",
                my: 0.5,
                mx: 0.1,
                p: 0,
                border:
                  activeSlide === index && isLoaded && photos.length > 1
                    ? "solid 4px"
                    : "none",
                borderColor: "primary.main",
                "&:hover": {
                  filter: "brightness(120%)",
                },
              }}
            />
          );
        })}
      </Stack>
    </>
  );
}
