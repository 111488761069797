import React from "react";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import FormikBasicAutocomplete from "../../../SharedComponents/FormikBasicAutocomplete";
import {
  PRICE_OPTIONS,
  PRICE_OPTIONS_PARKING,
  PRICE_OPTIONS_RENT,
  RENT_PRICE_OPTIONS_PARKING,
} from "../../../../utils/adOptions";
import { validationSchemaPriceFilter } from "../hooks/schema";
import useFilterType from "../hooks/useFilterType";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import FormPreview from "../../../AdForm/utils/FormPreview";
// import FormikAutocomplete from "../../../SharedComponents/FormikAutocomplete";

function PriceFilter({ formikRef, handleSubmit }) {
  const params = useParamsFromUrl();
  const {
    isFlat,
    isHouse,
    isLand,
    isPremises,
    isGarage,
    isParking,
    isApartmentComplex,
    isSale,
    isRent,
  } = useFilterType(params);

  const initialValues = {
    min_price: +params?.min_price || null,
    max_price: +params?.max_price || null,
    price_from: +params?.price_from || null,
    price_to: +params?.price_to || null,
  };

  let priceOptions = PRICE_OPTIONS;
  if (
    isRent &&
    (isFlat || isHouse || isLand || isPremises || isApartmentComplex)
  ) {
    priceOptions = PRICE_OPTIONS_RENT;
  }
  if (isSale && (isParking || isGarage)) {
    priceOptions = PRICE_OPTIONS_PARKING;
  }
  if (isRent && (isParking || isGarage)) {
    priceOptions = RENT_PRICE_OPTIONS_PARKING;
  }

  const minPrice =
    isPremises || isApartmentComplex ? "price_from" : "min_price";
  const maxPrice = isPremises || isApartmentComplex ? "price_to" : "max_price";

  return (
    <Grid container sx={{ border: "none", width: "100%" }}>
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchemaPriceFilter}
        enableReinitialize={true}
        validateOnChange={true}
        validateOnBlur={true}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form autoComplete="off" noValidate style={{ width: "100%" }}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <FormikBasicAutocomplete
                  // formik={formik}
                  name={minPrice}
                  label="Kaina nuo (€)"
                  // value={+formik.values[minPrice]}
                  options={
                    !!+formik.values[maxPrice]
                      ? priceOptions.filter(
                          (option) => option <= +formik.values[maxPrice]
                        )
                      : priceOptions
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <FormikBasicAutocomplete
                  // formik={formik}
                  name={maxPrice}
                  label="Kaina iki (€)"
                  // value={+formik.values[maxPrice]}
                  options={priceOptions.filter(
                    (option) => option >= +formik.values[minPrice]
                  )}
                  autoFocus={true}
                />
              </Grid>
            </Grid>
            {/* <FormPreview /> */}
          </Form>
        )}
      </Formik>
    </Grid>
  );
}

export default PriceFilter;
