import React from "react";
import { Typography } from "@mui/material";

export function getAdTitle(ad) {
  let [adTitle, ...object] = ad.properties.ad_type.split(" ");
  object = object.join(" ");
  let rooms = +ad.properties?.rooms;

  const isOneArea = ad.properties.area_from === ad.properties.area_to;
  let area;
  if (!Number(ad.properties.area_from)) {
    area = Number(ad.properties.area).toFixed(0);
  } else {
    area = isOneArea
      ? Number(ad.properties.area_from).toFixed(0)
      : `${Number(ad.properties.area_from).toFixed(0)}-${Number(
          ad.properties.area_to
        ).toFixed(0)}`;
  }

  let plotArea = object === "sklypas" && +ad.properties?.plot_area;
  console.log("area from AdTitle", area, typeof area);

  if (!!rooms) {
    adTitle += " " + rooms + (rooms === 1 ? " kambario " : " kambarių ");
  }
  if (!!area && area !== "0" && area !== "NaN") {
    adTitle += " " + area + " m² ploto ";
  }
  if (!!plotArea) {
    adTitle += " " + plotArea.toFixed(0) + " arų ploto ";
  }
  adTitle += object;

  if (ad.properties.ad_type === "Naujas daugiabučių projektas") {
    adTitle = ad.properties?.project_name
      ?.replaceAll("'", "")
      .replaceAll('"', "");
  }
  return adTitle;
}

function AdTitle({ ad }) {
  const adTitle = getAdTitle(ad);

  return (
    <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
      {adTitle}
    </Typography>
  );
}

export default AdTitle;
