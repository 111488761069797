import React from "react";
import { Grid, Typography } from "@mui/material";
import FormikMultipleSelect from "../../../SharedComponents/FormikMultipleSelect";
import FormikSelect from "../../../SharedComponents/FormikSelect";
import FormikTextField from "../../../SharedComponents/FormikTextField";
import useCustomMediaQuery from "../../../../hooks/useCustomMediaQuery";
import {
  CONDITION_CHOICES,
  EQUIPMENT_CHOICES_PREMISES,
  INSTALLATION_CHOICES,
  MIN_RENT_PERIOD_CHOICES,
  NUMBER_OF_PREMISES_CHOICES,
  OTHER_FEATURES_CHOICES_PREMISES,
  PREMISES_CHOICES_PREMISES,
  PREMISES_PURPOSE_CHOICES,
} from "../../../../utils/adOptions";
import { Form, Formik } from "formik";
import * as schema from "../formikValidationSchemas";
import * as init from "../formikInitialValues";
import * as adOptions from "../../../../utils/adOptions";
import { useGlobalContext } from "../../../../context";
// import FormPreview from "../../utils/FormPreview";
import FormikDispatcher from "../../utils/FormikDispatcher";

export default function PremisesPanel({
  ad,
  handleSubmit,
  edit,
  formikRef,
  initiateSubmit,
}) {
  const { isMobile } = useCustomMediaQuery();
  const { adFormState } = useGlobalContext();
  const panelName = "premisesPanel";
  const initialValues =
    (!edit && adFormState?.formik[panelName]) ||
    init.getPremisesInitialValues(ad, adOptions);
  const validationSchema = schema.premisesValidationSchema;

  // function handleSubmit(values) {
  //   crudAdDraft(dispatchAdForm, "PATCH", values, ad.id);
  //   handlePanelChange(1);
  // }

  // const premisesPurposeStr = formik.values.premises_purpose
  //   .map((x) => x.premises_purpose)
  //   .toString();

  // useEffect(() => {
  //   formik.values.premises_purpose.forEach(
  //     (purpose, index) => (purpose.rank = index + 1)
  //   );
  // }, [premisesPurposeStr]);

  function handleNumberOfPremisesChange(value, formik) {
    if (value === "one") {
      formik.setFieldValue("price_from", null);
      formik.setFieldTouched("price_from", false);
      formik.setFieldValue("price_to", null);
      formik.setFieldTouched("price_to", false);
      formik.setFieldValue("area_from", null);
      formik.setFieldTouched("area_from", false);
      formik.setFieldValue("area_to", null);
      formik.setFieldTouched("area_to", false);
    }
    if (value === "several") {
      formik.setFieldValue("price", null);
      formik.setFieldTouched("price", false);
      formik.setFieldValue("area", null);
      formik.setFieldTouched("area", false);
    }
  }

  // useEffect(() => {
  //   if (formik.values.number_of_premises === "one") {
  //     formik.setFieldValue("price_from", null);
  //     formik.setFieldTouched("price_from", false);
  //     formik.setFieldValue("price_to", null);
  //     formik.setFieldTouched("price_to", false);
  //     formik.setFieldValue("area_from", null);
  //     formik.setFieldTouched("area_from", false);
  //     formik.setFieldValue("area_to", null);
  //     formik.setFieldTouched("area_to", false);
  //   }
  //   if (formik.values.number_of_premises === "several") {
  //     formik.setFieldValue("price", null);
  //     formik.setFieldTouched("price", false);
  //     formik.setFieldValue("area", null);
  //     formik.setFieldTouched("area", false);
  //   }
  // }, [formik.values.number_of_premises]);

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <Form autoComplete="off" noValidate>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="premises_purpose"
                label="Patalpų paskirtis *"
                options={PREMISES_PURPOSE_CHOICES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <Typography sx={{ ml: 2.5, mt: isMobile ? 0 : 2 }}>
                Pagrindinė paskirtis:{" "}
                {!!formik.values.premises_purpose.length
                  ? PREMISES_PURPOSE_CHOICES.find(
                      (option) =>
                        option.value ===
                        formik.values.premises_purpose[0].premises_purpose
                    ).label.toLowerCase()
                  : "nepasirinkta"}
                {formik.values.premises_purpose.length > 1 && " ir kt."}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikSelect
                name="number_of_premises"
                label={`${
                  formik.values.ad_type === "premises_sale"
                    ? "Parduodamų"
                    : "Nuomojamų"
                } objektų kiekis *`}
                options={NUMBER_OF_PREMISES_CHOICES}
                onChange={(value) =>
                  handleNumberOfPremisesChange(value, formik)
                }
              />
            </Grid>

            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="floor_from"
                label="Aukštas nuo *"
                type="number"
                inputProps={{
                  min: 0,
                  max: 30,
                  step: 1,
                }}
              />
            </Grid>
            <Grid item xs={6} sm={3} sx={{ my: 1 }}>
              <FormikTextField
                name="floor_to"
                label="Aukštas iki *"
                type="number"
                inputProps={{
                  min: 0,
                  max: 30,
                  step: 1,
                }}
              />
            </Grid>

            {formik.values.number_of_premises !== "several" && (
              <Grid item xs={6} sx={{ my: 1 }}>
                <FormikTextField
                  name="area"
                  label="Plotas (kv. m) *"
                  type="number"
                  inputProps={{
                    min: 1,
                    max: 10000,
                    step: 1,
                  }}
                />
              </Grid>
            )}

            {formik.values.number_of_premises === "several" && (
              <>
                <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                  <FormikTextField
                    name="area_from"
                    label="Plotas nuo (kv. m) *"
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 10000,
                      step: 1,
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                  <FormikTextField
                    name="area_to"
                    label="Plotas iki (kv. m) *"
                    type="number"
                    inputProps={{
                      min: 1,
                      max: 10000,
                      step: 1,
                    }}
                  />
                </Grid>
              </>
            )}

            {formik.values.number_of_premises !== "several" && (
              <Grid item xs={6} sx={{ my: 1 }}>
                <FormikTextField
                  name="price"
                  label={`Kaina (Eur${
                    formik.values.ad_type === "premises_rent" ? "/mėn." : ""
                  }) *`}
                  type="number"
                />
              </Grid>
            )}

            {formik.values.number_of_premises === "several" && (
              <>
                <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                  <FormikTextField
                    name="price_from"
                    label={`Kaina nuo (Eur${
                      formik.values.ad_type === "premises_rent" ? "/mėn." : ""
                    }) *`}
                    type="number"
                  />
                </Grid>
                <Grid item xs={6} sm={3} sx={{ my: 1 }}>
                  <FormikTextField
                    name="price_to"
                    label={`Kaina iki (Eur${
                      formik.values.ad_type === "premises_rent" ? "/mėn." : ""
                    }) *`}
                    type="number"
                  />
                </Grid>
              </>
            )}
            {formik.values.ad_type === "premises_rent" && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikSelect
                  name="min_rent_period"
                  label="Minimalus nuomos terminas *"
                  options={MIN_RENT_PERIOD_CHOICES}
                />
              </Grid>
            )}
            {formik.values.ad_type === "premises_sale" && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikSelect
                  name="installation"
                  label="Baigtumas *"
                  options={INSTALLATION_CHOICES}
                />
              </Grid>
            )}
            {formik.values.installation === "full" && (
              <Grid item xs={12} sm={6} sx={{ my: 1 }}>
                <FormikSelect
                  name="condition"
                  label="Būklė *"
                  options={CONDITION_CHOICES}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="equipment"
                label="Papildoma įranga "
                options={EQUIPMENT_CHOICES_PREMISES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="premises"
                label="Papildomos patalpos"
                options={PREMISES_CHOICES_PREMISES}
              />
            </Grid>
            <Grid item xs={12} sm={6} sx={{ my: 1 }}>
              <FormikMultipleSelect
                name="other_features"
                label="Kiti ypatumai "
                options={OTHER_FEATURES_CHOICES_PREMISES}
              />
            </Grid>
          </Grid>
          {/* <FormPreview /> */}
          {!initiateSubmit && (
            <FormikDispatcher panelName={panelName} edit={edit} />
          )}
        </Form>
      )}
    </Formik>
  );
}
