import React, { useState } from "react";
import FormikTextField from "./FormikTextField";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

export default function FormikPasswordField({ name, label, ...props }) {
  const [showPassword, setShowPassword] = useState(false);

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <IconButton
          onClick={() => setShowPassword(!showPassword)}
          fontSize="small"
        >
          {showPassword ? (
            <Visibility fontSize="small" />
          ) : (
            <VisibilityOff fontSize="small" />
          )}
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <FormikTextField
      name={name}
      label={label}
      type={showPassword ? "text" : "password"}
      InputProps={inputProps}
      {...props}
    />
  );
}
