import React from "react";
import { Box, InputAdornment, Stack } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Formik } from "formik";
import * as Yup from "yup";
import { useGlobalContext } from "../../../context";
// import FormPreview from "../../AdForm/utils/FormPreview";
import FormikTextField from "../../SharedComponents/FormikTextField";
import { NPER } from "../../../utils/financialFunctions";
import FormikCheckbox from "../../SharedComponents/FormikCheckBox";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";

function MonthlyPayment() {
  const { isMobile } = useCustomMediaQuery();
  let Finance = require("financejs");
  let finance = new Finance();

  const { loanState, dispatchLoan } = useGlobalContext();
  const price = loanState.price;
  const loanSize = Math.floor(price * (1 - loanState.downPercent / 100));
  const interestRate = loanState.interestRate;
  const duration = loanState.duration;
  const maxDuration = loanState.maxDuration;
  const monthlyPayment = Math.round(
    // loanState.monthlyPayment ||
    finance.PMT(interestRate / 100 / 12, duration * 12, -loanSize)
  );
  const minMonthlyPayment = Math.floor(
    finance.PMT(
      Math.max(interestRate, 0.00001) / 100 / 12,
      maxDuration * 12,
      -loanSize
    )
  );

  // const fixMonthlyPayment = loanState.fixMonthlyPayment;

  const initialValues = { monthlyPayment, fixMonthlyPayment: false };
  const validationSchema = Yup.object({
    monthlyPayment: Yup.number()
      .positive("Turi būti teigiamas skaičius")
      .required("Turi būti skaičius")
      .min(minMonthlyPayment, "Įmoka nepakankama paskolai grąžinti"),
    fixMonthlyPayment: Yup.boolean(),
  });

  function handleSubmit(formikValues) {
    const monthlyPayment = Math.round(+formikValues.monthlyPayment);
    const fixMonthlyPayment = formikValues.fixMonthlyPayment;
    const duration =
      NPER(
        Math.max(interestRate, 0.00001) / 12 / 100,
        -monthlyPayment,
        loanSize,
        0,
        0
      ) / 12;
    dispatchLoan({
      type: "CHANGE_LOAN_STATE",
      payload: { monthlyPayment, duration, fixMonthlyPayment },
    });
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {(formik) => (
        <Stack direction={isMobile ? "row" : "column"}>
          <FormikTextField
            sx={{
              backgroundColor: loanState.fixMonthlyPayment
                ? grey[100]
                : "default",
              width: isMobile ? "48%" : "13ch",
            }}
            autoComplete="off"
            size="small"
            disabled={loanState.fixMonthlyPayment}
            variant="outlined"
            name="monthlyPayment"
            label="Mėnesinė įmoka"
            type="number"
            onChange={async (newValue) => {
              formik.setFieldValue("monthlyPayment", newValue, false);
              await formik.validateField("monthlyPayment");
              formik.submitForm();
            }}
            InputProps={{
              sx: { fontSize: "14px" },
              readOnly: formik.fixMonthlyPayment,
              endAdornment: <InputAdornment position="end">€</InputAdornment>,
              inputProps: {
                min: 0,
                max: price,
                step: 10,
              },
            }}
          />
          <Box
            sx={{
              justifyContent: "left",
              display: "flex",
              alignItems: "center",
              border: "none",
            }}
          >
            <FormikCheckbox
              name="fixMonthlyPayment"
              label="Užfiksuoti"
              onChange={() => formik.handleSubmit()}
              color="primary"
            />
          </Box>
          {/* <FormPreview /> */}
        </Stack>
      )}
    </Formik>
  );
}

export default MonthlyPayment;
