import React from "react";
import Grid from "@mui/material/Grid";
import {
  getAreaRange,
  getBuildingType,
  getConstructionYear,
  getEnergyEfficiency,
  getFloorRange,
  getInstallation,
  getPremisesPurpose,
} from "../../../IndividualAd/BasicAdInfo/utils";
import useAdType from "../../../IndividualAd/BasicAdInfo/useAdType";

const AdCardInfoPremises = ({ ad }) => {
  const { isSale, isRent } = useAdType(ad);

  const buildingType = getBuildingType(ad, 6, 6);
  const constructionYear = getConstructionYear(ad, 6, 6);
  const installation = getInstallation(ad, 6, 6);
  const energyEfficiency = getEnergyEfficiency(ad, 6, 6);
  const premisesPurpose = getPremisesPurpose(ad, 6, 6);
  const areaRange = getAreaRange(ad, 6, 6);
  const floorRange = getFloorRange(ad, 6, 6);

  return (
    <Grid
      container
      sx={{ flexGrow: 1, display: "flex", alignContent: "space-around" }}
    >
      {areaRange}
      {premisesPurpose}
      {floorRange}
      {buildingType}
      {constructionYear}
      {isSale && installation}
      {isRent && energyEfficiency}
    </Grid>
  );
};

export default AdCardInfoPremises;
