import React, { useState, useEffect, useRef } from "react";
import { Chip } from "@mui/material";
import { useGlobalContext } from "../../../../context";
import { useHistory } from "react-router-dom";
import reloadAds from "../../../../utils/getUrlFromParams";
import PriceFilter from "./PriceFilter";
import useParamsFromUrl from "../../hooks/useParamsFromUrl";
import CustomMenu from "../SharedComponents/CustomMenu";

const emptyPriceParams = {
  min_price: null,
  max_price: null,
  price_from: null,
  price_to: null,
};

export default function PriceFilterChip() {
  const { dispatch } = useGlobalContext();
  const formikRef = useRef(null);
  const history = useHistory();
  const params = useParamsFromUrl();

  const [chipLabel, setChipLabel] = useState("Kaina");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    changeChipLabel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    params?.min_price,
    params?.price_from,
    params?.max_price,
    params?.price_to,
  ]);

  const handleChipClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleXClick() {
    let newParams = { ...params, ...emptyPriceParams };
    delete newParams.page;
    reloadAds(newParams, dispatch, history);
    setAnchorEl(null);
  }

  function handleSubmit(formikValues) {
    console.log("formikRef", formikRef.current);
    if (formikRef.current.dirty) {
      let newParams = { ...params, ...formikValues };
      delete newParams.page;
      reloadAds(newParams, dispatch, history);
    }
    setAnchorEl(null);
  }

  function changeChipLabel() {
    let chipLabel = "Kaina";
    let minPrice = +params?.min_price || +params?.price_from || null;
    let maxPrice = +params?.max_price || +params?.price_to || null;

    if (!!minPrice && !!maxPrice)
      chipLabel =
        minPrice >= 1000
          ? `${Math.round(minPrice / 1000)}k-${Math.round(maxPrice / 1000)}k €`
          : `${minPrice}-${maxPrice} €`;
    if (!minPrice && !!maxPrice)
      chipLabel =
        maxPrice >= 1000
          ? `Iki ${Math.round(maxPrice / 1000)}k €`
          : `Iki ${maxPrice} €`;
    if (!!minPrice & !maxPrice)
      chipLabel =
        minPrice >= 1000
          ? `Nuo ${Math.round(minPrice / 1000)}k €`
          : `Nuo ${minPrice} €`;
    setChipLabel(chipLabel);
  }

  return (
    <>
      <Chip
        label={chipLabel}
        onClick={handleChipClick}
        onDelete={chipLabel === "Kaina" ? null : handleXClick}
        variant="outlined"
        color={chipLabel !== "Kaina" || anchorEl ? "primary" : "default"}
        sx={{ borderRadius: 1, mr: 1, fontSize: { xs: 10, sm: "small" } }}
      />
      <CustomMenu
        formikRef={formikRef}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
      >
        <PriceFilter formikRef={formikRef} handleSubmit={handleSubmit} />
      </CustomMenu>
    </>
  );
}
