import React from "react";
import { useGlobalContext } from "../../context";
import { Typography, Breadcrumbs, Link } from "@mui/material";
import reloadAds from "../../utils/getUrlFromParams";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

import useCustomMediaQuery from "../../hooks/useCustomMediaQuery";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const emptyStreetParams = { street: "" };
const emptyStreetSuburbParams = { street: "", suburb: "" };
const emptyStreetSuburbTownParams = { street: "", suburb: "", town: "" };

function BreadcrumbsComponent({ ad }) {
  const { routerState, dispatch } = useGlobalContext();
  const { isSmallOrMobile } = useCustomMediaQuery();
  const params = routerState.appParams;
  const history = useHistory();

  // const ad = state.ad;
  const locationArray = ["municipality", "town", "suburb", "street"];
  const locationObj = {};
  locationArray.forEach(
    (location) => (locationObj[location] = ad.properties[location]?.id)
  );

  const streetParams = { ...params, ...locationObj };
  delete streetParams.page;
  const locationParams = {};
  locationParams.street = streetParams;
  locationParams.suburb = { ...streetParams, ...emptyStreetParams };
  locationParams.town = { ...streetParams, ...emptyStreetSuburbParams };
  locationParams.municipality = {
    ...streetParams,
    ...emptyStreetSuburbTownParams,
  };

  console.log("locationArray from BreadCrumbs", locationArray, locationParams);
  console.log("ad from BreadCrumbsComponent", ad);

  return (
    <Breadcrumbs
      separator={
        <NavigateNextIcon
          sx={{ fontSize: "13px", mx: -0.5, mt: !isSmallOrMobile ? -0.2 : 0 }}
        />
      }
    >
      {locationArray
        .filter((location) => !!locationObj[location])
        .map((location) => {
          return (
            <Link
              key={location}
              underline="hover"
              color="textSecondary"
              onClick={() =>
                reloadAds(locationParams[location], dispatch, history)
              }
              style={{ cursor: "pointer" }}
            >
              <Typography variant={isSmallOrMobile ? "caption" : "body2"}>
                {ad.properties[location].name}
              </Typography>
            </Link>
          );
        })}
    </Breadcrumbs>
  );
}

export default BreadcrumbsComponent;
