import React from "react";
import { Typography, List } from "@mui/material";
import useDescription from "./useDescription";

const AccordionDetailParking = ({ ad }) => {
  const { parking } = useDescription(ad);

  return (
    parking && (
      <>
        <Typography variant="subtitle2">
          {ad.properties.ad_type.includes("garažas")
            ? "Garažas"
            : "Parkavimo vieta"}
        </Typography>
        <List dense>
          {parking.parkingDescription}
          {parking.parkingType}
          {parking.parkingRoof}
          {parking.parkingInstallation}
          {parking.electricalImprovements}
          {parking.security}
        </List>

        {["Nuomojamas garažas", "Nuomojama parkavimo vieta"].includes(
          ad.properties.ad_type
        ) && (
          <>
            <Typography variant="subtitle2" sx={{ mt: 2 }}>
              Nuomos sąlygos
            </Typography>
            <List dense>{parking.minRentPeriod}</List>
          </>
        )}
      </>
    )
  );
};

export default AccordionDetailParking;
