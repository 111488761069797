import React from "react";
import { AD_STATUS_OPTIONS } from "../../../../utils/defaults";
import { Typography } from "@mui/material";
import { red } from "@mui/material/colors";

export default function AdStatusText({ ad, sx, ...props }) {
  const statusText = AD_STATUS_OPTIONS.find(
    (option) => option.status === ad.properties.status
  )?.shortLabel;
  return (
    <>
      {ad.properties.status !== "active" && (
        <Typography
          variant="caption"
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor: [
              "rented",
              "sold",
              "expired",
              "deleted",
              "blocked",
            ].includes(ad.properties.status)
              ? red[500]
              : "orange",
            color: "white",
            px: 1,
            ...sx,
          }}
          {...props}
        >
          {statusText}
        </Typography>
      )}
    </>
  );
}
