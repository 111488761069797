const weekdayNames = {
  0: "Sekm",
  1: "Pirm",
  2: "Antr",
  3: "Treč",
  4: "Ketv",
  5: "Penkt",
  6: "Šešt",
};

// const fullWeekdayNames = {
//   0: "sekmadienis",
//   1: "pirmadienis",
//   2: "antradienis",
//   3: "trečiadienis",
//   4: "ketvirtadienis",
//   5: "penktadienis",
//   6: "šeštadienis",
// };

const fullWeekdayNamesKilminink = {
  0: "sekmadienio",
  1: "pirmadienio",
  2: "antradienio",
  3: "trečiadienio",
  4: "ketvirtadienio",
  5: "penktadienio",
  6: "šeštadienio",
};

const monthNames = {
  0: "Sausio",
  1: "Vasario",
  2: "Kovo",
  3: "Balandžio",
  4: "Gegužės",
  5: "Birželio",
  6: "Liepos",
  7: "Rugpjūčio",
  8: "Rugsėjo",
  9: "Spalio",
  10: "Lapkričio",
  11: "Gruodžio",
};

export default function humanizeDate(dateString) {
  if (!!dateString) {
    let plusIndex = dateString.indexOf("+");
    if (plusIndex > -1) dateString = dateString.slice(0, plusIndex) + "Z";
    let date = new Date(dateString);
    let today = new Date();
    let formattedDate;

    //   if today, show just time
    if (date.toDateString() === today.toDateString()) {
      formattedDate = new Intl.DateTimeFormat("lt", {
        timeStyle: "short",
      }).format(date);
      return formattedDate.toString();
    }
    //   if over the last 7 days, show weekday
    if (Math.abs((today - date) / (1000 * 60 * 60 * 24)) <= 7) {
      let time = new Intl.DateTimeFormat("lt", {
        timeStyle: "short",
      }).format(date);
      formattedDate = weekdayNames[date.getDay()] + ", " + time;
      return formattedDate;
    }
    //   else show month, day, time
    let time = new Intl.DateTimeFormat("lt", {
      timeStyle: "short",
    }).format(date);
    formattedDate =
      monthNames[date.getMonth()] + " " + date.getDate() + ", " + time;
    return formattedDate;
  } else {
    return dateString;
  }
}

export function humanizeCalendarDate(date) {
  let formattedDate = `${monthNames[date.getMonth()]} ${date.getDate()} d., ${
    fullWeekdayNamesKilminink[date.getDay()]
  }, įrašai`;
  return formattedDate;
}

export function getLocalDate(dateString) {
  // Convert the UTC date string to a Date object, which will be in the user's local time
  const date = new Date(dateString);
  const year = date.getFullYear();
  // getMonth() returns a zero-based index, so add 1 to get the correct month
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Ensure two digits
  const day = date.getDate().toString().padStart(2, "0"); // Ensure two digits

  return `${year}-${month}-${day}`;
}
