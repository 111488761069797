import { Slider } from "@mui/material";
import React from "react";
import useCustomMediaQuery from "../../../hooks/useCustomMediaQuery";
import { useGlobalContext } from "../../../context";
import { getMonthlyPaymentOrDuration } from "./Price";

function DownpaymentSlider() {
  const { loanState, dispatchLoan } = useGlobalContext();
  const { isMobile } = useCustomMediaQuery();
  const price = loanState.price;
  const minDownPercent = loanState.minDownPercent;
  const downPercent = loanState.downPercent;

  const marks = [
    {
      value: minDownPercent,
      label: `Min ${minDownPercent}%`,
    },
  ];

  function handleDownPercentChange(e, newValue) {
    let downPercent = Math.max(newValue, minDownPercent);
    let loanSize = price * (1 - downPercent / 100);
    let monthlyPayment, duration;
    ({ monthlyPayment, duration, loanSize } = getMonthlyPaymentOrDuration({
      loanState,
      loanSize,
    }));
    const downpayment = price - loanSize;
    downPercent = (1 - loanSize / price) * 100;
    dispatchLoan({
      type: "CHANGE_LOAN_STATE",
      payload: { downpayment, downPercent, loanSize, monthlyPayment, duration },
    });
  }

  return (
    <Slider
      sx={{ mt: 0.5 }}
      size={isMobile ? "small" : "medium"}
      value={Math.round(downPercent)}
      onChange={handleDownPercentChange}
      marks={marks}
      min={0}
      max={100}
      step={1}
      valueLabelDisplay="on"
      valueLabelFormat={(value) => `Pradinis įnašas ${value}%`}
    />
  );
}

export default React.memo(DownpaymentSlider);
